import React from "react";
import "./header.css";
import CTA from "./CTA";
import HeaderSkills from "./HeaderSkills";
import HeaderSocials from "./HeaderSocials";

const Header = () => {
  return (
    <header>
      <div className="container container__header">
        <h4>Hello, I'm</h4>
        <h1>Alcides Takeyoshi DML</h1>
        <h4>Front-End Developer</h4>
        <CTA />
        <HeaderSkills />
        <div className="sw-container">
          <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="1080.000000pt"
            height="1350.000000pt"
            viewBox="0 120 1080.000000 1500.000000"
            preserveAspectRatio="xMidYMid meet"
          >
            <g
              transform="translate(0.000000,1350.000000) scale(0.100000,-0.100000)"
              fill="#000000"
              stroke="none"
            >
              <path
                d="M5458 12114 c-3 -2 -22 -5 -42 -6 -20 -1 -36 -4 -36 -8 0 -4 -13 -5
            -30 -2 -19 3 -30 0 -30 -8 0 -8 -9 -11 -25 -8 -13 3 -22 2 -20 -3 6 -9 299 -4
            314 6 6 3 11 1 11 -4 0 -6 -14 -11 -31 -11 -18 0 -28 -4 -24 -10 4 -6 10 -8
            15 -5 4 3 13 -2 20 -10 7 -8 16 -12 21 -9 10 6 6 -52 -4 -63 -12 -11 -8 -43 5
            -43 8 0 9 -3 2 -10 -8 -8 -19 -7 -39 0 -39 15 -43 5 -5 -11 25 -11 30 -18 25
            -36 -4 -17 -1 -23 10 -23 12 0 15 -6 10 -20 -4 -13 -2 -20 7 -20 8 0 13 13 13
            35 0 21 -5 35 -12 35 -7 0 -13 7 -13 16 0 8 4 13 8 10 4 -2 14 0 22 5 12 8 12
            12 2 16 -6 2 -10 9 -6 14 3 5 -2 9 -10 9 -9 0 -16 5 -16 10 0 6 9 10 19 10 11
            0 23 -4 26 -10 10 -17 44 -11 54 9 11 21 5 31 -18 31 -9 0 -27 12 -40 25 -13
            14 -30 25 -39 25 -14 0 -14 2 -2 14 19 18 83 24 101 10 25 -20 42 -28 36 -17
            -8 12 14 23 45 23 13 0 18 -5 15 -14 -4 -10 -1 -13 9 -9 12 4 14 -1 11 -23 -2
            -16 -9 -29 -15 -28 -83 7 -81 6 -37 14 22 4 32 8 21 9 -13 1 -17 7 -13 21 3
            11 2 20 -3 20 -4 0 -10 -9 -13 -21 -3 -12 -10 -18 -15 -15 -6 3 -12 1 -14 -5
            -2 -7 -13 -9 -27 -4 -19 6 -21 9 -10 17 12 8 12 9 0 6 -28 -8 -23 -28 8 -34
            17 -4 31 -13 31 -21 0 -10 5 -11 18 -4 10 5 24 6 32 1 10 -6 2 -11 -24 -15
            -21 -4 -40 -14 -43 -21 -3 -8 -11 -14 -19 -14 -8 0 -14 -4 -14 -10 0 -5 9 -10
            20 -10 11 0 20 7 20 15 0 19 27 19 46 1 19 -20 18 -26 -7 -26 -13 0 -18 4 -13
            13 5 9 3 9 -7 -1 -12 -11 -12 -14 0 -22 12 -7 12 -12 3 -23 -7 -8 -10 -21 -6
            -30 4 -11 -4 -20 -23 -30 -35 -18 -115 -28 -133 -17 -15 9 -40 2 -40 -12 0 -5
            5 -6 10 -3 6 3 15 3 22 -1 8 -5 6 -11 -6 -20 -10 -7 -15 -17 -11 -23 9 -16 25
            -13 25 4 0 21 23 19 37 -5 16 -24 8 -29 -36 -24 -33 4 -60 27 -61 52 0 6 -4
            12 -10 12 -14 0 -13 -13 2 -28 9 -9 8 -12 -4 -12 -20 0 -42 26 -33 40 4 7 -1
            9 -17 5 -13 -3 -37 1 -53 9 -25 13 -26 16 -11 22 11 4 21 1 27 -7 6 -9 9 -10
            9 -2 0 17 -29 31 -51 26 -14 -4 -19 0 -19 16 0 24 10 27 28 9 9 -9 15 -9 23
            -1 9 9 5 14 -14 18 -14 4 -33 17 -41 30 -14 21 -13 24 2 37 9 8 31 15 48 16
            26 2 30 -1 29 -20 -1 -13 -8 -23 -16 -23 -8 0 -13 6 -11 13 3 19 -16 14 -27
            -7 -10 -17 -6 -19 39 -23 52 -3 58 0 38 21 -11 10 -10 15 3 24 8 7 18 20 22
            31 6 15 14 17 52 12 31 -5 45 -4 45 4 0 7 -8 9 -20 5 -11 -3 -20 -2 -20 4 0 5
            12 12 28 14 22 3 21 4 -10 6 -31 1 -35 4 -31 21 4 16 0 20 -20 20 -14 0 -29
            -5 -33 -12 -5 -7 0 -9 14 -6 15 4 22 0 26 -15 7 -29 0 -33 -22 -14 -20 18 -47
            23 -37 7 3 -5 -1 -10 -9 -10 -9 0 -16 -4 -16 -10 0 -14 13 -13 28 2 6 6 15 8
            19 3 5 -4 2 -10 -5 -12 -6 -3 -10 -9 -6 -14 3 -5 -4 -9 -15 -9 -11 0 -23 5
            -26 10 -3 6 -14 10 -23 10 -15 0 -15 -2 -2 -10 13 -9 13 -10 0 -10 -9 0 -23 9
            -32 20 -16 18 -16 20 0 29 16 10 16 10 0 11 -10 0 -18 -4 -18 -9 0 -6 -18 -21
            -40 -34 -30 -18 -37 -26 -28 -35 15 -15 32 -16 23 -2 -3 6 3 10 15 10 12 0 18
            5 15 10 -3 6 4 10 17 10 23 -1 23 -1 -4 -25 -28 -24 -38 -55 -18 -55 6 0 10
            -4 10 -10 0 -5 -9 -10 -19 -10 -11 0 -27 -7 -36 -16 -8 -9 -22 -13 -30 -10
            -25 9 -17 26 15 33 17 3 30 9 30 14 0 12 -15 11 -62 -3 -34 -10 -39 -15 -33
            -35 4 -16 1 -23 -10 -23 -8 0 -15 6 -15 13 0 8 -11 19 -25 25 -36 17 -32 33 5
            21 24 -9 33 -8 46 5 8 9 26 16 39 16 34 0 39 16 13 35 -21 15 -22 17 -5 26 9
            5 17 17 17 24 0 8 5 15 10 15 6 0 5 8 -2 20 -14 22 -33 26 -43 10 -3 -6 1 -7
            9 -4 11 4 16 0 16 -10 0 -9 -8 -16 -20 -16 -11 0 -20 5 -20 10 0 6 -7 10 -15
            10 -8 0 -12 -6 -9 -14 3 -7 -2 -16 -11 -20 -9 -3 -18 0 -21 9 -4 8 -12 15 -20
            15 -17 0 -17 -1 0 -35 8 -15 12 -34 9 -41 -3 -9 1 -14 12 -14 9 0 14 4 10 9
            -3 6 3 14 12 20 14 8 15 10 3 11 -8 0 -2 7 14 15 16 9 37 13 45 9 13 -5 11 -8
            -8 -13 -32 -8 -39 -31 -12 -38 32 -8 25 -23 -11 -23 -18 0 -43 -3 -55 -6 -16
            -4 -23 0 -29 18 -4 12 -7 29 -5 37 1 8 -3 17 -10 22 -10 6 -10 11 1 23 21 26
            2 32 -20 6 -25 -28 -25 -40 -2 -41 9 -1 1 -5 -18 -9 l-35 -7 32 -2 c25 -1 31
            -5 30 -21 -1 -23 -27 -28 -27 -5 0 14 -24 20 -58 16 -7 -1 -19 5 -28 13 -13
            14 -12 16 11 22 27 7 35 24 10 24 -8 0 -15 -4 -15 -10 0 -5 -7 -10 -15 -10 -9
            0 -12 6 -9 15 4 10 0 15 -10 15 -12 0 -15 -6 -10 -24 4 -17 1 -25 -13 -30 -10
            -3 -24 -9 -30 -12 -7 -3 -13 -1 -13 4 0 5 10 12 23 16 18 5 20 8 7 16 -8 5
            -24 9 -35 9 l-20 0 20 -9 c17 -7 17 -9 3 -9 -10 -1 -18 -7 -18 -13 1 -26 28
            -47 68 -54 23 -4 39 -11 36 -16 -3 -5 -13 -7 -21 -4 -9 4 -21 2 -28 -4 -15
            -12 -55 7 -55 25 0 6 -19 12 -42 14 -23 1 -45 4 -49 7 -4 2 -14 -3 -22 -11 -9
            -8 -23 -15 -31 -15 -9 0 -17 -4 -17 -10 -1 -5 -2 -15 -3 -22 -2 -18 -26 -22
            -26 -4 0 9 -7 16 -15 16 -12 0 -13 -4 -6 -19 8 -15 4 -25 -26 -55 -37 -36 -57
            -44 -68 -26 -10 16 -22 12 -40 -16 -17 -26 -17 -27 3 -22 16 5 20 1 20 -16 -1
            -11 -6 -27 -11 -33 -5 -7 -9 -28 -9 -48 0 -19 -5 -35 -10 -35 -6 0 -8 7 -4 15
            3 8 1 15 -5 15 -6 0 -8 7 -5 16 4 12 2 15 -9 10 -10 -4 -15 1 -16 17 -1 20 -2
            20 -6 2 -7 -28 -23 -43 -23 -22 0 9 -5 17 -11 17 -6 0 -9 -7 -6 -15 3 -7 -2
            -24 -13 -37 -13 -16 -15 -28 -9 -40 5 -10 6 -18 2 -18 -4 0 -2 -4 5 -9 10 -6
            9 -10 -7 -14 -23 -6 -27 3 -10 20 8 8 7 13 -2 18 -8 5 -10 4 -5 -4 4 -6 1 -13
            -6 -16 -7 -3 -14 -22 -14 -43 -2 -37 -1 -38 32 -36 24 2 34 -1 34 -11 0 -16
            -25 -21 -34 -6 -10 15 -55 10 -49 -5 3 -8 11 -14 19 -14 8 0 14 -4 14 -10 0
            -17 -38 -11 -50 8 -11 16 -11 16 -6 0 3 -12 0 -18 -10 -18 -12 0 -13 -4 -4
            -21 13 -24 4 -41 -10 -19 -8 12 -10 10 -10 -9 0 -19 4 -22 21 -18 15 4 19 2
            15 -10 -3 -8 -6 -29 -7 -46 0 -18 -3 -26 -6 -19 -2 6 -9 10 -14 6 -5 -3 -9 -1
            -9 5 0 5 5 13 10 16 6 3 10 14 9 23 0 11 -2 12 -6 4 -2 -6 -9 -10 -14 -6 -5 3
            -9 -4 -9 -16 0 -12 -4 -18 -10 -15 -5 3 -10 -3 -10 -15 0 -12 4 -19 9 -16 5 3
            14 0 20 -8 15 -18 -5 -26 -36 -15 -23 8 -33 -4 -13 -16 13 -8 13 -55 1 -55 -5
            0 -12 8 -14 18 -3 9 -5 -29 -5 -85 0 -55 -3 -106 -6 -112 -12 -18 -29 -13 -20
            7 4 9 8 43 9 74 1 47 -1 55 -12 46 -8 -6 -11 -19 -7 -30 6 -20 -12 -103 -26
            -123 -4 -5 -14 -36 -21 -69 -11 -52 -11 -59 4 -68 12 -6 17 -22 16 -56 0 -31
            -3 -41 -8 -28 -6 17 -10 14 -30 -19 -12 -21 -21 -47 -20 -59 3 -49 -2 -87 -11
            -81 -6 4 -10 -8 -10 -29 0 -19 -5 -47 -10 -61 -6 -15 -11 -49 -12 -76 -1 -27
            -6 -49 -10 -49 -4 0 -8 -28 -8 -62 0 -45 -3 -59 -10 -48 -7 11 -10 7 -11 -17
            -4 -98 -21 -213 -30 -208 -4 3 -10 -23 -12 -57 -3 -35 -12 -124 -21 -198 -8
            -74 -15 -151 -16 -170 -1 -27 -3 -31 -9 -15 -7 16 -9 14 -14 -14 -4 -19 -7
            -51 -7 -72 0 -21 -5 -41 -11 -44 -18 -12 -4 -28 27 -30 l30 -2 -2 114 c-2 79
            0 112 8 107 7 -4 9 8 6 37 -3 35 0 47 16 61 12 10 26 17 32 18 17 0 42 -69 49
            -138 7 -73 13 -94 26 -87 5 3 7 -11 6 -31 -2 -44 20 -59 26 -17 2 18 4 12 5
            -17 0 -25 -2 -39 -5 -32 -9 23 -23 12 -23 -19 0 -16 4 -28 9 -25 5 3 12 -1 15
            -10 3 -8 2 -13 -3 -10 -5 3 -7 -30 -4 -77 5 -72 2 -91 -21 -152 -49 -127 -150
            -254 -326 -409 -160 -140 -418 -394 -503 -496 -40 -47 -74 -87 -77 -90 -22
            -22 -78 -103 -125 -180 -99 -164 -121 -213 -176 -405 -1 -5 1 -63 5 -128 9
            -125 15 -137 66 -137 34 0 131 55 190 107 30 27 73 62 94 78 72 53 377 200
            476 229 52 15 104 31 115 35 23 10 157 -1 228 -18 126 -30 335 -165 338 -220
            1 -9 4 -5 8 9 7 22 9 19 19 -25 6 -30 7 -54 1 -61 -5 -7 -2 -44 10 -100 15
            -65 22 -83 27 -67 3 13 10 20 15 17 5 -3 9 0 9 5 0 6 5 11 10 11 16 0 12 -30
            -5 -36 -8 -4 -15 -14 -15 -24 0 -10 -5 -20 -11 -22 -8 -3 -5 -29 9 -88 12 -47
            26 -116 32 -155 6 -38 20 -99 29 -135 41 -147 64 -312 71 -510 1 -8 5 -42 11
            -75 5 -33 11 -89 13 -125 7 -96 24 -317 30 -380 3 -30 8 -80 10 -110 3 -30 10
            -98 16 -150 30 -264 43 -389 46 -430 1 -25 8 -65 14 -89 6 -24 11 -60 11 -80
            1 -20 7 -74 15 -120 8 -46 14 -111 14 -145 0 -34 7 -88 15 -120 11 -41 13 -68
            6 -91 -5 -18 -5 -37 -1 -42 5 -4 11 -35 14 -68 3 -33 7 -76 10 -95 7 -62 37
            -472 41 -555 2 -44 6 -143 9 -220 4 -77 9 -170 12 -207 l5 -68 -55 6 c-58 7
            -139 -12 -116 -26 11 -7 10 -15 -1 -42 -7 -18 -14 -35 -15 -38 -4 -28 -29 -88
            -34 -85 -7 5 -23 -32 -21 -47 1 -5 -3 -8 -9 -8 -5 0 -11 -24 -11 -52 l-2 -53
            -8 40 c-5 26 -6 4 -4 -65 3 -69 11 -127 24 -170 12 -36 21 -91 21 -123 0 -51
            -7 -71 -63 -180 -81 -156 -191 -339 -242 -401 -59 -73 -228 -257 -325 -355
            -101 -101 -189 -172 -357 -284 -68 -45 -123 -88 -123 -96 0 -19 43 -6 110 34
            173 104 194 114 216 109 36 -9 132 15 163 42 16 13 57 40 92 60 35 20 78 44
            94 54 17 9 60 37 97 61 85 56 121 79 177 108 25 12 62 36 82 52 20 16 41 29
            46 29 11 0 135 121 270 263 85 90 109 122 147 202 27 56 66 117 96 150 28 30
            50 63 50 74 0 10 9 26 20 36 11 10 20 26 20 35 0 30 73 118 117 141 50 25 113
            70 113 80 0 5 6 9 14 9 20 0 125 124 174 206 23 39 54 107 68 150 14 43 39
            106 56 139 17 33 34 78 38 100 4 22 19 99 34 170 15 72 34 175 41 230 17 116
            29 196 45 280 7 33 13 92 15 130 2 39 6 97 10 130 3 33 8 123 9 200 2 77 9
            158 14 180 6 22 13 92 17 155 3 63 9 178 14 255 5 77 6 145 4 152 -3 7 0 77 7
            155 6 79 13 233 16 343 8 331 11 376 29 475 9 52 21 118 26 145 22 133 39 219
            53 267 19 72 36 145 36 162 0 8 5 27 12 43 13 32 49 217 78 406 20 134 50 215
            90 252 15 14 43 21 98 26 42 4 99 15 127 24 63 22 290 26 350 6 68 -22 263
            -123 309 -160 24 -20 57 -44 72 -53 43 -26 64 -40 114 -74 25 -17 50 -33 56
            -35 7 -2 55 -44 107 -94 52 -49 123 -112 157 -139 l62 -49 43 21 c61 29 86 81
            93 188 3 48 0 103 -8 135 -12 52 -139 328 -165 359 -7 8 -15 28 -19 43 -17 73
            -263 407 -439 597 -23 25 -89 101 -147 170 -58 69 -131 151 -163 184 -85 86
            -184 237 -200 307 -4 15 0 48 8 75 18 59 18 57 4 48 -7 -4 -10 17 -7 70 2 42
            0 76 -3 76 -14 0 -59 -59 -68 -90 -10 -31 -10 -28 -6 26 3 33 19 96 35 140 35
            94 37 132 15 454 -15 225 -22 271 -36 249 -6 -10 -10 -9 -15 5 -4 10 -2 21 4
            25 6 3 17 35 25 71 18 77 31 495 18 555 l-8 40 -8 -32 c-7 -32 -35 -67 -35
            -45 0 6 5 12 11 14 6 2 11 24 11 48 -1 25 4 47 9 51 6 3 11 47 12 97 0 51 5
            92 10 92 4 0 0 13 -10 30 -13 23 -15 32 -5 42 8 8 12 8 12 1 0 -23 38 0 44 25
            3 15 8 37 12 50 4 13 2 22 -5 22 -6 0 -11 -12 -11 -26 0 -14 -4 -23 -10 -19
            -5 3 -10 17 -10 31 0 13 5 24 10 24 6 0 10 8 10 18 0 15 2 15 11 2 8 -12 9 -9
            3 10 -4 14 -6 42 -6 63 1 20 -2 37 -7 37 -14 0 -22 -29 -15 -58 5 -25 4 -25
            -9 -9 -8 9 -12 28 -10 41 3 13 0 27 -6 30 -6 4 -11 2 -11 -3 0 -6 -7 -11 -15
            -11 -10 0 -15 10 -15 27 0 21 3 24 12 15 18 -18 41 -15 34 4 -3 8 -2 12 4 9 6
            -3 10 3 10 14 0 25 -30 18 -31 -8 0 -12 -2 -11 -8 4 -5 11 -4 22 0 23 5 2 9
            10 9 19 0 10 6 12 20 8 16 -5 20 -2 19 17 0 13 -3 17 -6 11 -2 -7 -16 -13 -29
            -13 -13 0 -24 -5 -24 -12 0 -6 -2 -9 -5 -7 -10 11 8 50 22 45 10 -4 13 0 11
            16 -2 12 -9 23 -16 24 -7 1 -8 0 -2 -3 19 -9 10 -24 -12 -18 -17 6 -20 4 -15
            -9 4 -9 2 -16 -3 -16 -6 0 -10 5 -10 11 0 6 -7 9 -15 5 -9 -3 -15 0 -15 9 0 8
            9 15 20 15 11 0 20 7 20 16 0 8 4 13 9 10 5 -3 14 1 20 8 10 12 9 14 -6 12
            -10 -2 -22 2 -26 8 -6 7 -7 4 -3 -6 7 -23 -23 -40 -47 -27 -9 6 -17 18 -17 27
            1 14 4 13 20 -7 15 -17 20 -19 20 -8 0 9 -5 19 -10 22 -17 10 -2 24 20 18 11
            -3 20 -1 20 4 0 12 -48 15 -55 3 -8 -12 -25 -13 -25 -1 0 11 38 24 63 21 9 -1
            17 4 17 9 0 6 -9 11 -20 11 -20 0 -20 1 -4 25 10 15 12 25 5 25 -6 0 -11 -5
            -11 -11 0 -5 -4 -7 -10 -4 -5 3 -10 17 -10 30 0 13 -4 27 -10 30 -16 10 -11
            28 6 21 16 -6 14 0 -18 47 -11 15 -24 25 -29 22 -5 -4 -9 -1 -9 4 0 6 -5 11
            -11 11 -5 0 -7 -5 -4 -11 4 -5 9 -31 11 -56 4 -51 11 -64 23 -45 6 10 10 10
            20 -2 9 -11 9 -16 0 -22 -8 -5 -5 -12 10 -23 22 -17 21 -51 -1 -51 -6 0 -8 6
            -5 14 3 8 -1 22 -8 33 -8 10 -15 13 -15 7 0 -5 6 -16 13 -23 10 -11 9 -13 -3
            -9 -9 3 -14 16 -13 31 1 15 -3 26 -9 24 -16 -3 -40 86 -25 95 9 6 9 8 0 8 -7
            0 -13 8 -13 19 0 12 -9 20 -27 24 -16 3 -36 9 -46 12 -13 6 -16 3 -11 -10 6
            -15 4 -16 -14 -6 -20 11 -22 9 -22 -17 0 -15 5 -33 12 -40 9 -9 8 -12 -5 -12
            -10 0 -22 11 -27 25 -5 14 -14 25 -20 25 -18 0 -11 20 8 21 10 0 12 3 4 6 -6
            2 -10 9 -7 13 3 5 12 7 20 3 9 -3 22 0 30 7 8 7 22 10 30 6 21 -8 25 -7 25 2
            0 4 -3 7 -7 7 -5 0 -13 3 -19 7 -14 9 -77 -2 -86 -15 -4 -7 -11 -7 -22 2 -21
            17 -20 33 0 29 36 -6 21 38 -18 56 -10 5 -10 7 0 12 10 5 10 9 1 20 -9 11 -8
            14 4 14 18 0 41 -35 33 -49 -7 -11 22 -36 31 -27 3 3 1 6 -6 6 -9 0 -8 5 3 15
            9 9 26 14 41 11 14 -3 23 -2 21 2 -2 4 -19 9 -37 10 -19 2 -43 12 -54 22 -12
            11 -29 20 -37 20 -24 0 -33 -38 -14 -59 18 -19 21 -36 6 -26 -5 3 -12 -6 -16
            -20 -7 -28 -48 -36 -58 -10 -3 8 -12 15 -21 15 -8 0 -15 5 -15 10 0 13 4 13
            45 -4 40 -17 48 -5 10 12 -14 6 -25 17 -25 23 0 7 9 5 25 -5 22 -14 25 -14 25
            -1 0 8 -10 17 -23 20 -22 6 -65 75 -46 75 5 0 9 5 9 10 0 6 -13 10 -29 10 -17
            0 -33 5 -36 10 -4 6 9 10 30 10 28 0 38 -5 42 -20 3 -11 10 -20 15 -20 6 0 7
            8 4 17 -4 9 -1 20 6 25 9 5 6 8 -10 8 -15 0 -21 4 -16 12 5 7 3 8 -6 3 -7 -4
            -28 -10 -47 -12 -24 -3 -33 0 -33 12 0 8 -4 15 -10 15 -5 0 -10 -4 -10 -9 0
            -6 5 -13 10 -16 16 -10 12 -35 -6 -35 -9 0 -14 6 -12 12 3 7 -1 19 -8 26 -11
            11 -18 10 -39 -4 -16 -10 -25 -12 -25 -5 0 6 7 14 15 17 8 4 15 14 15 23 -1
            14 -2 14 -11 -1 -5 -10 -17 -18 -26 -18 -13 0 -13 3 -3 15 10 12 10 15 -1 16
            -39 2 -66 6 -89 12 -24 7 -24 7 -2 -4 12 -7 22 -15 22 -20 0 -8 -40 0 -56 12
            -15 12 -54 -18 -47 -37 4 -12 0 -15 -18 -14 -37 3 -45 1 -29 -10 13 -8 12 -10
            -3 -10 -10 0 -20 7 -23 15 -7 17 -44 21 -44 5 0 -5 -10 -10 -22 -10 -22 1 -22
            1 4 15 19 11 25 20 21 35 -3 11 -9 23 -14 26 -20 12 -6 19 40 20 43 2 49 -1
            48 -17 -1 -10 -5 -19 -10 -19 -4 0 -5 -4 -2 -10 4 -6 -5 -10 -21 -10 -37 0
            -28 -6 21 -13 25 -4 35 -3 26 2 -11 7 -7 14 20 31 l34 21 -30 -7 c-28 -6 -29
            -5 -11 9 19 14 16 15 -35 26 -30 7 -130 14 -224 17 -93 2 -182 6 -196 9 -15 2
            -29 2 -31 -1z m656 -100 c3 -9 4 -20 0 -25 -3 -5 0 -9 5 -9 6 0 11 -4 11 -10
            0 -5 -7 -10 -15 -10 -9 0 -15 9 -15 25 0 14 -4 25 -10 25 -5 0 -10 -5 -10 -11
            0 -7 -11 -9 -30 -5 -20 3 -29 2 -25 -4 9 -15 -21 -23 -42 -11 -16 9 -16 10 -2
            11 9 0 19 7 23 15 6 18 43 20 48 3 3 -8 9 -6 19 5 18 21 35 21 43 1z m-474
            -14 c0 -5 9 -10 20 -10 11 0 20 -4 20 -10 0 -11 -6 -12 -37 -4 -22 6 -33 34
            -13 34 6 0 10 -4 10 -10z m-637 -56 c-1 -5 -9 -11 -19 -12 -11 -3 -14 0 -9 8
            6 9 -1 10 -24 5 -36 -8 -41 5 -8 21 23 11 64 -4 60 -22z m867 27 c0 -10 -30
            -24 -37 -17 -3 3 -2 9 2 15 8 13 35 15 35 2z m133 -16 c26 -4 47 -11 47 -16 0
            -13 -38 -11 -46 2 -3 6 -15 9 -25 6 -10 -3 -22 -1 -25 4 -3 5 -13 5 -24 -1
            -24 -13 -50 -13 -50 0 0 6 9 10 19 10 11 0 23 5 26 10 4 7 11 8 18 1 7 -5 33
            -12 60 -16z m77 15 c0 -5 -12 -10 -26 -10 -14 0 -23 4 -19 10 3 6 15 10 26 10
            10 0 19 -4 19 -10z m100 0 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10
            16 10 5 0 9 -4 9 -10z m-375 -19 c-8 -13 -35 -15 -35 -2 0 5 8 11 18 13 21 5
            26 2 17 -11z m-747 -13 c-7 -19 -38 -22 -38 -4 0 10 9 16 21 16 12 0 19 -5 17
            -12z m1039 6 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m53 -4 c0
            -5 -4 -10 -10 -10 -5 0 -7 -7 -3 -16 4 -12 14 -15 41 -10 32 5 34 4 20 -10 -9
            -9 -29 -17 -45 -17 -26 -2 -28 -4 -18 -24 5 -13 13 -23 18 -23 4 0 7 -5 7 -11
            0 -6 -12 -9 -27 -7 -25 3 -28 7 -25 35 2 17 -2 34 -8 38 -7 4 -5 12 5 22 9 9
            14 22 11 29 -3 9 2 14 14 14 11 0 20 -4 20 -10z m130 0 c0 -6 -12 -10 -27 -11
            -16 0 -40 -2 -55 -4 -27 -3 -40 10 -17 17 26 8 99 6 99 -2z m-420 -9 c0 -5 -6
            -12 -12 -14 -7 -3 -2 -6 12 -7 23 -1 23 -2 5 -9 -28 -11 -51 -3 -52 20 -1 14
            5 19 23 19 13 0 24 -4 24 -9z m-1070 -11 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10
            10 0 6 5 10 10 10 6 0 10 -4 10 -10z m40 1 c0 -12 -20 -25 -27 -18 -7 7 6 27
            18 27 5 0 9 -4 9 -9z m1120 0 c0 -12 -29 -23 -41 -15 -5 3 -7 10 -4 15 8 12
            45 12 45 0z m44 3 c20 -8 11 -24 -15 -24 -10 0 -19 7 -19 15 0 16 9 19 34 9z
            m-1134 -14 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4
            -10z m260 1 c0 -8 -56 -21 -97 -21 -13 0 -23 5 -23 10 0 6 15 8 35 6 19 -3 37
            0 40 5 8 11 45 12 45 0z m970 -6 c-7 -8 -16 -13 -20 -10 -4 3 -13 -2 -21 -11
            -19 -23 -46 -35 -64 -28 -20 8 -1 34 26 34 10 0 24 7 31 15 7 8 24 14 37 15
            20 0 21 -2 11 -15z m215 5 c3 -5 0 -10 -7 -11 -10 0 -10 -2 0 -6 13 -5 17 -53
            5 -53 -19 1 -30 18 -30 48 1 31 19 44 32 22z m-1545 -11 c0 -6 8 -8 18 -5 14
            5 14 4 -2 -9 -11 -8 -26 -15 -33 -15 -7 0 -13 -4 -13 -10 0 -5 -10 -10 -22 -9
            l-23 0 22 11 c12 6 23 19 26 29 5 20 27 27 27 8z m1492 5 c12 -12 -5 -44 -24
            -44 -14 0 -18 5 -14 16 3 9 6 20 6 25 0 10 23 12 32 3z m-465 -21 c-9 -9 -19
            -14 -23 -11 -10 10 6 28 24 28 15 0 15 -1 -1 -17z m-957 -3 c0 -5 -11 -12 -25
            -16 -14 -3 -25 -10 -25 -15 0 -5 -7 -9 -15 -9 -8 0 -15 5 -15 10 0 6 6 10 14
            10 7 0 19 7 26 15 14 17 40 20 40 5z m48 -2 c-3 -7 -12 -14 -21 -16 -12 -2
            -15 1 -11 12 7 19 38 22 32 4z m96 3 c3 -5 -3 -11 -14 -14 -13 -3 -20 0 -20 9
            0 15 26 19 34 5z m864 -19 c4 -26 -13 -29 -35 -6 -14 13 -14 17 -2 25 20 13
            34 6 37 -19z m100 18 c6 0 12 -4 12 -9 0 -4 -18 -8 -40 -8 -22 0 -40 3 -40 7
            0 14 15 21 35 16 11 -3 26 -6 33 -6z m-858 -10 c0 -5 -7 -10 -15 -10 -8 0 -15
            5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m-150 -9 c0 -10 -45 -41 -60 -41
            -19 0 -10 25 13 36 28 15 47 16 47 5z m90 -1 c0 -5 11 -10 25 -10 30 0 32 -10
            5 -33 -22 -19 -50 -23 -50 -7 0 6 5 10 10 10 6 0 10 7 10 15 0 8 -7 15 -15 15
            -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m129 -4 c9 -10 9 -16 1
            -21 -12 -7 -40 11 -40 26 0 14 26 10 39 -5z m191 -5 c0 -11 7 -27 16 -36 21
            -21 7 -35 -28 -28 -28 5 -47 43 -22 43 8 0 14 5 14 10 0 6 -4 10 -10 10 -5 0
            -10 5 -10 10 0 6 9 10 20 10 13 0 20 -7 20 -19z m750 15 c0 -16 -14 -36 -26
            -36 -14 0 -19 22 -7 33 7 8 33 10 33 3z m-299 -36 c6 0 7 -4 4 -10 -10 -17
            -42 -11 -42 8 0 22 21 36 25 17 2 -8 8 -15 13 -15z m387 19 c-2 -6 -8 -10 -13
            -10 -5 0 -11 4 -13 10 -2 6 4 11 13 11 9 0 15 -5 13 -11z m-1343 -12 c15 -16
            8 -67 -9 -67 -28 1 -64 50 -36 50 6 0 10 -8 10 -17 0 -14 2 -15 13 -4 10 10 8
            14 -6 22 -9 6 -15 14 -12 20 9 13 24 11 40 -4z m140 2 c10 -15 -1 -23 -20 -15
            -9 3 -13 10 -10 16 8 13 22 13 30 -1z m965 1 c0 -5 -9 -10 -20 -10 -11 0 -20
            5 -20 10 0 6 9 10 20 10 11 0 20 -4 20 -10z m290 -4 c0 -8 -9 -18 -21 -21 -16
            -5 -18 -10 -9 -20 7 -8 9 -18 6 -24 -6 -10 -49 -3 -43 8 2 3 9 21 16 39 13 33
            51 46 51 18z m50 -7 c0 -5 -7 -9 -15 -9 -8 0 -15 7 -15 15 0 9 6 12 15 9 8 -4
            15 -10 15 -15z m-1526 2 c18 -7 18 -8 3 -14 -9 -3 -17 -3 -17 1 0 4 -18 7 -40
            5 -24 -1 -38 2 -35 8 7 11 61 11 89 0z m366 -1 c0 -5 -7 -10 -15 -10 -8 0 -15
            5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m134 4 c25 -9 19 -24 -9 -24 -16 0
            -25 6 -25 15 0 16 9 19 34 9z m626 -3 c0 -11 -45 -41 -62 -41 -22 0 -47 -37
            -41 -59 6 -24 -17 -37 -44 -26 -16 6 -16 8 4 24 15 13 19 21 11 29 -14 14 -1
            32 25 32 12 0 35 11 51 25 29 25 56 33 56 16z m143 -6 c-6 -18 -34 -16 -41 3
            -2 7 6 12 22 12 19 0 24 -4 19 -15z m-1287 -27 c20 4 25 1 22 -9 -3 -9 -17
            -13 -38 -11 -40 3 -67 21 -49 33 7 4 18 1 25 -6 7 -7 25 -10 40 -7z m204 12
            c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z
            m260 -5 c0 -8 -9 -15 -20 -15 -25 0 -26 17 -2 23 9 3 18 5 20 6 1 0 2 -6 2
            -14z m755 5 c3 -6 -2 -13 -12 -17 -10 -4 -23 -13 -29 -21 -6 -8 -16 -12 -22
            -8 -17 10 -15 26 3 26 8 0 14 3 14 8 -3 17 2 22 20 22 11 0 23 -5 26 -10z
            m135 1 c0 -11 -26 -22 -34 -14 -12 12 -5 23 14 23 11 0 20 -4 20 -9z m-1190
            -21 c8 -16 8 -20 -3 -20 -18 0 -39 24 -30 33 11 12 22 8 33 -13z m830 5 c0 -8
            -9 -15 -20 -15 -25 0 -26 17 -2 23 9 3 18 5 20 6 1 0 2 -6 2 -14z m575 6 c3
            -5 1 -12 -4 -15 -13 -8 -42 4 -35 15 7 12 31 12 39 0z m-1315 -16 c0 -8 -7
            -15 -15 -15 -8 0 -15 -4 -15 -10 0 -5 -9 -10 -20 -10 -20 0 -20 1 -4 25 18 27
            54 34 54 10z m128 3 c-3 -7 -13 -13 -23 -13 -10 0 -20 6 -22 13 -3 7 5 12 22
            12 17 0 25 -5 23 -12z m162 -9 c0 -5 -5 -9 -11 -9 -6 0 -17 -7 -25 -15 -12
            -11 -20 -12 -35 -4 -27 14 -19 34 14 34 14 0 29 3 33 7 8 9 24 1 24 -13z m760
            1 c0 -13 -7 -20 -20 -20 -19 0 -27 20 -13 33 13 14 33 6 33 -13z m97 3 c4 -10
            10 -20 14 -23 4 -3 9 -14 11 -25 2 -14 -3 -20 -17 -20 -15 0 -19 6 -18 23 2
            15 -3 22 -15 22 -26 0 -34 11 -21 27 16 18 38 16 46 -4z m113 7 c0 -5 -7 -10
            -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m-1630 -19 c0
            -11 -31 -24 -39 -16 -3 3 1 10 9 15 19 12 30 12 30 1z m50 2 c0 -17 -19 -35
            -29 -29 -18 12 -12 36 9 36 11 0 20 -3 20 -7z m372 -25 c-15 -15 -26 -4 -18
            18 5 13 9 15 18 6 9 -9 9 -15 0 -24z m380 19 c23 -11 28 -37 7 -37 -9 0 -49
            36 -49 45 0 9 17 6 42 -8z m256 -4 c-2 -10 -12 -18 -23 -18 -11 0 -21 8 -23
            18 -3 13 3 17 23 17 20 0 26 -4 23 -17z m307 7 c3 -6 0 -13 -8 -16 -8 -3 -17
            -18 -21 -35 -6 -34 -26 -38 -44 -9 -15 25 -8 40 19 40 10 0 18 3 18 8 -4 23
            23 32 36 12z m-1195 -15 c0 -18 1 -18 33 -6 34 13 89 0 77 -18 -7 -13 -11 -13
            -25 -1 -15 12 -18 12 -26 -3 -12 -21 -48 -22 -55 -2 -4 9 -11 15 -18 14 -33
            -4 -41 2 -22 16 25 19 36 19 36 0z m540 0 c0 -8 -9 -15 -20 -15 -11 0 -20 7
            -20 15 0 8 9 15 20 15 11 0 20 -7 20 -15z m40 5 c0 -5 -7 -10 -15 -10 -8 0
            -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m358 -8 c2 -7 -3 -12 -12 -12
            -9 0 -16 7 -16 16 0 17 22 14 28 -4z m130 6 c-7 -20 -48 -23 -48 -4 0 11 9 16
            26 16 16 0 24 -5 22 -12z m419 -4 c2 -6 -4 -14 -13 -18 -19 -7 -47 11 -38 26
            8 12 46 6 51 -8z m71 -11 c5 -34 -34 -31 -41 3 -4 21 -1 25 17 22 13 -2 22
            -12 24 -25z m70 15 c16 -16 15 -28 0 -28 -22 0 -41 17 -34 29 9 14 19 14 34
            -1z m-1700 -21 c2 -10 -3 -17 -11 -17 -8 0 -28 -7 -43 -15 -33 -17 -54 -19
            -54 -6 0 16 29 33 48 27 14 -5 21 -1 25 15 6 24 30 21 35 -4z m462 8 c7 -8 19
            -15 26 -15 8 0 14 -7 14 -16 0 -10 -7 -14 -22 -12 -15 2 -22 9 -22 21 1 9 -3
            17 -10 16 -23 -3 -36 1 -36 11 0 15 36 12 50 -5z m904 6 c17 -26 -26 -60 -50
            -40 -18 15 -18 29 1 29 8 0 15 5 15 10 0 12 27 13 34 1z m-976 -24 c3 -12 -3
            -17 -22 -17 -29 0 -44 19 -25 31 20 13 43 6 47 -14z m179 -2 c10 -22 9 -25 -7
            -25 -16 0 -30 18 -30 41 0 19 25 8 37 -16z m187 -6 c-9 -10 -21 -19 -26 -19
            -13 0 -9 17 8 34 22 23 39 8 18 -15z m-874 6 c0 -8 -7 -15 -16 -15 -14 0 -14
            3 -4 15 7 8 14 15 16 15 2 0 4 -7 4 -15z m385 -5 c0 -18 -33 -26 -47 -12 -6 6
            -7 15 -3 22 10 16 50 8 50 -10z m421 -9 c7 -13 10 -30 7 -38 -8 -20 -60 -45
            -68 -33 -9 15 3 30 20 24 16 -7 40 32 25 41 -5 3 -10 -1 -10 -9 0 -9 -5 -16
            -11 -16 -6 0 -9 7 -6 15 4 8 2 17 -4 20 -14 9 0 27 19 23 9 -2 22 -14 28 -27z
            m818 14 c4 -10 -1 -15 -14 -15 -11 0 -20 7 -20 15 0 8 6 15 14 15 8 0 16 -7
            20 -15z m-1069 -5 c3 -5 -1 -10 -9 -10 -9 0 -16 5 -16 10 0 6 4 10 9 10 6 0
            13 -4 16 -10z m185 -6 c0 -9 -5 -14 -12 -12 -18 6 -21 28 -4 28 9 0 16 -7 16
            -16z m190 0 c0 -8 4 -13 9 -9 5 3 12 1 16 -5 7 -11 -5 -13 -29 -4 -17 7 -22
            34 -6 34 6 0 10 -7 10 -16z m150 7 c0 -6 -5 -13 -10 -16 -6 -4 -8 -11 -5 -16
            4 -5 4 -9 1 -9 -2 0 -11 -3 -20 -6 -11 -4 -16 -1 -16 10 0 9 5 16 10 16 6 0
            10 7 10 15 0 8 7 15 15 15 8 0 15 -4 15 -9z m324 1 c2 -4 1 -14 -4 -22 -7 -11
            -12 -12 -21 -3 -6 6 -8 16 -5 22 8 13 23 14 30 3z m-121 -17 c-4 -15 -36 -12
            -41 3 -3 9 3 13 19 10 13 -1 23 -7 22 -13z m405 3 c-3 -7 -11 -13 -18 -13 -7
            0 -15 6 -17 13 -3 7 4 12 17 12 13 0 20 -5 18 -12z m122 2 c0 -5 -7 -10 -15
            -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m188 -9 c3 -8 -1 -12
            -9 -9 -7 2 -15 10 -17 17 -3 8 1 12 9 9 7 -2 15 -10 17 -17z m-1698 -6 c0 -8
            -9 -15 -20 -15 -11 0 -20 7 -20 15 0 8 9 15 20 15 11 0 20 -7 20 -15z m159 1
            c17 -9 20 -15 11 -21 -6 -4 -17 -2 -23 4 -6 6 -19 11 -30 11 -10 0 -15 5 -12
            10 8 13 25 12 54 -4z m441 -17 c0 -27 -2 -30 -15 -19 -24 20 -19 39 13 49 1 1
            2 -13 2 -30z m108 -14 c-9 -9 -38 7 -38 20 0 21 24 24 34 4 5 -11 7 -22 4 -24z
            m516 30 c11 -8 29 -12 43 -9 31 8 22 -18 -12 -36 -22 -12 -27 -11 -50 14 -35
            38 -21 61 19 31z m426 5 c8 -5 12 -12 9 -15 -8 -8 -39 5 -39 16 0 11 11 11 30
            -1z m-1768 -5 c12 -12 -5 -55 -21 -55 -13 0 -18 8 -18 30 0 21 5 30 17 30 9 0
            19 -2 22 -5z m-176 -21 c12 -10 14 -14 4 -11 -8 2 -23 0 -32 -5 -13 -6 -18 -5
            -18 5 0 29 18 34 46 11z m121 -17 c3 -21 -2 -29 -19 -36 -31 -12 -46 4 -25 27
            9 10 17 24 17 31 0 24 23 5 27 -22z m114 26 c-10 -4 -8 -9 9 -18 23 -12 23
            -13 4 -23 -11 -6 -25 -7 -32 -3 -16 10 -3 51 17 50 12 0 13 -2 2 -6z m304 -3
            c3 -6 17 -7 31 -4 32 8 31 -10 -2 -24 -36 -17 -81 8 -57 31 9 10 21 9 28 -3z
            m120 0 c3 -5 -3 -10 -14 -10 -12 0 -21 5 -21 10 0 6 6 10 14 10 8 0 18 -4 21
            -10z m445 0 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4
            15 -10z m66 -18 c-3 -6 -16 -8 -27 -5 -21 6 -21 7 -4 20 20 14 42 3 31 -15z
            m250 12 c-3 -9 -6 -20 -6 -25 0 -13 -37 -11 -45 2 -8 12 22 38 44 39 8 0 11
            -6 7 -16z m219 -8 c0 -22 -14 -29 -34 -17 -19 12 -6 43 16 39 10 -2 18 -12 18
            -22z m95 14 c12 -7 9 -13 -14 -35 -16 -14 -35 -24 -42 -21 -19 7 -18 26 1 26
            8 0 14 3 14 8 -5 31 12 41 41 22z m58 -7 c2 -8 -5 -13 -17 -13 -12 0 -21 6
            -21 16 0 18 31 15 38 -3z m60 -7 c6 -30 -17 -45 -34 -22 -8 11 -14 26 -14 34
            0 21 44 10 48 -12z m225 -16 c-8 -31 -23 -23 -23 11 0 26 3 29 14 20 8 -7 12
            -21 9 -31z m-2015 18 c-3 -7 -12 -14 -21 -16 -12 -2 -15 1 -11 12 7 19 38 22
            32 4z m300 -6 c3 -9 -3 -13 -19 -10 -12 1 -24 9 -27 16 -3 9 3 13 19 10 12 -1
            24 -9 27 -16z m345 3 c1 -5 -6 -11 -15 -13 -11 -2 -18 3 -18 13 0 17 30 18 33
            0z m240 1 c21 -16 23 -46 2 -46 -8 0 -15 7 -15 15 0 9 -7 18 -15 21 -8 4 -12
            10 -9 15 7 12 16 11 37 -5z m307 -6 c0 -13 -7 -20 -19 -20 -22 0 -35 15 -26
            30 12 19 45 11 45 -10z m-415 -12 c-10 -34 -23 -44 -37 -30 -9 9 -7 16 7 32
            24 27 38 25 30 -2z m495 7 c0 -9 7 -18 15 -21 22 -9 18 -24 -6 -24 -15 0 -19
            5 -15 15 3 10 -1 15 -14 15 -20 0 -33 13 -23 24 12 12 43 6 43 -9z m670 0 c10
            -12 10 -15 -2 -15 -8 0 -20 6 -28 13 -12 10 -12 9 -1 -5 12 -16 9 -22 -11 -19
            -14 2 -8 -17 7 -23 21 -8 19 -28 -2 -24 -12 2 -18 14 -19 38 -2 47 29 67 56
            35z m-2134 -61 c8 3 12 2 9 -3 -3 -5 -1 -12 5 -16 13 -8 13 -35 0 -35 -5 0
            -10 9 -10 20 0 14 -5 18 -17 13 -10 -4 -14 -2 -9 5 3 6 1 14 -5 17 -5 4 -8 13
            -5 21 3 8 0 14 -6 14 -6 0 -5 6 3 17 12 15 14 12 17 -21 3 -29 7 -36 18 -32z
            m18 50 c9 -22 -3 -32 -14 -14 -12 19 -12 30 -1 30 5 0 12 -7 15 -16z m574 4
            c-5 -14 -57 -33 -66 -23 -10 9 28 35 51 35 10 0 17 -5 15 -12z m190 -22 c-4
            -26 -40 -33 -58 -10 -12 14 -12 18 3 29 26 19 59 7 55 -19z m402 24 c0 -5 -5
            -10 -11 -10 -7 0 -17 -7 -24 -17 -7 -9 -17 -13 -24 -9 -9 6 -9 11 0 22 6 7 15
            12 19 9 5 -3 11 -1 15 5 8 13 25 13 25 0z m428 -7 c2 -8 -6 -13 -22 -13 -19 0
            -24 4 -19 15 6 18 34 16 41 -2z m-608 -7 c0 -9 7 -12 20 -9 14 4 20 0 20 -11
            0 -9 5 -16 10 -16 6 0 10 -5 10 -12 0 -9 -3 -9 -12 0 -7 7 -22 12 -35 12 -13
            0 -23 4 -23 10 0 5 -6 7 -14 4 -11 -4 -13 0 -9 15 6 23 33 29 33 7z m245 5 c8
            -14 -23 -41 -40 -35 -26 10 -18 44 9 44 14 0 27 -4 31 -9z m803 -24 c2 -19 -1
            -27 -12 -27 -9 0 -16 3 -16 8 0 4 -3 18 -7 31 -5 19 -2 22 13 19 12 -2 20 -13
            22 -31z m-1627 8 c0 -13 -41 -19 -41 -7 0 11 20 22 33 18 5 -1 8 -6 8 -11z
            m1320 -16 c22 -31 22 -32 4 -45 -10 -8 -29 -14 -41 -14 -25 0 -38 -22 -22 -38
            15 -15 1 -32 -28 -32 -17 0 -24 5 -24 20 0 11 5 20 11 20 6 0 9 6 6 13 -2 6 4
            24 14 38 14 20 25 25 46 22 26 -4 27 -3 9 11 -10 8 -15 19 -11 25 9 16 11 14
            36 -20z m167 14 c5 -17 -26 -29 -40 -15 -6 6 -7 15 -3 22 9 14 37 9 43 -7z
            m-1760 -5 c-3 -7 -19 -14 -36 -16 -25 -3 -31 0 -26 12 7 19 68 23 62 4z m336
            6 c9 -3 16 -12 16 -19 0 -16 -35 -29 -50 -20 -14 9 -13 32 3 38 6 3 13 6 14 6
            1 1 8 -2 17 -5z m367 -9 c-1 -15 -24 -12 -29 3 -3 9 2 13 12 10 10 -1 17 -7
            17 -13z m557 -3 c5 -17 -27 -27 -41 -13 -9 9 -8 14 3 21 18 11 32 8 38 -8z
            m732 -1 c0 -11 5 -23 10 -26 6 -3 10 -14 10 -24 0 -10 7 -24 15 -31 18 -15 20
            -30 4 -30 -6 0 -13 9 -16 20 -3 11 -9 20 -13 20 -4 0 -11 12 -14 28 -4 15 -9
            35 -12 45 -3 9 -1 17 5 17 6 0 11 -9 11 -19z m-2080 -5 c0 -8 -4 -17 -8 -20
            -13 -8 -35 11 -28 23 10 16 36 14 36 -3z m240 4 c0 -15 -38 -32 -45 -20 -3 5
            -3 15 0 20 8 13 45 13 45 0z m370 1 c0 -12 -29 -23 -41 -15 -18 10 -9 24 16
            24 14 0 25 -4 25 -9z m634 -6 c-1 -13 -51 -31 -60 -22 -4 4 -1 14 6 22 14 17
            55 17 54 0z m343 -1 c1 -5 -9 -17 -23 -28 -23 -16 -27 -17 -41 -3 -14 14 -12
            17 13 31 30 16 46 16 51 0z m337 0 c4 -11 0 -15 -16 -12 -12 2 -23 9 -26 16
            -6 18 35 15 42 -4z m-1384 -9 c0 -8 5 -15 10 -15 6 0 10 -9 10 -21 0 -15 -5
            -20 -22 -17 -18 2 -23 10 -24 36 -1 21 3 32 12 32 8 0 14 -7 14 -15z m113 -37
            c-6 -12 -41 -10 -48 3 -4 6 1 18 11 28 18 17 19 17 30 -3 6 -12 9 -24 7 -28z
            m206 39 c6 -8 8 -17 4 -20 -7 -8 -33 11 -33 24 0 13 16 11 29 -4z m621 5 c0
            -14 -11 -21 -26 -15 -21 8 -17 23 6 23 11 0 20 -4 20 -8z m-1333 -46 c-8 -21
            -48 -22 -42 -1 17 52 14 50 32 33 9 -9 13 -24 10 -32z m171 29 c10 -9 -15 -35
            -33 -35 -30 0 -41 16 -24 36 10 12 42 12 57 -1z m174 10 c6 -7 -14 -55 -22
            -55 -3 0 -7 14 -8 30 -2 28 14 41 30 25z m438 -15 c0 -11 -7 -20 -15 -20 -27
            0 -18 30 13 39 1 1 2 -8 2 -19z m113 -14 c-5 -26 -28 -42 -39 -25 -7 12 24 61
            35 55 5 -3 6 -17 4 -30z m57 26 c0 -14 -11 -21 -26 -15 -8 3 -11 9 -8 14 7 11
            34 12 34 1z m664 1 c4 -5 12 -9 17 -9 5 -1 14 -2 19 -3 16 -2 11 -31 -5 -31
            -8 0 -15 4 -15 8 0 5 -13 7 -30 4 -25 -4 -30 -1 -30 17 0 21 28 30 44 14z
            m-1678 -32 c-8 -13 -36 -15 -36 -3 0 4 5 13 12 20 14 14 34 0 24 -17z m1338
            20 c10 -16 -5 -41 -24 -41 -21 0 -44 25 -35 40 8 12 52 13 59 1z m466 -22 c0
            -25 -36 -25 -50 0 -7 14 -6 21 3 24 22 7 47 -5 47 -24z m-1096 7 c-7 -17 -45
            -29 -57 -17 -15 15 2 31 33 31 21 0 28 -4 24 -14z m178 -1 c0 -5 -5 -11 -11
            -13 -6 -2 -11 4 -11 13 0 9 5 15 11 13 6 -2 11 -8 11 -13z m333 -5 c0 -18 -33
            -26 -47 -12 -6 6 -7 15 -3 22 10 16 50 8 50 -10z m43 1 c3 -8 -1 -12 -9 -9 -7
            2 -15 10 -17 17 -3 8 1 12 9 9 7 -2 15 -10 17 -17z m567 10 c3 -5 1 -12 -5
            -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z m80 -4 c28 -21 1 -31 -30 -11 -14 9
            -25 18 -25 20 0 9 38 3 55 -9z m-1965 -7 c0 -5 -5 -10 -11 -10 -5 0 -8 -4 -5
            -9 4 -5 3 -16 0 -25 -8 -20 -44 -21 -44 -1 0 8 5 15 10 15 6 0 10 6 10 14 0
            13 13 23 33 25 4 1 7 -3 7 -9z m200 0 c0 -12 -35 -31 -43 -24 -3 3 -3 12 0 20
            6 16 43 19 43 4z m164 -9 c21 -23 14 -41 -15 -41 -14 0 -19 7 -19 30 0 35 10
            38 34 11z m761 9 c4 -6 1 -17 -5 -25 -16 -19 -52 -2 -44 20 7 17 40 20 49 5z
            m353 -7 c2 -8 -5 -13 -17 -13 -12 0 -21 6 -21 16 0 18 31 15 38 -3z m-588 -8
            c8 -2 18 -4 23 -4 4 -1 7 -10 7 -21 0 -24 -22 -26 -40 -5 -7 8 -21 15 -31 15
            -11 0 -19 7 -19 16 0 12 6 15 23 10 12 -4 29 -9 37 -11z m1140 5 c8 -5 12 -12
            9 -15 -8 -8 -39 5 -39 16 0 11 11 11 30 -1z m-2130 -10 c0 -5 -9 -10 -20 -10
            -11 0 -20 5 -20 10 0 6 9 10 20 10 11 0 20 -4 20 -10z m755 0 c3 -5 1 -10 -4
            -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m149 -11 c-8 -14 -24
            -10 -24 6 0 9 6 12 15 9 8 -4 12 -10 9 -15z m236 6 c0 -8 -4 -15 -10 -15 -5 0
            -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m-1190 1 c0 -3 -4 -8 -10 -11
            -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m728 -8 c-7 -19 -38 -22
            -38 -4 0 10 9 16 21 16 12 0 19 -5 17 -12z m625 -3 c-4 -15 -36 -12 -41 3 -3
            9 3 13 19 10 13 -1 23 -7 22 -13z m133 4 c7 -12 -15 -31 -31 -26 -5 2 -11 11
            -13 20 -4 19 32 24 44 6z m614 -15 c0 -16 -26 -19 -35 -4 -3 6 -4 14 0 20 8
            13 35 1 35 -16z m-1917 -4 c4 -17 -14 -50 -29 -50 -2 0 -4 16 -4 35 0 39 24
            50 33 15z m197 -6 c0 -16 -35 -19 -45 -5 -6 11 26 32 37 25 5 -3 8 -12 8 -20z
            m508 14 c-7 -20 -48 -23 -48 -4 0 11 9 16 26 16 16 0 24 -5 22 -12z m220 -9
            c-3 -25 -33 -47 -45 -35 -4 4 0 12 10 17 9 6 17 17 17 24 0 8 5 15 11 15 6 0
            9 -10 7 -21z m707 -14 c0 -42 -31 -47 -37 -5 -5 34 -1 42 22 38 9 -2 15 -14
            15 -33z m59 14 c-3 -5 0 -9 5 -9 16 0 13 -37 -3 -43 -7 -3 -19 0 -25 6 -12 12
            -15 50 -5 60 9 9 35 -4 28 -14z m84 -7 c-2 -8 -7 -17 -13 -19 -17 -5 -38 14
            -32 30 8 20 49 10 45 -11z m220 -2 c6 0 12 6 15 13 2 8 12 4 30 -13 30 -28 34
            -40 13 -40 -7 0 -16 9 -19 20 -3 11 -10 20 -16 20 -6 0 -11 -9 -11 -20 0 -11
            3 -20 8 -20 15 0 35 -45 37 -83 2 -37 2 -37 -13 -15 -11 16 -12 23 -4 26 17 5
            15 22 -4 22 -8 0 -20 14 -26 31 -6 17 -21 34 -33 39 -21 8 -23 15 -15 50 0 3
            7 -3 14 -12 7 -10 18 -18 24 -18z m-2107 -3 c-13 -13 -26 -3 -16 12 3 6 11 8
            17 5 6 -4 6 -10 -1 -17z m226 15 c20 -12 -1 -33 -43 -42 -46 -11 -71 4 -32 19
            15 5 29 15 33 20 7 13 25 14 42 3z m161 -15 c3 -12 -4 -17 -22 -17 -27 0 -37
            24 -13 33 19 8 32 2 35 -16z m160 6 c-2 -10 -10 -18 -18 -18 -8 0 -16 8 -18
            18 -2 12 3 17 18 17 15 0 20 -5 18 -17z m190 -15 c4 -20 -25 -31 -49 -18 -15
            9 -17 15 -9 30 8 15 15 17 33 11 12 -5 23 -15 25 -23z m617 12 c0 -22 -29 -18
            -33 3 -3 14 1 18 15 15 10 -2 18 -10 18 -18z m122 4 c5 -15 -20 -24 -37 -14
            -7 4 -9 13 -5 19 8 14 37 11 42 -5z m88 -4 c0 -22 -39 -19 -43 3 -3 15 1 18
            20 15 12 -2 23 -10 23 -18z m408 5 c1 -5 -6 -11 -15 -13 -11 -2 -18 3 -18 13
            0 17 30 18 33 0z m-1086 -6 c17 -17 4 -39 -23 -39 -37 0 -31 44 7 49 3 1 10
            -4 16 -10z m70 -6 c8 -22 -20 -25 -41 -4 l-21 22 29 -4 c17 -2 31 -8 33 -14z
            m147 8 c9 -15 -23 -36 -36 -23 -5 5 -7 15 -4 21 9 13 33 14 40 2z m56 -6 c0
            -8 -6 -15 -14 -15 -17 0 -28 14 -19 24 12 12 33 6 33 -9z m78 -1 c13 -4 22
            -14 22 -25 0 -15 -7 -19 -34 -19 -39 0 -53 14 -36 35 14 17 16 17 48 9z m-608
            -26 c-26 -21 -43 -19 -48 5 -3 13 4 16 34 15 l37 -1 -23 -19z m688 11 c-2 -6
            -8 -10 -13 -10 -5 0 -11 4 -13 10 -2 6 4 11 13 11 9 0 15 -5 13 -11z m-1278
            -9 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z
            m220 -5 c0 -8 7 -15 15 -15 9 0 15 -9 15 -21 0 -16 -5 -20 -22 -17 -13 2 -22
            9 -20 17 1 7 -2 19 -8 25 -5 7 -7 16 -3 19 11 12 23 8 23 -8z m1154 9 c9 -3
            16 -15 16 -25 0 -14 -7 -19 -25 -19 -29 0 -45 28 -24 41 17 11 14 10 33 3z
            m626 -20 c0 -7 -5 -16 -10 -19 -15 -9 -40 13 -34 30 7 16 44 7 44 -11z m-2108
            -6 c-7 -7 -12 -8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11 -7 -17z m48 13 c0 -5
            -7 -14 -15 -21 -12 -10 -15 -10 -15 2 0 8 3 18 7 21 9 10 23 9 23 -2z m491 -7
            c11 -14 10 -18 -6 -30 -21 -16 -51 -18 -59 -6 -7 12 22 52 38 52 8 0 20 -7 27
            -16z m-401 -6 c0 -15 -49 -40 -62 -32 -13 8 11 34 27 28 7 -3 16 -1 20 5 8 15
            15 14 15 -1z m888 -16 c4 -14 -27 -16 -47 -3 -7 5 -11 14 -7 19 8 13 48 1 54
            -16z m84 12 c-4 -13 -51 -24 -58 -13 -3 5 -3 13 1 18 7 12 61 7 57 -5z m91 1
            c1 -5 -6 -11 -15 -13 -11 -2 -18 3 -18 13 0 17 30 18 33 0z m441 -2 c7 -18 -9
            -33 -36 -33 -21 0 -32 16 -24 35 7 20 52 19 60 -2z m80 11 c9 -3 16 -10 16
            -16 0 -14 -53 -5 -58 10 -4 13 16 16 42 6z m286 -45 c0 -22 -29 -18 -42 5 -5
            9 -7 24 -4 32 5 12 9 12 26 -3 11 -10 20 -25 20 -34z m35 41 c3 -5 4 -14 0
            -20 -8 -13 -35 -1 -35 16 0 16 26 19 35 4z m-1775 -8 c0 -11 -41 -45 -47 -39
            -9 9 17 47 32 47 8 0 15 -3 15 -8z m163 1 c9 -9 -12 -33 -30 -33 -11 0 -11 4
            -3 20 11 21 22 25 33 13z m207 -13 c0 -4 -7 -10 -15 -13 -16 -7 -29 8 -20 23
            6 10 35 2 35 -10z m120 10 c0 -5 -9 -10 -20 -10 -11 0 -20 5 -20 10 0 6 9 10
            20 10 11 0 20 -4 20 -10z m110 -5 c0 -9 -9 -15 -25 -15 -16 0 -25 6 -25 15 0
            9 9 15 25 15 16 0 25 -6 25 -15z m543 -16 c-13 -13 -43 -5 -43 10 0 13 33 20
            45 9 5 -5 5 -12 -2 -19z m507 11 c0 -11 -6 -20 -14 -20 -18 0 -29 16 -21 30
            11 18 35 11 35 -10z m240 -5 c0 -7 7 -15 15 -19 8 -3 15 -10 15 -16 0 -5 -7
            -10 -15 -10 -8 0 -15 7 -15 15 0 8 -7 15 -15 15 -15 0 -21 21 -9 33 9 9 24 -2
            24 -18z m103 4 c25 -28 12 -38 -13 -11 -11 12 -20 24 -20 27 0 12 17 4 33 -16z
            m-2327 2 c-4 -5 0 -11 6 -14 7 -2 9 -8 5 -13 -9 -10 -28 16 -21 28 3 4 7 8 10
            8 3 0 3 -4 0 -9z m264 -6 c0 -16 -27 -32 -37 -22 -3 4 -3 13 0 22 8 20 37 20
            37 0z m1628 -7 c3 -13 -3 -18 -22 -18 -20 0 -26 5 -26 21 0 15 5 20 22 17 13
            -2 24 -11 26 -20z m-1838 8 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11
            10 11 6 0 10 -2 10 -4z m965 -5 c3 -5 1 -13 -5 -16 -15 -9 -43 3 -35 15 8 13
            32 13 40 1z m179 3 c22 -9 20 -31 -4 -37 -23 -6 -34 7 -26 28 6 17 8 17 30 9z
            m196 -4 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15
            -10z m-1324 -37 c-11 -11 -17 4 -9 23 7 18 8 18 11 1 2 -10 1 -21 -2 -24z m41
            23 c-3 -8 -2 -16 4 -18 5 -2 2 -13 -7 -26 -8 -12 -19 -20 -23 -17 -10 6 11 75
            23 75 4 0 6 -6 3 -14z m1213 -2 c0 -10 -7 -14 -22 -12 -32 5 -35 28 -4 28 17
            0 26 -5 26 -16z m291 1 c-1 -15 -24 -12 -29 3 -3 9 2 13 12 10 10 -1 17 -7 17
            -13z m-1419 -10 c0 -5 -5 -11 -11 -13 -6 -2 -11 4 -11 13 0 9 5 15 11 13 6 -2
            11 -8 11 -13z m546 -3 c2 -7 -3 -12 -12 -12 -9 0 -16 7 -16 16 0 17 22 14 28
            -4z m102 -2 c0 -14 -7 -20 -23 -20 -13 0 -26 3 -30 6 -12 12 6 34 29 34 17 0
            24 -6 24 -20z m304 11 c7 -10 -43 -25 -52 -16 -7 7 18 25 35 25 6 0 14 -4 17
            -9z m616 1 c0 -14 -11 -21 -26 -15 -21 8 -17 23 6 23 11 0 20 -4 20 -8z m251
            -5 c-1 -12 -15 -9 -19 4 -3 6 1 10 8 8 6 -3 11 -8 11 -12z m137 -10 c2 -12 -3
            -17 -17 -17 -12 0 -21 6 -21 13 0 31 32 34 38 4z m-723 -12 c0 -14 -8 -21 -27
            -23 -22 -3 -28 1 -28 16 0 25 10 33 35 30 12 -2 20 -10 20 -23z m-1070 -5 c0
            -8 -10 -16 -22 -18 -18 -3 -23 2 -23 18 0 16 5 21 23 18 12 -2 22 -10 22 -18z
            m75 11 c0 -5 -6 -14 -14 -20 -10 -8 -16 -8 -25 3 -9 11 -9 15 1 19 21 9 38 8
            38 -2z m180 -1 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10
            -4 10 -10z m374 2 c9 -14 -12 -35 -28 -29 -23 9 -20 37 3 37 11 0 22 -4 25 -8z
            m94 -15 c5 -23 -18 -30 -53 -17 l-29 11 24 14 c32 17 54 14 58 -8z m407 13 c3
            -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m96 -9 l23
            -18 -23 -8 c-28 -8 -70 23 -49 37 18 11 23 10 49 -11z m268 -15 c33 -34 34
            -56 4 -56 -17 0 -83 62 -83 79 0 23 47 10 79 -23z m124 20 c7 -18 -1 -26 -25
            -26 -11 0 -18 7 -18 20 0 23 35 28 43 6z m-1953 -5 c0 -5 8 -11 19 -14 14 -3
            17 -11 14 -31 -3 -14 -10 -29 -15 -32 -6 -4 -8 6 -3 27 5 25 4 30 -4 19 -10
            -13 -12 -13 -21 0 -13 19 -13 40 0 40 6 0 10 -4 10 -9z m138 -9 c2 -7 -5 -12
            -17 -12 -14 0 -19 5 -14 15 6 17 25 15 31 -3z m82 -8 c0 -7 -9 -18 -20 -24
            -16 -9 -22 -8 -26 5 -3 8 -4 21 -1 29 7 18 47 9 47 -10z m280 11 c0 -8 -6 -15
            -14 -15 -17 0 -28 14 -19 24 12 12 33 6 33 -9z m153 9 c11 -11 -5 -34 -24 -34
            -18 0 -26 20 -12 33 8 9 28 9 36 1z m1413 -10 c-3 -8 -1 -24 4 -35 17 -30 -13
            -35 -49 -8 -39 29 -40 42 -1 34 23 -5 30 -3 30 9 0 9 5 16 11 16 6 0 8 -7 5
            -16z m132 -12 c3 -19 -1 -23 -15 -20 -10 2 -19 14 -21 26 -3 19 1 23 15 20 10
            -2 19 -14 21 -26z m127 3 c0 -10 -6 -20 -12 -22 -8 -3 -13 5 -13 22 0 17 5 25
            13 23 6 -3 12 -13 12 -23z m-2057 -3 c2 -7 -4 -15 -13 -18 -20 -8 -27 -1 -19
            20 7 18 25 17 32 -2z m862 3 c0 -8 -9 -15 -20 -15 -11 0 -20 7 -20 15 0 8 9
            15 20 15 11 0 20 -7 20 -15z m70 7 c0 -14 -11 -21 -26 -15 -21 8 -17 23 6 23
            11 0 20 -4 20 -8z m74 -1 c8 -12 -21 -34 -34 -26 -17 11 -11 35 9 35 11 0 22
            -4 25 -9z m421 -1 c3 -5 4 -14 0 -20 -8 -13 -35 -1 -35 16 0 16 26 19 35 4z
            m200 0 c3 -5 4 -14 0 -20 -8 -13 -32 -2 -32 15 0 17 23 20 32 5z m475 -15 c10
            -12 10 -15 -4 -15 -9 0 -16 7 -16 15 0 8 2 15 4 15 2 0 9 -7 16 -15z m-1472
            -14 c4 -11 -4 -13 -38 -8 -64 8 -77 29 -16 25 31 -2 51 -8 54 -17z m1352 5 c0
            -21 -21 -31 -30 -15 -5 7 -6 17 -4 21 9 14 34 9 34 -6z m-1921 -3 c-1 -16 -35
            -67 -47 -71 -7 -2 -15 4 -18 13 -5 12 2 25 22 41 30 24 45 30 43 17z m239 -5
            c-7 -20 -48 -23 -48 -4 0 11 9 16 26 16 16 0 24 -5 22 -12z m157 3 c3 -6 -3
            -15 -14 -20 -27 -15 -31 -14 -31 9 0 21 33 29 45 11z m703 -8 c5 -17 -26 -29
            -40 -15 -6 6 -7 15 -3 22 9 14 37 9 43 -7z m722 2 c16 -20 1 -38 -23 -29 -17
            6 -22 25 -10 37 10 11 19 8 33 -8z m-1711 -4 c21 -13 5 -41 -24 -41 -38 0 -33
            44 5 49 3 0 11 -3 19 -8z m554 2 c9 -9 -12 -33 -29 -33 -8 0 -14 9 -14 20 0
            19 28 28 43 13z m67 -2 c0 -5 -6 -11 -12 -14 -18 -6 -39 3 -32 14 7 12 44 12
            44 0z m268 -8 c2 -9 -7 -13 -28 -13 -28 0 -31 2 -20 15 15 18 41 16 48 -2z
            m192 7 c0 -5 -11 -10 -25 -10 -14 0 -25 5 -25 10 0 6 11 10 25 10 14 0 25 -4
            25 -10z m439 1 c16 -10 6 -31 -14 -31 -8 0 -18 -7 -23 -17 -6 -10 -16 -14 -27
            -10 -20 8 -14 33 7 29 9 -1 18 7 21 18 6 22 14 24 36 11z m329 -19 c2 -15 -2
            -22 -12 -22 -11 0 -16 9 -16 26 0 31 23 28 28 -4z m-2198 10 c0 -13 -12 -22
            -22 -16 -10 6 -1 24 13 24 5 0 9 -4 9 -8z m50 -7 c0 -16 -27 -32 -37 -22 -3 4
            -3 13 0 22 8 20 37 20 37 0z m480 5 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3
            6 -1 10 4 10 6 0 11 -4 11 -10z m490 -4 c0 -18 -16 -29 -30 -21 -13 8 -1 35
            16 35 8 0 14 -6 14 -14z m73 8 c11 -11 -5 -34 -24 -34 -18 0 -26 20 -12 33 8
            9 28 9 36 1z m576 -3 c16 -10 6 -31 -14 -31 -17 0 -28 17 -20 30 8 12 16 12
            34 1z m599 -14 c2 -10 -3 -17 -12 -17 -10 0 -16 9 -16 21 0 24 23 21 28 -4z
            m32 -3 c0 -8 -4 -12 -10 -9 -5 3 -10 13 -10 21 0 8 5 12 10 9 6 -3 10 -13 10
            -21z m-2408 -16 c-9 -9 -12 -7 -12 12 0 19 3 21 12 12 9 -9 9 -15 0 -24z
            m1630 -10 c-10 -10 -16 -8 -30 5 -15 16 -15 19 -2 27 21 13 49 -15 32 -32z
            m77 33 c18 -11 5 -31 -20 -31 -10 0 -19 2 -19 4 0 2 -3 11 -6 20 -7 18 21 22
            45 7z m-1309 -21 c0 -13 -7 -20 -20 -20 -19 0 -27 20 -13 33 13 14 33 6 33
            -13z m503 -5 c-5 -13 -23 -22 -23 -11 0 15 14 35 21 31 4 -3 6 -12 2 -20z
            m367 15 c0 -9 -31 -30 -45 -30 -3 0 -1 9 5 20 11 20 40 28 40 10z m778 -13 c5
            -24 -15 -21 -28 4 -10 18 -9 20 7 17 10 -2 19 -11 21 -21z m192 8 c13 -16 6
            -35 -15 -35 -18 0 -28 17 -21 35 7 19 20 19 36 0z m-2015 -21 c0 -12 -8 -20
            -22 -22 -25 -4 -31 16 -11 36 17 17 33 10 33 -14z m305 11 c0 -8 -9 -15 -20
            -15 -20 0 -26 11 -13 23 12 13 33 7 33 -8z m79 -4 c13 -23 -8 -45 -33 -35 -19
            7 -22 40 -3 47 21 9 26 7 36 -12z m146 10 c5 -10 -6 -15 -62 -26 -21 -4 -33
            -3 -33 4 0 6 3 11 8 12 22 2 56 10 61 14 7 8 19 6 26 -4z m98 2 c12 -11 -14
            -33 -39 -33 -23 0 -32 19 -17 33 8 9 48 9 56 0z m215 -10 c-2 -10 -10 -18 -18
            -18 -8 0 -16 8 -18 18 -2 12 3 17 18 17 15 0 20 -5 18 -17z m-110 -5 c-7 -19
            -38 -22 -38 -4 0 10 9 16 21 16 12 0 19 -5 17 -12z m462 -14 c0 -17 -36 -19
            -46 -3 -8 14 14 29 33 22 7 -3 13 -11 13 -19z m124 9 c14 -35 -44 -51 -66 -19
            -12 17 -9 24 10 29 35 9 50 7 56 -10z m313 9 c7 -4 16 -22 19 -40 6 -29 4 -32
            -19 -32 -24 0 -48 31 -29 38 6 2 8 12 5 22 -6 21 3 25 24 12z m-1829 -24 c-4
            -27 -28 -36 -28 -10 0 20 8 32 22 32 5 0 8 -10 6 -22z m50 0 c-4 -27 -28 -36
            -28 -10 0 20 8 32 22 32 5 0 8 -10 6 -22z m1077 12 c3 -5 -1 -10 -9 -10 -9 0
            -16 5 -16 10 0 6 4 10 9 10 6 0 13 -4 16 -10z m174 -4 c9 -10 8 -16 -4 -26
            -17 -14 -45 -7 -45 13 0 29 30 37 49 13z m421 4 c13 -9 12 -12 -4 -25 -23 -17
            -42 -5 -33 19 7 18 16 20 37 6z m590 0 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10
            10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-328 -26 c10 -21 9 -27 -3 -35 -29 -18
            -62 18 -40 45 19 22 30 20 43 -10z m121 12 c5 -13 -10 -36 -24 -36 -14 0 -39
            31 -34 41 9 14 52 11 58 -5z m-2187 -33 c-11 -11 -19 6 -11 24 8 17 8 17 12 0
            3 -10 2 -21 -1 -24z m182 5 c-2 -21 -8 -33 -18 -33 -19 0 -25 32 -10 50 20 24
            31 17 28 -17z m412 22 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10
            6 0 10 -4 10 -10z m1660 -16 c0 -16 -16 -19 -25 -4 -8 13 4 32 16 25 5 -4 9
            -13 9 -21z m98 -2 c3 -16 -1 -19 -17 -15 -21 5 -27 23 -14 37 12 11 28 0 31
            -22z m-2318 3 c0 -8 -4 -15 -10 -15 -5 0 -7 7 -4 15 4 8 8 15 10 15 2 0 4 -7
            4 -15z m518 -3 c2 -7 -7 -12 -22 -12 -27 0 -35 15 -13 23 17 7 30 3 35 -11z
            m402 9 c0 -11 -26 -22 -34 -14 -12 12 -5 23 14 23 11 0 20 -4 20 -9z m125 -11
            c0 -8 -10 -16 -22 -18 -22 -3 -32 16 -16 32 12 11 38 2 38 -14z m100 -5 c0
            -25 -40 -33 -49 -10 -9 24 2 37 27 33 14 -2 22 -10 22 -23z m135 15 c0 -5 -7
            -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m200 0 c0 -5
            -4 -10 -9 -10 -6 0 -13 5 -16 10 -3 6 1 10 9 10 9 0 16 -4 16 -10z m130 0 c0
            -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m808
            -26 l4 -29 -21 22 c-26 27 -27 42 -3 39 11 -2 18 -13 20 -32z m-1678 21 c0 -2
            -9 -9 -20 -15 -22 -12 -44 -5 -35 11 6 9 55 13 55 4z m490 -13 c0 -24 -8 -32
            -26 -25 -22 8 -17 43 6 43 13 0 20 -7 20 -18z m445 -2 c0 -8 -10 -16 -22 -18
            -22 -3 -32 16 -16 32 12 11 38 2 38 -14z m695 -16 c0 -16 -26 -19 -35 -4 -4 6
            -4 18 -1 27 5 12 9 13 21 3 8 -7 15 -19 15 -26z m-2050 6 c0 -23 -22 -27 -39
            -7 -13 16 -5 27 21 27 11 0 18 -7 18 -20z m120 -5 c0 -20 -5 -25 -25 -25 -18
            0 -25 5 -25 18 0 24 8 32 32 32 13 0 18 -7 18 -25z m420 11 c0 -14 -29 -31
            -38 -22 -2 3 -2 12 1 21 8 19 37 20 37 1z m118 -9 c2 -10 -3 -17 -12 -17 -18
            0 -29 16 -21 31 9 14 29 6 33 -14z m436 17 c24 -9 19 -21 -9 -26 -27 -6 -41 5
            -30 22 7 12 17 12 39 4z m396 -14 c0 -11 -4 -20 -9 -20 -14 0 -23 18 -16 30
            10 17 25 11 25 -10z m-1597 -26 c-6 -16 -29 -19 -38 -4 -4 6 -1 17 5 26 15 18
            42 0 33 -22z m1342 16 c3 -5 -4 -12 -15 -15 -21 -7 -54 11 -45 25 6 9 53 1 60
            -10z m-951 1 c7 -11 -22 -23 -35 -15 -5 3 -7 10 -4 15 8 12 32 12 39 0z m181
            -1 c6 -10 -41 -37 -50 -28 -3 3 -3 13 1 22 7 18 39 22 49 6z m965 -3 c0 -19
            -10 -27 -26 -20 -21 8 -17 33 6 33 11 0 20 -6 20 -13z m523 -11 c9 -22 -14
            -52 -32 -42 -12 8 -15 49 -5 59 11 11 29 3 37 -17z m-2260 -1 c-3 -9 -8 -14
            -10 -11 -3 3 -2 9 2 15 9 16 15 13 8 -4z m507 -15 c0 -19 -25 -34 -41 -24 -13
            8 -11 21 7 38 18 19 34 13 34 -14z m395 5 c0 -13 -8 -21 -23 -23 -26 -4 -42
            25 -21 39 22 14 44 6 44 -16z m385 10 c0 -9 -7 -18 -16 -22 -18 -7 -39 11 -30
            26 11 17 46 13 46 -4z m-1369 -9 c10 6 12 -17 3 -40 -10 -25 -24 -8 -23 27 0
            18 3 26 6 19 2 -6 9 -10 14 -6z m1237 2 c-7 -19 -38 -22 -38 -4 0 10 9 16 21
            16 12 0 19 -5 17 -12z m910 0 c-6 -18 -28 -21 -28 -4 0 9 7 16 16 16 9 0 14
            -5 12 -12z m120 -62 c-2 -31 -7 -55 -13 -54 -10 3 -16 106 -7 121 11 21 23
            -15 20 -67z m-2148 29 c0 -12 -7 -25 -15 -29 -11 -4 -15 2 -15 23 0 15 3 31 6
            34 12 11 24 -3 24 -28z m236 9 c8 -30 -6 -47 -32 -42 -23 4 -30 34 -14 53 19
            23 39 18 46 -11z m804 12 c0 -8 -5 -18 -11 -22 -14 -8 -33 11 -25 25 10 16 36
            13 36 -3z m885 4 c4 -6 4 -18 1 -26 -4 -12 -10 -13 -26 -4 -11 6 -20 17 -20
            25 0 17 36 20 45 5z m163 -5 c2 -1 0 -14 -4 -29 -7 -29 -38 -36 -48 -10 -12
            30 3 56 27 48 12 -3 23 -8 25 -9z m332 -16 c0 -11 -4 -17 -10 -14 -5 3 -10 15
            -10 26 0 11 5 17 10 14 6 -3 10 -15 10 -26z m-2490 7 c0 -17 -14 -29 -23 -20
            -8 8 3 34 14 34 5 0 9 -6 9 -14z m130 -11 c0 -16 -6 -25 -15 -25 -9 0 -15 9
            -15 25 0 16 6 25 15 25 9 0 15 -9 15 -25z m260 21 c0 -16 -14 -36 -26 -36 -14
            0 -19 22 -7 33 7 8 33 10 33 3z m288 -8 c-5 -15 -35 -34 -43 -27 -3 4 -3 14 1
            23 7 18 48 23 42 4z m727 -18 c0 -38 -39 -35 -43 3 -3 25 0 28 20 25 17 -2 23
            -9 23 -28z m119 15 c3 -9 -1 -18 -11 -22 -17 -7 -45 7 -51 25 -6 18 55 15 62
            -3z m202 1 c17 -13 17 -16 4 -30 -14 -14 -19 -14 -35 -3 -11 9 -16 20 -12 31
            8 20 17 20 43 2z m-889 -30 c-8 -20 -47 -21 -47 -1 0 18 32 37 44 25 5 -5 6
            -16 3 -24z m201 11 c5 -24 -26 -33 -46 -13 -15 15 -15 18 -2 26 21 14 44 8 48
            -13z m207 -2 c0 -23 -34 -33 -45 -14 -13 20 1 40 24 37 13 -2 21 -10 21 -23z
            m389 16 c4 -5 3 -17 0 -26 -10 -24 -48 -15 -52 13 -3 18 1 22 22 22 14 0 27
            -4 30 -9z m200 -7 c18 -17 20 -30 7 -38 -15 -10 -39 4 -44 24 -8 32 12 40 37
            14z m586 -10 c0 -16 -16 -19 -25 -4 -8 13 4 32 16 25 5 -4 9 -13 9 -21z
            m-1825 6 c-3 -5 -13 -10 -21 -10 -8 0 -14 5 -14 10 0 6 9 10 21 10 11 0 17 -4
            14 -10z m157 -5 c0 -5 -5 -11 -11 -13 -6 -2 -11 4 -11 13 0 9 5 15 11 13 6 -2
            11 -8 11 -13z m1583 5 c3 -6 3 -20 0 -31 -6 -19 -9 -20 -22 -6 -9 9 -13 23
            -10 31 7 18 23 21 32 6z m35 0 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5
            10 10 10 6 0 10 -4 10 -10z m90 -28 c0 -24 -2 -25 -15 -11 -9 9 -13 23 -9 32
            9 24 24 11 24 -21z m-2439 -25 c-15 -15 -24 -2 -16 24 7 20 8 21 17 5 7 -12 7
            -21 -1 -29z m459 28 c0 -8 -7 -15 -15 -15 -16 0 -20 12 -8 23 11 12 23 8 23
            -8z m830 -11 c0 -8 -2 -14 -4 -14 -2 0 -11 -3 -20 -6 -11 -4 -16 0 -16 13 0
            11 3 22 6 26 10 9 34 -4 34 -19z m645 7 c9 -30 -5 -53 -29 -47 -11 3 -24 6
            -27 6 -12 0 -17 38 -6 49 18 18 55 13 62 -8z m-1725 -15 c13 -15 20 -32 17
            -40 -7 -17 -27 -22 -27 -6 0 5 -9 18 -21 29 -12 11 -18 24 -14 30 10 16 19 13
            45 -13z m695 12 c14 -6 25 -15 25 -20 0 -11 -28 -10 -35 2 -3 6 -15 10 -26 10
            -10 0 -19 5 -19 10 0 13 23 13 55 -2z m248 -2 c3 -8 3 -17 0 -20 -7 -7 -33 13
            -33 25 0 15 27 10 33 -5z m895 -20 c-4 -25 -43 -34 -51 -12 -8 20 19 47 39 39
            8 -3 14 -15 12 -27z m-1338 15 c0 -11 -28 -41 -38 -41 -4 0 -15 9 -24 19 -16
            18 -16 20 0 24 31 8 62 7 62 -2z m1545 -1 c3 -5 2 -10 -4 -10 -5 0 -13 5 -16
            10 -3 6 -2 10 4 10 5 0 13 -4 16 -10z m-2185 -20 c0 -11 -4 -20 -10 -20 -5 0
            -10 9 -10 20 0 11 5 20 10 20 6 0 10 -9 10 -20z m55 -29 c-3 -11 -13 -21 -21
            -21 -8 0 -14 -10 -14 -25 0 -28 -17 -34 -27 -9 -4 10 1 22 10 29 10 7 17 19
            17 28 0 8 3 23 6 32 10 26 38 -6 29 -34z m270 39 c9 -14 -17 -50 -37 -50 -24
            0 -31 16 -18 40 11 21 44 27 55 10z m141 -17 c-10 -10 -19 5 -10 18 6 11 8 11
            12 0 2 -7 1 -15 -2 -18z m931 10 c5 -13 -43 -30 -54 -20 -3 4 -3 13 1 22 7 19
            47 17 53 -2z m80 11 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
            m217 -3 c3 -4 3 -11 0 -14 -8 -8 -34 3 -34 14 0 11 27 12 34 0z m606 -7 c0
            -10 6 -14 15 -10 11 4 15 -2 15 -21 0 -26 -1 -26 -15 -7 -8 11 -15 14 -15 7 0
            -15 -26 -17 -35 -3 -10 16 5 50 21 50 8 0 14 -7 14 -16z m-2420 -9 c0 -9 6
            -12 15 -9 11 5 15 -1 15 -20 0 -16 -6 -26 -15 -26 -8 0 -15 5 -15 11 0 6 -6
            17 -14 25 -11 11 -16 11 -19 1 -3 -6 -6 -2 -6 11 -1 16 5 22 19 22 11 0 20 -7
            20 -15z m350 0 c0 -8 -7 -15 -15 -15 -16 0 -20 12 -8 23 11 12 23 8 23 -8z
            m730 11 c0 -15 -14 -35 -21 -30 -15 9 -10 34 6 34 8 0 15 -2 15 -4z m664 -17
            c16 -14 0 -21 -33 -14 -18 4 -31 13 -31 22 0 12 7 14 28 8 15 -3 31 -11 36
            -16z m426 -13 c-5 -5 -17 -5 -27 -2 -17 7 -17 8 1 22 16 11 21 11 27 2 5 -7 4
            -17 -1 -22z m-1520 10 c0 -16 -18 -31 -27 -22 -8 8 5 36 17 36 5 0 10 -6 10
            -14z m1870 -1 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10
            -7 10 -15z m-1350 -15 c0 -22 -22 -35 -44 -27 -9 4 -12 14 -9 26 7 29 53 29
            53 1z m-634 -5 c8 -20 -13 -45 -37 -45 -27 0 -33 19 -13 41 21 23 42 25 50 4z
            m856 3 c8 -22 -4 -58 -18 -58 -8 0 -20 12 -26 26 -10 21 -9 27 3 35 20 12 37
            11 41 -3z m518 -7 c7 -14 7 -22 0 -26 -13 -8 -40 13 -40 31 0 21 28 17 40 -5z
            m-1826 1 c7 -12 -4 -52 -14 -52 -12 0 -23 38 -15 50 7 12 22 13 29 2z m126
            -12 c0 -11 -7 -20 -15 -20 -18 0 -19 12 -3 28 16 16 18 15 18 -8z m478 8 c-2
            -6 -10 -14 -16 -16 -7 -2 -10 2 -6 12 7 18 28 22 22 4z m169 -3 c6 -18 -23
            -55 -44 -55 -10 0 -12 7 -7 23 13 44 39 61 51 32z m407 -1 c7 -19 -1 -34 -19
            -34 -18 0 -28 17 -21 35 7 20 32 19 40 -1z m374 -22 c-2 -9 -11 -18 -21 -20
            -19 -4 -32 20 -23 43 8 22 48 0 44 -23z m672 2 c0 -28 -3 -35 -17 -32 -12 2
            -19 14 -21 36 -3 27 0 32 17 32 17 0 21 -6 21 -36z m58 -12 c3 -41 -4 -52 -24
            -32 -13 13 -5 82 10 77 6 -2 12 -22 14 -45z m122 32 c0 -8 -4 -12 -10 -9 -5 3
            -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10 -16z m-1730 -13 c-13 -25 -27 -34
            -34 -22 -7 10 20 41 35 41 6 0 6 -7 -1 -19z m174 4 c-4 -8 -10 -15 -15 -15 -5
            0 -9 7 -9 15 0 8 7 15 15 15 9 0 12 -6 9 -15z m80 -9 c9 -13 7 -20 -9 -32 -10
            -8 -24 -13 -30 -11 -15 5 -18 36 -4 53 14 17 29 14 43 -10z m-1034 -7 c0 -11
            5 -17 10 -14 6 4 10 -5 10 -19 0 -14 -4 -26 -10 -26 -5 0 -10 7 -10 16 0 8 -4
            12 -10 9 -6 -4 -10 7 -10 24 0 17 5 31 10 31 6 0 10 -9 10 -21z m1876 4 c7
            -10 14 -31 14 -46 0 -25 -2 -26 -20 -17 -25 14 -42 44 -34 64 8 21 24 20 40
            -1z m134 -3 c0 -11 -4 -20 -9 -20 -13 0 -32 26 -24 33 13 14 33 6 33 -13z
            m183 -30 c-7 -27 -31 -33 -43 -10 -15 28 -12 48 8 53 22 5 41 -18 35 -43z
            m-1599 25 c-4 -8 -10 -12 -15 -9 -11 6 -2 24 11 24 5 0 7 -7 4 -15z m964 3 c7
            -7 12 -23 12 -36 0 -20 -4 -23 -27 -20 -23 2 -29 8 -31 36 -3 26 0 32 15 32
            11 0 24 -5 31 -12z m197 -18 c0 -18 -6 -26 -23 -28 -24 -4 -38 18 -28 44 3 9
            15 14 28 12 17 -2 23 -10 23 -28z m733 -11 l-3 -24 -13 23 c-7 12 -10 27 -6
            33 10 17 26 -6 22 -32z m-2128 12 c0 -11 -5 -23 -11 -27 -15 -9 -33 12 -26 30
            9 23 37 20 37 -3z m1727 3 c6 -17 -9 -37 -32 -40 -15 -2 -22 36 -8 49 12 12
            33 7 40 -9z m-1811 -5 c3 -6 -3 -20 -14 -32 -19 -19 -22 -19 -28 -4 -13 35 23
            66 42 36z m395 -2 c-1 -4 -1 -14 -1 -22 0 -8 -9 -15 -20 -15 -22 0 -26 20 -8
            38 13 13 30 12 29 -1z m1783 -19 c10 -38 7 -42 -14 -23 -23 21 -26 45 -6 45 7
            0 16 -10 20 -22z m-2399 -27 c-3 -12 -11 -21 -16 -21 -10 0 -12 43 -2 54 12
            12 25 -11 18 -33z m385 25 c0 -20 -26 -40 -35 -26 -10 16 2 40 20 40 8 0 15
            -6 15 -14z m1534 -2 c20 -8 21 -44 2 -44 -18 0 -39 27 -31 40 7 12 8 12 29 4z
            m285 -8 c10 -11 10 -20 1 -37 -11 -19 -14 -20 -27 -6 -9 9 -13 23 -8 36 7 25
            18 27 34 7z m-2268 -41 c5 4 9 -5 9 -19 0 -14 -4 -26 -10 -26 -5 0 -10 8 -10
            18 0 10 -5 23 -10 28 -6 6 -7 19 -3 30 7 16 8 15 11 -9 2 -15 8 -25 13 -22z
            m109 21 c0 -14 -4 -28 -10 -31 -6 -4 -10 7 -10 24 0 17 5 31 10 31 6 0 10 -11
            10 -24z m100 5 c0 -23 -16 -36 -25 -21 -9 14 1 40 15 40 5 0 10 -9 10 -19z
            m680 -11 c-21 -14 -30 -12 -30 4 0 18 22 27 35 14 6 -6 5 -12 -5 -18z m240 11
            c0 -20 -26 -36 -35 -21 -8 13 6 40 21 40 8 0 14 -9 14 -19z m112 -6 c13 -29
            12 -30 -24 -39 -23 -5 -28 -3 -34 18 -3 14 -3 30 1 35 12 20 45 12 57 -14z
            m594 2 c1 -9 -1 -19 -6 -22 -12 -7 -33 22 -25 35 9 15 28 7 31 -13z m-1676 -7
            c0 -11 -7 -23 -15 -27 -21 -7 -25 -2 -18 25 7 28 33 29 33 2z m430 12 c0 -5
            -6 -17 -14 -28 -12 -15 -17 -16 -27 -7 -10 10 -9 15 1 28 13 16 40 21 40 7z
            m373 -28 c-3 -8 -12 -14 -19 -14 -20 0 -30 30 -13 40 17 11 40 -7 32 -26z
            m367 6 c0 -23 -29 -35 -36 -15 -4 8 -4 22 0 30 7 20 36 8 36 -15z m160 5 c0
            -30 -15 -33 -30 -6 -14 27 -13 31 10 31 15 0 20 -7 20 -25z m-82 6 c17 -11 12
            -56 -7 -56 -19 0 -36 29 -28 49 7 18 16 20 35 7z m270 -3 c14 -14 16 -58 4
            -58 -17 0 -44 39 -39 55 8 18 19 19 35 3z m71 -7 c6 -10 7 -21 4 -25 -9 -8
            -33 14 -33 30 0 20 18 17 29 -5z m-989 0 c0 -23 -14 -41 -31 -41 -22 0 -27 35
            -6 43 20 9 37 8 37 -2z m1588 -24 c2 -19 -1 -27 -13 -27 -19 0 -30 24 -21 46
            9 23 31 11 34 -19z m72 -1 c0 -13 -7 -29 -15 -36 -12 -10 -15 -7 -21 14 -7 29
            1 46 22 46 8 0 14 -10 14 -24z m-2130 5 c0 -12 -29 -35 -36 -28 -11 10 5 37
            21 37 8 0 15 -4 15 -9z m90 -9 c0 -24 -40 -49 -51 -31 -4 7 -3 21 2 31 13 24
            49 24 49 0z m120 -7 c0 -13 -8 -29 -19 -34 -32 -18 -44 15 -15 43 22 23 34 20
            34 -9z m310 11 c0 -17 -39 -66 -40 -49 0 18 22 63 32 63 4 0 8 -6 8 -14z
            m1360 -7 c14 -25 6 -49 -14 -49 -23 0 -40 38 -25 56 16 19 26 18 39 -7z m350
            -9 c0 -16 -5 -30 -10 -30 -14 0 -24 26 -16 45 10 26 26 16 26 -15z m-2593 -2
            c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m743 18 c0 -2 -3 -11 -6
            -20 -9 -23 -24 -20 -24 4 0 11 7 20 15 20 8 0 15 -2 15 -4z m900 -11 c0 -8 -6
            -15 -14 -15 -8 0 -16 7 -20 15 -4 10 1 15 14 15 11 0 20 -7 20 -15z m-1537
            -20 c-7 -21 -13 -19 -13 6 0 11 4 18 10 14 5 -3 7 -12 3 -20z m37 1 c0 -27
            -15 -42 -25 -26 -8 13 3 50 16 50 5 0 9 -11 9 -24z m106 8 c-3 -9 -6 -18 -6
            -20 0 -3 -4 -3 -10 1 -11 7 -2 35 12 35 6 0 7 -7 4 -16z m1784 6 c0 -5 -5 -10
            -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m123 -5 c4 -8 2 -17
            -4 -21 -12 -7 -41 15 -33 27 8 14 31 11 37 -6z m157 -20 c0 -19 -13 -25 -29
            -15 -19 11 -6 41 14 34 8 -3 15 -12 15 -19z m220 -24 c-23 -56 -41 -32 -20 25
            8 20 14 24 22 16 8 -8 7 -19 -2 -41z m-2480 19 c0 -11 -4 -20 -10 -20 -5 0
            -10 9 -10 20 0 11 5 20 10 20 6 0 10 -9 10 -20z m147 -14 c-4 -9 -11 -16 -17
            -16 -11 0 -14 33 -3 44 11 10 26 -11 20 -28z m761 14 c4 -22 -35 -61 -53 -54
            -18 7 -19 27 -4 55 12 23 52 22 57 -1z m156 4 c11 -30 -33 -60 -49 -34 -11 17
            5 50 25 50 10 0 21 -7 24 -16z m826 -9 c0 -20 -5 -25 -24 -25 -26 0 -51 24
            -41 40 3 5 19 10 36 10 24 0 29 -4 29 -25z m-220 -21 c0 -25 -35 -12 -38 14
            -3 21 -1 22 17 12 12 -6 21 -18 21 -26z m-1437 14 c5 -16 -10 -48 -23 -48 -11
            0 -14 43 -3 53 10 11 21 8 26 -5z m176 -24 c-9 -11 -15 -12 -23 -4 -8 8 -6 15
            9 26 23 17 34 2 14 -22z m201 18 c0 -23 -19 -42 -42 -42 -20 0 -23 12 -8 41
            14 24 50 25 50 1z m1428 6 c13 -13 17 -78 5 -78 -28 1 -55 48 -42 72 11 21 21
            22 37 6z m-2008 -23 c0 -16 -6 -25 -15 -25 -15 0 -21 31 -8 43 13 14 23 6 23
            -18z m1149 -11 c-15 -19 -25 -18 -33 3 -8 21 14 36 31 21 10 -8 10 -14 2 -24z
            m201 11 c0 -26 -12 -45 -29 -45 -19 0 -42 32 -33 47 16 24 62 22 62 -2z m202
            8 c26 -24 23 -53 -5 -53 -29 0 -52 41 -34 59 14 14 17 14 39 -6z m738 -11 c0
            -34 -7 -43 -26 -35 -8 3 -14 18 -14 34 0 22 5 29 20 29 15 0 20 -7 20 -28z
            m-830 -13 c0 -10 -6 -19 -13 -19 -22 0 -27 11 -16 32 12 22 29 15 29 -13z
            m260 16 c0 -8 -6 -15 -14 -15 -8 0 -16 7 -20 15 -4 10 1 15 14 15 11 0 20 -7
            20 -15z m782 -35 c-17 -40 -34 -47 -40 -16 -4 17 37 65 50 59 3 -2 -1 -21 -10
            -43z m-2214 12 c3 -17 -2 -22 -17 -22 -14 0 -21 6 -21 18 0 38 33 41 38 4z
            m652 13 c0 -8 -7 -15 -15 -15 -16 0 -20 12 -8 23 11 12 23 8 23 -8z m1166 0
            c4 -8 3 -22 0 -31 -5 -13 -9 -14 -21 -4 -8 7 -15 21 -15 31 0 23 28 25 36 4z
            m279 -5 c0 -7 -6 -15 -12 -17 -8 -3 -13 4 -13 17 0 13 5 20 13 18 6 -3 12 -11
            12 -18z m-2323 -11 c-2 -8 -8 -18 -13 -23 -13 -14 -31 7 -24 26 8 19 41 16 37
            -3z m69 -22 c-8 -8 -11 -5 -11 9 0 27 14 41 18 19 2 -9 -1 -22 -7 -28z m539
            29 c0 -19 -29 -48 -41 -41 -15 10 -10 33 9 44 24 14 32 13 32 -3z m1670 -26
            c0 -16 -4 -31 -9 -35 -13 -7 -24 30 -16 55 9 28 25 15 25 -20z m-2380 -16 c0
            -19 -4 -34 -9 -34 -10 0 -21 60 -14 73 10 16 23 -6 23 -39z m2200 31 c0 -8 -4
            -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m-1696 -3 c6
            -11 -21 -52 -35 -52 -13 0 -11 28 4 45 14 16 24 18 31 7z m276 -11 c0 -11 -9
            -23 -21 -26 -26 -9 -33 -2 -24 24 8 27 45 28 45 2z m1200 -6 c0 -27 -9 -32
            -28 -13 -18 18 -14 38 8 38 15 0 20 -7 20 -25z m-1950 -4 c0 -6 -4 -13 -10
            -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16 6 0 10 -4 10 -9z m1740 -48 c0 -10 -9
            -13 -27 -11 -21 2 -29 9 -31 27 -5 34 19 46 41 20 9 -12 17 -28 17 -36z m60
            36 c0 -24 -23 -21 -28 4 -2 10 3 17 12 17 10 0 16 -9 16 -21z m-1580 -3 c0
            -14 -27 -42 -34 -35 -2 2 0 14 5 27 9 24 29 30 29 8z m170 -3 c0 -18 -28 -53
            -42 -53 -11 0 -10 27 2 51 13 23 40 25 40 2z m433 11 c9 -10 -4 -34 -18 -34
            -17 0 -28 17 -20 30 7 11 28 13 38 4z m297 -31 c0 -10 -26 -43 -35 -43 -35 0
            -47 44 -18 65 19 14 53 0 53 -22z m1113 15 c6 -8 7 -24 3 -34 -7 -18 -8 -18
            -26 4 -11 13 -16 30 -13 38 6 17 23 14 36 -8z m-2218 -46 c-13 -13 -23 22 -12
            42 11 20 12 20 15 -7 2 -16 1 -32 -3 -35z m105 28 c0 -20 -5 -30 -15 -30 -15
            0 -21 40 -8 53 14 15 23 6 23 -23z m1509 21 c17 -11 7 -45 -12 -39 -13 5 -23
            48 -10 48 4 0 14 -4 22 -9z m256 -7 c20 -21 15 -49 -9 -49 -23 0 -41 27 -33
            49 8 20 21 20 42 0z m535 1 c15 -18 9 -50 -9 -50 -19 0 -36 29 -28 49 8 20 21
            21 37 1z m-969 -5 c22 -12 26 -55 4 -63 -7 -3 -24 2 -36 12 -18 15 -20 21 -10
            39 13 25 15 26 42 12z m84 -8 c0 -18 -20 -15 -23 4 -3 10 1 15 10 12 7 -3 13
            -10 13 -16z m922 -4 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
            m-2387 -12 c0 -14 -4 -27 -9 -30 -12 -8 -22 20 -15 39 9 23 24 18 24 -9z m490
            10 c0 -19 -23 -36 -38 -27 -7 4 -10 15 -6 24 7 20 44 23 44 3z m690 -16 c14
            -26 -13 -59 -36 -44 -33 20 -41 44 -21 59 22 16 44 10 57 -15z m1054 13 c4 -9
            3 -20 -1 -24 -13 -13 -37 7 -30 25 8 21 23 20 31 -1z m-2004 -13 c0 -11 -7
            -20 -15 -20 -15 0 -21 21 -8 33 12 13 23 7 23 -13z m618 -7 c4 -25 -20 -36
            -43 -19 -16 12 -16 13 0 30 21 21 39 16 43 -11z m1162 7 c0 -11 -4 -20 -9 -20
            -13 0 -32 26 -24 33 13 14 33 6 33 -13z m78 -9 c2 -13 -1 -28 -7 -34 -17 -17
            -45 15 -36 42 9 29 39 23 43 -8z m-1511 13 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6
            6 11 1 17 -2 13 -5z m-427 -70 c-18 -21 -30 -17 -30 11 0 32 34 53 38 24 2
            -11 -2 -27 -8 -35z m1770 10 c0 -40 -34 -27 -38 14 -3 31 -2 33 17 22 13 -6
            21 -21 21 -36z m498 19 c-4 -39 -28 -42 -28 -4 0 21 5 31 16 31 11 0 14 -8 12
            -27z m-2551 -5 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m48 -8
            c9 -40 -3 -57 -22 -31 -14 21 -10 61 7 61 5 0 11 -13 15 -30z m800 -1 c0 -15
            -7 -25 -18 -27 -22 -4 -33 24 -17 43 18 21 35 13 35 -16z m-368 -12 c-7 -11
            -19 -23 -25 -25 -17 -5 -15 24 4 42 22 23 39 9 21 -17z m1277 17 c3 -9 6 -25
            6 -35 0 -32 -54 -22 -58 10 -5 36 39 58 52 25z m134 -5 c12 -18 11 -24 0 -36
            -12 -12 -17 -11 -31 5 -18 20 -14 52 7 52 6 0 17 -10 24 -21z m-1798 -14 c0
            -14 -4 -25 -9 -25 -12 0 -19 21 -13 38 8 21 22 13 22 -13z m634 9 c5 -14 -8
            -54 -19 -54 -28 0 -48 44 -28 63 12 13 41 7 47 -9z m-168 -9 c8 -19 -22 -65
            -41 -65 -20 0 -19 39 2 62 20 22 32 23 39 3z m654 -5 c0 -19 -20 -27 -33 -13
            -8 7 11 33 24 33 5 0 9 -9 9 -20z m438 0 c14 -23 8 -40 -12 -40 -20 0 -37 25
            -30 44 8 22 27 20 42 -4z m706 4 c12 -32 6 -54 -14 -54 -16 0 -20 7 -20 35 0
            37 22 49 34 19z m84 -30 c-2 -15 -8 -29 -15 -31 -18 -6 -28 44 -13 63 18 20
            32 4 28 -32z m-2284 -8 c-4 -17 -10 -24 -16 -18 -5 5 -6 21 -2 36 4 17 10 24
            16 18 5 -5 6 -21 2 -36z m61 4 c-5 -15 -10 -19 -17 -12 -5 5 -7 20 -3 32 5 15
            10 19 17 12 5 -5 7 -20 3 -32z m865 21 c0 -11 -5 -23 -11 -27 -15 -9 -33 12
            -26 30 9 23 37 20 37 -3z m342 9 c5 0 8 -10 6 -22 -3 -24 -34 -36 -50 -20 -15
            15 1 55 19 48 8 -3 19 -6 25 -6z m-1087 -48 c-6 -16 -7 -16 -16 1 -8 13 -7 23
            3 34 12 15 13 15 16 -1 2 -9 1 -25 -3 -34z m75 39 c0 -5 -7 -14 -15 -21 -13
            -10 -15 -9 -15 9 0 12 6 21 15 21 8 0 15 -4 15 -9z m1860 -23 c0 -14 -5 -18
            -16 -14 -9 3 -24 6 -35 6 -10 0 -19 5 -19 11 0 6 6 9 14 6 8 -3 17 1 20 8 8
            21 36 7 36 -17z m226 7 c4 -8 3 -27 0 -41 -6 -21 -9 -24 -21 -14 -15 13 -20
            51 -8 63 11 11 23 8 29 -8z m-331 -15 c3 -11 2 -27 -2 -35 -8 -14 -11 -13 -27
            4 -20 22 -15 51 8 51 8 0 17 -9 21 -20z m-2166 -45 c0 -30 -4 -53 -9 -50 -11
            7 -23 82 -15 95 15 24 24 7 24 -45z m114 -4 c-3 -12 -9 -21 -14 -21 -11 0 -12
            64 -2 74 11 10 22 -29 16 -53z m1427 45 c0 -19 -32 -30 -42 -14 -13 21 -9 28
            17 28 15 0 25 -6 25 -14z m1023 -11 c-7 -21 -13 -19 -13 6 0 11 4 18 10 14 5
            -3 7 -12 3 -20z m-2533 -11 c0 -8 -4 -14 -10 -14 -5 0 -10 9 -10 21 0 11 5 17
            10 14 6 -3 10 -13 10 -21z m860 16 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0
            6 5 10 10 10 6 0 10 -4 10 -10z m-820 -37 c0 -26 -3 -34 -11 -26 -9 9 -3 63 7
            63 2 0 4 -17 4 -37z m210 15 c0 -16 -3 -19 -11 -11 -6 6 -8 16 -5 22 11 17 16
            13 16 -11z m356 -3 c8 -31 -2 -58 -19 -53 -14 5 -21 42 -13 64 8 22 25 17 32
            -11z m1900 -2 c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2 -7 1 -15 -2 -18z
            m-2356 -18 c0 -40 -15 -47 -22 -11 -4 21 7 46 19 46 2 0 3 -16 3 -35z m794 19
            c20 -19 20 -23 1 -44 -20 -22 -65 -15 -65 10 0 19 25 50 40 50 5 0 16 -7 24
            -16z m1014 4 c15 -15 15 -45 0 -54 -13 -8 -38 22 -38 48 0 21 20 24 38 6z
            m-2035 -13 c-3 -9 -8 -14 -10 -11 -3 3 -2 9 2 15 9 16 15 13 8 -4z m1227 0
            c10 -12 10 -18 0 -30 -16 -19 -34 -19 -49 -1 -9 11 -8 18 4 30 20 20 29 20 45
            1z m114 -1 c20 -19 20 -29 0 -48 -12 -12 -20 -13 -35 -6 -21 12 -26 56 -6 63
            20 9 25 8 41 -9z m421 6 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10
            3 0 8 -4 11 -10z m89 -13 c-3 -66 -5 -77 -14 -77 -14 0 -23 45 -16 76 7 27 32
            28 30 1z m286 -23 c0 -30 -15 -38 -34 -18 -19 19 -21 51 -3 57 18 7 37 -13 37
            -39z m-1692 -1 c-2 -27 -8 -39 -20 -41 -22 -4 -24 28 -6 58 19 31 30 24 26
            -17z m-360 -14 c-1 -13 -7 -23 -13 -22 -13 3 -18 30 -11 50 10 24 29 1 24 -28z
            m760 19 c-4 -29 -38 -38 -38 -10 0 22 8 32 27 32 9 0 13 -8 11 -22z m1095 1
            c28 -40 -9 -91 -38 -53 -18 23 -19 46 -3 62 17 17 25 15 41 -9z m369 4 c5 -24
            -2 -53 -13 -53 -13 0 -31 40 -25 56 8 20 34 18 38 -3z m87 3 c13 -16 4 -49
            -13 -43 -14 4 -21 57 -8 57 5 0 15 -6 21 -14z m-2109 -3 c-1 -21 -13 -53 -21
            -53 -10 0 -12 43 -2 53 9 9 23 9 23 0z m230 -27 c0 -28 -25 -54 -33 -34 -6 17
            10 58 23 58 5 0 10 -11 10 -24z m80 17 c0 -5 -7 -16 -15 -27 -18 -24 -39 -12
            -25 15 10 18 40 27 40 12z m1938 -21 c2 -15 -2 -22 -12 -22 -11 0 -16 9 -16
            26 0 31 23 28 28 -4z m-2348 -12 c0 -16 -4 -30 -10 -30 -11 0 -14 43 -3 53 11
            12 13 8 13 -23z m186 -28 c-12 -12 -18 8 -10 35 l8 28 4 -28 c2 -16 1 -31 -2
            -35z m1240 33 c8 -34 -1 -55 -25 -55 -28 0 -45 34 -31 60 15 29 48 26 56 -5z
            m74 5 c0 -13 -5 -18 -15 -14 -16 7 -19 17 -8 27 12 13 23 7 23 -13z m794 4
            c10 -27 7 -52 -9 -58 -10 -3 -17 3 -21 19 -11 43 15 77 30 39z m-1559 -20 c0
            -10 -8 -20 -17 -22 -18 -3 -26 27 -11 42 12 11 28 0 28 -20z m1665 -13 c0 -22
            -4 -42 -9 -45 -10 -7 -25 60 -16 74 13 22 25 8 25 -29z m-2580 -2 c0 -16 -4
            -28 -9 -25 -5 4 -11 0 -14 -6 -2 -7 -8 -10 -12 -5 -5 4 -3 13 3 19 7 7 12 20
            12 30 0 10 5 18 10 18 6 0 10 -14 10 -31z m313 -27 l-18 -27 -3 28 c-2 16 2
            35 9 43 11 14 14 13 22 -1 6 -11 3 -25 -10 -43z m440 29 c6 -25 -14 -66 -33
            -66 -20 0 -25 32 -9 61 15 29 35 31 42 5z m347 6 c0 -14 -20 -37 -32 -37 -10
            0 -11 34 -1 43 11 12 33 8 33 -6z m1150 -19 c0 -5 -7 -8 -15 -8 -15 0 -20 21
            -8 33 7 7 23 -11 23 -25z m90 17 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8
            5 15 10 15 6 0 10 -7 10 -15z m294 -1 c3 -9 6 -23 6 -32 0 -14 -4 -13 -21 5
            -12 11 -18 26 -15 32 10 16 23 14 30 -5z m-2594 -17 c0 -26 -9 -39 -20 -32
            -10 6 0 55 11 55 5 0 9 -10 9 -23z m70 -12 c0 -27 -15 -41 -25 -25 -10 16 4
            62 15 55 6 -3 10 -17 10 -30z m117 13 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3
            4 -12 1 -19z m1251 -11 c3 -21 -1 -27 -16 -27 -30 0 -48 26 -32 45 19 23 45
            14 48 -18z m300 -20 c-2 -19 -9 -32 -17 -32 -21 0 -35 29 -27 59 6 23 9 25 27
            16 15 -9 19 -19 17 -43z m-1635 13 c-7 -28 -13 -25 -13 6 0 14 4 23 9 20 5 -3
            7 -15 4 -26z m2047 -6 c0 -15 -6 -24 -15 -24 -10 0 -15 10 -15 30 0 22 4 28
            15 24 8 -4 15 -17 15 -30z m-1925 17 c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0
            18 6 21 15 7z m982 -23 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z
            m863 -6 l0 -26 -21 21 c-23 22 -18 46 6 36 8 -3 15 -17 15 -31z m-750 12 c0
            -9 -7 -14 -17 -12 -25 5 -28 28 -4 28 12 0 21 -6 21 -16z m1043 -35 c-7 -27
            -37 -20 -41 10 -5 30 19 47 34 25 7 -8 10 -24 7 -35z m-2203 21 c0 -11 -4 -20
            -10 -20 -5 0 -10 9 -10 20 0 11 5 20 10 20 6 0 10 -9 10 -20z m1830 -4 c15
            -28 8 -56 -14 -56 -17 0 -31 42 -22 65 8 22 21 18 36 -9z m280 4 c0 -11 -7
            -20 -15 -20 -8 0 -15 9 -15 20 0 11 7 20 15 20 8 0 15 -9 15 -20z m214 14 c19
            -7 21 -47 3 -63 -19 -15 -27 -4 -27 36 0 36 0 36 24 27z m-2453 -67 c-11 -11
            -24 40 -14 56 4 7 10 0 15 -18 4 -16 4 -33 -1 -38z m369 49 c0 -8 -7 -17 -16
            -20 -21 -8 -26 -3 -18 18 8 20 34 21 34 2z m170 -24 c0 -36 -30 -79 -44 -65
            -10 10 -7 63 4 84 18 33 40 23 40 -19z m-577 -6 c4 4 7 -7 7 -24 0 -40 -4 -40
            -24 0 -20 38 -22 74 -3 40 7 -12 16 -19 20 -16z m842 -1 c0 -21 -5 -31 -19
            -33 -26 -5 -40 12 -32 43 5 20 12 26 29 23 17 -2 22 -10 22 -33z m309 22 c26
            -19 5 -57 -30 -57 -32 0 -41 25 -19 50 21 24 26 24 49 7z m1286 3 c8 -5 12
            -10 8 -10 -5 0 -10 1 -11 3 -2 1 -11 -4 -20 -12 -15 -12 -17 -11 -17 8 0 22
            16 27 40 11z m-2239 -53 c-18 -18 -25 1 -11 30 l14 28 4 -24 c2 -13 -1 -28 -7
            -34z m1459 27 c0 -16 -16 -19 -25 -4 -8 13 4 32 16 25 5 -4 9 -13 9 -21z
            m-1733 -6 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m35 5 c6 -26
            -2 -54 -13 -48 -12 8 -12 65 0 65 5 0 11 -8 13 -17z m128 5 c0 -7 -5 -20 -10
            -28 -8 -12 -10 -9 -10 13 0 15 5 27 10 27 6 0 10 -6 10 -12z m2488 -15 c-4
            -39 -28 -42 -28 -4 0 21 5 31 16 31 11 0 14 -8 12 -27z m-2238 4 c0 -7 -7 -22
            -15 -33 -14 -18 -14 -17 -15 14 0 22 5 32 15 32 8 0 15 -6 15 -13z m964 0 c21
            -15 18 -45 -6 -60 -12 -8 -21 -7 -32 2 -19 16 -21 57 -3 64 19 8 22 8 41 -6z
            m606 -2 c16 -20 2 -38 -25 -31 -15 4 -25 1 -29 -9 -10 -27 -36 -17 -36 14 0
            30 2 31 66 40 6 0 17 -6 24 -14z m310 -4 c14 -28 12 -58 -4 -64 -17 -7 -26 13
            -26 54 0 33 14 38 30 10z m-2090 -21 c0 -20 -5 -30 -15 -30 -10 0 -15 10 -15
            30 0 20 5 30 15 30 10 0 15 -10 15 -30z m875 0 c0 -20 -6 -26 -27 -28 -29 -3
            -35 9 -22 43 11 26 49 15 49 -15z m535 9 c0 -5 -7 -9 -15 -9 -15 0 -20 12 -9
            23 8 8 24 -1 24 -14z m-1250 -19 c0 -10 -4 -22 -10 -25 -6 -4 -10 7 -10 25 0
            18 4 29 10 25 6 -3 10 -15 10 -25z m-50 6 c0 -15 -18 -31 -26 -23 -7 7 7 37
            17 37 5 0 9 -6 9 -14z m240 -21 c0 -13 -8 -31 -17 -41 -17 -17 -18 -17 -21 9
            -4 30 15 70 29 62 5 -4 9 -17 9 -30z m-73 9 c7 -18 -12 -64 -27 -64 -11 0 -14
            63 -3 73 11 12 23 8 30 -9z m1689 -8 c7 -31 2 -58 -12 -63 -15 -5 -36 45 -29
            68 9 27 33 24 41 -5z m287 -22 c-3 -8 -9 -14 -13 -14 -12 0 -20 28 -13 46 6
            15 7 15 19 -1 7 -9 10 -23 7 -31z m-1173 21 c0 -8 -4 -15 -9 -15 -13 0 -22 16
            -14 24 11 11 23 6 23 -9z m550 -16 c0 -5 -7 -6 -15 -3 -8 4 -15 13 -15 21 0
            13 3 13 15 3 8 -7 15 -16 15 -21z m558 -7 c2 -24 -1 -32 -12 -32 -17 0 -32 42
            -21 60 12 20 30 4 33 -28z m143 17 c7 -14 8 -24 1 -31 -15 -15 -32 0 -32 28 0
            30 15 31 31 3z m79 4 c0 -10 -5 -25 -10 -33 -8 -13 -11 -13 -26 2 -14 14 -15
            20 -4 32 17 21 40 20 40 -1z m-2470 -28 c0 -29 -16 -41 -24 -19 -8 21 3 56 15
            49 5 -4 9 -17 9 -30z m536 -15 c-13 -43 -36 -67 -36 -36 0 9 4 16 8 16 4 0 14
            16 21 36 18 49 23 38 7 -16z m-676 13 c0 -20 -3 -24 -11 -16 -7 7 -9 19 -6 27
            10 24 17 19 17 -11z m30 2 c0 -14 -4 -25 -10 -25 -5 0 -10 11 -10 25 0 14 5
            25 10 25 6 0 10 -11 10 -25z m35 0 c0 -10 -6 -20 -12 -22 -8 -3 -13 5 -13 22
            0 17 5 25 13 23 6 -3 12 -13 12 -23z m35 -2 c0 -20 -3 -24 -11 -16 -7 7 -9 19
            -6 27 10 24 17 19 17 -11z m121 -26 c-8 -8 -11 -4 -11 14 0 33 14 47 18 19 2
            -12 -1 -27 -7 -33z m525 16 c-11 -11 -17 4 -9 23 7 18 8 18 11 1 2 -10 1 -21
            -2 -24z m779 17 c24 -27 13 -52 -22 -48 -31 3 -42 30 -23 53 16 20 23 19 45
            -5z m181 -6 c19 -28 -3 -60 -34 -50 -23 8 -39 47 -27 66 10 17 46 8 61 -16z
            m534 -15 c0 -11 -6 -19 -14 -19 -17 0 -31 34 -21 50 10 16 35 -8 35 -31z
            m-1914 -13 c-3 -14 -11 -26 -16 -26 -11 0 -13 30 -4 55 10 26 28 1 20 -29z
            m1752 16 c2 -7 -3 -12 -12 -12 -9 0 -16 7 -16 16 0 17 22 14 28 -4z m-1885
            -22 c-7 -28 -13 -25 -13 6 0 14 4 23 9 20 5 -3 7 -15 4 -26z m77 0 c0 -20 -5
            -30 -15 -30 -16 0 -19 17 -9 44 10 26 24 18 24 -14z m634 24 c14 -5 13 -9 -5
            -25 -12 -11 -28 -19 -35 -19 -22 0 -28 23 -9 37 21 15 27 16 49 7z m1754 -22
            c2 -15 -2 -22 -12 -22 -11 0 -16 9 -16 26 0 31 23 28 28 -4z m-2245 -12 c-3
            -11 -9 -23 -14 -26 -10 -6 -12 39 -3 49 12 12 22 -3 17 -23z m183 -26 c-3 -8
            -10 -14 -15 -14 -18 0 -19 28 -3 51 l17 24 3 -23 c2 -13 1 -30 -2 -38z m574
            41 c0 -8 -6 -15 -14 -15 -7 0 -19 7 -26 15 -11 13 -8 15 14 15 16 0 26 -5 26
            -15z m-1014 -53 c-12 -11 -17 7 -9 34 l8 29 3 -28 c2 -16 1 -31 -2 -35z m1211
            52 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-1296 -57 c-18 -18
            -25 1 -11 30 l14 28 4 -24 c2 -13 -1 -28 -7 -34z m925 -32 c-13 -50 -31 -4
            -26 65 1 24 2 24 17 -6 10 -21 13 -42 9 -59z m998 69 c19 -49 -7 -91 -39 -64
            -15 13 -21 61 -8 73 12 13 41 7 47 -9z m356 -15 c0 -10 -4 -19 -9 -19 -12 0
            -23 29 -15 41 8 14 24 0 24 -22z m148 -1 c-4 -37 -23 -48 -31 -17 -7 27 1 49
            20 49 10 0 13 -9 11 -32z m82 -8 c0 -16 -4 -31 -9 -35 -13 -7 -24 30 -16 55 9
            28 25 15 25 -20z m-2540 2 c0 -25 -4 -28 -30 -28 -25 0 -30 4 -30 23 0 20 3
            22 19 14 14 -8 20 -7 25 4 9 24 16 18 16 -13z m80 -3 c0 -10 -4 -19 -10 -19
            -5 0 -10 12 -10 26 0 14 4 23 10 19 6 -3 10 -15 10 -26z m1173 9 c7 -21 -4
            -38 -23 -38 -23 0 -34 16 -26 35 7 18 43 20 49 3z m543 -10 c7 -23 6 -99 -1
            -107 -13 -12 -35 26 -35 59 0 66 21 93 36 48z m459 1 c8 -23 1 -34 -14 -24
            -13 8 -15 45 -2 45 5 0 13 -9 16 -21z m145 2 c0 -11 5 -23 10 -26 6 -3 10 -14
            10 -23 0 -14 -2 -14 -14 -2 -20 20 -32 70 -17 70 6 0 11 -9 11 -19z m200 -33
            c0 -10 -4 -18 -10 -18 -14 0 -32 40 -25 57 5 14 7 14 20 -3 8 -10 15 -27 15
            -36z m-101 -20 c1 -10 -6 -18 -14 -18 -17 0 -18 4 -8 51 7 33 7 33 14 9 4 -14
            7 -33 8 -42z m-2091 17 c-6 -14 -15 -25 -20 -25 -12 0 -10 34 4 48 18 18 28 4
            16 -23z m-100 -31 l-12 -29 -4 28 c-4 31 20 73 26 45 2 -8 -3 -28 -10 -44z
            m1276 47 c9 -14 -4 -41 -19 -41 -18 0 -28 17 -21 35 6 17 32 20 40 6z m476
            -48 c0 -30 -18 -39 -35 -17 -18 23 -19 46 -3 62 14 14 37 -12 38 -45z m-1912
            20 c-4 -39 -28 -42 -28 -4 0 21 5 31 16 31 11 0 14 -8 12 -27z m42 3 c0 -26
            -15 -43 -24 -28 -7 12 4 52 15 52 5 0 9 -11 9 -24z m50 15 c0 -6 -4 -13 -10
            -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16 6 0 10 -4 10 -9z m21 -44 c-8 -8 -11 -4
            -11 14 0 33 14 47 18 19 2 -12 -1 -27 -7 -33z m427 24 c3 -22 -22 -61 -40 -61
            -4 0 -8 15 -8 33 0 54 41 77 48 28z m676 23 c16 -6 23 -33 9 -35 -5 0 -21 -2
            -38 -4 -16 -2 -25 -1 -19 3 6 4 13 15 17 25 7 19 9 20 31 11z m771 -15 c4 -11
            4 -23 0 -27 -9 -9 -40 13 -43 32 -5 24 35 20 43 -5z m-1028 0 c23 -23 -13 -71
            -47 -62 -24 6 -33 29 -20 54 12 21 50 25 67 8z m1266 -10 c6 -24 -1 -49 -14
            -49 -13 0 -31 40 -25 56 8 21 33 17 39 -7z m-2295 -53 c-5 -32 -23 -20 -23 14
            1 17 -3 30 -7 30 -4 0 -8 6 -8 13 1 6 10 1 21 -12 11 -13 19 -34 17 -45z
            m-115 40 c24 -18 23 -49 -1 -41 -16 4 -19 0 -20 -26 -2 -59 -12 -42 -12 19 0
            65 4 70 33 48z m64 -33 c-3 -10 -5 -2 -5 17 0 19 2 27 5 18 2 -10 2 -26 0 -35z
            m451 15 c-3 -34 -31 -51 -40 -26 -8 20 10 58 27 58 12 0 15 -8 13 -32z m90 -2
            c3 -27 -12 -56 -29 -56 -15 0 -21 47 -9 70 15 28 34 21 38 -14z m186 19 c-6
            -16 -24 -21 -24 -7 0 11 11 22 21 22 5 0 6 -7 3 -15z m1458 -8 c2 -12 3 -28 0
            -35 -7 -21 -32 7 -32 34 0 31 26 32 32 1z m-2215 -19 c-3 -7 -5 -2 -5 12 0 14
            2 19 5 13 2 -7 2 -19 0 -25z m113 22 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10
            0 6 5 10 10 10 6 0 10 -4 10 -10z m749 1 c22 -14 5 -68 -25 -75 -14 -4 -30 -4
            -35 0 -14 8 -2 56 18 72 20 14 24 15 42 3z m836 -12 c10 -31 -12 -49 -30 -26
            -15 21 -10 47 10 47 8 0 17 -9 20 -21z m-1360 -46 c-4 -11 -9 -13 -16 -6 -6 6
            -6 19 2 39 12 28 13 28 17 6 2 -13 1 -31 -3 -39z m1985 16 c0 -27 -18 -32 -30
            -8 -16 29 -5 56 15 39 8 -7 15 -21 15 -31z m260 11 c0 -20 -5 -30 -15 -30 -15
            0 -21 40 -8 53 14 15 23 6 23 -23z m-2350 -2 c0 -13 -4 -29 -9 -37 -13 -20
            -24 4 -17 35 8 30 26 32 26 2z m1658 -11 c4 -29 -12 -37 -22 -11 -10 26 -7 46
            7 42 6 -3 13 -17 15 -31z m-573 -17 c1 -16 -2 -30 -6 -30 -12 0 -22 46 -14 60
            10 16 18 5 20 -30z m1225 21 c0 -11 -4 -23 -10 -26 -6 -4 -10 5 -10 19 0 14 5
            26 10 26 6 0 10 -9 10 -19z m-561 -10 c8 -14 7 -22 -4 -31 -17 -14 -35 -5 -35
            19 0 34 24 42 39 12z m447 -27 c-7 -19 -22 -18 -30 3 -5 11 0 22 11 31 15 11
            18 11 21 -3 2 -9 1 -23 -2 -31z m-1675 -58 c13 -15 11 -26 -6 -26 -28 0 -45
            63 -28 107 5 13 9 6 16 -27 5 -25 13 -49 18 -54z m1752 82 c6 -19 -3 -38 -19
            -38 -8 0 -14 10 -14 25 0 26 25 35 33 13z m93 -35 c-10 -26 -18 -8 -9 23 5 21
            8 24 11 11 2 -10 1 -25 -2 -34z m-2416 23 c0 -24 -12 -46 -21 -40 -15 9 -10
            54 6 54 8 0 15 -6 15 -14z m215 3 c11 -16 -4 -59 -20 -59 -10 0 -15 10 -15 28
            0 39 20 56 35 31z m830 -19 c0 -31 -31 -39 -40 -11 -8 26 2 43 23 39 11 -2 17
            -13 17 -28z m303 18 c30 -30 4 -70 -39 -61 -28 5 -56 44 -45 62 10 15 69 14
            84 -1z m620 -17 c11 -32 1 -57 -21 -48 -15 6 -26 44 -19 65 7 22 30 13 40 -17z
            m-2028 -7 c0 -14 -4 -23 -10 -19 -5 3 -10 15 -10 26 0 10 5 19 10 19 6 0 10
            -12 10 -26z m140 17 c0 -19 -12 -41 -20 -36 -10 6 -1 45 11 45 5 0 9 -4 9 -9z
            m1355 -11 c7 -21 0 -40 -16 -40 -5 0 -9 14 -9 30 0 34 15 40 25 10z m-1635
            -21 c0 -16 -4 -28 -9 -25 -5 4 -12 0 -14 -6 -3 -7 -6 2 -6 20 -1 20 3 31 9 27
            6 -3 10 -1 10 4 0 6 2 11 5 11 3 0 5 -14 5 -31z m17 -6 c-3 -10 -5 -4 -5 12 0
            17 2 24 5 18 2 -7 2 -21 0 -30z m40 17 c-3 -11 -1 -20 4 -20 5 0 9 -11 9 -25
            0 -35 -16 -31 -24 6 -9 45 -8 59 5 59 6 0 9 -9 6 -20z m1078 -20 c-8 -14 -32
            -13 -41 2 -4 6 1 17 12 25 15 11 19 11 27 -2 4 -8 5 -20 2 -25z m1155 -11 c0
            -18 -5 -29 -14 -29 -16 0 -29 41 -20 64 10 25 34 0 34 -35z m90 30 c0 -11 -4
            -17 -10 -14 -5 3 -10 13 -10 21 0 8 5 14 10 14 6 0 10 -9 10 -21z m-1395 -22
            c-1 -12 -5 -21 -8 -19 -4 1 -14 2 -22 2 -20 0 -32 30 -15 40 21 13 47 0 45
            -23z m-665 13 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10
            -4 10 -10z m1510 -12 c0 -16 -3 -19 -11 -11 -6 6 -8 16 -5 22 11 17 16 13 16
            -11z m-470 -3 c0 -8 7 -15 15 -15 8 0 15 -4 15 -9 0 -14 -14 -21 -26 -13 -14
            9 -25 52 -13 52 5 0 9 -7 9 -15z m728 -29 c-2 -12 -10 -21 -18 -21 -16 0 -25
            28 -16 52 9 23 38 -4 34 -31z m486 29 c10 -28 -14 -60 -28 -37 -7 11 4 52 14
            52 4 0 10 -7 14 -15z m-2464 -25 c0 -16 -4 -30 -10 -30 -11 0 -14 43 -3 53 11
            12 13 8 13 -23z m250 23 c0 -5 -5 -15 -10 -23 -8 -12 -10 -11 -10 8 0 12 5 22
            10 22 6 0 10 -3 10 -7z m1980 -14 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5
            12 10 9 6 -3 10 -10 10 -16z m-2128 -14 c-2 -14 -8 -25 -13 -25 -5 0 -9 11 -9
            25 0 14 6 25 14 25 9 0 12 -8 8 -25z m367 1 c13 -14 21 -32 18 -43 -4 -16 -6
            -15 -12 4 -6 17 -13 22 -31 19 -20 -4 -24 -1 -24 20 0 31 20 31 49 0z m-609
            -28 c0 -10 -4 -18 -10 -18 -5 0 -10 15 -10 33 0 24 3 28 10 17 5 -8 10 -23 10
            -32z m60 21 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12 10 9 6 -3 10 -10
            10 -16z m1709 7 c15 -18 3 -46 -20 -46 -13 0 -19 7 -19 23 0 37 18 48 39 23z
            m735 -43 c4 -27 2 -43 -4 -43 -5 0 -10 7 -10 15 0 8 -5 24 -12 34 -12 20 -3
            60 11 45 5 -5 11 -28 15 -51z m54 45 c-6 -18 -28 -21 -28 -4 0 9 7 16 16 16 9
            0 14 -5 12 -12z m-1688 -8 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10
            10 10 6 0 10 -4 10 -10z m1150 -9 c12 -24 13 -41 0 -61 -7 -12 -12 -12 -29 4
            -28 25 -29 76 -1 76 10 0 24 -9 30 -19z m610 -3 c0 -16 -3 -19 -11 -11 -6 6
            -8 16 -5 22 11 17 16 13 16 -11z m-2440 -2 c0 -24 -12 -46 -21 -40 -15 9 -10
            54 6 54 8 0 15 -6 15 -14z m90 -15 c0 -17 -4 -32 -9 -35 -12 -8 -23 23 -16 45
            10 29 25 22 25 -10z m31 -8 c-9 -16 -10 -14 -11 12 0 21 3 26 11 18 8 -8 8
            -16 0 -30z m129 17 c-12 -22 -30 -18 -30 6 0 8 9 14 20 14 18 0 19 -3 10 -20z
            m68 -7 c-4 -34 -28 -43 -28 -10 0 25 7 37 22 37 5 0 8 -12 6 -27z m826 -6 c8
            -34 -31 -50 -53 -23 -9 11 -9 20 -2 34 14 25 47 18 55 -11z m932 7 c10 -37
            -12 -55 -26 -20 -14 37 -13 46 5 46 8 0 18 -12 21 -26z m159 6 c8 -24 -1 -40
            -21 -40 -9 0 -14 11 -14 30 0 35 25 42 35 10z m-2084 -57 c-9 -15 -10 -12 -11
            18 0 20 3 39 8 43 11 12 14 -42 3 -61z m1836 45 c-3 -8 -6 -5 -6 6 -1 11 2 17
            5 13 3 -3 4 -12 1 -19z m-2116 -41 c-8 -8 -11 -4 -11 14 0 33 14 47 18 19 2
            -12 -1 -27 -7 -33z m1849 34 c16 -31 12 -51 -10 -51 -23 0 -43 32 -34 54 8 22
            31 20 44 -3z m-1873 -28 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z
            m73 22 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10
            -15z m1040 -15 c0 -26 -4 -30 -27 -30 -43 0 -57 13 -44 39 8 14 21 21 41 21
            27 0 30 -3 30 -30z m560 9 c0 -23 -27 -36 -42 -21 -15 15 -4 37 21 39 15 1 21
            -4 21 -18z m654 5 c10 -25 7 -34 -9 -34 -9 0 -15 9 -15 25 0 28 15 34 24 9z
            m-1978 -19 c1 -8 -4 -19 -12 -26 -11 -9 -14 -8 -14 4 0 40 21 57 26 22z m2084
            -1 c0 -16 -16 -19 -25 -4 -8 13 4 32 16 25 5 -4 9 -13 9 -21z m-2469 -26 l-21
            -22 0 26 c0 17 6 28 18 30 25 5 26 -8 3 -34z m734 22 c7 -23 -10 -50 -31 -50
            -21 0 -33 31 -21 52 16 24 44 23 52 -2z m256 10 c5 0 9 -7 9 -15 0 -19 -46
            -20 -53 -1 -7 18 3 28 21 21 7 -3 18 -5 23 -5z m1572 4 c3 -4 1 -16 -4 -27 -9
            -15 -13 -17 -19 -7 -14 23 5 51 23 34z m37 -19 c0 -8 -4 -17 -10 -20 -6 -4
            -10 5 -10 20 0 15 4 24 10 20 6 -3 10 -12 10 -20z m34 16 c8 -12 -3 -41 -15
            -41 -5 0 -9 11 -9 25 0 25 14 34 24 16z m-310 -17 c11 -27 7 -37 -9 -24 -16
            14 -20 40 -6 40 5 0 12 -7 15 -16z m-2184 -8 c0 -8 -4 -17 -9 -21 -12 -7 -24
            12 -16 25 9 15 25 12 25 -4z m74 -24 c-4 -12 -10 -22 -15 -22 -11 0 -12 44 -2
            54 12 11 23 -9 17 -32z m356 9 c0 -31 -25 -65 -41 -56 -14 9 -11 38 7 63 21
            30 34 28 34 -7z m1002 5 c20 -30 -1 -49 -40 -35 -28 11 -40 38 -21 50 16 10
            51 2 61 -15z m644 -2 c8 -33 -9 -52 -31 -34 -18 15 -20 46 -2 53 22 10 27 7
            33 -19z m-1782 0 c9 -23 -2 -54 -20 -54 -9 0 -14 10 -14 28 0 42 22 58 34 26z
            m448 -9 c-7 -35 -45 -48 -56 -19 -9 22 13 46 38 42 15 -2 20 -9 18 -23z m348
            15 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z
            m1450 -16 c0 -8 -4 -14 -10 -14 -5 0 -10 9 -10 21 0 11 5 17 10 14 6 -3 10
            -13 10 -21z m-2607 -17 c-6 -33 -27 -31 -31 4 -2 17 0 20 6 11 7 -11 11 -10
            16 4 10 25 15 13 9 -19z m2055 -14 c3 -27 0 -31 -17 -26 -13 3 -21 14 -21 27
            0 48 33 46 38 -1z m-2103 -8 c1 -13 -3 -21 -7 -19 -4 3 -8 17 -8 31 0 32 15
            20 15 -12z m144 -7 c1 -5 -3 -8 -9 -8 -5 0 -10 12 -9 28 0 24 1 25 9 7 5 -11
            9 -23 9 -27z m81 35 c0 -5 -4 -15 -10 -23 -11 -18 -25 -7 -17 15 6 15 27 21
            27 8z m57 -30 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m183 18
            c0 -11 -4 -23 -10 -26 -6 -4 -10 5 -10 19 0 14 5 26 10 26 6 0 10 -9 10 -19z
            m1560 -12 c0 -20 -15 -26 -25 -9 -9 15 3 43 15 35 5 -3 10 -15 10 -26z m370 5
            c0 -14 -4 -23 -10 -19 -5 3 -10 15 -10 26 0 10 5 19 10 19 6 0 10 -12 10 -26z
            m50 -4 c0 -16 -4 -30 -10 -30 -11 0 -14 43 -3 53 11 12 13 8 13 -23z m-2473
            -27 c-3 -10 -5 -2 -5 17 0 19 2 27 5 18 2 -10 2 -26 0 -35z m109 11 c-5 -13
            -10 -14 -18 -6 -6 6 -8 18 -4 28 5 13 10 14 18 6 6 -6 8 -18 4 -28z m178 3
            c-7 -18 -24 -23 -24 -8 0 22 13 42 22 33 5 -5 6 -16 2 -25z m1022 -3 c-5 -13
            -9 -14 -21 -4 -20 16 -19 28 3 32 20 4 26 -6 18 -28z m518 17 c14 -15 15 -24
            6 -40 -15 -29 -44 -26 -59 5 -21 46 19 72 53 35z m436 -17 c0 -14 -4 -23 -10
            -19 -5 3 -10 15 -10 26 0 10 5 19 10 19 6 0 10 -12 10 -26z m260 1 c0 -25 -14
            -34 -24 -16 -8 12 3 41 15 41 5 0 9 -11 9 -25z m36 -12 c-11 -11 -19 6 -11 24
            8 17 8 17 12 0 3 -10 2 -21 -1 -24z m94 23 c0 -8 -4 -18 -10 -21 -5 -3 -10 3
            -10 14 0 12 5 21 10 21 6 0 10 -6 10 -14z m-670 -26 c13 -24 5 -41 -15 -33
            -16 6 -21 53 -5 53 5 0 14 -9 20 -20z m328 -26 c-5 -42 -28 -43 -28 -1 0 18 3
            37 7 41 12 12 25 -11 21 -40z m82 10 c0 -13 -4 -24 -10 -24 -5 0 -10 14 -10
            31 0 17 4 28 10 24 6 -3 10 -17 10 -31z m-2050 1 c0 -14 -4 -25 -10 -25 -11 0
            -14 33 -3 43 11 11 13 8 13 -18z m290 7 c0 -19 -18 -42 -32 -42 -10 0 -11 44
            -1 53 13 14 33 7 33 -11z m375 -1 c-3 -6 -11 -11 -16 -11 -5 0 -4 6 3 14 14
            16 24 13 13 -3z m74 5 c9 -10 8 -15 -4 -20 -21 -7 -45 3 -45 20 0 18 34 18 49
            0z m-1032 -28 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m1381 26
            c16 -5 22 -14 22 -36 0 -16 -4 -27 -8 -24 -4 2 -14 1 -22 -4 -19 -12 -50 11
            -50 37 0 18 10 30 30 32 3 0 15 -2 28 -5z m279 -26 c-3 -7 -5 -2 -5 12 0 14 2
            19 5 13 2 -7 2 -19 0 -25z m-1337 -18 c-15 -28 -23 -20 -14 15 4 17 10 23 16
            17 6 -6 6 -18 -2 -32z m1820 19 c0 -18 -2 -19 -15 -9 -8 7 -15 16 -15 21 0 5
            7 9 15 9 9 0 15 -9 15 -21z m420 -5 c0 -31 -23 -28 -28 4 -2 15 2 22 12 22 11
            0 16 -9 16 -26z m-2610 -21 c0 -32 -5 -28 -14 10 -4 19 -3 26 4 22 5 -3 10
            -18 10 -32z m2250 -4 c0 -11 -4 -19 -9 -19 -16 0 -33 30 -27 46 5 13 9 14 21
            4 8 -7 15 -21 15 -31z m-1839 -2 c-8 -8 -11 -7 -11 4 0 20 13 34 18 19 3 -7
            -1 -17 -7 -23z m889 11 c0 -19 -2 -20 -10 -8 -13 19 -13 30 0 30 6 0 10 -10
            10 -22z m1370 2 c0 -11 -4 -20 -10 -20 -5 0 -10 9 -10 20 0 11 5 20 10 20 6 0
            10 -9 10 -20z m-2472 -8 c2 -7 -3 -12 -12 -12 -9 0 -16 7 -16 16 0 17 22 14
            28 -4z m342 4 c0 -16 -18 -31 -27 -22 -8 8 5 36 17 36 5 0 10 -6 10 -14z
            m1370 -11 c0 -22 -32 -55 -52 -55 -16 0 -15 5 8 40 25 38 44 44 44 15z m-999
            1 c-6 -7 -8 -22 -4 -31 4 -13 3 -16 -5 -11 -7 4 -12 2 -12 -3 0 -6 -7 -11 -15
            -11 -8 0 -15 7 -15 15 0 25 24 55 44 55 14 0 16 -3 7 -14z m629 4 c0 -5 -4
            -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m810 -11 c0
            -11 -4 -17 -10 -14 -5 3 -10 13 -10 21 0 8 5 14 10 14 6 0 10 -9 10 -21z m120
            -9 c0 -10 -4 -21 -9 -25 -12 -7 -25 31 -16 45 9 15 25 2 25 -20z m64 22 c2 -4
            1 -14 -4 -21 -10 -17 -31 -5 -23 14 6 16 19 20 27 7z m-2407 -22 c1 -11 -2
            -22 -8 -25 -5 -4 -9 5 -9 19 0 30 15 35 17 6z m353 -4 c0 -30 -27 -55 -36 -33
            -6 17 12 57 26 57 6 0 10 -11 10 -24z m-463 -18 c-3 -7 -5 -2 -5 12 0 14 2 19
            5 13 2 -7 2 -19 0 -25z m68 23 c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18 6
            21 15 7z m68 -6 c4 -8 2 -17 -3 -20 -6 -4 -10 3 -10 14 0 25 6 27 13 6z m1063
            -43 c-15 -15 -16 -15 -16 4 0 28 24 52 28 28 2 -9 -3 -24 -12 -32z m324 38 c0
            -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9 -4 9 -10z m114 4
            c16 -6 23 -43 9 -45 -5 0 -16 -2 -25 -4 -22 -4 -42 20 -34 40 6 16 24 19 50 9z
            m424 -17 c2 -10 1 -25 -2 -34 -9 -23 -36 3 -36 34 0 18 5 24 17 21 10 -2 19
            -11 21 -21z m337 14 c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z
            m-2365 -33 c0 -5 -4 -8 -10 -8 -5 0 -10 10 -10 23 0 18 2 19 10 7 5 -8 10 -18
            10 -22z m610 8 c0 -18 -5 -23 -17 -21 -19 3 -19 25 0 37 17 11 17 11 17 -16z
            m118 -8 c5 -45 -49 -64 -65 -22 -4 11 2 24 17 36 29 23 44 19 48 -14z m1390
            20 c16 -16 15 -68 -1 -68 -21 0 -37 22 -37 52 0 30 17 37 38 16z m-1089 -20
            c12 -31 -5 -50 -42 -46 -62 6 -58 68 5 68 21 0 31 -6 37 -22z m255 6 c3 -8 2
            -12 -4 -9 -6 3 -10 10 -10 16 0 14 7 11 14 -7z m1296 -10 c0 -16 -16 -19 -25
            -4 -8 13 4 32 16 25 5 -4 9 -13 9 -21z m42 4 c-7 -7 -12 -8 -12 -2 0 14 12 26
            19 19 2 -3 -1 -11 -7 -17z m-2422 -14 c0 -14 -4 -23 -10 -19 -5 3 -10 15 -10
            26 0 10 5 19 10 19 6 0 10 -12 10 -26z m-60 1 c0 -8 -4 -15 -10 -15 -5 0 -10
            7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m2370 0 c0 -8 -4 -15 -10 -15 -5 0
            -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m-2559 -22 c-12 -20 -14 -14
            -5 12 4 9 9 14 11 11 3 -2 0 -13 -6 -23z m79 7 c0 -11 -4 -20 -10 -20 -5 0
            -10 9 -10 20 0 11 5 20 10 20 6 0 10 -9 10 -20z m30 10 c0 -5 -2 -10 -4 -10
            -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m50 -21 c0 -10 -4 -19
            -10 -19 -5 0 -10 12 -10 26 0 14 4 23 10 19 6 -3 10 -15 10 -26z m390 3 c0
            -32 -16 -53 -36 -45 -17 6 -18 27 -4 54 16 29 40 24 40 -9z m1438 16 c18 -23
            19 -62 1 -66 -23 -4 -49 24 -49 54 0 27 30 34 48 12z m-1578 -27 c0 -32 -18
            -51 -30 -32 -11 18 0 61 16 61 9 0 14 -11 14 -29z m1830 -1 c0 -30 -13 -40
            -25 -20 -8 13 4 50 16 50 5 0 9 -13 9 -30z m447 8 c-3 -8 -6 -5 -6 6 -1 11 2
            17 5 13 3 -3 4 -12 1 -19z m-2657 -13 c0 -14 -4 -25 -10 -25 -11 0 -14 33 -3
            43 11 11 13 8 13 -18z m247 -7 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19
            0 -25z m2143 28 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0
            10 -2 10 -4z m-2080 -31 c0 -14 -4 -25 -9 -25 -12 0 -23 29 -15 41 10 18 24 9
            24 -16z m1150 -10 c0 -12 -21 -25 -40 -25 -16 0 -19 30 -4 45 13 12 19 13 30
            4 8 -6 14 -17 14 -24z m990 5 c0 -11 -4 -20 -10 -20 -5 0 -10 9 -10 20 0 11 5
            20 10 20 6 0 10 -9 10 -20z m-100 0 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0
            6 5 10 10 10 6 0 10 -4 10 -10z m180 -5 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10
            15 0 8 5 15 10 15 6 0 10 -7 10 -15z m-2403 -35 c14 0 17 -12 6 -23 -11 -11
            -43 25 -42 47 0 20 1 20 11 -1 6 -13 17 -23 25 -23z m63 14 c0 -8 -4 -14 -10
            -14 -5 0 -10 9 -10 21 0 11 5 17 10 14 6 -3 10 -13 10 -21z m404 -17 c-7 -20
            -30 -23 -37 -4 -2 6 0 20 5 31 9 16 12 16 24 4 8 -8 12 -22 8 -31z m1204 11
            c3 -22 -1 -28 -15 -28 -11 0 -24 5 -31 12 -7 7 -12 20 -12 31 0 14 6 18 28 15
            21 -2 28 -9 30 -30z m506 7 c-4 -8 -10 -13 -15 -10 -4 3 -6 12 -3 20 4 8 10
            13 15 10 4 -3 6 -12 3 -20z m253 3 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4
            -12 1 -19z m-2527 3 c0 -6 -4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16 6
            0 10 -4 10 -9z m1140 -27 c0 -8 -2 -14 -4 -14 -2 0 -11 -3 -19 -6 -19 -7 -31
            24 -16 42 13 16 39 1 39 -22z m-1110 4 c0 -19 -2 -20 -10 -8 -13 19 -13 30 0
            30 6 0 10 -10 10 -22z m187 -45 c-10 -9 -13 -3 -20 37 l-6 35 16 -32 c9 -18
            13 -36 10 -40z m2010 51 c8 -22 -15 -44 -26 -25 -10 16 -4 41 10 41 5 0 12 -7
            16 -16z m-1389 -11 c2 -9 -7 -13 -27 -13 -21 0 -31 5 -31 16 0 19 51 16 58 -3z
            m750 -11 c4 -26 -6 -28 -37 -5 -23 16 -23 17 -5 24 27 11 39 6 42 -19z m480 5
            c2 -10 1 -25 -2 -34 -9 -23 -36 3 -36 34 0 18 5 24 17 21 10 -2 19 -11 21 -21z
            m60 -7 c3 -21 2 -22 -12 -11 -19 16 -21 44 -3 39 6 -3 14 -15 15 -28z m-1728
            -4 c0 -13 -7 -29 -15 -36 -13 -11 -15 -8 -15 17 0 16 3 33 7 36 13 14 23 6 23
            -17z m248 5 c3 -8 -1 -12 -9 -9 -7 2 -15 10 -17 17 -3 8 1 12 9 9 7 -2 15 -10
            17 -17z m1742 11 c0 -13 -12 -22 -22 -16 -10 6 -1 24 13 24 5 0 9 -4 9 -8z
            m-2068 -42 c4 0 8 -7 8 -15 0 -8 -3 -15 -7 -15 -9 0 -33 49 -32 64 0 6 5 1 11
            -11 6 -13 15 -23 20 -23z m686 29 c-2 -6 -8 -10 -13 -10 -5 0 -11 4 -13 10 -2
            6 4 11 13 11 9 0 15 -5 13 -11z m210 -35 c-2 -15 -8 -29 -14 -31 -17 -6 -27
            46 -13 63 17 20 31 3 27 -32z m1218 19 c-10 -10 -19 5 -10 18 6 11 8 11 12 0
            2 -7 1 -15 -2 -18z m114 13 c0 -16 -18 -31 -27 -22 -8 8 5 36 17 36 5 0 10 -6
            10 -14z m53 -6 c-3 -11 -7 -20 -9 -20 -2 0 -4 9 -4 20 0 11 4 20 9 20 5 0 7
            -9 4 -20z m-2543 -27 c0 -22 -2 -25 -10 -13 -13 20 -13 40 0 40 6 0 10 -12 10
            -27z m90 -4 c0 -10 -7 -19 -15 -19 -15 0 -21 31 -9 43 11 10 24 -3 24 -24z
            m110 21 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11
            -10z m205 -16 c0 -10 -8 -20 -17 -22 -18 -3 -26 27 -11 42 12 11 28 0 28 -20z
            m855 11 c0 -8 -9 -15 -20 -15 -22 0 -27 15 -7 23 21 9 27 7 27 -8z m1010 -10
            c0 -25 -14 -34 -24 -16 -8 12 3 41 15 41 5 0 9 -11 9 -25z m-2323 -22 c-3 -10
            -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m2253 22 c0 -8 -4 -15 -10 -15
            -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m-266 -11 c3 -9 6 -20 6
            -25 0 -14 -34 -11 -56 6 -16 13 -17 16 -4 25 22 15 47 12 54 -6z m-1817 -16
            c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m2013 -3 c0 -15 -14 -35
            -25 -35 -19 0 -27 17 -20 40 5 16 10 18 26 10 10 -6 19 -12 19 -15z m203 -12
            c-3 -7 -9 -13 -14 -13 -11 0 -12 34 -2 43 9 10 22 -13 16 -30z m77 18 c0 -20
            -13 -35 -23 -25 -7 8 4 44 14 44 5 0 9 -9 9 -19z m91 -4 c-10 -9 -11 -8 -5 6
            3 10 9 15 12 12 3 -3 0 -11 -7 -18z m-1091 -16 c0 -29 -18 -44 -49 -44 -39 0
            -55 14 -47 41 11 33 96 36 96 3z m-1430 4 c0 -8 -2 -15 -4 -15 -2 0 -6 7 -10
            15 -3 8 -1 15 4 15 6 0 10 -7 10 -15z m2487 -7 c-3 -8 -6 -5 -6 6 -1 11 2 17
            5 13 3 -3 4 -12 1 -19z m-2427 -10 c0 -18 -2 -20 -9 -8 -6 8 -7 18 -5 22 9 14
            14 9 14 -14z m538 -4 c4 -29 -23 -51 -43 -34 -16 13 -20 42 -8 54 14 14 48 1
            51 -20z m220 18 c15 -10 5 -42 -13 -42 -24 0 -45 12 -45 25 0 21 35 31 58 17z
            m1022 -7 c15 -18 9 -50 -9 -50 -19 0 -36 29 -28 49 8 20 21 21 37 1z m-1142
            -19 c3 -12 -1 -17 -10 -14 -7 3 -15 13 -16 22 -3 12 1 17 10 14 7 -3 15 -13
            16 -22z m350 -10 c-2 -15 -11 -22 -30 -24 -24 -3 -28 0 -28 22 0 15 7 26 18
            28 28 7 43 -3 40 -26z m-582 -24 c-12 -11 -18 7 -10 30 l8 23 4 -23 c2 -13 1
            -26 -2 -30z m1732 15 c-7 -14 -13 -17 -16 -9 -6 19 6 44 18 37 7 -4 6 -14 -2
            -28z m303 -10 c-8 -8 -11 -5 -11 9 0 27 14 41 18 19 2 -9 -1 -22 -7 -28z
            m-981 18 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10
            -15z m550 0 c7 -9 10 -18 7 -22 -8 -7 -37 15 -37 28 0 14 16 11 30 -6z m238 4
            c-2 -6 -8 -10 -13 -10 -5 0 -11 4 -13 10 -2 6 4 11 13 11 9 0 15 -5 13 -11z
            m138 -26 c-11 -11 -19 6 -11 24 8 17 8 17 12 0 3 -10 2 -21 -1 -24z m-909 5
            c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m1020 0 c-3 -8 -6 -5 -6
            6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-2407 2 c0 -5 -4 -10 -10 -10 -5 0 -10
            5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m50 -21 c0 -10 -4 -19 -9 -19 -12
            0 -23 29 -15 41 8 14 24 0 24 -22z m102 -21 c-15 -15 -27 14 -17 40 6 15 8 15
            18 -6 7 -16 7 -26 -1 -34z m39 -5 c-9 -15 -10 -13 -11 13 0 17 3 34 8 38 11
            12 14 -32 3 -51z m79 34 c0 -13 -7 -32 -15 -43 -12 -16 -16 -17 -24 -5 -9 15
            -5 37 12 59 15 19 27 14 27 -11z m1195 13 c3 -5 -1 -10 -9 -10 -9 0 -16 5 -16
            10 0 6 4 10 9 10 6 0 13 -4 16 -10z m766 -13 c-13 -13 -26 -3 -16 12 3 6 11 8
            17 5 6 -4 6 -10 -1 -17z m-1123 -5 c2 -7 -2 -10 -12 -6 -9 3 -16 11 -16 16 0
            13 23 5 28 -10z m772 -1 c10 -19 4 -51 -9 -51 -15 0 -31 25 -31 47 0 28 25 31
            40 4z m-1970 -6 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0
            10 -7 10 -15z m2090 5 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10
            6 0 10 -4 10 -10z m-476 -13 c25 -19 17 -52 -13 -52 -32 0 -49 28 -32 49 16
            19 22 20 45 3z m270 -3 c10 -25 -1 -29 -14 -5 -6 12 -7 21 -1 21 5 0 12 -7 15
            -16z m299 -4 c-3 -11 -7 -20 -9 -20 -2 0 -4 9 -4 20 0 11 4 20 9 20 5 0 7 -9
            4 -20z m-2143 -10 c0 -11 -4 -20 -10 -20 -5 0 -10 9 -10 20 0 11 5 20 10 20 6
            0 10 -9 10 -20z m2201 -23 c-8 -8 -11 -7 -11 4 0 9 3 19 7 23 4 4 9 2 11 -4 3
            -7 -1 -17 -7 -23z m56 1 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z
            m113 18 c0 -8 -4 -18 -10 -21 -5 -3 -10 3 -10 14 0 12 5 21 10 21 6 0 10 -6
            10 -14z m-2470 -22 c0 -8 -4 -14 -10 -14 -5 0 -10 9 -10 21 0 11 5 17 10 14 6
            -3 10 -13 10 -21z m660 11 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15
            10 15 6 0 10 -7 10 -15z m665 5 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2
            10 4 10 3 0 8 -4 11 -10z m1195 1 c0 -6 -4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9
            5 16 10 16 6 0 10 -4 10 -9z m-2490 -35 c-14 -14 -24 0 -16 21 6 15 8 15 16 3
            6 -9 5 -19 0 -24z m240 8 c0 -8 -4 -12 -10 -9 -5 3 -10 13 -10 21 0 8 5 12 10
            9 6 -3 10 -13 10 -21z m520 6 c0 -18 -3 -19 -20 -8 -14 9 -16 14 -7 20 20 13
            27 9 27 -12z m1284 5 c3 -8 1 -15 -4 -15 -6 0 -10 7 -10 15 0 8 2 15 4 15 2 0
            6 -7 10 -15z m346 3 c0 -17 -18 -44 -24 -37 -8 7 5 49 15 49 5 0 9 -6 9 -12z
            m-2340 -34 c0 -8 -4 -14 -10 -14 -5 0 -10 9 -10 21 0 11 5 17 10 14 6 -3 10
            -13 10 -21z m70 -5 c0 -11 -4 -17 -10 -14 -5 3 -10 13 -10 21 0 8 5 14 10 14
            6 0 10 -9 10 -21z m80 6 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10
            15 6 0 10 -7 10 -15z m985 1 c24 -18 13 -43 -19 -43 -20 0 -26 5 -26 22 0 36
            15 43 45 21z m545 5 c0 -14 -28 -33 -39 -27 -16 10 -1 36 20 36 10 0 19 -4 19
            -9z m234 -33 c-9 -14 -24 2 -24 25 1 21 1 21 15 3 8 -10 12 -23 9 -28z m540
            27 c-4 -8 -8 -15 -10 -15 -2 0 -4 7 -4 15 0 8 4 15 10 15 5 0 7 -7 4 -15z
            m-2422 -27 c-9 -9 -12 -7 -12 12 0 19 3 21 12 12 9 -9 9 -15 0 -24z m983 -19
            c-5 -16 -10 -18 -21 -8 -17 14 -18 46 -1 52 17 6 30 -19 22 -44z m741 19 c3
            -11 3 -22 0 -25 -9 -10 -36 18 -36 38 0 24 27 14 36 -13z m-1866 6 c0 -17 -22
            -14 -28 4 -2 7 3 12 12 12 9 0 16 -7 16 -16z m223 1 c4 -8 2 -17 -3 -20 -6 -4
            -10 3 -10 14 0 25 6 27 13 6z m112 6 c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0
            18 6 21 15 7z m155 -35 c-10 -28 -40 -34 -40 -7 0 27 30 57 41 40 4 -7 4 -22
            -1 -33z m1478 20 c5 -26 -16 -45 -28 -26 -5 8 -10 23 -10 33 0 24 34 18 38 -7z
            m190 7 c-5 -25 -28 -28 -28 -4 0 12 6 21 16 21 9 0 14 -7 12 -17z m60 6 c-2
            -6 -8 -10 -13 -10 -5 0 -11 4 -13 10 -2 6 4 11 13 11 9 0 15 -5 13 -11z
            m-1828 -35 c0 -23 -14 -32 -24 -16 -8 13 5 52 15 46 5 -3 9 -16 9 -30z m1054
            17 c11 -17 -6 -41 -29 -41 -30 0 -49 22 -33 38 14 14 54 16 62 3z m-1461 -24
            c3 -8 1 -20 -4 -28 -6 -10 -9 -7 -9 14 0 29 5 35 13 14z m102 2 c10 -15 -1
            -23 -20 -15 -9 3 -13 10 -10 16 8 13 22 13 30 -1z m915 -29 c0 -29 -28 -49
            -50 -35 -16 10 -12 53 7 63 26 15 43 3 43 -28z m1083 28 c6 -21 -4 -48 -19
            -48 -14 0 -20 41 -7 53 10 11 21 8 26 -5z m337 2 c0 -5 -4 -10 -10 -10 -5 0
            -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-2290 -27 c0 -22 -2 -25 -10
            -13 -13 20 -13 40 0 40 6 0 10 -12 10 -27z m37 5 c-3 -8 -6 -5 -6 6 -1 11 2
            17 5 13 3 -3 4 -12 1 -19z m113 -4 c0 -8 -4 -14 -9 -14 -11 0 -22 26 -14 34 9
            9 23 -3 23 -20z m396 18 c30 -20 18 -72 -16 -72 -24 0 -38 44 -20 65 14 17 20
            18 36 7z m1780 -17 c-3 -9 -11 -13 -16 -10 -8 5 -7 11 1 21 14 18 24 11 15
            -11z m-196 5 c0 -5 -7 -7 -15 -4 -8 4 -15 8 -15 10 0 2 7 4 15 4 8 0 15 -4 15
            -10z m70 -10 c0 -11 -4 -20 -10 -20 -5 0 -10 9 -10 20 0 11 5 20 10 20 6 0 10
            -9 10 -20z m53 0 c-3 -11 -9 -20 -14 -20 -5 0 -9 9 -9 20 0 11 6 20 14 20 9 0
            12 -7 9 -20z m-2183 -27 c0 -7 -4 -13 -10 -13 -5 0 -10 12 -10 28 0 21 2 24
            10 12 5 -8 10 -21 10 -27z m140 6 c0 -17 -4 -28 -10 -24 -5 3 -10 17 -10 31 0
            13 5 24 10 24 6 0 10 -14 10 -31z m186 -21 c-13 -18 -16 -19 -16 -5 0 9 5 17
            10 17 6 0 10 8 11 18 0 10 3 12 6 5 2 -7 -3 -23 -11 -35z m271 20 c-3 -8 -6
            -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m276 3 c2 -10 1 -25 -4 -32 -6 -10
            -9 -4 -9 19 0 36 5 42 13 13z m708 0 c23 -21 12 -35 -18 -24 -18 8 -28 43 -11
            43 5 0 18 -9 29 -19z m720 -18 c-9 -16 -10 -14 -11 12 0 21 3 26 11 18 8 -8 8
            -16 0 -30z m-367 18 c9 -14 -4 -41 -20 -41 -15 0 -17 10 -8 34 7 18 20 21 28
            7z m-944 -27 c0 -8 -9 -14 -20 -14 -19 0 -27 19 -14 33 10 9 34 -4 34 -19z
            m438 5 c3 -19 -21 -31 -43 -23 -8 4 -15 15 -15 26 0 15 6 19 27 16 16 -2 29
            -10 31 -19z m-1538 -22 c0 -5 -4 -5 -10 -2 -5 3 -10 14 -10 23 0 15 2 15 10 2
            5 -8 10 -19 10 -23z m517 11 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1
            -19z m685 10 c-9 -9 -15 -9 -24 0 -9 9 -7 12 12 12 19 0 21 -3 12 -12z m652
            -4 c11 -27 7 -37 -9 -24 -16 14 -20 40 -6 40 5 0 12 -7 15 -16z m71 -2 c0 -18
            -20 -15 -23 4 -3 10 1 15 10 12 7 -3 13 -10 13 -16z m255 4 c0 -16 -18 -31
            -27 -22 -8 8 5 36 17 36 5 0 10 -6 10 -14z m140 5 c0 -6 -4 -13 -10 -16 -5 -3
            -10 1 -10 9 0 9 5 16 10 16 6 0 10 -4 10 -9z m-2430 -27 c0 -31 -23 -28 -28 4
            -2 15 2 22 12 22 11 0 16 -9 16 -26z m2217 -11 c-17 -18 -22 -8 -11 22 5 12
            10 13 17 6 8 -8 6 -16 -6 -28z m253 28 c0 -6 -4 -13 -10 -16 -5 -3 -10 1 -10
            9 0 9 5 16 10 16 6 0 10 -4 10 -9z m17 -13 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13
            3 -3 4 -12 1 -19z m-624 -9 c-4 -15 -9 -17 -17 -9 -8 8 -7 13 4 20 8 5 16 10
            17 10 0 0 -1 -9 -4 -21z m485 0 c-2 -6 -8 -10 -13 -10 -5 0 -11 4 -13 10 -2 6
            4 11 13 11 9 0 15 -5 13 -11z m-908 -8 c0 -5 -9 -7 -20 -4 -11 3 -20 7 -20 9
            0 2 9 4 20 4 11 0 20 -4 20 -9z m-1040 -21 c0 -11 -4 -20 -10 -20 -5 0 -10 9
            -10 20 0 11 5 20 10 20 6 0 10 -9 10 -20z m1680 -1 c0 -11 -4 -17 -10 -14 -5
            3 -10 13 -10 21 0 8 5 14 10 14 6 0 10 -9 10 -21z m440 6 c0 -8 -4 -15 -10
            -15 -5 0 -7 7 -4 15 4 8 8 15 10 15 2 0 4 -7 4 -15z m-2350 -28 c0 -5 -4 -5
            -10 -2 -5 3 -10 14 -10 23 0 15 2 15 10 2 5 -8 10 -19 10 -23z m50 12 c0 -11
            -4 -17 -10 -14 -5 3 -10 13 -10 21 0 8 5 14 10 14 6 0 10 -9 10 -21z m83 -32
            c-3 -6 -12 -2 -20 8 -7 11 -13 15 -13 10 0 -5 -5 -4 -11 2 -6 6 -8 16 -4 23 5
            8 14 5 31 -11 13 -12 21 -26 17 -32z m2101 38 c-4 -8 -10 -15 -15 -15 -5 0 -9
            7 -9 15 0 8 7 15 15 15 9 0 12 -6 9 -15z m138 -7 c-7 -7 -12 -8 -12 -2 0 14
            12 26 19 19 2 -3 -1 -11 -7 -17z m-2192 -10 c0 -19 -2 -20 -10 -8 -13 19 -13
            30 0 30 6 0 10 -10 10 -22z m260 3 c0 -17 -18 -31 -41 -31 -5 0 -9 11 -9 25 0
            20 5 25 25 25 18 0 25 -5 25 -19z m1318 -29 c-2 -21 -8 -27 -28 -27 -18 0 -26
            6 -28 23 -5 34 15 54 40 41 13 -7 18 -19 16 -37z m-720 8 c-1 -36 -28 -66 -28
            -31 0 21 16 61 25 61 2 0 3 -14 3 -30z m142 -7 c0 -6 -7 -18 -15 -27 -31 -31
            -69 12 -39 45 13 15 20 16 35 6 10 -6 19 -17 19 -24z m780 27 c0 -5 -2 -10 -4
            -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m160 0 c0 -5 -4 -10
            -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-1202 -34 c2
            -10 -1 -23 -7 -29 -16 -16 -34 6 -26 33 8 24 28 22 33 -4z m-994 -2 c3 -8 2
            -12 -4 -9 -6 3 -10 10 -10 16 0 14 7 11 14 -7z m300 -27 c-9 -24 -24 -11 -24
            21 0 24 2 25 15 11 9 -9 13 -23 9 -32z m46 13 c0 -16 -4 -30 -10 -30 -11 0
            -14 43 -3 53 11 12 13 8 13 -23z m495 9 c9 -29 -7 -42 -30 -25 -11 8 -16 19
            -12 30 9 23 34 20 42 -5z m1385 7 c0 -15 -18 -31 -26 -23 -7 7 7 37 17 37 5 0
            9 -6 9 -14z m157 -18 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z
            m-2347 -4 c0 -8 -4 -14 -10 -14 -5 0 -10 9 -10 21 0 11 5 17 10 14 6 -3 10
            -13 10 -21z m388 -11 c-4 -43 -25 -55 -34 -19 -8 33 1 56 21 56 13 0 15 -9 13
            -37z m1009 21 c7 -18 -6 -45 -24 -51 -6 -2 -18 3 -27 12 -12 12 -13 21 -6 36
            13 23 48 25 57 3z m231 -6 c-4 -27 -23 -34 -32 -13 -8 21 1 35 20 35 10 0 14
            -8 12 -22z m-1408 -4 c0 -8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6
            0 10 -7 10 -16z m1790 -10 c0 -8 -4 -14 -10 -14 -5 0 -10 9 -10 21 0 11 5 17
            10 14 6 -3 10 -13 10 -21z m-1880 -15 c0 -21 -16 -26 -23 -6 -6 17 3 38 14 31
            5 -3 9 -14 9 -25z m-103 -11 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0
            -25z m586 13 c6 -24 -7 -46 -22 -37 -16 10 -14 56 3 56 7 0 16 -9 19 -19z
            m1697 15 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10
            -4z m-2237 -26 c3 -11 1 -20 -4 -20 -5 0 -9 9 -9 20 0 11 2 20 4 20 2 0 6 -9
            9 -20z m1384 -12 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m379
            -5 c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2 -7 1 -15 -2 -18z m72 3 c5 -22 -19
            -40 -30 -23 -4 7 -8 20 -8 30 0 25 34 18 38 -7z m65 18 c9 -10 -4 -34 -19 -34
            -8 0 -14 6 -14 13 0 22 19 35 33 21z m517 -4 c0 -5 -5 -10 -11 -10 -5 0 -7 5
            -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-2280 -36 c0 -13 -4 -24 -9 -24 -13 0
            -24 37 -16 50 10 16 25 1 25 -26z m60 0 c0 -8 -6 -11 -15 -8 -8 4 -15 12 -15
            20 0 8 6 11 15 8 8 -4 15 -12 15 -20z m860 9 c0 -10 -4 -14 -8 -8 -4 6 -11 13
            -16 18 -5 4 -2 7 7 7 10 0 17 -8 17 -17z m1090 13 c0 -3 -4 -8 -10 -11 -5 -3
            -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-1698 -58 c-14 -14 -32 -1 -32
            21 0 28 12 33 28 10 10 -14 11 -24 4 -31z m1531 27 c-3 -8 -9 -15 -14 -15 -10
            0 -12 24 -3 33 11 11 24 -3 17 -18z m-803 5 c0 -5 -4 -10 -9 -10 -6 0 -13 5
            -16 10 -3 6 1 10 9 10 9 0 16 -4 16 -10z m870 1 c0 -6 -4 -13 -10 -16 -5 -3
            -10 1 -10 9 0 9 5 16 10 16 6 0 10 -4 10 -9z m-1127 -27 c-8 -21 -23 -17 -23
            6 0 13 5 18 14 14 8 -3 12 -12 9 -20z m727 -15 c0 -5 -4 -9 -10 -9 -5 0 -10 7
            -10 16 0 8 5 12 10 9 6 -3 10 -10 10 -16z m-1580 -5 c0 -8 -4 -12 -10 -9 -5 3
            -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10 -16z m1825 6 c3 -5 -1 -20 -9 -32
            -14 -19 -16 -19 -22 -5 -12 32 15 64 31 37z m-1937 -25 c-3 -3 -9 2 -12 12 -6
            14 -5 15 5 6 7 -7 10 -15 7 -18z m37 15 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10
            0 6 2 10 4 10 3 0 8 -4 11 -10z m1493 -17 c4 -24 -22 -47 -43 -39 -20 7 -19
            43 3 55 23 14 36 9 40 -16z m246 21 c17 -6 23 -34 8 -34 -5 0 -17 9 -27 20
            -18 20 -11 25 19 14z m338 -16 c-7 -7 -12 -8 -12 -2 0 14 12 26 19 19 2 -3 -1
            -11 -7 -17z m-1869 -19 c13 -9 0 -42 -13 -34 -5 3 -10 21 -9 38 0 18 3 25 6
            17 3 -8 10 -18 16 -21z m140 6 c-3 -8 -9 -15 -14 -15 -10 0 -12 24 -3 33 11
            11 24 -3 17 -18z m28 -8 c-8 -8 -11 -7 -11 4 0 20 13 34 18 19 3 -7 -1 -17 -7
            -23z m472 10 c5 -15 -10 -57 -20 -57 -18 0 -34 40 -23 60 11 20 35 18 43 -3z
            m247 19 c0 -2 3 -11 6 -19 7 -19 -27 -32 -38 -15 -14 22 -9 38 12 38 11 0 20
            -2 20 -4z m1061 -39 c-8 -8 -11 -7 -11 4 0 9 3 19 7 23 4 4 9 2 11 -4 3 -7 -1
            -17 -7 -23z m-2301 8 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15
            6 0 10 -7 10 -15z m426 -12 c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2 -7 1 -15
            -2 -18z m419 -3 c0 -32 -30 -39 -45 -10 -14 26 -4 42 23 38 16 -2 22 -10 22
            -28z m1135 20 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10
            -4 10 -10z m188 -11 c-2 -6 -8 -10 -13 -10 -5 0 -11 4 -13 10 -2 6 4 11 13 11
            9 0 15 -5 13 -11z m292 1 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10
            10 6 0 10 -4 10 -10z m-2190 -32 c-1 -28 -2 -30 -11 -15 -6 10 -8 25 -5 33 9
            24 16 16 16 -18z m1124 26 c19 -7 21 -28 3 -43 -19 -15 -53 10 -44 33 7 17 16
            20 41 10z m-1304 -30 c0 -8 -4 -12 -10 -9 -5 3 -10 13 -10 21 0 8 5 12 10 9 6
            -3 10 -13 10 -21z m120 11 c0 -8 -2 -15 -4 -15 -2 0 -6 7 -10 15 -3 8 -1 15 4
            15 6 0 10 -7 10 -15z m1880 1 c0 -8 -4 -18 -10 -21 -5 -3 -10 3 -10 14 0 12 5
            21 10 21 6 0 10 -6 10 -14z m-1770 -37 c0 -19 -5 -29 -15 -29 -15 0 -20 26 -9
            54 9 24 24 9 24 -25z m732 -1 c-9 -9 -12 -7 -12 12 0 19 3 21 12 12 9 -9 9
            -15 0 -24z m-1108 6 c11 -29 6 -44 -14 -44 -21 0 -40 27 -33 48 7 17 40 15 47
            -4z m111 7 c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z m335 -15 c0
            -29 -19 -44 -30 -25 -9 14 5 49 20 49 6 0 10 -11 10 -24z m254 18 c20 -8 21
            -60 1 -68 -17 -6 -35 22 -35 53 0 22 8 25 34 15z m1152 -37 c-3 -26 -8 -32
            -30 -34 -20 -1 -26 3 -26 18 0 34 18 58 40 52 15 -4 19 -12 16 -36z m410 -3
            c-4 -9 -9 -15 -11 -12 -3 3 -3 13 1 22 4 9 9 15 11 12 3 -3 3 -13 -1 -22z
            m-2416 -9 c0 -8 -4 -15 -9 -15 -13 0 -22 16 -14 24 11 11 23 6 23 -9z m700 5
            c31 -17 37 -90 6 -90 -13 0 -36 54 -36 83 0 20 4 21 30 7z m760 0 c0 -5 -4
            -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m670 -21 c0
            -10 -7 -19 -15 -19 -15 0 -21 31 -9 43 11 10 24 -3 24 -24z m78 3 c2 -13 -3
            -25 -13 -28 -23 -9 -34 7 -24 34 11 28 33 24 37 -6z m-1418 -25 c0 -5 -4 -5
            -10 -2 -5 3 -10 14 -10 23 0 15 2 15 10 2 5 -8 10 -19 10 -23z m-553 1 c-3 -8
            -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m73 -31 c0 -33 -25 -12 -28 24
            -2 32 -1 33 13 15 8 -11 15 -28 15 -39z m30 28 c0 -14 -2 -25 -4 -25 -2 0 -6
            11 -8 25 -3 14 -1 25 3 25 5 0 9 -11 9 -25z m213 -6 c3 -13 1 -19 -6 -16 -15
            5 -22 37 -9 37 5 0 12 -9 15 -21z m854 15 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6
            11 1 17 -2 13 -5z m-911 -31 c-7 -7 -26 7 -26 19 0 6 6 6 15 -2 9 -7 13 -15
            11 -17z m638 11 c9 -22 8 -24 -9 -24 -8 0 -15 9 -15 20 0 24 15 27 24 4z m74
            -11 c4 -24 -24 -39 -32 -17 -7 19 4 46 18 41 6 -2 12 -13 14 -24z m1580 4 c2
            -10 -3 -17 -12 -17 -10 0 -16 9 -16 21 0 24 23 21 28 -4z m-2418 -20 c0 -12
            -5 -29 -10 -37 -7 -11 -10 -3 -10 28 0 26 4 41 10 37 6 -3 10 -16 10 -28z
            m1410 12 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12 10 9 6 -3 10 -10 10
            -16z m-736 -6 c4 -9 2 -21 -3 -26 -12 -12 -44 6 -47 27 -3 22 42 21 50 -1z
            m298 2 c0 -5 -5 -11 -11 -13 -6 -2 -11 4 -11 13 0 9 5 15 11 13 6 -2 11 -8 11
            -13z m-1472 -5 c0 -5 -9 -14 -21 -20 -19 -11 -20 -10 -9 9 11 22 30 29 30 11z
            m136 -17 c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2 -7 1 -15 -2 -18z m102 1 c2
            -11 -4 -26 -12 -33 -12 -10 -17 -8 -26 9 -6 12 -9 28 -6 37 8 21 40 12 44 -13z
            m2102 7 c0 -13 -29 -35 -37 -28 -7 8 15 37 28 37 5 0 9 -4 9 -9z m-1997 -14
            c3 -8 1 -20 -4 -28 -11 -19 -34 3 -26 25 8 20 23 21 30 3z m217 5 c0 -5 -10
            -19 -21 -31 -12 -13 -28 -42 -35 -65 -7 -22 -13 -32 -13 -21 -1 32 25 97 45
            112 21 15 24 16 24 5z m1841 -5 c-1 -4 -1 -14 -1 -22 0 -8 -7 -15 -15 -15 -16
            0 -19 9 -9 35 6 15 26 17 25 2z m-991 -18 c0 -6 -4 -7 -10 -4 -5 3 -10 11 -10
            16 0 6 5 7 10 4 6 -3 10 -11 10 -16z m785 -37 c-11 -11 -25 15 -19 33 7 17 8
            16 16 -5 5 -13 6 -25 3 -28z m-1775 12 c0 -8 -4 -12 -10 -9 -5 3 -10 13 -10
            21 0 8 5 12 10 9 6 -3 10 -13 10 -21z m1250 -3 c0 -33 -11 -44 -34 -35 -18 7
            -30 39 -21 54 3 5 17 10 31 10 20 0 24 -5 24 -29z m1184 7 c7 -30 -6 -41 -29
            -24 -11 8 -16 19 -12 30 9 23 34 20 41 -6z m-2186 -21 c-4 -44 -28 -47 -28 -4
            0 24 5 34 16 34 11 0 14 -9 12 -30z m62 3 c0 -22 -16 -35 -25 -20 -9 14 4 52
            16 45 5 -4 9 -15 9 -25z m40 -11 c0 -18 -4 -28 -10 -24 -5 3 -10 20 -10 36 0
            18 4 28 10 24 6 -3 10 -20 10 -36z m-690 21 c20 -13 2 -44 -29 -48 -24 -4 -30
            25 -9 46 14 14 18 15 38 2z m903 -15 c-7 -21 -13 -19 -13 6 0 11 4 18 10 14 5
            -3 7 -12 3 -20z m327 15 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16
            10 5 0 9 -4 9 -10z m-1410 -14 c0 -8 -4 -17 -9 -21 -12 -7 -24 12 -16 25 9 15
            25 12 25 -4z m278 -5 c3 -8 -1 -12 -9 -9 -7 2 -15 10 -17 17 -3 8 1 12 9 9 7
            -2 15 -10 17 -17z m1362 9 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10
            10 10 6 0 10 -4 10 -10z m-1576 -15 c-4 -8 -10 -15 -15 -15 -4 0 -6 7 -3 15 4
            8 10 15 15 15 4 0 6 -7 3 -15z m974 -3 c2 -7 -2 -10 -12 -6 -9 3 -16 11 -16
            16 0 13 23 5 28 -10z m2069 1 c4 -11 -26 -27 -35 -19 -2 3 -2 12 2 21 6 18 27
            16 33 -2z m-2709 -16 c2 -12 -3 -17 -17 -17 -15 0 -21 6 -21 21 0 25 33 22 38
            -4z m177 -34 c-6 -16 -8 -16 -16 5 -4 12 -5 30 -2 39 6 15 8 14 16 -5 5 -11 6
            -29 2 -39z m895 48 c0 -6 -4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16 6
            0 10 -4 10 -9z m950 -7 c0 -17 -22 -14 -28 4 -2 7 3 12 12 12 9 0 16 -7 16
            -16z m-850 -4 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10
            -4 10 -10z m945 -11 c9 -27 -19 -59 -36 -42 -15 15 -3 63 16 63 7 0 17 -10 20
            -21z m42 -11 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m271 10
            c-4 -29 -38 -38 -38 -10 0 19 11 29 33 31 5 1 7 -9 5 -21z m-2153 -26 c0 -46
            -19 -44 -23 2 -3 28 0 37 10 34 7 -3 13 -19 13 -36z m1950 28 c-3 -5 -10 -10
            -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8 0 12 -4 9 -10z m-2149 -15 c4 -8 3
            -22 0 -30 -8 -22 -46 -1 -46 25 0 24 38 28 46 5z m1644 -21 c0 -9 -6 -22 -14
            -30 -13 -12 -17 -12 -30 2 -19 18 -20 36 -4 52 14 14 48 -2 48 -24z m224 21
            c3 -8 1 -15 -4 -15 -6 0 -10 7 -10 15 0 8 2 15 4 15 2 0 6 -7 10 -15z m-2008
            -20 c11 -27 -1 -39 -19 -21 -17 17 -15 36 4 36 5 0 12 -7 15 -15z m191 -17
            c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m828 -3 c0 -24 -5 -31
            -23 -33 -27 -4 -38 19 -22 48 17 31 45 22 45 -15z m1653 13 c-2 -13 -11 -24
            -21 -26 -22 -4 -35 27 -16 39 25 16 41 10 37 -13z m192 7 c0 -8 -7 -15 -15
            -15 -26 0 -18 20 13 29 1 1 2 -6 2 -14z m-3290 -10 c0 -8 -7 -15 -15 -15 -26
            0 -18 20 13 29 1 1 2 -6 2 -14z m200 -1 c0 -8 -4 -12 -10 -9 -5 3 -10 10 -10
            16 0 5 5 9 10 9 6 0 10 -7 10 -16z m140 -4 c0 -13 -7 -20 -20 -20 -13 0 -20 7
            -20 20 0 13 7 20 20 20 13 0 20 -7 20 -20z m-206 -13 c8 -13 7 -20 -4 -27 -16
            -10 -30 4 -30 33 0 22 19 18 34 -6z m595 -24 c1 -7 -3 -13 -9 -13 -5 0 -10 15
            -9 33 0 27 2 29 9 12 5 -11 9 -26 9 -32z m1561 37 c0 -5 -2 -10 -4 -10 -3 0
            -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m447 -12 c-3 -8 -6 -5 -6 6 -1
            11 2 17 5 13 3 -3 4 -12 1 -19z m253 12 c0 -5 -9 -14 -20 -20 -22 -12 -27 -1
            -8 18 14 14 28 16 28 2z m-1202 -11 c-2 -6 -8 -10 -13 -10 -5 0 -11 4 -13 10
            -2 6 4 11 13 11 9 0 15 -5 13 -11z m-1733 -18 c-3 -6 -11 -8 -17 -5 -6 4 -5 9
            3 15 16 10 23 4 14 -10z m1835 -1 c0 -11 -7 -20 -15 -20 -15 0 -21 21 -8 33
            12 13 23 7 23 -13z m940 -5 c-7 -9 -15 -13 -18 -10 -3 2 1 11 8 20 7 9 15 13
            18 10 3 -2 -1 -11 -8 -20z m-1990 0 c0 -9 -8 -19 -17 -22 -10 -4 -21 -11 -25
            -17 -5 -8 -8 -7 -8 1 0 10 38 53 47 53 2 0 3 -7 3 -15z m-863 -27 c-3 -7 -5
            -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m3243 4 c0 -13 -23 -32 -37 -32
            -13 0 -18 41 -7 53 8 7 44 -10 44 -21z m-2719 2 c10 -13 10 -17 -1 -24 -18
            -11 -41 7 -33 26 7 18 17 18 34 -2z m201 -50 c-8 -15 -11 -12 -17 14 -3 17 -3
            37 1 44 12 19 27 -38 16 -58z m248 46 c0 -11 -2 -20 -4 -20 -2 0 -6 9 -9 20
            -3 11 -1 20 4 20 5 0 9 -9 9 -20z m380 10 c0 -5 -4 -10 -9 -10 -6 0 -13 5 -16
            10 -3 6 1 10 9 10 9 0 16 -4 16 -10z m1044 -5 c8 -22 -11 -65 -29 -65 -18 0
            -20 33 -3 60 15 24 24 25 32 5z m319 -31 c-3 -8 -11 -14 -18 -14 -19 0 -27 30
            -13 47 10 13 14 13 24 -2 7 -9 10 -23 7 -31z m-2415 12 c3 -13 -1 -17 -14 -14
            -11 2 -20 11 -22 22 -3 13 1 17 14 14 11 -2 20 -11 22 -22z m150 6 c2 -7 -3
            -12 -12 -12 -9 0 -16 7 -16 16 0 17 22 14 28 -4z m166 9 c8 -13 -4 -42 -15
            -35 -12 7 -12 44 0 44 6 0 12 -4 15 -9z m490 -13 c11 -41 7 -58 -14 -58 -27 0
            -44 34 -30 60 14 27 36 25 44 -2z m76 7 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10
            15 0 8 5 15 10 15 6 0 10 -7 10 -15z m2200 5 c0 -5 -4 -10 -10 -10 -5 0 -10 5
            -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-1793 -32 c-3 -7 -5 -2 -5 12 0 14
            2 19 5 13 2 -7 2 -19 0 -25z m-1367 -18 c0 -13 -1 -13 -10 0 -5 8 -10 22 -10
            30 0 13 1 13 10 0 5 -8 10 -22 10 -30z m800 24 c0 -8 -5 -12 -10 -9 -6 4 -8
            11 -5 16 9 14 15 11 15 -7z m1564 0 c5 -14 4 -15 -9 -4 -17 14 -19 20 -6 20 5
            0 12 -7 15 -16z m524 4 c-3 -7 -12 -14 -21 -16 -12 -2 -15 1 -11 12 7 19 38
            22 32 4z m-2978 -13 c0 -8 -4 -15 -9 -15 -13 0 -22 16 -14 24 11 11 23 6 23
            -9z m565 -6 c4 -11 4 -23 0 -27 -7 -7 -25 17 -25 36 0 20 18 14 25 -9z m235
            -24 c0 -42 -16 -54 -31 -22 -8 18 -8 30 0 46 16 29 31 17 31 -24z m-860 10
            c19 -22 -5 -48 -31 -34 -30 15 -22 44 12 48 4 1 12 -6 19 -14z m348 -8 c2 -9
            -4 -20 -13 -23 -20 -8 -50 19 -40 36 10 17 49 8 53 -13z m382 17 c0 -3 -13
            -22 -29 -42 -15 -20 -35 -55 -44 -77 -16 -39 -16 -39 -16 -10 -1 31 60 135 79
            135 6 0 10 -3 10 -6z m1007 -10 c9 -23 -11 -38 -26 -20 -13 16 -7 36 10 36 5
            0 12 -7 16 -16z m681 -21 c-2 -20 -8 -38 -15 -40 -18 -6 -28 44 -13 62 21 25
            31 17 28 -22z m282 20 c0 -23 -17 -49 -26 -41 -9 10 4 58 16 58 6 0 10 -8 10
            -17z m230 -8 c0 -20 -5 -25 -25 -25 -18 0 -25 5 -25 18 0 10 3 22 7 25 3 4 15
            7 25 7 13 0 18 -7 18 -25z m-2420 -10 c0 -8 -7 -15 -15 -15 -16 0 -20 12 -8
            23 11 12 23 8 23 -8z m890 5 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10
            4 10 6 0 11 -4 11 -10z m1986 -28 c-10 -10 -29 15 -21 28 6 9 10 8 17 -4 6
            -10 7 -20 4 -24z m-3470 -6 c1 -9 -4 -12 -12 -9 -8 3 -14 15 -14 26 0 16 3 18
            12 9 6 -6 13 -18 14 -26z m994 7 c0 -20 -3 -24 -11 -16 -7 7 -9 19 -6 27 10
            24 17 19 17 -11z m420 18 c0 -18 -18 -21 -30 -6 -10 13 -9 15 9 15 11 0 21 -4
            21 -9z m552 3 c9 -9 -3 -44 -16 -44 -22 0 -39 18 -33 34 6 15 38 22 49 10z
            m-1774 -27 c2 -12 -3 -17 -17 -17 -12 0 -21 6 -21 13 0 31 32 34 38 4z m2688
            -12 c-7 -18 -26 -20 -26 -2 0 18 24 41 28 27 2 -6 1 -17 -2 -25z m304 4 c0
            -12 -7 -19 -20 -19 -22 0 -26 20 -8 38 15 15 28 6 28 -19z m-2867 -5 c-4 -10
            -10 -11 -24 -3 -24 12 -16 29 10 22 12 -3 17 -11 14 -19z m2486 -18 c-9 -8
            -10 -7 -5 7 3 10 7 24 7 30 1 7 3 4 6 -7 3 -10 -1 -23 -8 -30z m561 20 c0 -24
            -23 -41 -41 -30 -16 9 -10 42 9 47 30 8 32 7 32 -17z m-3010 4 c0 -5 -4 -10
            -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m334 -5 c8 -22
            -4 -47 -21 -41 -8 3 -17 6 -19 6 -2 0 -4 11 -4 25 0 18 5 25 19 25 10 0 21 -7
            25 -15z m180 -1 c11 -28 6 -64 -8 -64 -18 0 -27 26 -20 56 7 28 19 31 28 8z
            m-624 -14 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4
            -10z m260 -11 c0 -25 -33 -22 -38 4 -2 12 3 17 17 17 15 0 21 -6 21 -21z
            m2440 1 c0 -11 -7 -20 -15 -20 -27 0 -18 30 13 39 1 1 2 -8 2 -19z m-2530 -6
            c0 -17 -22 -14 -28 4 -2 7 3 12 12 12 9 0 16 -7 16 -16z m2150 6 c0 -5 -4 -10
            -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-1986 -8 c2 -4
            1 -14 -4 -22 -7 -11 -12 -12 -21 -3 -6 6 -8 16 -5 22 8 13 23 14 30 3z m1206
            -13 c0 -5 -7 -9 -15 -9 -15 0 -20 12 -9 23 8 8 24 -1 24 -14z m940 0 c0 -6 -4
            -7 -10 -4 -5 3 -10 11 -10 16 0 6 5 7 10 4 6 -3 10 -11 10 -16z m306 -8 c-8
            -13 -36 -15 -36 -3 0 4 5 13 12 20 14 14 34 0 24 -17z m64 19 c0 -5 -5 -10
            -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m445 1 c3 -5 1 -12
            -5 -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z m-3605 -22 c0 -10 -5 -21 -11 -25
            -14 -9 -33 11 -26 29 7 20 37 17 37 -4z m1563 -15 c-8 -21 -43 -18 -43 5 0 11
            6 21 13 24 17 6 37 -12 30 -29z m725 14 c-4 -29 -38 -38 -38 -10 0 22 8 32 27
            32 9 0 13 -8 11 -22z m-2150 -5 c-2 -10 -10 -18 -18 -18 -8 0 -16 8 -18 18 -2
            12 3 17 18 17 15 0 20 -5 18 -17z m1290 -16 c3 -25 0 -28 -20 -25 -17 2 -24
            11 -26 31 -3 25 0 28 20 25 17 -2 24 -11 26 -31z m2112 18 c0 -8 -4 -15 -10
            -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m-3620 -16 c0 -5 -4
            -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12 10 9 6 -3 10 -10 10 -16z m498 5 c2 -1
            2 -10 0 -20 -3 -16 -4 -17 -20 -1 -9 9 -24 17 -33 17 -9 0 -13 5 -10 10 5 8
            52 4 63 -6z m2186 -8 c-6 -16 -24 -23 -24 -8 0 10 22 33 27 28 2 -2 1 -11 -3
            -20z m-2299 -11 c0 -25 -40 -33 -49 -10 -9 24 2 37 27 33 14 -2 22 -10 22 -23z
            m1660 15 c3 -5 -3 -10 -15 -10 -12 0 -18 5 -15 10 3 6 10 10 15 10 5 0 12 -4
            15 -10z m945 1 c0 -5 -7 -11 -15 -15 -15 -5 -20 5 -8 17 9 10 23 9 23 -2z
            m-3080 -11 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4
            10 -10z m381 -23 c-16 -16 -26 0 -10 19 9 11 15 12 17 4 2 -6 -1 -17 -7 -23z
            m569 17 c0 -8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10
            -16z m88 -26 c4 -46 -23 -61 -32 -17 -3 16 -3 36 1 44 10 26 28 11 31 -27z
            m1526 25 c9 -22 -10 -41 -25 -26 -13 13 -5 43 10 43 5 0 12 -8 15 -17z m786 2
            c16 -19 -3 -45 -31 -45 -21 0 -24 12 -9 41 13 23 23 24 40 4z m167 -1 c8 -21
            -13 -42 -28 -27 -13 13 -5 43 11 43 6 0 13 -7 17 -16z m-2629 -12 c2 -7 -3
            -12 -12 -12 -9 0 -16 7 -16 16 0 17 22 14 28 -4z m2282 2 c0 -2 -7 -4 -15 -4
            -8 0 -15 4 -15 10 0 5 7 7 15 4 8 -4 15 -8 15 -10z m260 7 c0 -12 -20 -25 -27
            -18 -7 7 6 27 18 27 5 0 9 -4 9 -9z m205 -10 c-8 -13 -35 -15 -35 -2 0 5 8 11
            18 13 21 5 26 2 17 -11z m-2965 -12 c0 -11 -4 -17 -10 -14 -5 3 -10 13 -10 21
            0 8 5 14 10 14 6 0 10 -9 10 -21z m646 -13 c3 -18 0 -26 -10 -26 -19 0 -29 30
            -15 47 14 17 18 13 25 -21z m98 18 c7 -18 0 -34 -15 -34 -5 0 -9 11 -9 25 0
            28 15 34 24 9z m1466 -20 c0 -27 -25 -43 -42 -26 -8 8 -8 18 0 37 14 31 42 23
            42 -11z m-2140 6 c0 -13 -5 -18 -14 -14 -8 3 -12 12 -9 20 8 21 23 17 23 -6z
            m480 -10 c0 -10 -4 -22 -10 -25 -6 -4 -10 7 -10 25 0 18 4 29 10 25 6 -3 10
            -15 10 -25z m1070 20 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10
            6 0 10 -4 10 -10z m-1455 -21 c8 -24 7 -26 -20 -31 -15 -2 -21 3 -23 25 -3 21
            1 27 17 27 11 0 22 -9 26 -21z m2269 -16 c-15 -24 -34 -28 -34 -6 0 26 21 46
            34 33 7 -7 7 -16 0 -27z m136 17 c0 -11 -7 -20 -15 -20 -8 0 -15 9 -15 20 0
            11 7 20 15 20 8 0 15 -9 15 -20z m610 5 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10
            15 0 8 5 15 10 15 6 0 10 -7 10 -15z m-3733 -17 c-3 -8 -6 -5 -6 6 -1 11 2 17
            5 13 3 -3 4 -12 1 -19z m103 7 c0 -8 -9 -15 -20 -15 -11 0 -20 7 -20 15 0 8 9
            15 20 15 11 0 20 -7 20 -15z m101 -1 c10 -13 10 -17 -2 -25 -8 -5 -17 -9 -20
            -9 -13 0 -31 29 -24 40 9 15 31 12 46 -6z m1736 -28 c-7 -18 -42 -22 -52 -5
            -4 5 1 19 11 30 15 17 19 18 32 5 8 -8 12 -22 9 -30z m593 24 c0 -21 -15 -27
            -25 -10 -7 12 2 30 16 30 5 0 9 -9 9 -20z m-2720 6 c0 -3 -4 -8 -10 -11 -5 -3
            -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m610 -5 c0 -5 -7 -12 -16 -15
            -22 -9 -24 -8 -24 9 0 8 9 15 20 15 11 0 20 -4 20 -9z m454 -61 c4 -33 3 -49
            -3 -40 -12 21 -23 117 -12 105 5 -6 11 -35 15 -65z m176 49 c0 -5 -7 -9 -15
            -9 -15 0 -20 12 -9 23 8 8 24 -1 24 -14z m2574 5 c10 -26 7 -34 -13 -34 -23 0
            -35 15 -27 35 7 20 32 19 40 -1z m-3678 -9 c3 -8 3 -19 0 -24 -10 -16 -36 -1
            -36 20 0 22 28 26 36 4z m881 -1 c8 -21 -13 -42 -28 -27 -13 13 -5 43 11 43 6
            0 13 -7 17 -16z m873 7 c0 -6 -4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10
            16 6 0 10 -4 10 -9z m-1385 -12 c10 -15 -1 -23 -20 -15 -9 3 -13 10 -10 16 8
            13 22 13 30 -1z m153 -11 c-2 -12 -9 -23 -15 -26 -19 -6 -29 28 -12 39 23 14
            31 10 27 -13z m2856 7 c3 -9 0 -15 -9 -15 -16 0 -27 14 -18 24 10 10 21 7 27
            -9z m-3454 -21 c0 -8 -4 -12 -10 -9 -5 3 -10 13 -10 21 0 8 5 12 10 9 6 -3 10
            -13 10 -21z m2218 5 c15 -24 1 -49 -29 -49 -24 0 -32 16 -25 46 8 30 36 32 54
            3z m1152 6 c-7 -9 -15 -13 -17 -11 -7 7 7 26 19 26 6 0 6 -6 -2 -15z m263 9
            c9 -9 -4 -34 -18 -34 -14 0 -27 25 -18 34 4 3 12 6 18 6 6 0 14 -3 18 -6z
            m-2498 -14 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8 0 12 -4
            9 -10z m1826 -33 c-8 -8 -11 -5 -11 9 0 27 14 41 18 19 2 -9 -1 -22 -7 -28z
            m224 34 c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z m346 -4 c-1
            -12 -15 -9 -19 4 -3 6 1 10 8 8 6 -3 11 -8 11 -12z m-801 -22 c0 -16 -6 -25
            -15 -25 -18 0 -20 21 -3 38 17 17 18 16 18 -13z m-2020 1 c0 -15 -24 -28 -34
            -19 -13 14 -5 33 14 33 11 0 20 -6 20 -14z m-325 -6 c3 -5 2 -10 -4 -10 -5 0
            -13 5 -16 10 -3 6 -2 10 4 10 5 0 13 -4 16 -10z m155 0 c0 -5 -4 -10 -9 -10
            -6 0 -13 5 -16 10 -3 6 1 10 9 10 9 0 16 -4 16 -10z m2760 -5 c0 -8 -7 -15
            -15 -15 -8 0 -15 7 -15 15 0 8 7 15 15 15 8 0 15 -7 15 -15z m-3347 -15 c6
            -23 -10 -37 -25 -22 -12 12 -4 42 11 42 5 0 11 -9 14 -20z m294 -2 c-3 -8 -6
            -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m543 -4 c0 -8 -6 -14 -13 -14 -19
            0 -27 10 -20 25 6 17 33 8 33 -11z m2685 5 c-4 -5 -13 -9 -22 -9 -13 0 -13 3
            -3 15 13 15 35 11 25 -6z m-3335 -15 c0 -16 -16 -19 -25 -4 -8 13 4 32 16 25
            5 -4 9 -13 9 -21z m350 17 c0 -5 -7 -14 -15 -21 -18 -15 -42 1 -31 19 8 13 46
            15 46 2z m383 -5 c6 -16 -16 -40 -26 -29 -4 3 -7 15 -7 25 0 21 25 24 33 4z
            m2257 -1 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10
            -15z m430 -21 c0 -19 -5 -24 -25 -24 -32 0 -44 27 -19 44 24 18 44 9 44 -20z
            m207 22 c-5 -16 -27 -19 -27 -3 0 7 7 14 15 14 9 0 14 -5 12 -11z m183 -8 c0
            -10 -4 -18 -10 -18 -5 0 -10 2 -10 4 0 2 -2 11 -5 19 -4 8 0 14 10 14 8 0 15
            -9 15 -19z m-2186 -10 c13 -22 -5 -44 -28 -35 -22 9 -22 60 0 55 9 -2 22 -11
            28 -20z m-1424 -3 c0 -8 -7 -15 -15 -15 -16 0 -20 12 -8 23 11 12 23 8 23 -8z
            m602 3 c-9 -9 -15 -9 -24 0 -9 9 -7 12 12 12 19 0 21 -3 12 -12z m334 -15 c-4
            -4 4 -38 20 -78 5 -14 1 -12 -15 7 -12 15 -25 45 -28 65 -6 35 -5 37 11 24 9
            -8 15 -16 12 -18z m1242 1 c3 -23 -21 -49 -38 -39 -5 3 -10 20 -10 36 0 26 3
            30 23 27 13 -2 23 -11 25 -24z m-899 2 c15 -18 -2 -56 -24 -56 -16 0 -31 43
            -20 59 9 15 30 14 44 -3z m1441 -13 c0 -20 -3 -24 -11 -16 -7 7 -9 19 -6 27
            10 24 17 19 17 -11z m-3035 7 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7
            10 16 10 8 0 12 -4 9 -10z m535 -6 c0 -9 -7 -14 -17 -12 -25 5 -28 28 -4 28
            12 0 21 -6 21 -16z m140 7 c0 -14 -18 -23 -30 -16 -6 4 -8 11 -5 16 8 12 35
            12 35 0z m510 -42 c0 -24 -4 -38 -10 -34 -5 3 -10 24 -10 46 0 24 4 38 10 34
            6 -3 10 -24 10 -46z m2621 34 c7 -7 5 -15 -6 -27 -20 -20 -35 -12 -35 19 0 24
            21 28 41 8z m-2461 -33 c0 -22 -4 -40 -8 -40 -28 0 -44 63 -19 73 24 10 27 7
            27 -33z m2075 9 c-11 -17 -35 -9 -35 11 0 18 3 19 20 10 11 -6 18 -15 15 -21z
            m259 -2 c-3 -9 -12 -17 -20 -17 -17 0 -19 37 -1 43 18 7 29 -7 21 -26z m-3439
            13 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z
            m-475 -10 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4
            -10z m988 1 c21 -13 10 -46 -16 -49 -24 -4 -38 18 -28 43 6 17 24 19 44 6z
            m362 -42 c0 -21 -4 -27 -15 -23 -17 7 -30 48 -20 64 11 18 35 -9 35 -41z
            m1450 41 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z
            m620 -5 c0 -9 -7 -18 -16 -22 -18 -7 -39 11 -30 26 11 17 46 13 46 -4z m-3080
            -16 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12 10 9 6 -3 10 -10 10 -16z
            m1037 -11 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m2197 24 c2
            -4 1 -14 -3 -20 -10 -16 -41 -5 -41 14 0 15 35 20 44 6z m256 -7 c0 -9 -9 -15
            -21 -15 -18 0 -19 2 -9 15 7 8 16 15 21 15 5 0 9 -7 9 -15z m-3390 -15 c0 -15
            -28 -30 -44 -24 -19 7 -20 16 -5 31 11 11 49 6 49 -7z m165 0 c0 -8 -8 -16
            -17 -18 -13 -2 -18 3 -18 18 0 15 5 20 18 18 9 -2 17 -10 17 -18z m185 10 c0
            -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m1720
            0 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9 -4 9 -10z
            m970 -22 c0 -21 -3 -29 -10 -22 -5 5 -7 19 -4 32 9 31 14 27 14 -10z m624 17
            c-4 -8 -11 -12 -16 -9 -6 4 -5 10 3 15 19 12 19 11 13 -6z m-4012 -19 c12 -22
            5 -30 -21 -21 -25 8 -29 44 -5 41 9 -1 21 -10 26 -20z m185 8 c9 -24 -19 -37
            -34 -17 -18 24 -16 33 7 33 11 0 23 -7 27 -16z m578 5 c4 -6 13 -8 21 -5 8 3
            14 1 14 -4 0 -6 -18 -10 -40 -10 -38 0 -54 13 -27 23 19 8 25 8 32 -4z m325
            -8 c0 -27 -13 -33 -35 -17 -21 15 -12 36 16 36 12 0 19 -7 19 -19z m88 -20 c3
            -27 0 -32 -15 -29 -19 3 -28 30 -19 54 10 26 31 10 34 -25z m2697 30 c3 -5 1
            -12 -5 -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z m-3445 -16 c0 -8 -4 -15 -10
            -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m198 -7 c-3 -25 -25
            -33 -47 -19 -21 14 -5 41 24 41 22 0 26 -4 23 -22z m1589 -21 c-5 -15 -19 -18
            -36 -8 -8 5 -11 16 -8 25 5 14 11 15 27 6 12 -6 20 -16 17 -23z m460 11 c-3
            -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m133 8 c0 -22 -11 -46 -21
            -46 -5 0 -9 14 -9 30 0 20 5 30 15 30 8 0 15 -6 15 -14z m96 -31 c-20 -20 -26
            -19 -26 5 0 24 33 46 38 24 2 -7 -3 -21 -12 -29z m337 12 c-3 -5 -10 -4 -15 1
            -6 6 -7 16 -3 22 6 9 9 9 16 -1 4 -7 5 -17 2 -22z m381 8 c-18 -27 -34 -33
            -34 -12 0 17 20 37 37 37 10 0 10 -6 -3 -25z m-3704 5 c0 -5 -4 -10 -10 -10
            -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m1875 0 c3 -5 1 -10 -4
            -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m1727 -12 c-7 -7 -12
            -8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11 -7 -17z m-2410 -11 c3 -13 1 -32 -3
            -43 -5 -13 -8 -7 -8 24 -1 46 4 55 11 19z m1955 -1 c-2 -6 -18 -12 -36 -14
            -29 -3 -31 -2 -22 16 7 14 17 18 36 14 14 -2 24 -10 22 -16z m-2113 -27 c-3
            -5 -12 -9 -20 -9 -14 0 -20 31 -7 44 9 9 34 -24 27 -35z m-857 -1 c-18 -11
            -29 2 -16 18 10 12 14 12 20 2 4 -7 3 -16 -4 -20z m938 22 c3 -6 -1 -13 -10
            -16 -19 -8 -30 0 -20 15 8 14 22 14 30 1z m2293 -1 c-2 -6 -8 -10 -13 -10 -5
            0 -11 4 -13 10 -2 6 4 11 13 11 9 0 15 -5 13 -11z m-2499 -36 c-11 -32 -41
            -40 -47 -12 -2 12 48 53 55 45 2 -2 -2 -17 -8 -33z m3305 22 c6 -18 -16 -30
            -39 -21 -20 7 -8 36 15 36 10 0 21 -7 24 -15z m-3849 -5 c-3 -5 -13 -10 -21
            -10 -8 0 -14 5 -14 10 0 6 9 10 21 10 11 0 17 -4 14 -10z m95 -5 c0 -8 -7 -15
            -15 -15 -16 0 -20 12 -8 23 11 12 23 8 23 -8z m170 -1 c0 -9 -5 -14 -12 -12
            -18 6 -21 28 -4 28 9 0 16 -7 16 -16z m1804 1 c8 -21 -33 -42 -45 -24 -5 8 -9
            20 -9 27 0 17 47 15 54 -3z m1316 5 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3
            6 -1 10 4 10 6 0 11 -4 11 -10z m200 -5 c0 -8 -7 -15 -15 -15 -8 0 -15 7 -15
            15 0 8 7 15 15 15 8 0 15 -7 15 -15z m118 3 c-6 -18 -28 -21 -28 -4 0 9 7 16
            16 16 9 0 14 -5 12 -12z m-4088 -18 c0 -11 -7 -20 -15 -20 -27 0 -18 30 13 39
            1 1 2 -8 2 -19z m80 -1 c0 -18 -2 -19 -15 -9 -15 12 -12 30 6 30 5 0 9 -10 9
            -21z m490 1 c0 -13 -7 -20 -20 -20 -13 0 -20 7 -20 20 0 13 7 20 20 20 13 0
            20 -7 20 -20z m3029 6 c9 -11 8 -15 -5 -20 -23 -9 -24 -8 -24 14 0 23 13 26
            29 6z m259 -3 c2 -8 -6 -13 -22 -13 -25 0 -33 10 -19 24 10 10 36 3 41 -11z
            m-3040 -18 c-3 -3 -9 2 -12 12 -6 14 -5 15 5 6 7 -7 10 -15 7 -18z m2462 10
            c0 -8 -7 -15 -15 -15 -8 0 -15 7 -15 15 0 8 7 15 15 15 8 0 15 -7 15 -15z
            m-1804 -22 c-3 -8 -6 -17 -6 -19 0 -2 -4 -4 -10 -4 -5 0 -10 11 -10 25 0 18 4
            23 16 18 8 -3 13 -12 10 -20z m324 -9 c0 -19 -39 -18 -47 2 -3 9 0 20 8 24 15
            10 39 -6 39 -26z m-1502 3 c2 -12 -3 -17 -17 -17 -23 0 -24 1 -15 25 8 19 28
            15 32 -8z m1252 -18 c0 -11 -4 -19 -9 -19 -10 0 -21 24 -21 47 0 13 3 13 15 3
            8 -7 15 -21 15 -31z m1877 24 c3 -7 -6 -17 -17 -23 -30 -16 -47 1 -24 24 17
            18 35 17 41 -1z m63 7 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10
            6 0 11 -4 11 -10z m-2760 -15 c11 -13 8 -15 -18 -15 -31 0 -47 11 -35 24 11
            11 40 6 53 -9z m686 -12 c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2 -7 1 -15 -2
            -18z m528 10 c9 -23 -16 -37 -31 -16 -7 9 -10 20 -7 24 9 15 31 10 38 -8z
            m2046 2 c0 -8 -9 -19 -20 -25 -24 -13 -27 2 -4 24 19 20 24 20 24 1z m-3862
            -7 c18 -18 14 -38 -7 -38 -23 0 -35 15 -27 35 7 18 18 19 34 3z m285 5 c11
            -10 -13 -32 -29 -26 -20 8 -17 33 4 33 10 0 22 -3 25 -7z m3277 -39 c0 -23
            -31 -30 -46 -9 -10 13 -11 22 -3 31 14 17 49 1 49 -22z m85 25 c10 -14 1 -21
            -19 -13 -9 3 -13 10 -10 15 7 12 21 11 29 -2z m390 -19 c-5 -8 -11 -8 -21 1
            -12 10 -12 14 -2 20 16 10 33 -5 23 -21z m-3667 9 c-2 -6 -8 -10 -13 -10 -5 0
            -11 4 -13 10 -2 6 4 11 13 11 9 0 15 -5 13 -11z m377 -20 c12 -18 -6 -25 -19
            -6 -7 9 -19 17 -25 17 -7 0 -9 5 -6 10 7 11 39 -2 50 -21z m295 11 c0 -21 -8
            -24 -34 -14 -25 10 -19 34 9 34 18 0 25 -5 25 -20z m1570 6 c0 -7 -6 -19 -14
            -25 -19 -16 -32 5 -16 24 16 19 30 19 30 1z m-2692 -14 c2 -7 -3 -12 -12 -12
            -9 0 -16 7 -16 16 0 17 22 14 28 -4z m547 8 c3 -5 -1 -10 -9 -10 -9 0 -16 5
            -16 10 0 6 4 10 9 10 6 0 13 -4 16 -10z m1745 0 c0 -5 -5 -10 -11 -10 -5 0 -7
            5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m877 -12 c-3 -8 -6 -5 -6 6 -1 11 2
            17 5 13 3 -3 4 -12 1 -19z m-2512 2 c4 -6 -6 -10 -22 -10 -22 0 -25 2 -13 10
            19 12 27 12 35 0z m2835 -10 c0 -11 -7 -20 -15 -20 -15 0 -21 21 -8 33 12 13
            23 7 23 -13z m518 3 c-2 -10 -13 -19 -26 -21 -17 -3 -22 2 -22 17 0 16 6 21
            26 21 19 0 25 -5 22 -17z m-3726 -40 c8 -10 3 -13 -21 -13 -29 0 -32 2 -25 23
            9 30 28 41 33 19 2 -9 8 -23 13 -29z m3003 36 c4 -6 5 -12 2 -15 -7 -7 -37 7
            -37 17 0 13 27 11 35 -2z m-2314 -10 c11 -5 17 -14 14 -19 -6 -10 -45 9 -45
            22 0 11 8 10 31 -3z m244 -23 c16 -38 11 -68 -10 -60 -23 9 -31 94 -9 94 3 0
            11 -15 19 -34z m124 -40 c1 -22 -2 -37 -5 -33 -11 10 -24 88 -17 99 9 16 21
            -19 22 -66z m1799 42 c-3 -34 -20 -42 -26 -13 -5 22 5 45 18 45 7 0 10 -14 8
            -32z m262 22 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9 -4
            9 -10z m900 -15 c0 -19 -20 -29 -32 -17 -8 8 -7 16 2 26 14 17 30 12 30 -9z
            m-3022 -50 c0 -29 0 -29 -8 -5 -4 14 -8 36 -8 50 -1 23 0 23 8 5 5 -11 8 -33
            8 -50z m2590 47 c2 -7 -3 -12 -12 -12 -9 0 -16 7 -16 16 0 17 22 14 28 -4z
            m-3698 -7 c0 -8 -9 -15 -20 -15 -13 0 -18 5 -14 15 4 8 12 15 20 15 8 0 14 -7
            14 -15z m212 -23 c-21 -16 -30 2 -11 24 10 12 14 12 20 2 6 -8 2 -18 -9 -26z
            m98 29 c0 -12 -29 -23 -42 -15 -6 4 -5 10 3 15 18 11 39 11 39 0z m97 3 c-3
            -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m233 -15 c0 -5 -51 -9 -112
            -9 -74 0 -108 4 -98 10 8 5 41 6 75 3 33 -2 71 -1 85 5 26 10 50 6 50 -9z
            m2990 -10 c0 -15 -30 -23 -42 -11 -6 6 -8 18 -4 27 4 13 10 14 26 5 11 -6 20
            -15 20 -21z m-2854 5 c18 -15 18 -15 -11 -9 -37 8 -57 25 -29 25 11 0 29 -7
            40 -16z m1262 -7 c5 -23 -16 -47 -34 -40 -14 5 -19 44 -7 57 12 12 38 1 41
            -17z m1479 -3 c-9 -9 -28 6 -21 18 4 6 10 6 17 -1 6 -6 8 -13 4 -17z m407 5
            c-6 -11 -24 -2 -24 11 0 5 7 7 15 4 8 -4 12 -10 9 -15z m116 6 c0 -8 -2 -15
            -4 -15 -2 0 -6 7 -10 15 -3 8 -1 15 4 15 6 0 10 -7 10 -15z m-4137 -2 c-7 -2
            -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m2362 -3 c3 -5 1 -10 -4 -10
            -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m1125 0 c0 -5 -4 -10 -10
            -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-3630 -14 c0 -8 -5
            -18 -11 -22 -14 -8 -33 11 -25 25 10 16 36 13 36 -3z m639 10 c-12 -11 -135
            -56 -155 -56 -12 0 -24 -6 -27 -12 -3 -10 -7 -10 -17 -1 -20 19 -1 35 34 29
            18 -4 36 -3 40 1 4 4 31 15 59 25 52 17 74 21 66 14z m2359 -37 c-3 -27 -38
            -40 -38 -14 0 9 5 26 11 37 14 25 32 10 27 -23z m-3118 15 c0 -8 -4 -12 -10
            -9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10 -16z m390 12 c0 -3 -4 -8
            -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m700 -6 c12 -8
            10 -10 -10 -10 -14 0 -32 5 -40 10 -12 8 -10 10 10 10 14 0 32 -5 40 -10z
            m1010 6 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10
            -4z m-835 -17 c12 -19 -5 -23 -22 -6 -16 16 -16 17 -1 17 9 0 20 -5 23 -11z
            m335 -10 c0 -11 -4 -18 -10 -14 -5 3 -7 12 -3 20 7 21 13 19 13 -6z m1630 4
            c0 -2 -6 -11 -14 -18 -12 -12 -15 -12 -20 1 -8 21 1 34 19 27 8 -3 15 -7 15
            -10z m324 -16 c-3 -9 -12 -17 -20 -17 -11 0 -11 5 -2 25 12 27 33 20 22 -8z
            m616 23 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z
            m-3950 -15 c0 -8 -7 -15 -15 -15 -16 0 -20 12 -8 23 11 12 23 8 23 -8z m949 2
            c6 -8 8 -17 5 -20 -7 -7 -44 14 -44 25 0 13 27 9 39 -5z m401 9 c0 -3 -4 -8
            -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m2098 -19 c2 -11
            -3 -17 -17 -17 -23 0 -35 15 -26 31 10 15 39 6 43 -14z m-1980 -25 c2 -23 -1
            -32 -11 -32 -16 0 -27 21 -27 52 0 35 35 17 38 -20z m1712 18 c0 -13 -28 -25
            -38 -16 -3 4 0 11 8 16 20 13 30 12 30 0z m420 1 c0 -14 -18 -23 -30 -16 -6 4
            -8 11 -5 16 8 12 35 12 35 0z m528 -8 c-2 -10 -10 -18 -18 -18 -8 0 -16 8 -18
            18 -2 12 3 17 18 17 15 0 20 -5 18 -17z m222 2 c0 -8 -7 -15 -15 -15 -16 0
            -20 12 -8 23 11 12 23 8 23 -8z m-4275 -5 c-3 -5 -13 -10 -21 -10 -8 0 -12 5
            -9 10 3 6 13 10 21 10 8 0 12 -4 9 -10z m75 -11 c0 -5 -10 -9 -21 -9 -18 0
            -19 2 -9 15 12 15 30 12 30 -6z m115 11 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4
            10 3 6 11 10 16 10 6 0 7 -4 4 -10z m1522 2 c19 -12 9 -47 -15 -50 -13 -2 -22
            4 -27 17 -11 35 12 52 42 33z m453 -2 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3
            6 8 10 11 10 2 0 4 -4 4 -10z m1565 0 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9 10
            0 6 7 10 16 10 8 0 12 -4 9 -10z m135 -6 c0 -17 -22 -14 -28 4 -2 7 3 12 12
            12 9 0 16 -7 16 -16z m88 -2 c2 -7 -3 -12 -12 -12 -9 0 -16 7 -16 16 0 17 22
            14 28 -4z m197 8 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8 0
            12 -4 9 -10z m-3075 -12 c0 -4 -4 -8 -9 -8 -6 0 -12 4 -15 8 -3 5 1 9 9 9 8 0
            15 -4 15 -9z m-1319 -21 c-13 -13 -26 -3 -16 12 3 6 11 8 17 5 6 -4 6 -10 -1
            -17z m469 3 c-39 -21 -65 -26 -55 -9 9 13 44 28 70 28 15 0 11 -5 -15 -19z
            m2068 -8 c2 -16 -3 -25 -20 -31 -27 -10 -48 3 -48 29 0 37 63 38 68 2z m-1295
            -2 c3 -11 2 -20 -3 -20 -4 0 -10 9 -13 20 -3 11 -2 20 3 20 4 0 10 -9 13 -20z
            m-1113 6 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10
            -4z m240 -6 c0 -12 -28 -25 -36 -17 -9 9 6 27 22 27 8 0 14 -5 14 -10z m980
            -37 c0 -7 -4 -13 -10 -13 -5 0 -7 7 -4 15 4 8 1 15 -5 15 -6 0 -11 8 -11 18 1
            15 2 15 15 -2 8 -11 15 -26 15 -33z m1607 25 c-3 -8 -6 -5 -6 6 -1 11 2 17 5
            13 3 -3 4 -12 1 -19z m-2749 -4 c-9 -9 -21 -13 -27 -9 -16 10 -3 25 22 25 20
            0 20 -1 5 -16z m2628 2 c6 -16 -12 -56 -25 -56 -13 0 -22 30 -16 51 7 22 33
            25 41 5z m874 4 c0 -5 -9 -10 -20 -10 -11 0 -20 5 -20 10 0 6 9 10 20 10 11 0
            20 -4 20 -10z m-3460 -10 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11
            10 2 0 4 -4 4 -10z m3698 -7 c2 -8 -6 -13 -22 -13 -25 0 -33 10 -19 24 10 10
            36 3 41 -11z m532 7 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8
            0 15 -4 15 -10z m-4530 -27 c0 -13 -3 -13 -15 -3 -8 7 -12 16 -9 21 8 13 24 1
            24 -18z m395 -4 c-33 -17 -64 -35 -69 -40 -6 -5 -17 -9 -25 -9 -21 1 38 50 59
            50 8 0 26 7 40 14 14 8 32 14 40 15 8 0 -12 -13 -45 -30z m3495 21 c12 -8 9
            -10 -12 -10 -16 0 -28 5 -28 10 0 13 20 13 40 0z m185 0 c3 -5 1 -10 -4 -10
            -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m100 -11 c11 -17 0 -20 -21
            -4 -17 13 -17 14 -2 15 9 0 20 -5 23 -11z m-4055 -8 c0 -11 -26 -22 -34 -14
            -12 12 -5 23 14 23 11 0 20 -4 20 -9z m60 -1 c0 -5 -7 -10 -16 -10 -8 0 -12 5
            -9 10 3 6 10 10 16 10 5 0 9 -4 9 -10z m1340 -5 c0 -8 -4 -15 -10 -15 -5 0
            -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m1751 -18 c-16 -16 -26 0 -10
            19 9 11 15 12 17 4 2 -6 -1 -17 -7 -23z m239 23 c-20 -13 -43 -13 -35 0 3 6
            16 10 28 10 18 0 19 -2 7 -10z m770 -8 c0 -24 -8 -32 -26 -25 -22 8 -17 43 6
            43 13 0 20 -7 20 -18z m80 8 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10
            15 10 8 0 15 -4 15 -10z m108 1 c11 -6 10 -11 -3 -25 -19 -19 -31 -20 -40 -6
            -17 28 14 50 43 31z m-4178 -10 c0 -5 -7 -14 -15 -21 -16 -14 -18 -10 -9 14 6
            17 24 22 24 7z m3523 2 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z
            m827 -7 c0 -8 -7 -16 -15 -20 -10 -4 -15 1 -15 14 0 11 7 20 15 20 8 0 15 -6
            15 -14z m-4400 0 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0
            10 -2 10 -4z m1310 -6 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10
            6 0 11 -4 11 -10z m1643 3 c-7 -2 -13 -11 -13 -19 0 -8 -7 -14 -15 -14 -13 0
            -14 4 -5 20 6 11 18 20 28 19 10 0 12 -3 5 -6z m404 1 c-3 -3 -12 -4 -19 -1
            -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-1987 -30 c0 -8 -4 -12 -10 -9 -5 3 -10 13
            -10 21 0 8 5 12 10 9 6 -3 10 -13 10 -21z m2528 8 c3 -9 -3 -13 -19 -10 -12 1
            -24 9 -27 16 -3 9 3 13 19 10 12 -1 24 -9 27 -16z m-4211 -14 c-3 -8 -6 -5 -6
            6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m245 0 c-6 -6 -15 -8 -19 -4 -4 4 -1 11
            7 16 19 12 27 3 12 -12z m3594 15 c-12 -12 -58 -15 -51 -3 3 5 18 10 32 10 15
            0 23 -3 19 -7z m791 -19 c-9 -9 -28 6 -21 18 4 6 10 6 17 -1 6 -6 8 -13 4 -17z
            m-4507 6 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z
            m1430 -5 c10 -12 10 -19 1 -28 -9 -9 -14 -9 -21 3 -22 34 -4 54 20 25z m2385
            5 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m175
            0 c8 -5 11 -12 7 -16 -4 -4 -15 0 -24 9 -18 18 -8 23 17 7z m-207 -7 c-7 -2
            -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m-3566 -23 c-31 -25 -36 -25
            -30 -2 2 10 15 19 30 20 l26 2 -26 -20z m1439 -17 c-11 -11 -19 6 -11 24 8 17
            8 17 12 0 3 -10 2 -21 -1 -24z m192 9 c2 -12 -1 -22 -6 -22 -15 0 -34 28 -27
            40 10 17 30 6 33 -18z m1182 18 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8
            10 11 10 2 0 4 -4 4 -10z m897 4 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17
            -2 13 -5z m233 -4 c0 -5 -4 -10 -9 -10 -6 0 -13 5 -16 10 -3 6 1 10 9 10 9 0
            16 -4 16 -10z m-670 -14 c-14 -8 -29 -12 -33 -9 -12 8 14 23 38 22 18 0 17 -1
            -5 -13z m830 -2 c0 -17 -22 -14 -28 4 -2 7 3 12 12 12 9 0 16 -7 16 -16z
            m-4278 -16 c-7 -7 -12 -8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11 -7 -17z m118 5
            c0 -19 -33 -51 -95 -92 -22 -14 -42 -28 -45 -31 -10 -11 -65 -47 -82 -53 -12
            -4 -18 -1 -18 8 0 8 7 15 15 15 29 0 62 23 80 56 13 25 25 34 44 34 28 0 54
            13 51 24 -2 10 33 56 42 56 5 0 8 -8 8 -17z m4096 -10 c-7 -7 -26 7 -26 19 0
            6 6 6 15 -2 9 -7 13 -15 11 -17z m-2301 -3 c0 -7 -6 -15 -12 -17 -8 -3 -13 4
            -13 17 0 13 5 20 13 18 6 -3 12 -11 12 -18z m2026 4 c17 -8 36 -13 42 -11 21
            8 54 -11 60 -32 6 -25 -6 -27 -29 -4 -9 9 -25 19 -36 20 -33 5 -97 43 -73 43
            4 0 20 -7 36 -16z m-3891 -4 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10
            10 10 6 0 10 -4 10 -10z m2247 -24 c-8 -19 -37 -21 -37 -3 0 7 5 18 12 25 15
            15 33 -1 25 -22z m1809 6 c-9 -9 -37 8 -31 18 5 8 11 7 21 -1 8 -6 12 -14 10
            -17z m66 0 c-19 -6 -44 6 -37 18 4 7 14 6 28 -2 14 -7 17 -13 9 -16z m308 7
            c0 -6 -4 -7 -10 -4 -5 3 -10 11 -10 16 0 6 5 7 10 4 6 -3 10 -11 10 -16z m171
            -2 c-13 -13 -26 -3 -16 12 3 6 11 8 17 5 6 -4 6 -10 -1 -17z m-291 3 c0 -5 -5
            -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-2332 -17 c6
            -19 -25 -16 -32 3 -4 11 -1 14 11 12 9 -2 18 -9 21 -15z m-568 -50 c0 -57 -5
            -53 -13 10 -4 31 -3 46 3 42 6 -3 10 -27 10 -52z m1008 34 c3 -12 -3 -17 -22
            -17 -25 0 -35 18 -19 34 12 12 38 2 41 -17z m732 -32 c-16 -42 -24 -22 -9 22
            7 21 14 31 16 22 2 -8 -1 -28 -7 -44z m-385 35 c-3 -5 -12 -10 -18 -10 -7 0
            -6 4 3 10 19 12 23 12 15 0z m-131 -15 c-7 -16 -24 -21 -24 -6 0 8 7 13 27 20
            1 1 0 -6 -3 -14z m396 -19 c0 -25 -17 -46 -37 -46 -18 0 -16 31 3 58 18 26 34
            21 34 -12z m1244 5 c3 -5 0 -11 -8 -14 -15 -6 -50 26 -40 36 7 7 40 -8 48 -22z
            m-2957 -13 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m110 0 c-3
            -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m2703 -9 c0 -11 -11 -11 -29
            0 -7 5 -10 14 -6 20 5 9 10 9 21 0 8 -6 14 -15 14 -20z m250 16 c0 -17 -2 -18
            -24 -9 -23 9 -20 24 4 24 11 0 20 -7 20 -15z m-4480 -5 c0 -5 -4 -10 -10 -10
            -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m1634 -7 c7 -16 -3 -43
            -15 -43 -5 0 -14 10 -19 23 -5 13 -9 16 -10 7 0 -13 -1 -13 -10 0 -15 23 -12
            27 20 27 16 0 31 -6 34 -14z m2530 2 c11 -8 17 -18 13 -21 -3 -3 -18 3 -33 15
            -30 23 -10 29 20 6z m-2290 -30 c7 -20 -3 -45 -20 -45 -20 0 -36 34 -22 48 16
            16 35 15 42 -3z m2741 -25 c-8 -13 -32 -13 -40 0 -4 6 -1 17 5 25 10 13 14 12
            27 0 8 -8 11 -19 8 -25z m137 18 c-12 -12 -35 -1 -27 12 3 5 13 6 21 3 10 -4
            12 -9 6 -15z m-1752 -28 l-8 -35 -1 31 c-1 17 2 34 6 37 11 12 12 3 3 -33z
            m1440 24 c0 -8 -5 -12 -10 -9 -6 4 -8 11 -5 16 9 14 15 11 15 -7z m-120 -4 c8
            -5 11 -12 8 -16 -10 -9 -38 3 -38 16 0 12 10 13 30 0z m-2315 -25 c0 -27 -26
            -25 -36 3 -8 20 -6 23 14 20 14 -2 22 -10 22 -23z m2255 3 c0 -5 5 -6 10 -3 6
            3 10 2 10 -4 0 -5 9 -13 20 -16 21 -7 33 -16 97 -74 18 -16 38 -28 46 -27 14
            1 97 -68 97 -81 0 -4 -5 -2 -12 5 -7 7 -20 12 -30 12 -10 0 -18 4 -18 9 0 5
            -9 13 -21 16 -13 4 -18 12 -15 21 3 9 1 12 -5 9 -5 -4 -26 6 -47 22 -20 15
            -41 30 -47 33 -5 3 -36 22 -67 43 -59 38 -75 59 -38 50 11 -3 20 -10 20 -15z
            m115 12 c3 -5 2 -10 -4 -10 -5 0 -13 5 -16 10 -3 6 -2 10 4 10 5 0 13 -4 16
            -10z m-1477 -54 c-6 -14 -15 -26 -20 -26 -12 0 -10 54 4 68 19 19 31 -11 16
            -42z m-1408 -3 c0 -44 -5 -41 -14 10 -5 27 -4 37 3 32 6 -3 11 -22 11 -42z
            m-1580 17 c-8 -5 -17 -7 -19 -4 -3 3 1 9 9 14 8 5 17 7 19 4 3 -3 -1 -9 -9
            -14z m4605 10 c3 -5 4 -14 0 -20 -8 -13 -35 -1 -35 16 0 16 26 19 35 4z m175
            0 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z
            m-2140 -20 c18 -11 3 -43 -24 -48 -22 -5 -31 23 -14 42 16 18 18 19 38 6z
            m260 -14 c0 -8 -6 -19 -14 -25 -11 -10 -16 -8 -24 6 -8 13 -6 20 6 29 19 14
            32 9 32 -10z m1616 10 c19 -20 18 -40 -2 -32 -9 3 -18 6 -20 6 -2 0 -4 9 -4
            20 0 24 6 25 26 6z m-66 -6 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10
            4 10 6 0 11 -4 11 -10z m-1980 -19 c0 -10 -7 -22 -16 -25 -23 -9 -24 -8 -24
            19 0 18 5 25 20 25 13 0 20 -7 20 -19z m702 -23 c-7 -7 -12 -8 -12 -2 0 14 12
            26 19 19 2 -3 -1 -11 -7 -17z m1540 7 c0 -5 -5 -11 -11 -13 -6 -2 -11 4 -11
            13 0 9 5 15 11 13 6 -2 11 -8 11 -13z m-3017 -5 c3 -5 1 -10 -4 -10 -6 0 -11
            5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m3119 -16 c4 -9 4 -19 2 -21 -8 -8
            -26 8 -26 23 0 19 16 18 24 -2z m-2804 -20 c0 -8 -4 -14 -10 -14 -5 0 -10 9
            -10 21 0 11 5 17 10 14 6 -3 10 -13 10 -21z m2590 11 c10 -12 10 -15 -4 -15
            -9 0 -16 7 -16 15 0 8 2 15 4 15 2 0 9 -7 16 -15z m-2729 -11 c11 -14 10 -15
            -11 -12 -13 1 -25 9 -28 16 -5 17 23 15 39 -4z m234 -4 c3 -5 3 -15 -1 -21 -6
            -11 -23 6 -24 24 0 10 18 9 25 -3z m2575 0 c0 -5 -4 -10 -9 -10 -6 0 -13 5
            -16 10 -3 6 1 10 9 10 9 0 16 -4 16 -10z m-1404 -45 c-6 -32 -13 -39 -33 -32
            -11 3 -4 27 14 55 19 31 27 22 19 -23z m1649 -5 c3 -5 2 -10 -4 -10 -5 0 -13
            5 -16 10 -3 6 -2 10 4 10 5 0 13 -4 16 -10z m-187 -19 c3 -8 -1 -12 -9 -9 -7
            2 -15 10 -17 17 -3 8 1 12 9 9 7 -2 15 -10 17 -17z m-3128 -38 c0 -13 -4 -23
            -10 -23 -5 0 -10 17 -10 38 0 27 3 33 10 22 5 -8 10 -25 10 -37z m68 -4 c-3
            -33 -3 -34 -16 -11 -7 12 -10 32 -7 43 10 32 27 8 23 -32z m987 31 c0 -7 -6
            -15 -12 -17 -8 -3 -13 4 -13 17 0 13 5 20 13 18 6 -3 12 -11 12 -18z m172 -6
            c-9 -9 -28 6 -21 18 4 6 10 6 17 -1 6 -6 8 -13 4 -17z m100 -6 c-3 -7 -5 -2
            -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m1903 12 c0 -5 -7 -10 -15 -10 -8 0
            -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m-2300 -14 c0 -17 -14 -29 -23
            -20 -8 8 3 34 14 34 5 0 9 -6 9 -14z m2113 -16 c14 -11 30 -20 35 -20 5 0 12
            -7 15 -14 4 -11 1 -13 -11 -8 -32 13 -82 46 -82 54 0 13 16 9 43 -12z m-2870
            -21 c6 -9 7 -24 3 -34 -6 -17 -7 -17 -26 0 -25 22 -27 58 -4 53 9 -2 21 -10
            27 -19z m647 11 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0
            10 -4 10 -10z m2346 -17 c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2 -7 1 -15 -2
            -18z m-2806 -9 c15 -38 12 -46 -11 -42 -27 4 -44 30 -36 52 10 24 36 19 47
            -10z m120 6 c13 -25 5 -40 -21 -40 -14 0 -19 7 -19 30 0 35 23 41 40 10z
            m1024 -29 c-4 -18 -10 -28 -15 -23 -5 4 -6 23 -3 41 4 18 11 29 16 24 4 -5 5
            -24 2 -42z m-1544 -40 c-1 -30 -2 -33 -11 -18 -16 28 -28 75 -21 82 12 12 32
            -29 32 -64z m3198 3 c-2 -7 6 -20 17 -28 19 -16 19 -16 0 -9 -33 12 -55 36
            -55 62 0 23 0 24 20 6 11 -10 19 -24 18 -31z m49 14 c-3 -7 -5 -2 -5 12 0 14
            2 19 5 13 2 -7 2 -19 0 -25z m-1817 -37 c0 -23 -29 -62 -31 -41 -2 19 1 41 6
            58 6 18 25 5 25 -17z m-352 -25 c6 -31 -43 -50 -54 -21 -8 20 15 47 35 43 9
            -2 17 -12 19 -22z m204 -2 c-4 -18 -19 -34 -34 -34 -13 0 -9 48 5 53 20 9 32
            1 29 -19z m1985 20 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
            m-3177 -40 c0 -8 -4 -12 -10 -9 -5 3 -10 13 -10 21 0 8 5 12 10 9 6 -3 10 -13
            10 -21z m830 11 c10 -12 10 -18 -1 -32 -7 -10 -14 -19 -15 -21 -1 -1 -9 0 -18
            4 -19 7 -21 35 -4 52 16 16 23 15 38 -3z m2360 0 c14 -8 19 -14 10 -14 -8 0
            -24 6 -34 14 -25 19 -9 19 24 0z m-3150 -20 c0 -8 -4 -15 -10 -15 -5 0 -10 7
            -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m-133 -27 c-3 -7 -5 -2 -5 12 0 14 2
            19 5 13 2 -7 2 -19 0 -25z m223 10 c0 -19 -2 -20 -10 -8 -13 19 -13 30 0 30 6
            0 10 -10 10 -22z m140 -3 c10 -12 10 -15 -4 -15 -9 0 -16 7 -16 15 0 8 2 15 4
            15 2 0 9 -7 16 -15z m60 -30 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15
            10 15 6 0 10 -7 10 -15z m113 -1 c3 -3 3 -12 0 -20 -4 -11 -9 -12 -19 -3 -8 6
            -11 15 -8 20 6 10 18 12 27 3z m1131 -31 c-8 -32 -12 -39 -19 -31 -9 8 6 58
            16 58 5 0 6 -12 3 -27z m-1554 -64 c0 -16 -4 -29 -10 -29 -5 0 -10 16 -10 36
            0 21 4 33 10 29 6 -3 10 -19 10 -36z m104 9 c9 -33 8 -38 -8 -38 -16 0 -31 34
            -21 50 10 17 22 11 29 -12z m856 8 c0 -8 -4 -17 -9 -21 -12 -7 -24 12 -16 25
            9 15 25 12 25 -4z m530 6 c0 -13 -12 -22 -22 -16 -10 6 -1 24 13 24 5 0 9 -4
            9 -8z m-66 -39 c-10 -40 -14 -41 -14 -5 0 18 5 32 10 32 6 0 8 -11 4 -27z
            m-1134 -12 c5 -11 7 -27 4 -36 -9 -22 -34 0 -34 31 0 29 15 31 30 5z m500 -6
            c0 -8 -7 -15 -15 -15 -16 0 -20 12 -8 23 11 12 23 8 23 -8z m-376 -31 c10 -26
            7 -34 -13 -34 -24 0 -33 13 -25 34 8 21 30 21 38 0z m896 -18 c0 -24 -16 -43
            -27 -32 -8 8 6 56 17 56 6 0 10 -11 10 -24z m-157 -36 c-7 -27 -22 -35 -29
            -16 -7 18 13 49 26 41 5 -3 6 -14 3 -25z m-205 -17 c-4 -37 -38 -45 -38 -10 0
            23 10 34 33 36 5 1 7 -11 5 -26z m-848 -45 c0 -25 -3 -29 -10 -18 -13 20 -13
            50 0 50 6 0 10 -15 10 -32z m663 16 c10 -10 -12 -44 -28 -44 -9 0 -15 10 -15
            25 0 18 5 25 18 25 10 0 22 -3 25 -6z m-575 -15 c-2 -6 -8 -10 -13 -10 -5 0
            -11 4 -13 10 -2 6 4 11 13 11 9 0 15 -5 13 -11z m102 -20 c0 -11 -4 -17 -10
            -14 -5 3 -10 15 -10 26 0 11 5 17 10 14 6 -3 10 -15 10 -26z m96 -45 c-5 -13
            -10 -14 -18 -6 -6 6 -8 18 -4 28 5 13 10 14 18 6 6 -6 8 -18 4 -28z m572 -26
            c-6 -18 -28 -21 -28 -4 0 9 7 16 16 16 9 0 14 -5 12 -12z m449 -45 c-3 -10 -5
            -2 -5 17 0 19 2 27 5 18 2 -10 2 -26 0 -35z m-1398 -40 c2 -23 -19 -13 -25 11
            -8 35 8 59 17 26 4 -14 7 -31 8 -37z m811 36 c0 -5 -4 -9 -10 -9 -5 0 -10 7
            -10 16 0 8 5 12 10 9 6 -3 10 -10 10 -16z m-714 -24 c7 -29 1 -41 -15 -30 -14
            8 -14 55 -1 55 5 0 12 -11 16 -25z m204 -25 c16 -16 30 -35 30 -42 0 -9 -3 -9
            -12 0 -7 7 -22 12 -35 12 -23 0 -43 26 -36 48 7 19 20 15 53 -18z m1020 -14
            c0 -24 -22 -51 -33 -40 -3 3 -4 12 -1 20 2 8 6 21 9 29 7 24 25 17 25 -9z
            m-340 -40 c0 -22 -16 -36 -41 -36 -14 0 -11 48 4 53 26 11 37 6 37 -17z m184
            15 c9 -14 -4 -41 -20 -41 -15 0 -17 10 -8 34 7 18 20 21 28 7z m-319 -41 c9
            -14 -13 -50 -31 -50 -24 0 -30 31 -9 46 22 17 32 17 40 4z m-848 -102 c-3 -8
            -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m100 -30 c-3 -8 -6 -5 -6 6 -1
            11 2 17 5 13 3 -3 4 -12 1 -19z m143 -9 c0 -11 -4 -17 -10 -14 -5 3 -10 15
            -10 26 0 11 5 17 10 14 6 -3 10 -15 10 -26z m90 -5 c0 -8 -4 -14 -10 -14 -5 0
            -10 9 -10 21 0 11 5 17 10 14 6 -3 10 -13 10 -21z m826 -31 c-11 -11 -17 4 -9
            23 7 18 8 18 11 1 2 -10 1 -21 -2 -24z m-106 2 c0 -8 -7 -15 -15 -15 -8 0 -15
            7 -15 15 0 8 7 15 15 15 8 0 15 -7 15 -15z m-160 -17 c0 -16 -3 -19 -11 -11
            -6 6 -8 16 -5 22 11 17 16 13 16 -11z m-871 -85 c0 -31 -2 -34 -9 -18 -12 28
            -12 55 0 55 6 0 10 -17 9 -37z m1291 10 c0 -36 -15 -61 -23 -41 -7 19 3 68 14
            68 5 0 9 -12 9 -27z m-1198 -50 c1 -12 -1 -23 -7 -25 -5 -2 -11 13 -13 32 -4
            38 14 32 20 -7z m378 1 c0 -14 -21 -19 -32 -8 -3 3 -4 14 -1 25 4 15 8 17 19
            8 8 -6 14 -18 14 -25z m-160 -20 c0 -13 -4 -24 -9 -24 -11 0 -21 25 -21 52 0
            17 3 18 15 8 8 -7 15 -23 15 -36z m838 14 c-4 -27 -28 -36 -28 -10 0 20 8 32
            22 32 5 0 8 -10 6 -22z m-920 0 c14 -14 16 -48 2 -48 -10 0 -30 32 -30 49 0
            14 13 14 28 -1z m507 -36 c5 -15 -11 -42 -25 -42 -11 0 -20 28 -14 43 7 18 32
            17 39 -1z m195 -1 c0 -21 -13 -41 -26 -41 -17 0 -17 5 -4 31 11 20 30 26 30
            10z m-923 -113 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m93 -18
            c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m105
            -30 c3 -5 2 -10 -4 -10 -5 0 -13 5 -16 10 -3 6 -2 10 4 10 5 0 13 -4 16 -10z
            m219 -25 c3 -8 1 -15 -4 -15 -6 0 -10 7 -10 15 0 8 2 15 4 15 2 0 6 -7 10 -15z
            m877 -142 c-9 -16 -10 -15 -11 9 0 14 3 35 8 45 6 15 8 14 10 -9 2 -15 -1 -35
            -7 -45z m-1271 -3 c0 -20 -2 -22 -10 -10 -5 8 -10 26 -10 40 0 20 2 22 10 10
            5 -8 10 -26 10 -40z m95 -39 c5 -17 4 -22 -6 -19 -8 3 -15 18 -17 34 -4 33 11
            24 23 -15z m1039 30 c7 -11 -20 -48 -29 -39 -9 8 4 48 15 48 5 0 11 -4 14 -9z
            m-884 -41 c0 -5 3 -15 6 -24 9 -22 -23 -21 -34 2 -12 23 -4 42 14 35 8 -3 14
            -9 14 -13z m139 -53 c1 -10 -3 -16 -8 -13 -11 7 -20 53 -12 61 7 7 19 -23 20
            -48z m537 8 c-3 -8 -12 -15 -21 -15 -21 0 -19 38 3 42 19 4 26 -6 18 -27z
            m-196 1 c0 -14 -21 -58 -26 -54 -8 8 7 58 17 58 5 0 9 -2 9 -4z m-713 -138
            c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m1250 -30 c-3 -8 -6 -5
            -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-810 -70 c-2 -13 -4 -3 -4 22 0 25 2
            35 4 23 2 -13 2 -33 0 -45z m-330 10 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3
            4 -12 1 -19z m137 -14 c3 -9 -1 -14 -14 -14 -13 0 -20 7 -20 20 0 22 25 18 34
            -6z m840 -19 c-4 -8 -10 -13 -15 -10 -4 3 -6 12 -3 20 4 8 10 13 15 10 4 -3 6
            -12 3 -20z m-1055 -115 c1 -13 0 -13 -9 0 -5 8 -9 26 -9 40 l1 25 8 -25 c4
            -14 8 -32 9 -40z m1217 -15 c-4 -14 -11 -25 -16 -25 -10 0 -4 43 8 65 9 15 14
            -14 8 -40z m-1106 -73 c0 -12 -3 -12 -15 -2 -8 7 -15 22 -15 34 1 21 1 21 15
            2 8 -11 15 -26 15 -34z m954 -1 c7 -11 -18 -45 -31 -40 -7 3 -7 12 -2 27 9 22
            24 29 33 13z m-774 -50 c6 -12 6 -22 1 -25 -10 -7 -41 20 -41 35 0 17 29 9 40
            -10z m-283 -23 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m817 -22
            c-7 -19 -34 -29 -34 -12 0 16 10 26 26 26 8 0 11 -6 8 -14z m396 -31 c0 -8 -4
            -15 -10 -15 -5 0 -7 7 -4 15 4 8 8 15 10 15 2 0 4 -7 4 -15z m-200 -115 c0 -5
            -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-981 -55 c1
            -17 -1 -17 -9 -5 -5 8 -9 26 -9 40 0 23 1 23 9 5 5 -11 9 -29 9 -40z m211 35
            c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z
            m630 -50 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9 -4 9
            -10z m335 -12 c-10 -31 -25 -47 -25 -26 0 24 11 48 22 48 5 0 6 -10 3 -22z
            m-1067 -63 c14 -29 -2 -44 -17 -16 -6 11 -11 24 -11 30 0 20 17 11 28 -14z
            m907 -36 c7 -10 -21 -38 -37 -39 -12 0 -9 25 4 38 15 15 25 15 33 1z m-778
            -61 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-214 -18 c3 -11 1
            -18 -4 -14 -5 3 -9 12 -9 20 0 20 7 17 13 -6z m1134 -32 c-3 -8 -6 -5 -6 6 -1
            11 2 17 5 13 3 -3 4 -12 1 -19z m-333 -3 c-4 -8 -10 -15 -15 -15 -5 0 -9 7 -9
            15 0 8 7 15 15 15 9 0 12 -6 9 -15z m-774 -178 c0 -10 -4 -15 -10 -12 -5 3
            -10 18 -10 33 0 21 2 24 10 12 5 -8 10 -23 10 -33z m1090 -39 c0 -19 -18 -43
            -25 -36 -9 9 4 48 16 48 5 0 9 -6 9 -12z m-955 -86 c-8 -8 -35 20 -35 37 1 12
            5 11 21 -8 11 -13 17 -26 14 -29z m795 -57 c0 -16 -27 -32 -37 -21 -7 7 16 36
            28 36 5 0 9 -7 9 -15z m-910 -33 c0 -15 -2 -15 -10 -2 -13 20 -13 33 0 25 6
            -3 10 -14 10 -23z m709 -48 c-13 -16 -39 -19 -39 -4 0 12 32 30 43 23 4 -3 3
            -11 -4 -19z m-691 -106 c7 -7 12 -21 12 -32 -1 -20 -1 -20 -20 3 -29 34 -22
            59 8 29z m1012 -76 c0 -15 -28 -47 -35 -40 -8 8 13 48 25 48 6 0 10 -4 10 -8z
            m-680 -98 c0 -8 -4 -14 -10 -14 -5 0 -10 9 -10 21 0 11 5 17 10 14 6 -3 10
            -13 10 -21z m520 12 c0 -15 -37 -32 -47 -22 -9 8 17 36 33 36 8 0 14 -6 14
            -14z m-119 -40 c-6 -8 -20 -16 -31 -19 -17 -4 -18 -2 -9 14 5 10 19 19 31 19
            16 0 18 -3 9 -14z m-268 -41 c-3 -9 -8 -14 -10 -11 -3 3 -2 9 2 15 9 16 15 13
            8 -4z m-432 -65 c5 0 9 -7 9 -15 0 -8 -4 -15 -10 -15 -15 0 -32 39 -23 53 5 9
            9 7 11 -5 2 -10 8 -18 13 -18z m390 -7 c-12 -20 -14 -14 -5 12 4 9 9 14 11 11
            3 -2 0 -13 -6 -23z m517 -60 c-2 -10 -11 -19 -21 -21 -16 -3 -17 -1 -7 17 13
            25 33 28 28 4z m-719 -52 c7 -5 10 -14 6 -20 -5 -9 -10 -9 -21 0 -26 22 -14
            38 15 20z m551 -51 c0 -5 -12 -10 -27 -10 -22 0 -25 2 -13 10 20 13 40 13 40
            0z m-722 -102 c14 -14 16 -42 2 -33 -14 9 -32 45 -22 45 4 0 13 -5 20 -12z
            m842 -46 c0 -11 -22 -32 -32 -32 -5 0 -4 9 2 20 11 20 30 28 30 12z m-626
            -103 c-4 -6 -11 -6 -20 2 -21 17 -17 31 6 19 11 -6 18 -15 14 -21z m442 -4
            c-22 -17 -36 -19 -36 -7 0 14 12 22 34 22 21 -1 21 -1 2 -15z m-156 -59 c0 -9
            -30 -14 -35 -6 -4 6 3 10 14 10 12 0 21 -2 21 -4z m-460 -31 c21 -25 1 -31
            -22 -7 -18 18 -19 22 -6 22 8 0 21 -7 28 -15z m-185 -35 c-11 -5 -29 -8 -40
            -8 -16 0 -15 2 5 8 34 11 60 11 35 0z m339 -45 c3 -9 0 -15 -8 -15 -8 0 -16 7
            -20 15 -3 9 0 15 8 15 8 0 16 -7 20 -15z m466 11 c0 -3 -4 -8 -10 -11 -5 -3
            -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-640 -121 c9 -11 10 -15 1 -15
            -7 0 -19 7 -27 15 -13 13 -13 15 -1 15 8 0 20 -7 27 -15z m120 -46 c0 -6 -4
            -7 -10 -4 -5 3 -10 11 -10 16 0 6 5 7 10 4 6 -3 10 -11 10 -16z m-94 -86 c-8
            -8 -36 5 -36 17 0 7 6 7 21 0 11 -7 18 -14 15 -17z m459 -13 c-3 -5 -10 -10
            -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8 0 12 -4 9 -10z m90 -30 c3 -5 -3
            -10 -14 -10 -12 0 -21 5 -21 10 0 6 6 10 14 10 8 0 18 -4 21 -10z m-480 -29
            c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z m-199 -57 c-4 -9 -9
            -15 -11 -12 -3 3 -3 13 1 22 4 9 9 15 11 12 3 -3 3 -13 -1 -22z m594 -4 c-19
            -13 -30 -13 -30 0 0 6 10 10 23 10 18 0 19 -2 7 -10z m-420 -35 c0 -8 -4 -15
            -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m-10 -150 c0 -8
            -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m-70 -87
            c0 -16 -3 -19 -11 -11 -6 6 -8 16 -5 22 11 17 16 13 16 -11z m-118 -50 c-7 -7
            -12 -8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11 -7 -17z m28 -108 c0 -5 -4 -10
            -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-50 -69 c0 -6
            -4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16 6 0 10 -4 10 -9z"
              />
              <path
                d="M5190 12073 c-14 -3 -28 -8 -32 -12 -12 -10 70 -3 89 8 16 9 15 10
            -7 9 -14 0 -36 -3 -50 -5z"
              />
              <path d="M5047 12047 c-4 -10 2 -13 19 -9 13 2 24 8 24 13 0 14 -37 10 -43 -4z" />
              <path
                d="M5350 12050 c-8 -5 -10 -10 -5 -10 6 0 17 5 25 10 8 5 11 10 5 10 -5
            0 -17 -5 -25 -10z"
              />
              <path d="M5400 12050 c-13 -8 -12 -10 3 -10 9 0 17 5 17 10 0 12 -1 12 -20 0z" />
              <path
                d="M5630 12056 c0 -2 7 -6 15 -10 8 -3 15 -1 15 4 0 6 -7 10 -15 10 -8
            0 -15 -2 -15 -4z"
              />
              <path
                d="M5413 12028 c-13 -6 -23 -15 -23 -20 0 -14 16 -9 35 12 20 22 19 23
            -12 8z"
              />
              <path d="M6228 12033 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
              <path
                d="M5037 12000 c-9 -11 -13 -20 -8 -20 4 0 16 9 26 20 10 11 14 20 9 20
            -5 0 -17 -9 -27 -20z"
              />
              <path
                d="M4840 11985 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6
            2 -11 -4 -11 -13z"
              />
              <path
                d="M6370 11975 c-1 -5 -1 -20 -1 -32 2 -13 -4 -23 -16 -25 -15 -3 -15
            -5 5 -10 17 -5 22 -2 22 13 0 20 15 26 25 9 3 -5 17 -10 31 -10 15 0 23 4 19
            10 -4 6 -14 9 -23 6 -19 -6 -46 11 -55 34 -3 8 -6 11 -7 5z"
              />
              <path d="M4748 11963 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
              <path d="M4808 11963 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
              <path d="M5508 11963 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />
              <path
                d="M5490 11940 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
            0 -10 -4 -10 -10z"
              />
              <path
                d="M5510 11904 c0 -2 9 -13 19 -23 13 -13 21 -16 24 -7 3 6 -6 17 -19
            23 -13 6 -24 9 -24 7z"
              />
              <path
                d="M5668 11903 c-16 -2 -28 -9 -28 -14 0 -7 13 -8 33 -4 29 5 30 5 14
            -8 -16 -14 -16 -15 -1 -21 20 -8 21 -7 26 23 3 26 -3 30 -44 24z"
              />
              <path
                d="M6470 11898 c0 -6 3 -8 6 -5 4 3 16 2 28 -4 20 -10 20 -10 2 5 -24
            19 -36 20 -36 4z"
              />
              <path
                d="M4620 11881 c0 -5 7 -12 16 -15 24 -9 28 -7 14 9 -14 17 -30 20 -30
            6z"
              />
              <path
                d="M5480 11876 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
            -10 -1 -10 -9z"
              />
              <path
                d="M5430 11859 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
            -10 -5 -10 -11z"
              />
              <path
                d="M5516 11841 c5 -21 4 -23 -12 -16 -11 4 -29 10 -42 12 -18 5 -21 2
            -15 -13 4 -11 8 -13 11 -6 5 17 22 15 22 -3 0 -9 6 -12 16 -8 9 3 20 0 25 -8
            7 -11 9 -10 9 5 0 14 6 17 25 13 17 -3 24 0 22 7 -2 6 -12 10 -21 8 -10 -2
            -24 5 -32 15 -14 17 -15 16 -8 -6z"
              />
              <path d="M4584 11835 c4 -25 14 -27 36 -8 l20 20 -29 1 c-19 0 -28 -4 -27 -13z" />
              <path
                d="M5660 11830 c-23 -5 -23 -7 -6 -13 11 -4 24 -3 30 3 14 14 5 18 -24
            10z"
              />
              <path
                d="M6529 11838 c-6 -28 -4 -37 11 -41 12 -3 17 1 16 16 -1 16 -24 38
            -27 25z"
              />
              <path
                d="M6590 11789 c0 -38 16 -56 22 -25 3 15 0 31 -8 37 -11 9 -14 7 -14
            -12z"
              />
              <path
                d="M6625 11750 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10
            -2 0 -7 -4 -10 -10z"
              />
              <path
                d="M4476 11739 c3 -6 12 -8 20 -5 8 3 11 0 8 -8 -9 -22 16 -30 31 -10
            17 23 15 25 -29 29 -22 2 -34 0 -30 -6z"
              />
              <path d="M4360 11534 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z" />
              <path
                d="M4277 11363 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
            -9 2 -12 -2z"
              />
              <path d="M6841 11264 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
              <path
                d="M6830 10932 c0 -10 -4 -22 -8 -27 -5 -6 -6 -37 -2 -70 4 -33 8 -47 9
            -32 0 16 6 38 12 49 8 15 8 19 0 14 -6 -4 -11 -3 -11 3 0 5 6 12 13 15 8 4 9
            8 1 11 -6 3 -9 17 -6 30 2 14 2 25 -2 25 -3 0 -6 -8 -6 -18z"
              />
              <path
                d="M6965 10484 c-7 -51 -3 -63 18 -43 22 23 27 49 14 69 -18 30 -24 25
            -32 -26z"
              />
              <path d="M6951 10504 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
              <path
                d="M3952 10242 c-9 -54 -9 -78 -1 -94 6 -11 9 -36 7 -54 -2 -19 -1 -34
            3 -34 10 0 15 93 9 180 l-5 75 -13 -73z"
              />
              <path d="M3952 10030 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
              <path d="M3942 9805 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z" />
              <path d="M6882 9420 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z" />
              <path d="M4671 3084 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            </g>
          </svg>
        </div>
        <div className="sw-container-left">
          <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="1080.000000pt"
            height="1350.000000pt"
            viewBox="0 120 1080.000000 2500.000000"
            preserveAspectRatio="xMidYMid meet"
          >
            <g
              transform="translate(0.000000,1350.000000) scale(0.100000,-0.100000)"
              fill="#000000"
              stroke="none"
            >
              <path
                d="M5458 12114 c-3 -2 -22 -5 -42 -6 -20 -1 -36 -4 -36 -8 0 -4 -13 -5
            -30 -2 -19 3 -30 0 -30 -8 0 -8 -9 -11 -25 -8 -13 3 -22 2 -20 -3 6 -9 299 -4
            314 6 6 3 11 1 11 -4 0 -6 -14 -11 -31 -11 -18 0 -28 -4 -24 -10 4 -6 10 -8
            15 -5 4 3 13 -2 20 -10 7 -8 16 -12 21 -9 10 6 6 -52 -4 -63 -12 -11 -8 -43 5
            -43 8 0 9 -3 2 -10 -8 -8 -19 -7 -39 0 -39 15 -43 5 -5 -11 25 -11 30 -18 25
            -36 -4 -17 -1 -23 10 -23 12 0 15 -6 10 -20 -4 -13 -2 -20 7 -20 8 0 13 13 13
            35 0 21 -5 35 -12 35 -7 0 -13 7 -13 16 0 8 4 13 8 10 4 -2 14 0 22 5 12 8 12
            12 2 16 -6 2 -10 9 -6 14 3 5 -2 9 -10 9 -9 0 -16 5 -16 10 0 6 9 10 19 10 11
            0 23 -4 26 -10 10 -17 44 -11 54 9 11 21 5 31 -18 31 -9 0 -27 12 -40 25 -13
            14 -30 25 -39 25 -14 0 -14 2 -2 14 19 18 83 24 101 10 25 -20 42 -28 36 -17
            -8 12 14 23 45 23 13 0 18 -5 15 -14 -4 -10 -1 -13 9 -9 12 4 14 -1 11 -23 -2
            -16 -9 -29 -15 -28 -83 7 -81 6 -37 14 22 4 32 8 21 9 -13 1 -17 7 -13 21 3
            11 2 20 -3 20 -4 0 -10 -9 -13 -21 -3 -12 -10 -18 -15 -15 -6 3 -12 1 -14 -5
            -2 -7 -13 -9 -27 -4 -19 6 -21 9 -10 17 12 8 12 9 0 6 -28 -8 -23 -28 8 -34
            17 -4 31 -13 31 -21 0 -10 5 -11 18 -4 10 5 24 6 32 1 10 -6 2 -11 -24 -15
            -21 -4 -40 -14 -43 -21 -3 -8 -11 -14 -19 -14 -8 0 -14 -4 -14 -10 0 -5 9 -10
            20 -10 11 0 20 7 20 15 0 19 27 19 46 1 19 -20 18 -26 -7 -26 -13 0 -18 4 -13
            13 5 9 3 9 -7 -1 -12 -11 -12 -14 0 -22 12 -7 12 -12 3 -23 -7 -8 -10 -21 -6
            -30 4 -11 -4 -20 -23 -30 -35 -18 -115 -28 -133 -17 -15 9 -40 2 -40 -12 0 -5
            5 -6 10 -3 6 3 15 3 22 -1 8 -5 6 -11 -6 -20 -10 -7 -15 -17 -11 -23 9 -16 25
            -13 25 4 0 21 23 19 37 -5 16 -24 8 -29 -36 -24 -33 4 -60 27 -61 52 0 6 -4
            12 -10 12 -14 0 -13 -13 2 -28 9 -9 8 -12 -4 -12 -20 0 -42 26 -33 40 4 7 -1
            9 -17 5 -13 -3 -37 1 -53 9 -25 13 -26 16 -11 22 11 4 21 1 27 -7 6 -9 9 -10
            9 -2 0 17 -29 31 -51 26 -14 -4 -19 0 -19 16 0 24 10 27 28 9 9 -9 15 -9 23
            -1 9 9 5 14 -14 18 -14 4 -33 17 -41 30 -14 21 -13 24 2 37 9 8 31 15 48 16
            26 2 30 -1 29 -20 -1 -13 -8 -23 -16 -23 -8 0 -13 6 -11 13 3 19 -16 14 -27
            -7 -10 -17 -6 -19 39 -23 52 -3 58 0 38 21 -11 10 -10 15 3 24 8 7 18 20 22
            31 6 15 14 17 52 12 31 -5 45 -4 45 4 0 7 -8 9 -20 5 -11 -3 -20 -2 -20 4 0 5
            12 12 28 14 22 3 21 4 -10 6 -31 1 -35 4 -31 21 4 16 0 20 -20 20 -14 0 -29
            -5 -33 -12 -5 -7 0 -9 14 -6 15 4 22 0 26 -15 7 -29 0 -33 -22 -14 -20 18 -47
            23 -37 7 3 -5 -1 -10 -9 -10 -9 0 -16 -4 -16 -10 0 -14 13 -13 28 2 6 6 15 8
            19 3 5 -4 2 -10 -5 -12 -6 -3 -10 -9 -6 -14 3 -5 -4 -9 -15 -9 -11 0 -23 5
            -26 10 -3 6 -14 10 -23 10 -15 0 -15 -2 -2 -10 13 -9 13 -10 0 -10 -9 0 -23 9
            -32 20 -16 18 -16 20 0 29 16 10 16 10 0 11 -10 0 -18 -4 -18 -9 0 -6 -18 -21
            -40 -34 -30 -18 -37 -26 -28 -35 15 -15 32 -16 23 -2 -3 6 3 10 15 10 12 0 18
            5 15 10 -3 6 4 10 17 10 23 -1 23 -1 -4 -25 -28 -24 -38 -55 -18 -55 6 0 10
            -4 10 -10 0 -5 -9 -10 -19 -10 -11 0 -27 -7 -36 -16 -8 -9 -22 -13 -30 -10
            -25 9 -17 26 15 33 17 3 30 9 30 14 0 12 -15 11 -62 -3 -34 -10 -39 -15 -33
            -35 4 -16 1 -23 -10 -23 -8 0 -15 6 -15 13 0 8 -11 19 -25 25 -36 17 -32 33 5
            21 24 -9 33 -8 46 5 8 9 26 16 39 16 34 0 39 16 13 35 -21 15 -22 17 -5 26 9
            5 17 17 17 24 0 8 5 15 10 15 6 0 5 8 -2 20 -14 22 -33 26 -43 10 -3 -6 1 -7
            9 -4 11 4 16 0 16 -10 0 -9 -8 -16 -20 -16 -11 0 -20 5 -20 10 0 6 -7 10 -15
            10 -8 0 -12 -6 -9 -14 3 -7 -2 -16 -11 -20 -9 -3 -18 0 -21 9 -4 8 -12 15 -20
            15 -17 0 -17 -1 0 -35 8 -15 12 -34 9 -41 -3 -9 1 -14 12 -14 9 0 14 4 10 9
            -3 6 3 14 12 20 14 8 15 10 3 11 -8 0 -2 7 14 15 16 9 37 13 45 9 13 -5 11 -8
            -8 -13 -32 -8 -39 -31 -12 -38 32 -8 25 -23 -11 -23 -18 0 -43 -3 -55 -6 -16
            -4 -23 0 -29 18 -4 12 -7 29 -5 37 1 8 -3 17 -10 22 -10 6 -10 11 1 23 21 26
            2 32 -20 6 -25 -28 -25 -40 -2 -41 9 -1 1 -5 -18 -9 l-35 -7 32 -2 c25 -1 31
            -5 30 -21 -1 -23 -27 -28 -27 -5 0 14 -24 20 -58 16 -7 -1 -19 5 -28 13 -13
            14 -12 16 11 22 27 7 35 24 10 24 -8 0 -15 -4 -15 -10 0 -5 -7 -10 -15 -10 -9
            0 -12 6 -9 15 4 10 0 15 -10 15 -12 0 -15 -6 -10 -24 4 -17 1 -25 -13 -30 -10
            -3 -24 -9 -30 -12 -7 -3 -13 -1 -13 4 0 5 10 12 23 16 18 5 20 8 7 16 -8 5
            -24 9 -35 9 l-20 0 20 -9 c17 -7 17 -9 3 -9 -10 -1 -18 -7 -18 -13 1 -26 28
            -47 68 -54 23 -4 39 -11 36 -16 -3 -5 -13 -7 -21 -4 -9 4 -21 2 -28 -4 -15
            -12 -55 7 -55 25 0 6 -19 12 -42 14 -23 1 -45 4 -49 7 -4 2 -14 -3 -22 -11 -9
            -8 -23 -15 -31 -15 -9 0 -17 -4 -17 -10 -1 -5 -2 -15 -3 -22 -2 -18 -26 -22
            -26 -4 0 9 -7 16 -15 16 -12 0 -13 -4 -6 -19 8 -15 4 -25 -26 -55 -37 -36 -57
            -44 -68 -26 -10 16 -22 12 -40 -16 -17 -26 -17 -27 3 -22 16 5 20 1 20 -16 -1
            -11 -6 -27 -11 -33 -5 -7 -9 -28 -9 -48 0 -19 -5 -35 -10 -35 -6 0 -8 7 -4 15
            3 8 1 15 -5 15 -6 0 -8 7 -5 16 4 12 2 15 -9 10 -10 -4 -15 1 -16 17 -1 20 -2
            20 -6 2 -7 -28 -23 -43 -23 -22 0 9 -5 17 -11 17 -6 0 -9 -7 -6 -15 3 -7 -2
            -24 -13 -37 -13 -16 -15 -28 -9 -40 5 -10 6 -18 2 -18 -4 0 -2 -4 5 -9 10 -6
            9 -10 -7 -14 -23 -6 -27 3 -10 20 8 8 7 13 -2 18 -8 5 -10 4 -5 -4 4 -6 1 -13
            -6 -16 -7 -3 -14 -22 -14 -43 -2 -37 -1 -38 32 -36 24 2 34 -1 34 -11 0 -16
            -25 -21 -34 -6 -10 15 -55 10 -49 -5 3 -8 11 -14 19 -14 8 0 14 -4 14 -10 0
            -17 -38 -11 -50 8 -11 16 -11 16 -6 0 3 -12 0 -18 -10 -18 -12 0 -13 -4 -4
            -21 13 -24 4 -41 -10 -19 -8 12 -10 10 -10 -9 0 -19 4 -22 21 -18 15 4 19 2
            15 -10 -3 -8 -6 -29 -7 -46 0 -18 -3 -26 -6 -19 -2 6 -9 10 -14 6 -5 -3 -9 -1
            -9 5 0 5 5 13 10 16 6 3 10 14 9 23 0 11 -2 12 -6 4 -2 -6 -9 -10 -14 -6 -5 3
            -9 -4 -9 -16 0 -12 -4 -18 -10 -15 -5 3 -10 -3 -10 -15 0 -12 4 -19 9 -16 5 3
            14 0 20 -8 15 -18 -5 -26 -36 -15 -23 8 -33 -4 -13 -16 13 -8 13 -55 1 -55 -5
            0 -12 8 -14 18 -3 9 -5 -29 -5 -85 0 -55 -3 -106 -6 -112 -12 -18 -29 -13 -20
            7 4 9 8 43 9 74 1 47 -1 55 -12 46 -8 -6 -11 -19 -7 -30 6 -20 -12 -103 -26
            -123 -4 -5 -14 -36 -21 -69 -11 -52 -11 -59 4 -68 12 -6 17 -22 16 -56 0 -31
            -3 -41 -8 -28 -6 17 -10 14 -30 -19 -12 -21 -21 -47 -20 -59 3 -49 -2 -87 -11
            -81 -6 4 -10 -8 -10 -29 0 -19 -5 -47 -10 -61 -6 -15 -11 -49 -12 -76 -1 -27
            -6 -49 -10 -49 -4 0 -8 -28 -8 -62 0 -45 -3 -59 -10 -48 -7 11 -10 7 -11 -17
            -4 -98 -21 -213 -30 -208 -4 3 -10 -23 -12 -57 -3 -35 -12 -124 -21 -198 -8
            -74 -15 -151 -16 -170 -1 -27 -3 -31 -9 -15 -7 16 -9 14 -14 -14 -4 -19 -7
            -51 -7 -72 0 -21 -5 -41 -11 -44 -18 -12 -4 -28 27 -30 l30 -2 -2 114 c-2 79
            0 112 8 107 7 -4 9 8 6 37 -3 35 0 47 16 61 12 10 26 17 32 18 17 0 42 -69 49
            -138 7 -73 13 -94 26 -87 5 3 7 -11 6 -31 -2 -44 20 -59 26 -17 2 18 4 12 5
            -17 0 -25 -2 -39 -5 -32 -9 23 -23 12 -23 -19 0 -16 4 -28 9 -25 5 3 12 -1 15
            -10 3 -8 2 -13 -3 -10 -5 3 -7 -30 -4 -77 5 -72 2 -91 -21 -152 -49 -127 -150
            -254 -326 -409 -160 -140 -418 -394 -503 -496 -40 -47 -74 -87 -77 -90 -22
            -22 -78 -103 -125 -180 -99 -164 -121 -213 -176 -405 -1 -5 1 -63 5 -128 9
            -125 15 -137 66 -137 34 0 131 55 190 107 30 27 73 62 94 78 72 53 377 200
            476 229 52 15 104 31 115 35 23 10 157 -1 228 -18 126 -30 335 -165 338 -220
            1 -9 4 -5 8 9 7 22 9 19 19 -25 6 -30 7 -54 1 -61 -5 -7 -2 -44 10 -100 15
            -65 22 -83 27 -67 3 13 10 20 15 17 5 -3 9 0 9 5 0 6 5 11 10 11 16 0 12 -30
            -5 -36 -8 -4 -15 -14 -15 -24 0 -10 -5 -20 -11 -22 -8 -3 -5 -29 9 -88 12 -47
            26 -116 32 -155 6 -38 20 -99 29 -135 41 -147 64 -312 71 -510 1 -8 5 -42 11
            -75 5 -33 11 -89 13 -125 7 -96 24 -317 30 -380 3 -30 8 -80 10 -110 3 -30 10
            -98 16 -150 30 -264 43 -389 46 -430 1 -25 8 -65 14 -89 6 -24 11 -60 11 -80
            1 -20 7 -74 15 -120 8 -46 14 -111 14 -145 0 -34 7 -88 15 -120 11 -41 13 -68
            6 -91 -5 -18 -5 -37 -1 -42 5 -4 11 -35 14 -68 3 -33 7 -76 10 -95 7 -62 37
            -472 41 -555 2 -44 6 -143 9 -220 4 -77 9 -170 12 -207 l5 -68 -55 6 c-58 7
            -139 -12 -116 -26 11 -7 10 -15 -1 -42 -7 -18 -14 -35 -15 -38 -4 -28 -29 -88
            -34 -85 -7 5 -23 -32 -21 -47 1 -5 -3 -8 -9 -8 -5 0 -11 -24 -11 -52 l-2 -53
            -8 40 c-5 26 -6 4 -4 -65 3 -69 11 -127 24 -170 12 -36 21 -91 21 -123 0 -51
            -7 -71 -63 -180 -81 -156 -191 -339 -242 -401 -59 -73 -228 -257 -325 -355
            -101 -101 -189 -172 -357 -284 -68 -45 -123 -88 -123 -96 0 -19 43 -6 110 34
            173 104 194 114 216 109 36 -9 132 15 163 42 16 13 57 40 92 60 35 20 78 44
            94 54 17 9 60 37 97 61 85 56 121 79 177 108 25 12 62 36 82 52 20 16 41 29
            46 29 11 0 135 121 270 263 85 90 109 122 147 202 27 56 66 117 96 150 28 30
            50 63 50 74 0 10 9 26 20 36 11 10 20 26 20 35 0 30 73 118 117 141 50 25 113
            70 113 80 0 5 6 9 14 9 20 0 125 124 174 206 23 39 54 107 68 150 14 43 39
            106 56 139 17 33 34 78 38 100 4 22 19 99 34 170 15 72 34 175 41 230 17 116
            29 196 45 280 7 33 13 92 15 130 2 39 6 97 10 130 3 33 8 123 9 200 2 77 9
            158 14 180 6 22 13 92 17 155 3 63 9 178 14 255 5 77 6 145 4 152 -3 7 0 77 7
            155 6 79 13 233 16 343 8 331 11 376 29 475 9 52 21 118 26 145 22 133 39 219
            53 267 19 72 36 145 36 162 0 8 5 27 12 43 13 32 49 217 78 406 20 134 50 215
            90 252 15 14 43 21 98 26 42 4 99 15 127 24 63 22 290 26 350 6 68 -22 263
            -123 309 -160 24 -20 57 -44 72 -53 43 -26 64 -40 114 -74 25 -17 50 -33 56
            -35 7 -2 55 -44 107 -94 52 -49 123 -112 157 -139 l62 -49 43 21 c61 29 86 81
            93 188 3 48 0 103 -8 135 -12 52 -139 328 -165 359 -7 8 -15 28 -19 43 -17 73
            -263 407 -439 597 -23 25 -89 101 -147 170 -58 69 -131 151 -163 184 -85 86
            -184 237 -200 307 -4 15 0 48 8 75 18 59 18 57 4 48 -7 -4 -10 17 -7 70 2 42
            0 76 -3 76 -14 0 -59 -59 -68 -90 -10 -31 -10 -28 -6 26 3 33 19 96 35 140 35
            94 37 132 15 454 -15 225 -22 271 -36 249 -6 -10 -10 -9 -15 5 -4 10 -2 21 4
            25 6 3 17 35 25 71 18 77 31 495 18 555 l-8 40 -8 -32 c-7 -32 -35 -67 -35
            -45 0 6 5 12 11 14 6 2 11 24 11 48 -1 25 4 47 9 51 6 3 11 47 12 97 0 51 5
            92 10 92 4 0 0 13 -10 30 -13 23 -15 32 -5 42 8 8 12 8 12 1 0 -23 38 0 44 25
            3 15 8 37 12 50 4 13 2 22 -5 22 -6 0 -11 -12 -11 -26 0 -14 -4 -23 -10 -19
            -5 3 -10 17 -10 31 0 13 5 24 10 24 6 0 10 8 10 18 0 15 2 15 11 2 8 -12 9 -9
            3 10 -4 14 -6 42 -6 63 1 20 -2 37 -7 37 -14 0 -22 -29 -15 -58 5 -25 4 -25
            -9 -9 -8 9 -12 28 -10 41 3 13 0 27 -6 30 -6 4 -11 2 -11 -3 0 -6 -7 -11 -15
            -11 -10 0 -15 10 -15 27 0 21 3 24 12 15 18 -18 41 -15 34 4 -3 8 -2 12 4 9 6
            -3 10 3 10 14 0 25 -30 18 -31 -8 0 -12 -2 -11 -8 4 -5 11 -4 22 0 23 5 2 9
            10 9 19 0 10 6 12 20 8 16 -5 20 -2 19 17 0 13 -3 17 -6 11 -2 -7 -16 -13 -29
            -13 -13 0 -24 -5 -24 -12 0 -6 -2 -9 -5 -7 -10 11 8 50 22 45 10 -4 13 0 11
            16 -2 12 -9 23 -16 24 -7 1 -8 0 -2 -3 19 -9 10 -24 -12 -18 -17 6 -20 4 -15
            -9 4 -9 2 -16 -3 -16 -6 0 -10 5 -10 11 0 6 -7 9 -15 5 -9 -3 -15 0 -15 9 0 8
            9 15 20 15 11 0 20 7 20 16 0 8 4 13 9 10 5 -3 14 1 20 8 10 12 9 14 -6 12
            -10 -2 -22 2 -26 8 -6 7 -7 4 -3 -6 7 -23 -23 -40 -47 -27 -9 6 -17 18 -17 27
            1 14 4 13 20 -7 15 -17 20 -19 20 -8 0 9 -5 19 -10 22 -17 10 -2 24 20 18 11
            -3 20 -1 20 4 0 12 -48 15 -55 3 -8 -12 -25 -13 -25 -1 0 11 38 24 63 21 9 -1
            17 4 17 9 0 6 -9 11 -20 11 -20 0 -20 1 -4 25 10 15 12 25 5 25 -6 0 -11 -5
            -11 -11 0 -5 -4 -7 -10 -4 -5 3 -10 17 -10 30 0 13 -4 27 -10 30 -16 10 -11
            28 6 21 16 -6 14 0 -18 47 -11 15 -24 25 -29 22 -5 -4 -9 -1 -9 4 0 6 -5 11
            -11 11 -5 0 -7 -5 -4 -11 4 -5 9 -31 11 -56 4 -51 11 -64 23 -45 6 10 10 10
            20 -2 9 -11 9 -16 0 -22 -8 -5 -5 -12 10 -23 22 -17 21 -51 -1 -51 -6 0 -8 6
            -5 14 3 8 -1 22 -8 33 -8 10 -15 13 -15 7 0 -5 6 -16 13 -23 10 -11 9 -13 -3
            -9 -9 3 -14 16 -13 31 1 15 -3 26 -9 24 -16 -3 -40 86 -25 95 9 6 9 8 0 8 -7
            0 -13 8 -13 19 0 12 -9 20 -27 24 -16 3 -36 9 -46 12 -13 6 -16 3 -11 -10 6
            -15 4 -16 -14 -6 -20 11 -22 9 -22 -17 0 -15 5 -33 12 -40 9 -9 8 -12 -5 -12
            -10 0 -22 11 -27 25 -5 14 -14 25 -20 25 -18 0 -11 20 8 21 10 0 12 3 4 6 -6
            2 -10 9 -7 13 3 5 12 7 20 3 9 -3 22 0 30 7 8 7 22 10 30 6 21 -8 25 -7 25 2
            0 4 -3 7 -7 7 -5 0 -13 3 -19 7 -14 9 -77 -2 -86 -15 -4 -7 -11 -7 -22 2 -21
            17 -20 33 0 29 36 -6 21 38 -18 56 -10 5 -10 7 0 12 10 5 10 9 1 20 -9 11 -8
            14 4 14 18 0 41 -35 33 -49 -7 -11 22 -36 31 -27 3 3 1 6 -6 6 -9 0 -8 5 3 15
            9 9 26 14 41 11 14 -3 23 -2 21 2 -2 4 -19 9 -37 10 -19 2 -43 12 -54 22 -12
            11 -29 20 -37 20 -24 0 -33 -38 -14 -59 18 -19 21 -36 6 -26 -5 3 -12 -6 -16
            -20 -7 -28 -48 -36 -58 -10 -3 8 -12 15 -21 15 -8 0 -15 5 -15 10 0 13 4 13
            45 -4 40 -17 48 -5 10 12 -14 6 -25 17 -25 23 0 7 9 5 25 -5 22 -14 25 -14 25
            -1 0 8 -10 17 -23 20 -22 6 -65 75 -46 75 5 0 9 5 9 10 0 6 -13 10 -29 10 -17
            0 -33 5 -36 10 -4 6 9 10 30 10 28 0 38 -5 42 -20 3 -11 10 -20 15 -20 6 0 7
            8 4 17 -4 9 -1 20 6 25 9 5 6 8 -10 8 -15 0 -21 4 -16 12 5 7 3 8 -6 3 -7 -4
            -28 -10 -47 -12 -24 -3 -33 0 -33 12 0 8 -4 15 -10 15 -5 0 -10 -4 -10 -9 0
            -6 5 -13 10 -16 16 -10 12 -35 -6 -35 -9 0 -14 6 -12 12 3 7 -1 19 -8 26 -11
            11 -18 10 -39 -4 -16 -10 -25 -12 -25 -5 0 6 7 14 15 17 8 4 15 14 15 23 -1
            14 -2 14 -11 -1 -5 -10 -17 -18 -26 -18 -13 0 -13 3 -3 15 10 12 10 15 -1 16
            -39 2 -66 6 -89 12 -24 7 -24 7 -2 -4 12 -7 22 -15 22 -20 0 -8 -40 0 -56 12
            -15 12 -54 -18 -47 -37 4 -12 0 -15 -18 -14 -37 3 -45 1 -29 -10 13 -8 12 -10
            -3 -10 -10 0 -20 7 -23 15 -7 17 -44 21 -44 5 0 -5 -10 -10 -22 -10 -22 1 -22
            1 4 15 19 11 25 20 21 35 -3 11 -9 23 -14 26 -20 12 -6 19 40 20 43 2 49 -1
            48 -17 -1 -10 -5 -19 -10 -19 -4 0 -5 -4 -2 -10 4 -6 -5 -10 -21 -10 -37 0
            -28 -6 21 -13 25 -4 35 -3 26 2 -11 7 -7 14 20 31 l34 21 -30 -7 c-28 -6 -29
            -5 -11 9 19 14 16 15 -35 26 -30 7 -130 14 -224 17 -93 2 -182 6 -196 9 -15 2
            -29 2 -31 -1z m656 -100 c3 -9 4 -20 0 -25 -3 -5 0 -9 5 -9 6 0 11 -4 11 -10
            0 -5 -7 -10 -15 -10 -9 0 -15 9 -15 25 0 14 -4 25 -10 25 -5 0 -10 -5 -10 -11
            0 -7 -11 -9 -30 -5 -20 3 -29 2 -25 -4 9 -15 -21 -23 -42 -11 -16 9 -16 10 -2
            11 9 0 19 7 23 15 6 18 43 20 48 3 3 -8 9 -6 19 5 18 21 35 21 43 1z m-474
            -14 c0 -5 9 -10 20 -10 11 0 20 -4 20 -10 0 -11 -6 -12 -37 -4 -22 6 -33 34
            -13 34 6 0 10 -4 10 -10z m-637 -56 c-1 -5 -9 -11 -19 -12 -11 -3 -14 0 -9 8
            6 9 -1 10 -24 5 -36 -8 -41 5 -8 21 23 11 64 -4 60 -22z m867 27 c0 -10 -30
            -24 -37 -17 -3 3 -2 9 2 15 8 13 35 15 35 2z m133 -16 c26 -4 47 -11 47 -16 0
            -13 -38 -11 -46 2 -3 6 -15 9 -25 6 -10 -3 -22 -1 -25 4 -3 5 -13 5 -24 -1
            -24 -13 -50 -13 -50 0 0 6 9 10 19 10 11 0 23 5 26 10 4 7 11 8 18 1 7 -5 33
            -12 60 -16z m77 15 c0 -5 -12 -10 -26 -10 -14 0 -23 4 -19 10 3 6 15 10 26 10
            10 0 19 -4 19 -10z m100 0 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10
            16 10 5 0 9 -4 9 -10z m-375 -19 c-8 -13 -35 -15 -35 -2 0 5 8 11 18 13 21 5
            26 2 17 -11z m-747 -13 c-7 -19 -38 -22 -38 -4 0 10 9 16 21 16 12 0 19 -5 17
            -12z m1039 6 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m53 -4 c0
            -5 -4 -10 -10 -10 -5 0 -7 -7 -3 -16 4 -12 14 -15 41 -10 32 5 34 4 20 -10 -9
            -9 -29 -17 -45 -17 -26 -2 -28 -4 -18 -24 5 -13 13 -23 18 -23 4 0 7 -5 7 -11
            0 -6 -12 -9 -27 -7 -25 3 -28 7 -25 35 2 17 -2 34 -8 38 -7 4 -5 12 5 22 9 9
            14 22 11 29 -3 9 2 14 14 14 11 0 20 -4 20 -10z m130 0 c0 -6 -12 -10 -27 -11
            -16 0 -40 -2 -55 -4 -27 -3 -40 10 -17 17 26 8 99 6 99 -2z m-420 -9 c0 -5 -6
            -12 -12 -14 -7 -3 -2 -6 12 -7 23 -1 23 -2 5 -9 -28 -11 -51 -3 -52 20 -1 14
            5 19 23 19 13 0 24 -4 24 -9z m-1070 -11 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10
            10 0 6 5 10 10 10 6 0 10 -4 10 -10z m40 1 c0 -12 -20 -25 -27 -18 -7 7 6 27
            18 27 5 0 9 -4 9 -9z m1120 0 c0 -12 -29 -23 -41 -15 -5 3 -7 10 -4 15 8 12
            45 12 45 0z m44 3 c20 -8 11 -24 -15 -24 -10 0 -19 7 -19 15 0 16 9 19 34 9z
            m-1134 -14 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4
            -10z m260 1 c0 -8 -56 -21 -97 -21 -13 0 -23 5 -23 10 0 6 15 8 35 6 19 -3 37
            0 40 5 8 11 45 12 45 0z m970 -6 c-7 -8 -16 -13 -20 -10 -4 3 -13 -2 -21 -11
            -19 -23 -46 -35 -64 -28 -20 8 -1 34 26 34 10 0 24 7 31 15 7 8 24 14 37 15
            20 0 21 -2 11 -15z m215 5 c3 -5 0 -10 -7 -11 -10 0 -10 -2 0 -6 13 -5 17 -53
            5 -53 -19 1 -30 18 -30 48 1 31 19 44 32 22z m-1545 -11 c0 -6 8 -8 18 -5 14
            5 14 4 -2 -9 -11 -8 -26 -15 -33 -15 -7 0 -13 -4 -13 -10 0 -5 -10 -10 -22 -9
            l-23 0 22 11 c12 6 23 19 26 29 5 20 27 27 27 8z m1492 5 c12 -12 -5 -44 -24
            -44 -14 0 -18 5 -14 16 3 9 6 20 6 25 0 10 23 12 32 3z m-465 -21 c-9 -9 -19
            -14 -23 -11 -10 10 6 28 24 28 15 0 15 -1 -1 -17z m-957 -3 c0 -5 -11 -12 -25
            -16 -14 -3 -25 -10 -25 -15 0 -5 -7 -9 -15 -9 -8 0 -15 5 -15 10 0 6 6 10 14
            10 7 0 19 7 26 15 14 17 40 20 40 5z m48 -2 c-3 -7 -12 -14 -21 -16 -12 -2
            -15 1 -11 12 7 19 38 22 32 4z m96 3 c3 -5 -3 -11 -14 -14 -13 -3 -20 0 -20 9
            0 15 26 19 34 5z m864 -19 c4 -26 -13 -29 -35 -6 -14 13 -14 17 -2 25 20 13
            34 6 37 -19z m100 18 c6 0 12 -4 12 -9 0 -4 -18 -8 -40 -8 -22 0 -40 3 -40 7
            0 14 15 21 35 16 11 -3 26 -6 33 -6z m-858 -10 c0 -5 -7 -10 -15 -10 -8 0 -15
            5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m-150 -9 c0 -10 -45 -41 -60 -41
            -19 0 -10 25 13 36 28 15 47 16 47 5z m90 -1 c0 -5 11 -10 25 -10 30 0 32 -10
            5 -33 -22 -19 -50 -23 -50 -7 0 6 5 10 10 10 6 0 10 7 10 15 0 8 -7 15 -15 15
            -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m129 -4 c9 -10 9 -16 1
            -21 -12 -7 -40 11 -40 26 0 14 26 10 39 -5z m191 -5 c0 -11 7 -27 16 -36 21
            -21 7 -35 -28 -28 -28 5 -47 43 -22 43 8 0 14 5 14 10 0 6 -4 10 -10 10 -5 0
            -10 5 -10 10 0 6 9 10 20 10 13 0 20 -7 20 -19z m750 15 c0 -16 -14 -36 -26
            -36 -14 0 -19 22 -7 33 7 8 33 10 33 3z m-299 -36 c6 0 7 -4 4 -10 -10 -17
            -42 -11 -42 8 0 22 21 36 25 17 2 -8 8 -15 13 -15z m387 19 c-2 -6 -8 -10 -13
            -10 -5 0 -11 4 -13 10 -2 6 4 11 13 11 9 0 15 -5 13 -11z m-1343 -12 c15 -16
            8 -67 -9 -67 -28 1 -64 50 -36 50 6 0 10 -8 10 -17 0 -14 2 -15 13 -4 10 10 8
            14 -6 22 -9 6 -15 14 -12 20 9 13 24 11 40 -4z m140 2 c10 -15 -1 -23 -20 -15
            -9 3 -13 10 -10 16 8 13 22 13 30 -1z m965 1 c0 -5 -9 -10 -20 -10 -11 0 -20
            5 -20 10 0 6 9 10 20 10 11 0 20 -4 20 -10z m290 -4 c0 -8 -9 -18 -21 -21 -16
            -5 -18 -10 -9 -20 7 -8 9 -18 6 -24 -6 -10 -49 -3 -43 8 2 3 9 21 16 39 13 33
            51 46 51 18z m50 -7 c0 -5 -7 -9 -15 -9 -8 0 -15 7 -15 15 0 9 6 12 15 9 8 -4
            15 -10 15 -15z m-1526 2 c18 -7 18 -8 3 -14 -9 -3 -17 -3 -17 1 0 4 -18 7 -40
            5 -24 -1 -38 2 -35 8 7 11 61 11 89 0z m366 -1 c0 -5 -7 -10 -15 -10 -8 0 -15
            5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m134 4 c25 -9 19 -24 -9 -24 -16 0
            -25 6 -25 15 0 16 9 19 34 9z m626 -3 c0 -11 -45 -41 -62 -41 -22 0 -47 -37
            -41 -59 6 -24 -17 -37 -44 -26 -16 6 -16 8 4 24 15 13 19 21 11 29 -14 14 -1
            32 25 32 12 0 35 11 51 25 29 25 56 33 56 16z m143 -6 c-6 -18 -34 -16 -41 3
            -2 7 6 12 22 12 19 0 24 -4 19 -15z m-1287 -27 c20 4 25 1 22 -9 -3 -9 -17
            -13 -38 -11 -40 3 -67 21 -49 33 7 4 18 1 25 -6 7 -7 25 -10 40 -7z m204 12
            c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z
            m260 -5 c0 -8 -9 -15 -20 -15 -25 0 -26 17 -2 23 9 3 18 5 20 6 1 0 2 -6 2
            -14z m755 5 c3 -6 -2 -13 -12 -17 -10 -4 -23 -13 -29 -21 -6 -8 -16 -12 -22
            -8 -17 10 -15 26 3 26 8 0 14 3 14 8 -3 17 2 22 20 22 11 0 23 -5 26 -10z
            m135 1 c0 -11 -26 -22 -34 -14 -12 12 -5 23 14 23 11 0 20 -4 20 -9z m-1190
            -21 c8 -16 8 -20 -3 -20 -18 0 -39 24 -30 33 11 12 22 8 33 -13z m830 5 c0 -8
            -9 -15 -20 -15 -25 0 -26 17 -2 23 9 3 18 5 20 6 1 0 2 -6 2 -14z m575 6 c3
            -5 1 -12 -4 -15 -13 -8 -42 4 -35 15 7 12 31 12 39 0z m-1315 -16 c0 -8 -7
            -15 -15 -15 -8 0 -15 -4 -15 -10 0 -5 -9 -10 -20 -10 -20 0 -20 1 -4 25 18 27
            54 34 54 10z m128 3 c-3 -7 -13 -13 -23 -13 -10 0 -20 6 -22 13 -3 7 5 12 22
            12 17 0 25 -5 23 -12z m162 -9 c0 -5 -5 -9 -11 -9 -6 0 -17 -7 -25 -15 -12
            -11 -20 -12 -35 -4 -27 14 -19 34 14 34 14 0 29 3 33 7 8 9 24 1 24 -13z m760
            1 c0 -13 -7 -20 -20 -20 -19 0 -27 20 -13 33 13 14 33 6 33 -13z m97 3 c4 -10
            10 -20 14 -23 4 -3 9 -14 11 -25 2 -14 -3 -20 -17 -20 -15 0 -19 6 -18 23 2
            15 -3 22 -15 22 -26 0 -34 11 -21 27 16 18 38 16 46 -4z m113 7 c0 -5 -7 -10
            -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m-1630 -19 c0
            -11 -31 -24 -39 -16 -3 3 1 10 9 15 19 12 30 12 30 1z m50 2 c0 -17 -19 -35
            -29 -29 -18 12 -12 36 9 36 11 0 20 -3 20 -7z m372 -25 c-15 -15 -26 -4 -18
            18 5 13 9 15 18 6 9 -9 9 -15 0 -24z m380 19 c23 -11 28 -37 7 -37 -9 0 -49
            36 -49 45 0 9 17 6 42 -8z m256 -4 c-2 -10 -12 -18 -23 -18 -11 0 -21 8 -23
            18 -3 13 3 17 23 17 20 0 26 -4 23 -17z m307 7 c3 -6 0 -13 -8 -16 -8 -3 -17
            -18 -21 -35 -6 -34 -26 -38 -44 -9 -15 25 -8 40 19 40 10 0 18 3 18 8 -4 23
            23 32 36 12z m-1195 -15 c0 -18 1 -18 33 -6 34 13 89 0 77 -18 -7 -13 -11 -13
            -25 -1 -15 12 -18 12 -26 -3 -12 -21 -48 -22 -55 -2 -4 9 -11 15 -18 14 -33
            -4 -41 2 -22 16 25 19 36 19 36 0z m540 0 c0 -8 -9 -15 -20 -15 -11 0 -20 7
            -20 15 0 8 9 15 20 15 11 0 20 -7 20 -15z m40 5 c0 -5 -7 -10 -15 -10 -8 0
            -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m358 -8 c2 -7 -3 -12 -12 -12
            -9 0 -16 7 -16 16 0 17 22 14 28 -4z m130 6 c-7 -20 -48 -23 -48 -4 0 11 9 16
            26 16 16 0 24 -5 22 -12z m419 -4 c2 -6 -4 -14 -13 -18 -19 -7 -47 11 -38 26
            8 12 46 6 51 -8z m71 -11 c5 -34 -34 -31 -41 3 -4 21 -1 25 17 22 13 -2 22
            -12 24 -25z m70 15 c16 -16 15 -28 0 -28 -22 0 -41 17 -34 29 9 14 19 14 34
            -1z m-1700 -21 c2 -10 -3 -17 -11 -17 -8 0 -28 -7 -43 -15 -33 -17 -54 -19
            -54 -6 0 16 29 33 48 27 14 -5 21 -1 25 15 6 24 30 21 35 -4z m462 8 c7 -8 19
            -15 26 -15 8 0 14 -7 14 -16 0 -10 -7 -14 -22 -12 -15 2 -22 9 -22 21 1 9 -3
            17 -10 16 -23 -3 -36 1 -36 11 0 15 36 12 50 -5z m904 6 c17 -26 -26 -60 -50
            -40 -18 15 -18 29 1 29 8 0 15 5 15 10 0 12 27 13 34 1z m-976 -24 c3 -12 -3
            -17 -22 -17 -29 0 -44 19 -25 31 20 13 43 6 47 -14z m179 -2 c10 -22 9 -25 -7
            -25 -16 0 -30 18 -30 41 0 19 25 8 37 -16z m187 -6 c-9 -10 -21 -19 -26 -19
            -13 0 -9 17 8 34 22 23 39 8 18 -15z m-874 6 c0 -8 -7 -15 -16 -15 -14 0 -14
            3 -4 15 7 8 14 15 16 15 2 0 4 -7 4 -15z m385 -5 c0 -18 -33 -26 -47 -12 -6 6
            -7 15 -3 22 10 16 50 8 50 -10z m421 -9 c7 -13 10 -30 7 -38 -8 -20 -60 -45
            -68 -33 -9 15 3 30 20 24 16 -7 40 32 25 41 -5 3 -10 -1 -10 -9 0 -9 -5 -16
            -11 -16 -6 0 -9 7 -6 15 4 8 2 17 -4 20 -14 9 0 27 19 23 9 -2 22 -14 28 -27z
            m818 14 c4 -10 -1 -15 -14 -15 -11 0 -20 7 -20 15 0 8 6 15 14 15 8 0 16 -7
            20 -15z m-1069 -5 c3 -5 -1 -10 -9 -10 -9 0 -16 5 -16 10 0 6 4 10 9 10 6 0
            13 -4 16 -10z m185 -6 c0 -9 -5 -14 -12 -12 -18 6 -21 28 -4 28 9 0 16 -7 16
            -16z m190 0 c0 -8 4 -13 9 -9 5 3 12 1 16 -5 7 -11 -5 -13 -29 -4 -17 7 -22
            34 -6 34 6 0 10 -7 10 -16z m150 7 c0 -6 -5 -13 -10 -16 -6 -4 -8 -11 -5 -16
            4 -5 4 -9 1 -9 -2 0 -11 -3 -20 -6 -11 -4 -16 -1 -16 10 0 9 5 16 10 16 6 0
            10 7 10 15 0 8 7 15 15 15 8 0 15 -4 15 -9z m324 1 c2 -4 1 -14 -4 -22 -7 -11
            -12 -12 -21 -3 -6 6 -8 16 -5 22 8 13 23 14 30 3z m-121 -17 c-4 -15 -36 -12
            -41 3 -3 9 3 13 19 10 13 -1 23 -7 22 -13z m405 3 c-3 -7 -11 -13 -18 -13 -7
            0 -15 6 -17 13 -3 7 4 12 17 12 13 0 20 -5 18 -12z m122 2 c0 -5 -7 -10 -15
            -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m188 -9 c3 -8 -1 -12
            -9 -9 -7 2 -15 10 -17 17 -3 8 1 12 9 9 7 -2 15 -10 17 -17z m-1698 -6 c0 -8
            -9 -15 -20 -15 -11 0 -20 7 -20 15 0 8 9 15 20 15 11 0 20 -7 20 -15z m159 1
            c17 -9 20 -15 11 -21 -6 -4 -17 -2 -23 4 -6 6 -19 11 -30 11 -10 0 -15 5 -12
            10 8 13 25 12 54 -4z m441 -17 c0 -27 -2 -30 -15 -19 -24 20 -19 39 13 49 1 1
            2 -13 2 -30z m108 -14 c-9 -9 -38 7 -38 20 0 21 24 24 34 4 5 -11 7 -22 4 -24z
            m516 30 c11 -8 29 -12 43 -9 31 8 22 -18 -12 -36 -22 -12 -27 -11 -50 14 -35
            38 -21 61 19 31z m426 5 c8 -5 12 -12 9 -15 -8 -8 -39 5 -39 16 0 11 11 11 30
            -1z m-1768 -5 c12 -12 -5 -55 -21 -55 -13 0 -18 8 -18 30 0 21 5 30 17 30 9 0
            19 -2 22 -5z m-176 -21 c12 -10 14 -14 4 -11 -8 2 -23 0 -32 -5 -13 -6 -18 -5
            -18 5 0 29 18 34 46 11z m121 -17 c3 -21 -2 -29 -19 -36 -31 -12 -46 4 -25 27
            9 10 17 24 17 31 0 24 23 5 27 -22z m114 26 c-10 -4 -8 -9 9 -18 23 -12 23
            -13 4 -23 -11 -6 -25 -7 -32 -3 -16 10 -3 51 17 50 12 0 13 -2 2 -6z m304 -3
            c3 -6 17 -7 31 -4 32 8 31 -10 -2 -24 -36 -17 -81 8 -57 31 9 10 21 9 28 -3z
            m120 0 c3 -5 -3 -10 -14 -10 -12 0 -21 5 -21 10 0 6 6 10 14 10 8 0 18 -4 21
            -10z m445 0 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4
            15 -10z m66 -18 c-3 -6 -16 -8 -27 -5 -21 6 -21 7 -4 20 20 14 42 3 31 -15z
            m250 12 c-3 -9 -6 -20 -6 -25 0 -13 -37 -11 -45 2 -8 12 22 38 44 39 8 0 11
            -6 7 -16z m219 -8 c0 -22 -14 -29 -34 -17 -19 12 -6 43 16 39 10 -2 18 -12 18
            -22z m95 14 c12 -7 9 -13 -14 -35 -16 -14 -35 -24 -42 -21 -19 7 -18 26 1 26
            8 0 14 3 14 8 -5 31 12 41 41 22z m58 -7 c2 -8 -5 -13 -17 -13 -12 0 -21 6
            -21 16 0 18 31 15 38 -3z m60 -7 c6 -30 -17 -45 -34 -22 -8 11 -14 26 -14 34
            0 21 44 10 48 -12z m225 -16 c-8 -31 -23 -23 -23 11 0 26 3 29 14 20 8 -7 12
            -21 9 -31z m-2015 18 c-3 -7 -12 -14 -21 -16 -12 -2 -15 1 -11 12 7 19 38 22
            32 4z m300 -6 c3 -9 -3 -13 -19 -10 -12 1 -24 9 -27 16 -3 9 3 13 19 10 12 -1
            24 -9 27 -16z m345 3 c1 -5 -6 -11 -15 -13 -11 -2 -18 3 -18 13 0 17 30 18 33
            0z m240 1 c21 -16 23 -46 2 -46 -8 0 -15 7 -15 15 0 9 -7 18 -15 21 -8 4 -12
            10 -9 15 7 12 16 11 37 -5z m307 -6 c0 -13 -7 -20 -19 -20 -22 0 -35 15 -26
            30 12 19 45 11 45 -10z m-415 -12 c-10 -34 -23 -44 -37 -30 -9 9 -7 16 7 32
            24 27 38 25 30 -2z m495 7 c0 -9 7 -18 15 -21 22 -9 18 -24 -6 -24 -15 0 -19
            5 -15 15 3 10 -1 15 -14 15 -20 0 -33 13 -23 24 12 12 43 6 43 -9z m670 0 c10
            -12 10 -15 -2 -15 -8 0 -20 6 -28 13 -12 10 -12 9 -1 -5 12 -16 9 -22 -11 -19
            -14 2 -8 -17 7 -23 21 -8 19 -28 -2 -24 -12 2 -18 14 -19 38 -2 47 29 67 56
            35z m-2134 -61 c8 3 12 2 9 -3 -3 -5 -1 -12 5 -16 13 -8 13 -35 0 -35 -5 0
            -10 9 -10 20 0 14 -5 18 -17 13 -10 -4 -14 -2 -9 5 3 6 1 14 -5 17 -5 4 -8 13
            -5 21 3 8 0 14 -6 14 -6 0 -5 6 3 17 12 15 14 12 17 -21 3 -29 7 -36 18 -32z
            m18 50 c9 -22 -3 -32 -14 -14 -12 19 -12 30 -1 30 5 0 12 -7 15 -16z m574 4
            c-5 -14 -57 -33 -66 -23 -10 9 28 35 51 35 10 0 17 -5 15 -12z m190 -22 c-4
            -26 -40 -33 -58 -10 -12 14 -12 18 3 29 26 19 59 7 55 -19z m402 24 c0 -5 -5
            -10 -11 -10 -7 0 -17 -7 -24 -17 -7 -9 -17 -13 -24 -9 -9 6 -9 11 0 22 6 7 15
            12 19 9 5 -3 11 -1 15 5 8 13 25 13 25 0z m428 -7 c2 -8 -6 -13 -22 -13 -19 0
            -24 4 -19 15 6 18 34 16 41 -2z m-608 -7 c0 -9 7 -12 20 -9 14 4 20 0 20 -11
            0 -9 5 -16 10 -16 6 0 10 -5 10 -12 0 -9 -3 -9 -12 0 -7 7 -22 12 -35 12 -13
            0 -23 4 -23 10 0 5 -6 7 -14 4 -11 -4 -13 0 -9 15 6 23 33 29 33 7z m245 5 c8
            -14 -23 -41 -40 -35 -26 10 -18 44 9 44 14 0 27 -4 31 -9z m803 -24 c2 -19 -1
            -27 -12 -27 -9 0 -16 3 -16 8 0 4 -3 18 -7 31 -5 19 -2 22 13 19 12 -2 20 -13
            22 -31z m-1627 8 c0 -13 -41 -19 -41 -7 0 11 20 22 33 18 5 -1 8 -6 8 -11z
            m1320 -16 c22 -31 22 -32 4 -45 -10 -8 -29 -14 -41 -14 -25 0 -38 -22 -22 -38
            15 -15 1 -32 -28 -32 -17 0 -24 5 -24 20 0 11 5 20 11 20 6 0 9 6 6 13 -2 6 4
            24 14 38 14 20 25 25 46 22 26 -4 27 -3 9 11 -10 8 -15 19 -11 25 9 16 11 14
            36 -20z m167 14 c5 -17 -26 -29 -40 -15 -6 6 -7 15 -3 22 9 14 37 9 43 -7z
            m-1760 -5 c-3 -7 -19 -14 -36 -16 -25 -3 -31 0 -26 12 7 19 68 23 62 4z m336
            6 c9 -3 16 -12 16 -19 0 -16 -35 -29 -50 -20 -14 9 -13 32 3 38 6 3 13 6 14 6
            1 1 8 -2 17 -5z m367 -9 c-1 -15 -24 -12 -29 3 -3 9 2 13 12 10 10 -1 17 -7
            17 -13z m557 -3 c5 -17 -27 -27 -41 -13 -9 9 -8 14 3 21 18 11 32 8 38 -8z
            m732 -1 c0 -11 5 -23 10 -26 6 -3 10 -14 10 -24 0 -10 7 -24 15 -31 18 -15 20
            -30 4 -30 -6 0 -13 9 -16 20 -3 11 -9 20 -13 20 -4 0 -11 12 -14 28 -4 15 -9
            35 -12 45 -3 9 -1 17 5 17 6 0 11 -9 11 -19z m-2080 -5 c0 -8 -4 -17 -8 -20
            -13 -8 -35 11 -28 23 10 16 36 14 36 -3z m240 4 c0 -15 -38 -32 -45 -20 -3 5
            -3 15 0 20 8 13 45 13 45 0z m370 1 c0 -12 -29 -23 -41 -15 -18 10 -9 24 16
            24 14 0 25 -4 25 -9z m634 -6 c-1 -13 -51 -31 -60 -22 -4 4 -1 14 6 22 14 17
            55 17 54 0z m343 -1 c1 -5 -9 -17 -23 -28 -23 -16 -27 -17 -41 -3 -14 14 -12
            17 13 31 30 16 46 16 51 0z m337 0 c4 -11 0 -15 -16 -12 -12 2 -23 9 -26 16
            -6 18 35 15 42 -4z m-1384 -9 c0 -8 5 -15 10 -15 6 0 10 -9 10 -21 0 -15 -5
            -20 -22 -17 -18 2 -23 10 -24 36 -1 21 3 32 12 32 8 0 14 -7 14 -15z m113 -37
            c-6 -12 -41 -10 -48 3 -4 6 1 18 11 28 18 17 19 17 30 -3 6 -12 9 -24 7 -28z
            m206 39 c6 -8 8 -17 4 -20 -7 -8 -33 11 -33 24 0 13 16 11 29 -4z m621 5 c0
            -14 -11 -21 -26 -15 -21 8 -17 23 6 23 11 0 20 -4 20 -8z m-1333 -46 c-8 -21
            -48 -22 -42 -1 17 52 14 50 32 33 9 -9 13 -24 10 -32z m171 29 c10 -9 -15 -35
            -33 -35 -30 0 -41 16 -24 36 10 12 42 12 57 -1z m174 10 c6 -7 -14 -55 -22
            -55 -3 0 -7 14 -8 30 -2 28 14 41 30 25z m438 -15 c0 -11 -7 -20 -15 -20 -27
            0 -18 30 13 39 1 1 2 -8 2 -19z m113 -14 c-5 -26 -28 -42 -39 -25 -7 12 24 61
            35 55 5 -3 6 -17 4 -30z m57 26 c0 -14 -11 -21 -26 -15 -8 3 -11 9 -8 14 7 11
            34 12 34 1z m664 1 c4 -5 12 -9 17 -9 5 -1 14 -2 19 -3 16 -2 11 -31 -5 -31
            -8 0 -15 4 -15 8 0 5 -13 7 -30 4 -25 -4 -30 -1 -30 17 0 21 28 30 44 14z
            m-1678 -32 c-8 -13 -36 -15 -36 -3 0 4 5 13 12 20 14 14 34 0 24 -17z m1338
            20 c10 -16 -5 -41 -24 -41 -21 0 -44 25 -35 40 8 12 52 13 59 1z m466 -22 c0
            -25 -36 -25 -50 0 -7 14 -6 21 3 24 22 7 47 -5 47 -24z m-1096 7 c-7 -17 -45
            -29 -57 -17 -15 15 2 31 33 31 21 0 28 -4 24 -14z m178 -1 c0 -5 -5 -11 -11
            -13 -6 -2 -11 4 -11 13 0 9 5 15 11 13 6 -2 11 -8 11 -13z m333 -5 c0 -18 -33
            -26 -47 -12 -6 6 -7 15 -3 22 10 16 50 8 50 -10z m43 1 c3 -8 -1 -12 -9 -9 -7
            2 -15 10 -17 17 -3 8 1 12 9 9 7 -2 15 -10 17 -17z m567 10 c3 -5 1 -12 -5
            -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z m80 -4 c28 -21 1 -31 -30 -11 -14 9
            -25 18 -25 20 0 9 38 3 55 -9z m-1965 -7 c0 -5 -5 -10 -11 -10 -5 0 -8 -4 -5
            -9 4 -5 3 -16 0 -25 -8 -20 -44 -21 -44 -1 0 8 5 15 10 15 6 0 10 6 10 14 0
            13 13 23 33 25 4 1 7 -3 7 -9z m200 0 c0 -12 -35 -31 -43 -24 -3 3 -3 12 0 20
            6 16 43 19 43 4z m164 -9 c21 -23 14 -41 -15 -41 -14 0 -19 7 -19 30 0 35 10
            38 34 11z m761 9 c4 -6 1 -17 -5 -25 -16 -19 -52 -2 -44 20 7 17 40 20 49 5z
            m353 -7 c2 -8 -5 -13 -17 -13 -12 0 -21 6 -21 16 0 18 31 15 38 -3z m-588 -8
            c8 -2 18 -4 23 -4 4 -1 7 -10 7 -21 0 -24 -22 -26 -40 -5 -7 8 -21 15 -31 15
            -11 0 -19 7 -19 16 0 12 6 15 23 10 12 -4 29 -9 37 -11z m1140 5 c8 -5 12 -12
            9 -15 -8 -8 -39 5 -39 16 0 11 11 11 30 -1z m-2130 -10 c0 -5 -9 -10 -20 -10
            -11 0 -20 5 -20 10 0 6 9 10 20 10 11 0 20 -4 20 -10z m755 0 c3 -5 1 -10 -4
            -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m149 -11 c-8 -14 -24
            -10 -24 6 0 9 6 12 15 9 8 -4 12 -10 9 -15z m236 6 c0 -8 -4 -15 -10 -15 -5 0
            -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m-1190 1 c0 -3 -4 -8 -10 -11
            -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m728 -8 c-7 -19 -38 -22
            -38 -4 0 10 9 16 21 16 12 0 19 -5 17 -12z m625 -3 c-4 -15 -36 -12 -41 3 -3
            9 3 13 19 10 13 -1 23 -7 22 -13z m133 4 c7 -12 -15 -31 -31 -26 -5 2 -11 11
            -13 20 -4 19 32 24 44 6z m614 -15 c0 -16 -26 -19 -35 -4 -3 6 -4 14 0 20 8
            13 35 1 35 -16z m-1917 -4 c4 -17 -14 -50 -29 -50 -2 0 -4 16 -4 35 0 39 24
            50 33 15z m197 -6 c0 -16 -35 -19 -45 -5 -6 11 26 32 37 25 5 -3 8 -12 8 -20z
            m508 14 c-7 -20 -48 -23 -48 -4 0 11 9 16 26 16 16 0 24 -5 22 -12z m220 -9
            c-3 -25 -33 -47 -45 -35 -4 4 0 12 10 17 9 6 17 17 17 24 0 8 5 15 11 15 6 0
            9 -10 7 -21z m707 -14 c0 -42 -31 -47 -37 -5 -5 34 -1 42 22 38 9 -2 15 -14
            15 -33z m59 14 c-3 -5 0 -9 5 -9 16 0 13 -37 -3 -43 -7 -3 -19 0 -25 6 -12 12
            -15 50 -5 60 9 9 35 -4 28 -14z m84 -7 c-2 -8 -7 -17 -13 -19 -17 -5 -38 14
            -32 30 8 20 49 10 45 -11z m220 -2 c6 0 12 6 15 13 2 8 12 4 30 -13 30 -28 34
            -40 13 -40 -7 0 -16 9 -19 20 -3 11 -10 20 -16 20 -6 0 -11 -9 -11 -20 0 -11
            3 -20 8 -20 15 0 35 -45 37 -83 2 -37 2 -37 -13 -15 -11 16 -12 23 -4 26 17 5
            15 22 -4 22 -8 0 -20 14 -26 31 -6 17 -21 34 -33 39 -21 8 -23 15 -15 50 0 3
            7 -3 14 -12 7 -10 18 -18 24 -18z m-2107 -3 c-13 -13 -26 -3 -16 12 3 6 11 8
            17 5 6 -4 6 -10 -1 -17z m226 15 c20 -12 -1 -33 -43 -42 -46 -11 -71 4 -32 19
            15 5 29 15 33 20 7 13 25 14 42 3z m161 -15 c3 -12 -4 -17 -22 -17 -27 0 -37
            24 -13 33 19 8 32 2 35 -16z m160 6 c-2 -10 -10 -18 -18 -18 -8 0 -16 8 -18
            18 -2 12 3 17 18 17 15 0 20 -5 18 -17z m190 -15 c4 -20 -25 -31 -49 -18 -15
            9 -17 15 -9 30 8 15 15 17 33 11 12 -5 23 -15 25 -23z m617 12 c0 -22 -29 -18
            -33 3 -3 14 1 18 15 15 10 -2 18 -10 18 -18z m122 4 c5 -15 -20 -24 -37 -14
            -7 4 -9 13 -5 19 8 14 37 11 42 -5z m88 -4 c0 -22 -39 -19 -43 3 -3 15 1 18
            20 15 12 -2 23 -10 23 -18z m408 5 c1 -5 -6 -11 -15 -13 -11 -2 -18 3 -18 13
            0 17 30 18 33 0z m-1086 -6 c17 -17 4 -39 -23 -39 -37 0 -31 44 7 49 3 1 10
            -4 16 -10z m70 -6 c8 -22 -20 -25 -41 -4 l-21 22 29 -4 c17 -2 31 -8 33 -14z
            m147 8 c9 -15 -23 -36 -36 -23 -5 5 -7 15 -4 21 9 13 33 14 40 2z m56 -6 c0
            -8 -6 -15 -14 -15 -17 0 -28 14 -19 24 12 12 33 6 33 -9z m78 -1 c13 -4 22
            -14 22 -25 0 -15 -7 -19 -34 -19 -39 0 -53 14 -36 35 14 17 16 17 48 9z m-608
            -26 c-26 -21 -43 -19 -48 5 -3 13 4 16 34 15 l37 -1 -23 -19z m688 11 c-2 -6
            -8 -10 -13 -10 -5 0 -11 4 -13 10 -2 6 4 11 13 11 9 0 15 -5 13 -11z m-1278
            -9 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z
            m220 -5 c0 -8 7 -15 15 -15 9 0 15 -9 15 -21 0 -16 -5 -20 -22 -17 -13 2 -22
            9 -20 17 1 7 -2 19 -8 25 -5 7 -7 16 -3 19 11 12 23 8 23 -8z m1154 9 c9 -3
            16 -15 16 -25 0 -14 -7 -19 -25 -19 -29 0 -45 28 -24 41 17 11 14 10 33 3z
            m626 -20 c0 -7 -5 -16 -10 -19 -15 -9 -40 13 -34 30 7 16 44 7 44 -11z m-2108
            -6 c-7 -7 -12 -8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11 -7 -17z m48 13 c0 -5
            -7 -14 -15 -21 -12 -10 -15 -10 -15 2 0 8 3 18 7 21 9 10 23 9 23 -2z m491 -7
            c11 -14 10 -18 -6 -30 -21 -16 -51 -18 -59 -6 -7 12 22 52 38 52 8 0 20 -7 27
            -16z m-401 -6 c0 -15 -49 -40 -62 -32 -13 8 11 34 27 28 7 -3 16 -1 20 5 8 15
            15 14 15 -1z m888 -16 c4 -14 -27 -16 -47 -3 -7 5 -11 14 -7 19 8 13 48 1 54
            -16z m84 12 c-4 -13 -51 -24 -58 -13 -3 5 -3 13 1 18 7 12 61 7 57 -5z m91 1
            c1 -5 -6 -11 -15 -13 -11 -2 -18 3 -18 13 0 17 30 18 33 0z m441 -2 c7 -18 -9
            -33 -36 -33 -21 0 -32 16 -24 35 7 20 52 19 60 -2z m80 11 c9 -3 16 -10 16
            -16 0 -14 -53 -5 -58 10 -4 13 16 16 42 6z m286 -45 c0 -22 -29 -18 -42 5 -5
            9 -7 24 -4 32 5 12 9 12 26 -3 11 -10 20 -25 20 -34z m35 41 c3 -5 4 -14 0
            -20 -8 -13 -35 -1 -35 16 0 16 26 19 35 4z m-1775 -8 c0 -11 -41 -45 -47 -39
            -9 9 17 47 32 47 8 0 15 -3 15 -8z m163 1 c9 -9 -12 -33 -30 -33 -11 0 -11 4
            -3 20 11 21 22 25 33 13z m207 -13 c0 -4 -7 -10 -15 -13 -16 -7 -29 8 -20 23
            6 10 35 2 35 -10z m120 10 c0 -5 -9 -10 -20 -10 -11 0 -20 5 -20 10 0 6 9 10
            20 10 11 0 20 -4 20 -10z m110 -5 c0 -9 -9 -15 -25 -15 -16 0 -25 6 -25 15 0
            9 9 15 25 15 16 0 25 -6 25 -15z m543 -16 c-13 -13 -43 -5 -43 10 0 13 33 20
            45 9 5 -5 5 -12 -2 -19z m507 11 c0 -11 -6 -20 -14 -20 -18 0 -29 16 -21 30
            11 18 35 11 35 -10z m240 -5 c0 -7 7 -15 15 -19 8 -3 15 -10 15 -16 0 -5 -7
            -10 -15 -10 -8 0 -15 7 -15 15 0 8 -7 15 -15 15 -15 0 -21 21 -9 33 9 9 24 -2
            24 -18z m103 4 c25 -28 12 -38 -13 -11 -11 12 -20 24 -20 27 0 12 17 4 33 -16z
            m-2327 2 c-4 -5 0 -11 6 -14 7 -2 9 -8 5 -13 -9 -10 -28 16 -21 28 3 4 7 8 10
            8 3 0 3 -4 0 -9z m264 -6 c0 -16 -27 -32 -37 -22 -3 4 -3 13 0 22 8 20 37 20
            37 0z m1628 -7 c3 -13 -3 -18 -22 -18 -20 0 -26 5 -26 21 0 15 5 20 22 17 13
            -2 24 -11 26 -20z m-1838 8 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11
            10 11 6 0 10 -2 10 -4z m965 -5 c3 -5 1 -13 -5 -16 -15 -9 -43 3 -35 15 8 13
            32 13 40 1z m179 3 c22 -9 20 -31 -4 -37 -23 -6 -34 7 -26 28 6 17 8 17 30 9z
            m196 -4 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15
            -10z m-1324 -37 c-11 -11 -17 4 -9 23 7 18 8 18 11 1 2 -10 1 -21 -2 -24z m41
            23 c-3 -8 -2 -16 4 -18 5 -2 2 -13 -7 -26 -8 -12 -19 -20 -23 -17 -10 6 11 75
            23 75 4 0 6 -6 3 -14z m1213 -2 c0 -10 -7 -14 -22 -12 -32 5 -35 28 -4 28 17
            0 26 -5 26 -16z m291 1 c-1 -15 -24 -12 -29 3 -3 9 2 13 12 10 10 -1 17 -7 17
            -13z m-1419 -10 c0 -5 -5 -11 -11 -13 -6 -2 -11 4 -11 13 0 9 5 15 11 13 6 -2
            11 -8 11 -13z m546 -3 c2 -7 -3 -12 -12 -12 -9 0 -16 7 -16 16 0 17 22 14 28
            -4z m102 -2 c0 -14 -7 -20 -23 -20 -13 0 -26 3 -30 6 -12 12 6 34 29 34 17 0
            24 -6 24 -20z m304 11 c7 -10 -43 -25 -52 -16 -7 7 18 25 35 25 6 0 14 -4 17
            -9z m616 1 c0 -14 -11 -21 -26 -15 -21 8 -17 23 6 23 11 0 20 -4 20 -8z m251
            -5 c-1 -12 -15 -9 -19 4 -3 6 1 10 8 8 6 -3 11 -8 11 -12z m137 -10 c2 -12 -3
            -17 -17 -17 -12 0 -21 6 -21 13 0 31 32 34 38 4z m-723 -12 c0 -14 -8 -21 -27
            -23 -22 -3 -28 1 -28 16 0 25 10 33 35 30 12 -2 20 -10 20 -23z m-1070 -5 c0
            -8 -10 -16 -22 -18 -18 -3 -23 2 -23 18 0 16 5 21 23 18 12 -2 22 -10 22 -18z
            m75 11 c0 -5 -6 -14 -14 -20 -10 -8 -16 -8 -25 3 -9 11 -9 15 1 19 21 9 38 8
            38 -2z m180 -1 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10
            -4 10 -10z m374 2 c9 -14 -12 -35 -28 -29 -23 9 -20 37 3 37 11 0 22 -4 25 -8z
            m94 -15 c5 -23 -18 -30 -53 -17 l-29 11 24 14 c32 17 54 14 58 -8z m407 13 c3
            -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m96 -9 l23
            -18 -23 -8 c-28 -8 -70 23 -49 37 18 11 23 10 49 -11z m268 -15 c33 -34 34
            -56 4 -56 -17 0 -83 62 -83 79 0 23 47 10 79 -23z m124 20 c7 -18 -1 -26 -25
            -26 -11 0 -18 7 -18 20 0 23 35 28 43 6z m-1953 -5 c0 -5 8 -11 19 -14 14 -3
            17 -11 14 -31 -3 -14 -10 -29 -15 -32 -6 -4 -8 6 -3 27 5 25 4 30 -4 19 -10
            -13 -12 -13 -21 0 -13 19 -13 40 0 40 6 0 10 -4 10 -9z m138 -9 c2 -7 -5 -12
            -17 -12 -14 0 -19 5 -14 15 6 17 25 15 31 -3z m82 -8 c0 -7 -9 -18 -20 -24
            -16 -9 -22 -8 -26 5 -3 8 -4 21 -1 29 7 18 47 9 47 -10z m280 11 c0 -8 -6 -15
            -14 -15 -17 0 -28 14 -19 24 12 12 33 6 33 -9z m153 9 c11 -11 -5 -34 -24 -34
            -18 0 -26 20 -12 33 8 9 28 9 36 1z m1413 -10 c-3 -8 -1 -24 4 -35 17 -30 -13
            -35 -49 -8 -39 29 -40 42 -1 34 23 -5 30 -3 30 9 0 9 5 16 11 16 6 0 8 -7 5
            -16z m132 -12 c3 -19 -1 -23 -15 -20 -10 2 -19 14 -21 26 -3 19 1 23 15 20 10
            -2 19 -14 21 -26z m127 3 c0 -10 -6 -20 -12 -22 -8 -3 -13 5 -13 22 0 17 5 25
            13 23 6 -3 12 -13 12 -23z m-2057 -3 c2 -7 -4 -15 -13 -18 -20 -8 -27 -1 -19
            20 7 18 25 17 32 -2z m862 3 c0 -8 -9 -15 -20 -15 -11 0 -20 7 -20 15 0 8 9
            15 20 15 11 0 20 -7 20 -15z m70 7 c0 -14 -11 -21 -26 -15 -21 8 -17 23 6 23
            11 0 20 -4 20 -8z m74 -1 c8 -12 -21 -34 -34 -26 -17 11 -11 35 9 35 11 0 22
            -4 25 -9z m421 -1 c3 -5 4 -14 0 -20 -8 -13 -35 -1 -35 16 0 16 26 19 35 4z
            m200 0 c3 -5 4 -14 0 -20 -8 -13 -32 -2 -32 15 0 17 23 20 32 5z m475 -15 c10
            -12 10 -15 -4 -15 -9 0 -16 7 -16 15 0 8 2 15 4 15 2 0 9 -7 16 -15z m-1472
            -14 c4 -11 -4 -13 -38 -8 -64 8 -77 29 -16 25 31 -2 51 -8 54 -17z m1352 5 c0
            -21 -21 -31 -30 -15 -5 7 -6 17 -4 21 9 14 34 9 34 -6z m-1921 -3 c-1 -16 -35
            -67 -47 -71 -7 -2 -15 4 -18 13 -5 12 2 25 22 41 30 24 45 30 43 17z m239 -5
            c-7 -20 -48 -23 -48 -4 0 11 9 16 26 16 16 0 24 -5 22 -12z m157 3 c3 -6 -3
            -15 -14 -20 -27 -15 -31 -14 -31 9 0 21 33 29 45 11z m703 -8 c5 -17 -26 -29
            -40 -15 -6 6 -7 15 -3 22 9 14 37 9 43 -7z m722 2 c16 -20 1 -38 -23 -29 -17
            6 -22 25 -10 37 10 11 19 8 33 -8z m-1711 -4 c21 -13 5 -41 -24 -41 -38 0 -33
            44 5 49 3 0 11 -3 19 -8z m554 2 c9 -9 -12 -33 -29 -33 -8 0 -14 9 -14 20 0
            19 28 28 43 13z m67 -2 c0 -5 -6 -11 -12 -14 -18 -6 -39 3 -32 14 7 12 44 12
            44 0z m268 -8 c2 -9 -7 -13 -28 -13 -28 0 -31 2 -20 15 15 18 41 16 48 -2z
            m192 7 c0 -5 -11 -10 -25 -10 -14 0 -25 5 -25 10 0 6 11 10 25 10 14 0 25 -4
            25 -10z m439 1 c16 -10 6 -31 -14 -31 -8 0 -18 -7 -23 -17 -6 -10 -16 -14 -27
            -10 -20 8 -14 33 7 29 9 -1 18 7 21 18 6 22 14 24 36 11z m329 -19 c2 -15 -2
            -22 -12 -22 -11 0 -16 9 -16 26 0 31 23 28 28 -4z m-2198 10 c0 -13 -12 -22
            -22 -16 -10 6 -1 24 13 24 5 0 9 -4 9 -8z m50 -7 c0 -16 -27 -32 -37 -22 -3 4
            -3 13 0 22 8 20 37 20 37 0z m480 5 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3
            6 -1 10 4 10 6 0 11 -4 11 -10z m490 -4 c0 -18 -16 -29 -30 -21 -13 8 -1 35
            16 35 8 0 14 -6 14 -14z m73 8 c11 -11 -5 -34 -24 -34 -18 0 -26 20 -12 33 8
            9 28 9 36 1z m576 -3 c16 -10 6 -31 -14 -31 -17 0 -28 17 -20 30 8 12 16 12
            34 1z m599 -14 c2 -10 -3 -17 -12 -17 -10 0 -16 9 -16 21 0 24 23 21 28 -4z
            m32 -3 c0 -8 -4 -12 -10 -9 -5 3 -10 13 -10 21 0 8 5 12 10 9 6 -3 10 -13 10
            -21z m-2408 -16 c-9 -9 -12 -7 -12 12 0 19 3 21 12 12 9 -9 9 -15 0 -24z
            m1630 -10 c-10 -10 -16 -8 -30 5 -15 16 -15 19 -2 27 21 13 49 -15 32 -32z
            m77 33 c18 -11 5 -31 -20 -31 -10 0 -19 2 -19 4 0 2 -3 11 -6 20 -7 18 21 22
            45 7z m-1309 -21 c0 -13 -7 -20 -20 -20 -19 0 -27 20 -13 33 13 14 33 6 33
            -13z m503 -5 c-5 -13 -23 -22 -23 -11 0 15 14 35 21 31 4 -3 6 -12 2 -20z
            m367 15 c0 -9 -31 -30 -45 -30 -3 0 -1 9 5 20 11 20 40 28 40 10z m778 -13 c5
            -24 -15 -21 -28 4 -10 18 -9 20 7 17 10 -2 19 -11 21 -21z m192 8 c13 -16 6
            -35 -15 -35 -18 0 -28 17 -21 35 7 19 20 19 36 0z m-2015 -21 c0 -12 -8 -20
            -22 -22 -25 -4 -31 16 -11 36 17 17 33 10 33 -14z m305 11 c0 -8 -9 -15 -20
            -15 -20 0 -26 11 -13 23 12 13 33 7 33 -8z m79 -4 c13 -23 -8 -45 -33 -35 -19
            7 -22 40 -3 47 21 9 26 7 36 -12z m146 10 c5 -10 -6 -15 -62 -26 -21 -4 -33
            -3 -33 4 0 6 3 11 8 12 22 2 56 10 61 14 7 8 19 6 26 -4z m98 2 c12 -11 -14
            -33 -39 -33 -23 0 -32 19 -17 33 8 9 48 9 56 0z m215 -10 c-2 -10 -10 -18 -18
            -18 -8 0 -16 8 -18 18 -2 12 3 17 18 17 15 0 20 -5 18 -17z m-110 -5 c-7 -19
            -38 -22 -38 -4 0 10 9 16 21 16 12 0 19 -5 17 -12z m462 -14 c0 -17 -36 -19
            -46 -3 -8 14 14 29 33 22 7 -3 13 -11 13 -19z m124 9 c14 -35 -44 -51 -66 -19
            -12 17 -9 24 10 29 35 9 50 7 56 -10z m313 9 c7 -4 16 -22 19 -40 6 -29 4 -32
            -19 -32 -24 0 -48 31 -29 38 6 2 8 12 5 22 -6 21 3 25 24 12z m-1829 -24 c-4
            -27 -28 -36 -28 -10 0 20 8 32 22 32 5 0 8 -10 6 -22z m50 0 c-4 -27 -28 -36
            -28 -10 0 20 8 32 22 32 5 0 8 -10 6 -22z m1077 12 c3 -5 -1 -10 -9 -10 -9 0
            -16 5 -16 10 0 6 4 10 9 10 6 0 13 -4 16 -10z m174 -4 c9 -10 8 -16 -4 -26
            -17 -14 -45 -7 -45 13 0 29 30 37 49 13z m421 4 c13 -9 12 -12 -4 -25 -23 -17
            -42 -5 -33 19 7 18 16 20 37 6z m590 0 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10
            10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-328 -26 c10 -21 9 -27 -3 -35 -29 -18
            -62 18 -40 45 19 22 30 20 43 -10z m121 12 c5 -13 -10 -36 -24 -36 -14 0 -39
            31 -34 41 9 14 52 11 58 -5z m-2187 -33 c-11 -11 -19 6 -11 24 8 17 8 17 12 0
            3 -10 2 -21 -1 -24z m182 5 c-2 -21 -8 -33 -18 -33 -19 0 -25 32 -10 50 20 24
            31 17 28 -17z m412 22 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10
            6 0 10 -4 10 -10z m1660 -16 c0 -16 -16 -19 -25 -4 -8 13 4 32 16 25 5 -4 9
            -13 9 -21z m98 -2 c3 -16 -1 -19 -17 -15 -21 5 -27 23 -14 37 12 11 28 0 31
            -22z m-2318 3 c0 -8 -4 -15 -10 -15 -5 0 -7 7 -4 15 4 8 8 15 10 15 2 0 4 -7
            4 -15z m518 -3 c2 -7 -7 -12 -22 -12 -27 0 -35 15 -13 23 17 7 30 3 35 -11z
            m402 9 c0 -11 -26 -22 -34 -14 -12 12 -5 23 14 23 11 0 20 -4 20 -9z m125 -11
            c0 -8 -10 -16 -22 -18 -22 -3 -32 16 -16 32 12 11 38 2 38 -14z m100 -5 c0
            -25 -40 -33 -49 -10 -9 24 2 37 27 33 14 -2 22 -10 22 -23z m135 15 c0 -5 -7
            -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m200 0 c0 -5
            -4 -10 -9 -10 -6 0 -13 5 -16 10 -3 6 1 10 9 10 9 0 16 -4 16 -10z m130 0 c0
            -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m808
            -26 l4 -29 -21 22 c-26 27 -27 42 -3 39 11 -2 18 -13 20 -32z m-1678 21 c0 -2
            -9 -9 -20 -15 -22 -12 -44 -5 -35 11 6 9 55 13 55 4z m490 -13 c0 -24 -8 -32
            -26 -25 -22 8 -17 43 6 43 13 0 20 -7 20 -18z m445 -2 c0 -8 -10 -16 -22 -18
            -22 -3 -32 16 -16 32 12 11 38 2 38 -14z m695 -16 c0 -16 -26 -19 -35 -4 -4 6
            -4 18 -1 27 5 12 9 13 21 3 8 -7 15 -19 15 -26z m-2050 6 c0 -23 -22 -27 -39
            -7 -13 16 -5 27 21 27 11 0 18 -7 18 -20z m120 -5 c0 -20 -5 -25 -25 -25 -18
            0 -25 5 -25 18 0 24 8 32 32 32 13 0 18 -7 18 -25z m420 11 c0 -14 -29 -31
            -38 -22 -2 3 -2 12 1 21 8 19 37 20 37 1z m118 -9 c2 -10 -3 -17 -12 -17 -18
            0 -29 16 -21 31 9 14 29 6 33 -14z m436 17 c24 -9 19 -21 -9 -26 -27 -6 -41 5
            -30 22 7 12 17 12 39 4z m396 -14 c0 -11 -4 -20 -9 -20 -14 0 -23 18 -16 30
            10 17 25 11 25 -10z m-1597 -26 c-6 -16 -29 -19 -38 -4 -4 6 -1 17 5 26 15 18
            42 0 33 -22z m1342 16 c3 -5 -4 -12 -15 -15 -21 -7 -54 11 -45 25 6 9 53 1 60
            -10z m-951 1 c7 -11 -22 -23 -35 -15 -5 3 -7 10 -4 15 8 12 32 12 39 0z m181
            -1 c6 -10 -41 -37 -50 -28 -3 3 -3 13 1 22 7 18 39 22 49 6z m965 -3 c0 -19
            -10 -27 -26 -20 -21 8 -17 33 6 33 11 0 20 -6 20 -13z m523 -11 c9 -22 -14
            -52 -32 -42 -12 8 -15 49 -5 59 11 11 29 3 37 -17z m-2260 -1 c-3 -9 -8 -14
            -10 -11 -3 3 -2 9 2 15 9 16 15 13 8 -4z m507 -15 c0 -19 -25 -34 -41 -24 -13
            8 -11 21 7 38 18 19 34 13 34 -14z m395 5 c0 -13 -8 -21 -23 -23 -26 -4 -42
            25 -21 39 22 14 44 6 44 -16z m385 10 c0 -9 -7 -18 -16 -22 -18 -7 -39 11 -30
            26 11 17 46 13 46 -4z m-1369 -9 c10 6 12 -17 3 -40 -10 -25 -24 -8 -23 27 0
            18 3 26 6 19 2 -6 9 -10 14 -6z m1237 2 c-7 -19 -38 -22 -38 -4 0 10 9 16 21
            16 12 0 19 -5 17 -12z m910 0 c-6 -18 -28 -21 -28 -4 0 9 7 16 16 16 9 0 14
            -5 12 -12z m120 -62 c-2 -31 -7 -55 -13 -54 -10 3 -16 106 -7 121 11 21 23
            -15 20 -67z m-2148 29 c0 -12 -7 -25 -15 -29 -11 -4 -15 2 -15 23 0 15 3 31 6
            34 12 11 24 -3 24 -28z m236 9 c8 -30 -6 -47 -32 -42 -23 4 -30 34 -14 53 19
            23 39 18 46 -11z m804 12 c0 -8 -5 -18 -11 -22 -14 -8 -33 11 -25 25 10 16 36
            13 36 -3z m885 4 c4 -6 4 -18 1 -26 -4 -12 -10 -13 -26 -4 -11 6 -20 17 -20
            25 0 17 36 20 45 5z m163 -5 c2 -1 0 -14 -4 -29 -7 -29 -38 -36 -48 -10 -12
            30 3 56 27 48 12 -3 23 -8 25 -9z m332 -16 c0 -11 -4 -17 -10 -14 -5 3 -10 15
            -10 26 0 11 5 17 10 14 6 -3 10 -15 10 -26z m-2490 7 c0 -17 -14 -29 -23 -20
            -8 8 3 34 14 34 5 0 9 -6 9 -14z m130 -11 c0 -16 -6 -25 -15 -25 -9 0 -15 9
            -15 25 0 16 6 25 15 25 9 0 15 -9 15 -25z m260 21 c0 -16 -14 -36 -26 -36 -14
            0 -19 22 -7 33 7 8 33 10 33 3z m288 -8 c-5 -15 -35 -34 -43 -27 -3 4 -3 14 1
            23 7 18 48 23 42 4z m727 -18 c0 -38 -39 -35 -43 3 -3 25 0 28 20 25 17 -2 23
            -9 23 -28z m119 15 c3 -9 -1 -18 -11 -22 -17 -7 -45 7 -51 25 -6 18 55 15 62
            -3z m202 1 c17 -13 17 -16 4 -30 -14 -14 -19 -14 -35 -3 -11 9 -16 20 -12 31
            8 20 17 20 43 2z m-889 -30 c-8 -20 -47 -21 -47 -1 0 18 32 37 44 25 5 -5 6
            -16 3 -24z m201 11 c5 -24 -26 -33 -46 -13 -15 15 -15 18 -2 26 21 14 44 8 48
            -13z m207 -2 c0 -23 -34 -33 -45 -14 -13 20 1 40 24 37 13 -2 21 -10 21 -23z
            m389 16 c4 -5 3 -17 0 -26 -10 -24 -48 -15 -52 13 -3 18 1 22 22 22 14 0 27
            -4 30 -9z m200 -7 c18 -17 20 -30 7 -38 -15 -10 -39 4 -44 24 -8 32 12 40 37
            14z m586 -10 c0 -16 -16 -19 -25 -4 -8 13 4 32 16 25 5 -4 9 -13 9 -21z
            m-1825 6 c-3 -5 -13 -10 -21 -10 -8 0 -14 5 -14 10 0 6 9 10 21 10 11 0 17 -4
            14 -10z m157 -5 c0 -5 -5 -11 -11 -13 -6 -2 -11 4 -11 13 0 9 5 15 11 13 6 -2
            11 -8 11 -13z m1583 5 c3 -6 3 -20 0 -31 -6 -19 -9 -20 -22 -6 -9 9 -13 23
            -10 31 7 18 23 21 32 6z m35 0 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5
            10 10 10 6 0 10 -4 10 -10z m90 -28 c0 -24 -2 -25 -15 -11 -9 9 -13 23 -9 32
            9 24 24 11 24 -21z m-2439 -25 c-15 -15 -24 -2 -16 24 7 20 8 21 17 5 7 -12 7
            -21 -1 -29z m459 28 c0 -8 -7 -15 -15 -15 -16 0 -20 12 -8 23 11 12 23 8 23
            -8z m830 -11 c0 -8 -2 -14 -4 -14 -2 0 -11 -3 -20 -6 -11 -4 -16 0 -16 13 0
            11 3 22 6 26 10 9 34 -4 34 -19z m645 7 c9 -30 -5 -53 -29 -47 -11 3 -24 6
            -27 6 -12 0 -17 38 -6 49 18 18 55 13 62 -8z m-1725 -15 c13 -15 20 -32 17
            -40 -7 -17 -27 -22 -27 -6 0 5 -9 18 -21 29 -12 11 -18 24 -14 30 10 16 19 13
            45 -13z m695 12 c14 -6 25 -15 25 -20 0 -11 -28 -10 -35 2 -3 6 -15 10 -26 10
            -10 0 -19 5 -19 10 0 13 23 13 55 -2z m248 -2 c3 -8 3 -17 0 -20 -7 -7 -33 13
            -33 25 0 15 27 10 33 -5z m895 -20 c-4 -25 -43 -34 -51 -12 -8 20 19 47 39 39
            8 -3 14 -15 12 -27z m-1338 15 c0 -11 -28 -41 -38 -41 -4 0 -15 9 -24 19 -16
            18 -16 20 0 24 31 8 62 7 62 -2z m1545 -1 c3 -5 2 -10 -4 -10 -5 0 -13 5 -16
            10 -3 6 -2 10 4 10 5 0 13 -4 16 -10z m-2185 -20 c0 -11 -4 -20 -10 -20 -5 0
            -10 9 -10 20 0 11 5 20 10 20 6 0 10 -9 10 -20z m55 -29 c-3 -11 -13 -21 -21
            -21 -8 0 -14 -10 -14 -25 0 -28 -17 -34 -27 -9 -4 10 1 22 10 29 10 7 17 19
            17 28 0 8 3 23 6 32 10 26 38 -6 29 -34z m270 39 c9 -14 -17 -50 -37 -50 -24
            0 -31 16 -18 40 11 21 44 27 55 10z m141 -17 c-10 -10 -19 5 -10 18 6 11 8 11
            12 0 2 -7 1 -15 -2 -18z m931 10 c5 -13 -43 -30 -54 -20 -3 4 -3 13 1 22 7 19
            47 17 53 -2z m80 11 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
            m217 -3 c3 -4 3 -11 0 -14 -8 -8 -34 3 -34 14 0 11 27 12 34 0z m606 -7 c0
            -10 6 -14 15 -10 11 4 15 -2 15 -21 0 -26 -1 -26 -15 -7 -8 11 -15 14 -15 7 0
            -15 -26 -17 -35 -3 -10 16 5 50 21 50 8 0 14 -7 14 -16z m-2420 -9 c0 -9 6
            -12 15 -9 11 5 15 -1 15 -20 0 -16 -6 -26 -15 -26 -8 0 -15 5 -15 11 0 6 -6
            17 -14 25 -11 11 -16 11 -19 1 -3 -6 -6 -2 -6 11 -1 16 5 22 19 22 11 0 20 -7
            20 -15z m350 0 c0 -8 -7 -15 -15 -15 -16 0 -20 12 -8 23 11 12 23 8 23 -8z
            m730 11 c0 -15 -14 -35 -21 -30 -15 9 -10 34 6 34 8 0 15 -2 15 -4z m664 -17
            c16 -14 0 -21 -33 -14 -18 4 -31 13 -31 22 0 12 7 14 28 8 15 -3 31 -11 36
            -16z m426 -13 c-5 -5 -17 -5 -27 -2 -17 7 -17 8 1 22 16 11 21 11 27 2 5 -7 4
            -17 -1 -22z m-1520 10 c0 -16 -18 -31 -27 -22 -8 8 5 36 17 36 5 0 10 -6 10
            -14z m1870 -1 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10
            -7 10 -15z m-1350 -15 c0 -22 -22 -35 -44 -27 -9 4 -12 14 -9 26 7 29 53 29
            53 1z m-634 -5 c8 -20 -13 -45 -37 -45 -27 0 -33 19 -13 41 21 23 42 25 50 4z
            m856 3 c8 -22 -4 -58 -18 -58 -8 0 -20 12 -26 26 -10 21 -9 27 3 35 20 12 37
            11 41 -3z m518 -7 c7 -14 7 -22 0 -26 -13 -8 -40 13 -40 31 0 21 28 17 40 -5z
            m-1826 1 c7 -12 -4 -52 -14 -52 -12 0 -23 38 -15 50 7 12 22 13 29 2z m126
            -12 c0 -11 -7 -20 -15 -20 -18 0 -19 12 -3 28 16 16 18 15 18 -8z m478 8 c-2
            -6 -10 -14 -16 -16 -7 -2 -10 2 -6 12 7 18 28 22 22 4z m169 -3 c6 -18 -23
            -55 -44 -55 -10 0 -12 7 -7 23 13 44 39 61 51 32z m407 -1 c7 -19 -1 -34 -19
            -34 -18 0 -28 17 -21 35 7 20 32 19 40 -1z m374 -22 c-2 -9 -11 -18 -21 -20
            -19 -4 -32 20 -23 43 8 22 48 0 44 -23z m672 2 c0 -28 -3 -35 -17 -32 -12 2
            -19 14 -21 36 -3 27 0 32 17 32 17 0 21 -6 21 -36z m58 -12 c3 -41 -4 -52 -24
            -32 -13 13 -5 82 10 77 6 -2 12 -22 14 -45z m122 32 c0 -8 -4 -12 -10 -9 -5 3
            -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10 -16z m-1730 -13 c-13 -25 -27 -34
            -34 -22 -7 10 20 41 35 41 6 0 6 -7 -1 -19z m174 4 c-4 -8 -10 -15 -15 -15 -5
            0 -9 7 -9 15 0 8 7 15 15 15 9 0 12 -6 9 -15z m80 -9 c9 -13 7 -20 -9 -32 -10
            -8 -24 -13 -30 -11 -15 5 -18 36 -4 53 14 17 29 14 43 -10z m-1034 -7 c0 -11
            5 -17 10 -14 6 4 10 -5 10 -19 0 -14 -4 -26 -10 -26 -5 0 -10 7 -10 16 0 8 -4
            12 -10 9 -6 -4 -10 7 -10 24 0 17 5 31 10 31 6 0 10 -9 10 -21z m1876 4 c7
            -10 14 -31 14 -46 0 -25 -2 -26 -20 -17 -25 14 -42 44 -34 64 8 21 24 20 40
            -1z m134 -3 c0 -11 -4 -20 -9 -20 -13 0 -32 26 -24 33 13 14 33 6 33 -13z
            m183 -30 c-7 -27 -31 -33 -43 -10 -15 28 -12 48 8 53 22 5 41 -18 35 -43z
            m-1599 25 c-4 -8 -10 -12 -15 -9 -11 6 -2 24 11 24 5 0 7 -7 4 -15z m964 3 c7
            -7 12 -23 12 -36 0 -20 -4 -23 -27 -20 -23 2 -29 8 -31 36 -3 26 0 32 15 32
            11 0 24 -5 31 -12z m197 -18 c0 -18 -6 -26 -23 -28 -24 -4 -38 18 -28 44 3 9
            15 14 28 12 17 -2 23 -10 23 -28z m733 -11 l-3 -24 -13 23 c-7 12 -10 27 -6
            33 10 17 26 -6 22 -32z m-2128 12 c0 -11 -5 -23 -11 -27 -15 -9 -33 12 -26 30
            9 23 37 20 37 -3z m1727 3 c6 -17 -9 -37 -32 -40 -15 -2 -22 36 -8 49 12 12
            33 7 40 -9z m-1811 -5 c3 -6 -3 -20 -14 -32 -19 -19 -22 -19 -28 -4 -13 35 23
            66 42 36z m395 -2 c-1 -4 -1 -14 -1 -22 0 -8 -9 -15 -20 -15 -22 0 -26 20 -8
            38 13 13 30 12 29 -1z m1783 -19 c10 -38 7 -42 -14 -23 -23 21 -26 45 -6 45 7
            0 16 -10 20 -22z m-2399 -27 c-3 -12 -11 -21 -16 -21 -10 0 -12 43 -2 54 12
            12 25 -11 18 -33z m385 25 c0 -20 -26 -40 -35 -26 -10 16 2 40 20 40 8 0 15
            -6 15 -14z m1534 -2 c20 -8 21 -44 2 -44 -18 0 -39 27 -31 40 7 12 8 12 29 4z
            m285 -8 c10 -11 10 -20 1 -37 -11 -19 -14 -20 -27 -6 -9 9 -13 23 -8 36 7 25
            18 27 34 7z m-2268 -41 c5 4 9 -5 9 -19 0 -14 -4 -26 -10 -26 -5 0 -10 8 -10
            18 0 10 -5 23 -10 28 -6 6 -7 19 -3 30 7 16 8 15 11 -9 2 -15 8 -25 13 -22z
            m109 21 c0 -14 -4 -28 -10 -31 -6 -4 -10 7 -10 24 0 17 5 31 10 31 6 0 10 -11
            10 -24z m100 5 c0 -23 -16 -36 -25 -21 -9 14 1 40 15 40 5 0 10 -9 10 -19z
            m680 -11 c-21 -14 -30 -12 -30 4 0 18 22 27 35 14 6 -6 5 -12 -5 -18z m240 11
            c0 -20 -26 -36 -35 -21 -8 13 6 40 21 40 8 0 14 -9 14 -19z m112 -6 c13 -29
            12 -30 -24 -39 -23 -5 -28 -3 -34 18 -3 14 -3 30 1 35 12 20 45 12 57 -14z
            m594 2 c1 -9 -1 -19 -6 -22 -12 -7 -33 22 -25 35 9 15 28 7 31 -13z m-1676 -7
            c0 -11 -7 -23 -15 -27 -21 -7 -25 -2 -18 25 7 28 33 29 33 2z m430 12 c0 -5
            -6 -17 -14 -28 -12 -15 -17 -16 -27 -7 -10 10 -9 15 1 28 13 16 40 21 40 7z
            m373 -28 c-3 -8 -12 -14 -19 -14 -20 0 -30 30 -13 40 17 11 40 -7 32 -26z
            m367 6 c0 -23 -29 -35 -36 -15 -4 8 -4 22 0 30 7 20 36 8 36 -15z m160 5 c0
            -30 -15 -33 -30 -6 -14 27 -13 31 10 31 15 0 20 -7 20 -25z m-82 6 c17 -11 12
            -56 -7 -56 -19 0 -36 29 -28 49 7 18 16 20 35 7z m270 -3 c14 -14 16 -58 4
            -58 -17 0 -44 39 -39 55 8 18 19 19 35 3z m71 -7 c6 -10 7 -21 4 -25 -9 -8
            -33 14 -33 30 0 20 18 17 29 -5z m-989 0 c0 -23 -14 -41 -31 -41 -22 0 -27 35
            -6 43 20 9 37 8 37 -2z m1588 -24 c2 -19 -1 -27 -13 -27 -19 0 -30 24 -21 46
            9 23 31 11 34 -19z m72 -1 c0 -13 -7 -29 -15 -36 -12 -10 -15 -7 -21 14 -7 29
            1 46 22 46 8 0 14 -10 14 -24z m-2130 5 c0 -12 -29 -35 -36 -28 -11 10 5 37
            21 37 8 0 15 -4 15 -9z m90 -9 c0 -24 -40 -49 -51 -31 -4 7 -3 21 2 31 13 24
            49 24 49 0z m120 -7 c0 -13 -8 -29 -19 -34 -32 -18 -44 15 -15 43 22 23 34 20
            34 -9z m310 11 c0 -17 -39 -66 -40 -49 0 18 22 63 32 63 4 0 8 -6 8 -14z
            m1360 -7 c14 -25 6 -49 -14 -49 -23 0 -40 38 -25 56 16 19 26 18 39 -7z m350
            -9 c0 -16 -5 -30 -10 -30 -14 0 -24 26 -16 45 10 26 26 16 26 -15z m-2593 -2
            c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m743 18 c0 -2 -3 -11 -6
            -20 -9 -23 -24 -20 -24 4 0 11 7 20 15 20 8 0 15 -2 15 -4z m900 -11 c0 -8 -6
            -15 -14 -15 -8 0 -16 7 -20 15 -4 10 1 15 14 15 11 0 20 -7 20 -15z m-1537
            -20 c-7 -21 -13 -19 -13 6 0 11 4 18 10 14 5 -3 7 -12 3 -20z m37 1 c0 -27
            -15 -42 -25 -26 -8 13 3 50 16 50 5 0 9 -11 9 -24z m106 8 c-3 -9 -6 -18 -6
            -20 0 -3 -4 -3 -10 1 -11 7 -2 35 12 35 6 0 7 -7 4 -16z m1784 6 c0 -5 -5 -10
            -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m123 -5 c4 -8 2 -17
            -4 -21 -12 -7 -41 15 -33 27 8 14 31 11 37 -6z m157 -20 c0 -19 -13 -25 -29
            -15 -19 11 -6 41 14 34 8 -3 15 -12 15 -19z m220 -24 c-23 -56 -41 -32 -20 25
            8 20 14 24 22 16 8 -8 7 -19 -2 -41z m-2480 19 c0 -11 -4 -20 -10 -20 -5 0
            -10 9 -10 20 0 11 5 20 10 20 6 0 10 -9 10 -20z m147 -14 c-4 -9 -11 -16 -17
            -16 -11 0 -14 33 -3 44 11 10 26 -11 20 -28z m761 14 c4 -22 -35 -61 -53 -54
            -18 7 -19 27 -4 55 12 23 52 22 57 -1z m156 4 c11 -30 -33 -60 -49 -34 -11 17
            5 50 25 50 10 0 21 -7 24 -16z m826 -9 c0 -20 -5 -25 -24 -25 -26 0 -51 24
            -41 40 3 5 19 10 36 10 24 0 29 -4 29 -25z m-220 -21 c0 -25 -35 -12 -38 14
            -3 21 -1 22 17 12 12 -6 21 -18 21 -26z m-1437 14 c5 -16 -10 -48 -23 -48 -11
            0 -14 43 -3 53 10 11 21 8 26 -5z m176 -24 c-9 -11 -15 -12 -23 -4 -8 8 -6 15
            9 26 23 17 34 2 14 -22z m201 18 c0 -23 -19 -42 -42 -42 -20 0 -23 12 -8 41
            14 24 50 25 50 1z m1428 6 c13 -13 17 -78 5 -78 -28 1 -55 48 -42 72 11 21 21
            22 37 6z m-2008 -23 c0 -16 -6 -25 -15 -25 -15 0 -21 31 -8 43 13 14 23 6 23
            -18z m1149 -11 c-15 -19 -25 -18 -33 3 -8 21 14 36 31 21 10 -8 10 -14 2 -24z
            m201 11 c0 -26 -12 -45 -29 -45 -19 0 -42 32 -33 47 16 24 62 22 62 -2z m202
            8 c26 -24 23 -53 -5 -53 -29 0 -52 41 -34 59 14 14 17 14 39 -6z m738 -11 c0
            -34 -7 -43 -26 -35 -8 3 -14 18 -14 34 0 22 5 29 20 29 15 0 20 -7 20 -28z
            m-830 -13 c0 -10 -6 -19 -13 -19 -22 0 -27 11 -16 32 12 22 29 15 29 -13z
            m260 16 c0 -8 -6 -15 -14 -15 -8 0 -16 7 -20 15 -4 10 1 15 14 15 11 0 20 -7
            20 -15z m782 -35 c-17 -40 -34 -47 -40 -16 -4 17 37 65 50 59 3 -2 -1 -21 -10
            -43z m-2214 12 c3 -17 -2 -22 -17 -22 -14 0 -21 6 -21 18 0 38 33 41 38 4z
            m652 13 c0 -8 -7 -15 -15 -15 -16 0 -20 12 -8 23 11 12 23 8 23 -8z m1166 0
            c4 -8 3 -22 0 -31 -5 -13 -9 -14 -21 -4 -8 7 -15 21 -15 31 0 23 28 25 36 4z
            m279 -5 c0 -7 -6 -15 -12 -17 -8 -3 -13 4 -13 17 0 13 5 20 13 18 6 -3 12 -11
            12 -18z m-2323 -11 c-2 -8 -8 -18 -13 -23 -13 -14 -31 7 -24 26 8 19 41 16 37
            -3z m69 -22 c-8 -8 -11 -5 -11 9 0 27 14 41 18 19 2 -9 -1 -22 -7 -28z m539
            29 c0 -19 -29 -48 -41 -41 -15 10 -10 33 9 44 24 14 32 13 32 -3z m1670 -26
            c0 -16 -4 -31 -9 -35 -13 -7 -24 30 -16 55 9 28 25 15 25 -20z m-2380 -16 c0
            -19 -4 -34 -9 -34 -10 0 -21 60 -14 73 10 16 23 -6 23 -39z m2200 31 c0 -8 -4
            -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m-1696 -3 c6
            -11 -21 -52 -35 -52 -13 0 -11 28 4 45 14 16 24 18 31 7z m276 -11 c0 -11 -9
            -23 -21 -26 -26 -9 -33 -2 -24 24 8 27 45 28 45 2z m1200 -6 c0 -27 -9 -32
            -28 -13 -18 18 -14 38 8 38 15 0 20 -7 20 -25z m-1950 -4 c0 -6 -4 -13 -10
            -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16 6 0 10 -4 10 -9z m1740 -48 c0 -10 -9
            -13 -27 -11 -21 2 -29 9 -31 27 -5 34 19 46 41 20 9 -12 17 -28 17 -36z m60
            36 c0 -24 -23 -21 -28 4 -2 10 3 17 12 17 10 0 16 -9 16 -21z m-1580 -3 c0
            -14 -27 -42 -34 -35 -2 2 0 14 5 27 9 24 29 30 29 8z m170 -3 c0 -18 -28 -53
            -42 -53 -11 0 -10 27 2 51 13 23 40 25 40 2z m433 11 c9 -10 -4 -34 -18 -34
            -17 0 -28 17 -20 30 7 11 28 13 38 4z m297 -31 c0 -10 -26 -43 -35 -43 -35 0
            -47 44 -18 65 19 14 53 0 53 -22z m1113 15 c6 -8 7 -24 3 -34 -7 -18 -8 -18
            -26 4 -11 13 -16 30 -13 38 6 17 23 14 36 -8z m-2218 -46 c-13 -13 -23 22 -12
            42 11 20 12 20 15 -7 2 -16 1 -32 -3 -35z m105 28 c0 -20 -5 -30 -15 -30 -15
            0 -21 40 -8 53 14 15 23 6 23 -23z m1509 21 c17 -11 7 -45 -12 -39 -13 5 -23
            48 -10 48 4 0 14 -4 22 -9z m256 -7 c20 -21 15 -49 -9 -49 -23 0 -41 27 -33
            49 8 20 21 20 42 0z m535 1 c15 -18 9 -50 -9 -50 -19 0 -36 29 -28 49 8 20 21
            21 37 1z m-969 -5 c22 -12 26 -55 4 -63 -7 -3 -24 2 -36 12 -18 15 -20 21 -10
            39 13 25 15 26 42 12z m84 -8 c0 -18 -20 -15 -23 4 -3 10 1 15 10 12 7 -3 13
            -10 13 -16z m922 -4 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
            m-2387 -12 c0 -14 -4 -27 -9 -30 -12 -8 -22 20 -15 39 9 23 24 18 24 -9z m490
            10 c0 -19 -23 -36 -38 -27 -7 4 -10 15 -6 24 7 20 44 23 44 3z m690 -16 c14
            -26 -13 -59 -36 -44 -33 20 -41 44 -21 59 22 16 44 10 57 -15z m1054 13 c4 -9
            3 -20 -1 -24 -13 -13 -37 7 -30 25 8 21 23 20 31 -1z m-2004 -13 c0 -11 -7
            -20 -15 -20 -15 0 -21 21 -8 33 12 13 23 7 23 -13z m618 -7 c4 -25 -20 -36
            -43 -19 -16 12 -16 13 0 30 21 21 39 16 43 -11z m1162 7 c0 -11 -4 -20 -9 -20
            -13 0 -32 26 -24 33 13 14 33 6 33 -13z m78 -9 c2 -13 -1 -28 -7 -34 -17 -17
            -45 15 -36 42 9 29 39 23 43 -8z m-1511 13 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6
            6 11 1 17 -2 13 -5z m-427 -70 c-18 -21 -30 -17 -30 11 0 32 34 53 38 24 2
            -11 -2 -27 -8 -35z m1770 10 c0 -40 -34 -27 -38 14 -3 31 -2 33 17 22 13 -6
            21 -21 21 -36z m498 19 c-4 -39 -28 -42 -28 -4 0 21 5 31 16 31 11 0 14 -8 12
            -27z m-2551 -5 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m48 -8
            c9 -40 -3 -57 -22 -31 -14 21 -10 61 7 61 5 0 11 -13 15 -30z m800 -1 c0 -15
            -7 -25 -18 -27 -22 -4 -33 24 -17 43 18 21 35 13 35 -16z m-368 -12 c-7 -11
            -19 -23 -25 -25 -17 -5 -15 24 4 42 22 23 39 9 21 -17z m1277 17 c3 -9 6 -25
            6 -35 0 -32 -54 -22 -58 10 -5 36 39 58 52 25z m134 -5 c12 -18 11 -24 0 -36
            -12 -12 -17 -11 -31 5 -18 20 -14 52 7 52 6 0 17 -10 24 -21z m-1798 -14 c0
            -14 -4 -25 -9 -25 -12 0 -19 21 -13 38 8 21 22 13 22 -13z m634 9 c5 -14 -8
            -54 -19 -54 -28 0 -48 44 -28 63 12 13 41 7 47 -9z m-168 -9 c8 -19 -22 -65
            -41 -65 -20 0 -19 39 2 62 20 22 32 23 39 3z m654 -5 c0 -19 -20 -27 -33 -13
            -8 7 11 33 24 33 5 0 9 -9 9 -20z m438 0 c14 -23 8 -40 -12 -40 -20 0 -37 25
            -30 44 8 22 27 20 42 -4z m706 4 c12 -32 6 -54 -14 -54 -16 0 -20 7 -20 35 0
            37 22 49 34 19z m84 -30 c-2 -15 -8 -29 -15 -31 -18 -6 -28 44 -13 63 18 20
            32 4 28 -32z m-2284 -8 c-4 -17 -10 -24 -16 -18 -5 5 -6 21 -2 36 4 17 10 24
            16 18 5 -5 6 -21 2 -36z m61 4 c-5 -15 -10 -19 -17 -12 -5 5 -7 20 -3 32 5 15
            10 19 17 12 5 -5 7 -20 3 -32z m865 21 c0 -11 -5 -23 -11 -27 -15 -9 -33 12
            -26 30 9 23 37 20 37 -3z m342 9 c5 0 8 -10 6 -22 -3 -24 -34 -36 -50 -20 -15
            15 1 55 19 48 8 -3 19 -6 25 -6z m-1087 -48 c-6 -16 -7 -16 -16 1 -8 13 -7 23
            3 34 12 15 13 15 16 -1 2 -9 1 -25 -3 -34z m75 39 c0 -5 -7 -14 -15 -21 -13
            -10 -15 -9 -15 9 0 12 6 21 15 21 8 0 15 -4 15 -9z m1860 -23 c0 -14 -5 -18
            -16 -14 -9 3 -24 6 -35 6 -10 0 -19 5 -19 11 0 6 6 9 14 6 8 -3 17 1 20 8 8
            21 36 7 36 -17z m226 7 c4 -8 3 -27 0 -41 -6 -21 -9 -24 -21 -14 -15 13 -20
            51 -8 63 11 11 23 8 29 -8z m-331 -15 c3 -11 2 -27 -2 -35 -8 -14 -11 -13 -27
            4 -20 22 -15 51 8 51 8 0 17 -9 21 -20z m-2166 -45 c0 -30 -4 -53 -9 -50 -11
            7 -23 82 -15 95 15 24 24 7 24 -45z m114 -4 c-3 -12 -9 -21 -14 -21 -11 0 -12
            64 -2 74 11 10 22 -29 16 -53z m1427 45 c0 -19 -32 -30 -42 -14 -13 21 -9 28
            17 28 15 0 25 -6 25 -14z m1023 -11 c-7 -21 -13 -19 -13 6 0 11 4 18 10 14 5
            -3 7 -12 3 -20z m-2533 -11 c0 -8 -4 -14 -10 -14 -5 0 -10 9 -10 21 0 11 5 17
            10 14 6 -3 10 -13 10 -21z m860 16 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0
            6 5 10 10 10 6 0 10 -4 10 -10z m-820 -37 c0 -26 -3 -34 -11 -26 -9 9 -3 63 7
            63 2 0 4 -17 4 -37z m210 15 c0 -16 -3 -19 -11 -11 -6 6 -8 16 -5 22 11 17 16
            13 16 -11z m356 -3 c8 -31 -2 -58 -19 -53 -14 5 -21 42 -13 64 8 22 25 17 32
            -11z m1900 -2 c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2 -7 1 -15 -2 -18z
            m-2356 -18 c0 -40 -15 -47 -22 -11 -4 21 7 46 19 46 2 0 3 -16 3 -35z m794 19
            c20 -19 20 -23 1 -44 -20 -22 -65 -15 -65 10 0 19 25 50 40 50 5 0 16 -7 24
            -16z m1014 4 c15 -15 15 -45 0 -54 -13 -8 -38 22 -38 48 0 21 20 24 38 6z
            m-2035 -13 c-3 -9 -8 -14 -10 -11 -3 3 -2 9 2 15 9 16 15 13 8 -4z m1227 0
            c10 -12 10 -18 0 -30 -16 -19 -34 -19 -49 -1 -9 11 -8 18 4 30 20 20 29 20 45
            1z m114 -1 c20 -19 20 -29 0 -48 -12 -12 -20 -13 -35 -6 -21 12 -26 56 -6 63
            20 9 25 8 41 -9z m421 6 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10
            3 0 8 -4 11 -10z m89 -13 c-3 -66 -5 -77 -14 -77 -14 0 -23 45 -16 76 7 27 32
            28 30 1z m286 -23 c0 -30 -15 -38 -34 -18 -19 19 -21 51 -3 57 18 7 37 -13 37
            -39z m-1692 -1 c-2 -27 -8 -39 -20 -41 -22 -4 -24 28 -6 58 19 31 30 24 26
            -17z m-360 -14 c-1 -13 -7 -23 -13 -22 -13 3 -18 30 -11 50 10 24 29 1 24 -28z
            m760 19 c-4 -29 -38 -38 -38 -10 0 22 8 32 27 32 9 0 13 -8 11 -22z m1095 1
            c28 -40 -9 -91 -38 -53 -18 23 -19 46 -3 62 17 17 25 15 41 -9z m369 4 c5 -24
            -2 -53 -13 -53 -13 0 -31 40 -25 56 8 20 34 18 38 -3z m87 3 c13 -16 4 -49
            -13 -43 -14 4 -21 57 -8 57 5 0 15 -6 21 -14z m-2109 -3 c-1 -21 -13 -53 -21
            -53 -10 0 -12 43 -2 53 9 9 23 9 23 0z m230 -27 c0 -28 -25 -54 -33 -34 -6 17
            10 58 23 58 5 0 10 -11 10 -24z m80 17 c0 -5 -7 -16 -15 -27 -18 -24 -39 -12
            -25 15 10 18 40 27 40 12z m1938 -21 c2 -15 -2 -22 -12 -22 -11 0 -16 9 -16
            26 0 31 23 28 28 -4z m-2348 -12 c0 -16 -4 -30 -10 -30 -11 0 -14 43 -3 53 11
            12 13 8 13 -23z m186 -28 c-12 -12 -18 8 -10 35 l8 28 4 -28 c2 -16 1 -31 -2
            -35z m1240 33 c8 -34 -1 -55 -25 -55 -28 0 -45 34 -31 60 15 29 48 26 56 -5z
            m74 5 c0 -13 -5 -18 -15 -14 -16 7 -19 17 -8 27 12 13 23 7 23 -13z m794 4
            c10 -27 7 -52 -9 -58 -10 -3 -17 3 -21 19 -11 43 15 77 30 39z m-1559 -20 c0
            -10 -8 -20 -17 -22 -18 -3 -26 27 -11 42 12 11 28 0 28 -20z m1665 -13 c0 -22
            -4 -42 -9 -45 -10 -7 -25 60 -16 74 13 22 25 8 25 -29z m-2580 -2 c0 -16 -4
            -28 -9 -25 -5 4 -11 0 -14 -6 -2 -7 -8 -10 -12 -5 -5 4 -3 13 3 19 7 7 12 20
            12 30 0 10 5 18 10 18 6 0 10 -14 10 -31z m313 -27 l-18 -27 -3 28 c-2 16 2
            35 9 43 11 14 14 13 22 -1 6 -11 3 -25 -10 -43z m440 29 c6 -25 -14 -66 -33
            -66 -20 0 -25 32 -9 61 15 29 35 31 42 5z m347 6 c0 -14 -20 -37 -32 -37 -10
            0 -11 34 -1 43 11 12 33 8 33 -6z m1150 -19 c0 -5 -7 -8 -15 -8 -15 0 -20 21
            -8 33 7 7 23 -11 23 -25z m90 17 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8
            5 15 10 15 6 0 10 -7 10 -15z m294 -1 c3 -9 6 -23 6 -32 0 -14 -4 -13 -21 5
            -12 11 -18 26 -15 32 10 16 23 14 30 -5z m-2594 -17 c0 -26 -9 -39 -20 -32
            -10 6 0 55 11 55 5 0 9 -10 9 -23z m70 -12 c0 -27 -15 -41 -25 -25 -10 16 4
            62 15 55 6 -3 10 -17 10 -30z m117 13 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3
            4 -12 1 -19z m1251 -11 c3 -21 -1 -27 -16 -27 -30 0 -48 26 -32 45 19 23 45
            14 48 -18z m300 -20 c-2 -19 -9 -32 -17 -32 -21 0 -35 29 -27 59 6 23 9 25 27
            16 15 -9 19 -19 17 -43z m-1635 13 c-7 -28 -13 -25 -13 6 0 14 4 23 9 20 5 -3
            7 -15 4 -26z m2047 -6 c0 -15 -6 -24 -15 -24 -10 0 -15 10 -15 30 0 22 4 28
            15 24 8 -4 15 -17 15 -30z m-1925 17 c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0
            18 6 21 15 7z m982 -23 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z
            m863 -6 l0 -26 -21 21 c-23 22 -18 46 6 36 8 -3 15 -17 15 -31z m-750 12 c0
            -9 -7 -14 -17 -12 -25 5 -28 28 -4 28 12 0 21 -6 21 -16z m1043 -35 c-7 -27
            -37 -20 -41 10 -5 30 19 47 34 25 7 -8 10 -24 7 -35z m-2203 21 c0 -11 -4 -20
            -10 -20 -5 0 -10 9 -10 20 0 11 5 20 10 20 6 0 10 -9 10 -20z m1830 -4 c15
            -28 8 -56 -14 -56 -17 0 -31 42 -22 65 8 22 21 18 36 -9z m280 4 c0 -11 -7
            -20 -15 -20 -8 0 -15 9 -15 20 0 11 7 20 15 20 8 0 15 -9 15 -20z m214 14 c19
            -7 21 -47 3 -63 -19 -15 -27 -4 -27 36 0 36 0 36 24 27z m-2453 -67 c-11 -11
            -24 40 -14 56 4 7 10 0 15 -18 4 -16 4 -33 -1 -38z m369 49 c0 -8 -7 -17 -16
            -20 -21 -8 -26 -3 -18 18 8 20 34 21 34 2z m170 -24 c0 -36 -30 -79 -44 -65
            -10 10 -7 63 4 84 18 33 40 23 40 -19z m-577 -6 c4 4 7 -7 7 -24 0 -40 -4 -40
            -24 0 -20 38 -22 74 -3 40 7 -12 16 -19 20 -16z m842 -1 c0 -21 -5 -31 -19
            -33 -26 -5 -40 12 -32 43 5 20 12 26 29 23 17 -2 22 -10 22 -33z m309 22 c26
            -19 5 -57 -30 -57 -32 0 -41 25 -19 50 21 24 26 24 49 7z m1286 3 c8 -5 12
            -10 8 -10 -5 0 -10 1 -11 3 -2 1 -11 -4 -20 -12 -15 -12 -17 -11 -17 8 0 22
            16 27 40 11z m-2239 -53 c-18 -18 -25 1 -11 30 l14 28 4 -24 c2 -13 -1 -28 -7
            -34z m1459 27 c0 -16 -16 -19 -25 -4 -8 13 4 32 16 25 5 -4 9 -13 9 -21z
            m-1733 -6 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m35 5 c6 -26
            -2 -54 -13 -48 -12 8 -12 65 0 65 5 0 11 -8 13 -17z m128 5 c0 -7 -5 -20 -10
            -28 -8 -12 -10 -9 -10 13 0 15 5 27 10 27 6 0 10 -6 10 -12z m2488 -15 c-4
            -39 -28 -42 -28 -4 0 21 5 31 16 31 11 0 14 -8 12 -27z m-2238 4 c0 -7 -7 -22
            -15 -33 -14 -18 -14 -17 -15 14 0 22 5 32 15 32 8 0 15 -6 15 -13z m964 0 c21
            -15 18 -45 -6 -60 -12 -8 -21 -7 -32 2 -19 16 -21 57 -3 64 19 8 22 8 41 -6z
            m606 -2 c16 -20 2 -38 -25 -31 -15 4 -25 1 -29 -9 -10 -27 -36 -17 -36 14 0
            30 2 31 66 40 6 0 17 -6 24 -14z m310 -4 c14 -28 12 -58 -4 -64 -17 -7 -26 13
            -26 54 0 33 14 38 30 10z m-2090 -21 c0 -20 -5 -30 -15 -30 -10 0 -15 10 -15
            30 0 20 5 30 15 30 10 0 15 -10 15 -30z m875 0 c0 -20 -6 -26 -27 -28 -29 -3
            -35 9 -22 43 11 26 49 15 49 -15z m535 9 c0 -5 -7 -9 -15 -9 -15 0 -20 12 -9
            23 8 8 24 -1 24 -14z m-1250 -19 c0 -10 -4 -22 -10 -25 -6 -4 -10 7 -10 25 0
            18 4 29 10 25 6 -3 10 -15 10 -25z m-50 6 c0 -15 -18 -31 -26 -23 -7 7 7 37
            17 37 5 0 9 -6 9 -14z m240 -21 c0 -13 -8 -31 -17 -41 -17 -17 -18 -17 -21 9
            -4 30 15 70 29 62 5 -4 9 -17 9 -30z m-73 9 c7 -18 -12 -64 -27 -64 -11 0 -14
            63 -3 73 11 12 23 8 30 -9z m1689 -8 c7 -31 2 -58 -12 -63 -15 -5 -36 45 -29
            68 9 27 33 24 41 -5z m287 -22 c-3 -8 -9 -14 -13 -14 -12 0 -20 28 -13 46 6
            15 7 15 19 -1 7 -9 10 -23 7 -31z m-1173 21 c0 -8 -4 -15 -9 -15 -13 0 -22 16
            -14 24 11 11 23 6 23 -9z m550 -16 c0 -5 -7 -6 -15 -3 -8 4 -15 13 -15 21 0
            13 3 13 15 3 8 -7 15 -16 15 -21z m558 -7 c2 -24 -1 -32 -12 -32 -17 0 -32 42
            -21 60 12 20 30 4 33 -28z m143 17 c7 -14 8 -24 1 -31 -15 -15 -32 0 -32 28 0
            30 15 31 31 3z m79 4 c0 -10 -5 -25 -10 -33 -8 -13 -11 -13 -26 2 -14 14 -15
            20 -4 32 17 21 40 20 40 -1z m-2470 -28 c0 -29 -16 -41 -24 -19 -8 21 3 56 15
            49 5 -4 9 -17 9 -30z m536 -15 c-13 -43 -36 -67 -36 -36 0 9 4 16 8 16 4 0 14
            16 21 36 18 49 23 38 7 -16z m-676 13 c0 -20 -3 -24 -11 -16 -7 7 -9 19 -6 27
            10 24 17 19 17 -11z m30 2 c0 -14 -4 -25 -10 -25 -5 0 -10 11 -10 25 0 14 5
            25 10 25 6 0 10 -11 10 -25z m35 0 c0 -10 -6 -20 -12 -22 -8 -3 -13 5 -13 22
            0 17 5 25 13 23 6 -3 12 -13 12 -23z m35 -2 c0 -20 -3 -24 -11 -16 -7 7 -9 19
            -6 27 10 24 17 19 17 -11z m121 -26 c-8 -8 -11 -4 -11 14 0 33 14 47 18 19 2
            -12 -1 -27 -7 -33z m525 16 c-11 -11 -17 4 -9 23 7 18 8 18 11 1 2 -10 1 -21
            -2 -24z m779 17 c24 -27 13 -52 -22 -48 -31 3 -42 30 -23 53 16 20 23 19 45
            -5z m181 -6 c19 -28 -3 -60 -34 -50 -23 8 -39 47 -27 66 10 17 46 8 61 -16z
            m534 -15 c0 -11 -6 -19 -14 -19 -17 0 -31 34 -21 50 10 16 35 -8 35 -31z
            m-1914 -13 c-3 -14 -11 -26 -16 -26 -11 0 -13 30 -4 55 10 26 28 1 20 -29z
            m1752 16 c2 -7 -3 -12 -12 -12 -9 0 -16 7 -16 16 0 17 22 14 28 -4z m-1885
            -22 c-7 -28 -13 -25 -13 6 0 14 4 23 9 20 5 -3 7 -15 4 -26z m77 0 c0 -20 -5
            -30 -15 -30 -16 0 -19 17 -9 44 10 26 24 18 24 -14z m634 24 c14 -5 13 -9 -5
            -25 -12 -11 -28 -19 -35 -19 -22 0 -28 23 -9 37 21 15 27 16 49 7z m1754 -22
            c2 -15 -2 -22 -12 -22 -11 0 -16 9 -16 26 0 31 23 28 28 -4z m-2245 -12 c-3
            -11 -9 -23 -14 -26 -10 -6 -12 39 -3 49 12 12 22 -3 17 -23z m183 -26 c-3 -8
            -10 -14 -15 -14 -18 0 -19 28 -3 51 l17 24 3 -23 c2 -13 1 -30 -2 -38z m574
            41 c0 -8 -6 -15 -14 -15 -7 0 -19 7 -26 15 -11 13 -8 15 14 15 16 0 26 -5 26
            -15z m-1014 -53 c-12 -11 -17 7 -9 34 l8 29 3 -28 c2 -16 1 -31 -2 -35z m1211
            52 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-1296 -57 c-18 -18
            -25 1 -11 30 l14 28 4 -24 c2 -13 -1 -28 -7 -34z m925 -32 c-13 -50 -31 -4
            -26 65 1 24 2 24 17 -6 10 -21 13 -42 9 -59z m998 69 c19 -49 -7 -91 -39 -64
            -15 13 -21 61 -8 73 12 13 41 7 47 -9z m356 -15 c0 -10 -4 -19 -9 -19 -12 0
            -23 29 -15 41 8 14 24 0 24 -22z m148 -1 c-4 -37 -23 -48 -31 -17 -7 27 1 49
            20 49 10 0 13 -9 11 -32z m82 -8 c0 -16 -4 -31 -9 -35 -13 -7 -24 30 -16 55 9
            28 25 15 25 -20z m-2540 2 c0 -25 -4 -28 -30 -28 -25 0 -30 4 -30 23 0 20 3
            22 19 14 14 -8 20 -7 25 4 9 24 16 18 16 -13z m80 -3 c0 -10 -4 -19 -10 -19
            -5 0 -10 12 -10 26 0 14 4 23 10 19 6 -3 10 -15 10 -26z m1173 9 c7 -21 -4
            -38 -23 -38 -23 0 -34 16 -26 35 7 18 43 20 49 3z m543 -10 c7 -23 6 -99 -1
            -107 -13 -12 -35 26 -35 59 0 66 21 93 36 48z m459 1 c8 -23 1 -34 -14 -24
            -13 8 -15 45 -2 45 5 0 13 -9 16 -21z m145 2 c0 -11 5 -23 10 -26 6 -3 10 -14
            10 -23 0 -14 -2 -14 -14 -2 -20 20 -32 70 -17 70 6 0 11 -9 11 -19z m200 -33
            c0 -10 -4 -18 -10 -18 -14 0 -32 40 -25 57 5 14 7 14 20 -3 8 -10 15 -27 15
            -36z m-101 -20 c1 -10 -6 -18 -14 -18 -17 0 -18 4 -8 51 7 33 7 33 14 9 4 -14
            7 -33 8 -42z m-2091 17 c-6 -14 -15 -25 -20 -25 -12 0 -10 34 4 48 18 18 28 4
            16 -23z m-100 -31 l-12 -29 -4 28 c-4 31 20 73 26 45 2 -8 -3 -28 -10 -44z
            m1276 47 c9 -14 -4 -41 -19 -41 -18 0 -28 17 -21 35 6 17 32 20 40 6z m476
            -48 c0 -30 -18 -39 -35 -17 -18 23 -19 46 -3 62 14 14 37 -12 38 -45z m-1912
            20 c-4 -39 -28 -42 -28 -4 0 21 5 31 16 31 11 0 14 -8 12 -27z m42 3 c0 -26
            -15 -43 -24 -28 -7 12 4 52 15 52 5 0 9 -11 9 -24z m50 15 c0 -6 -4 -13 -10
            -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16 6 0 10 -4 10 -9z m21 -44 c-8 -8 -11 -4
            -11 14 0 33 14 47 18 19 2 -12 -1 -27 -7 -33z m427 24 c3 -22 -22 -61 -40 -61
            -4 0 -8 15 -8 33 0 54 41 77 48 28z m676 23 c16 -6 23 -33 9 -35 -5 0 -21 -2
            -38 -4 -16 -2 -25 -1 -19 3 6 4 13 15 17 25 7 19 9 20 31 11z m771 -15 c4 -11
            4 -23 0 -27 -9 -9 -40 13 -43 32 -5 24 35 20 43 -5z m-1028 0 c23 -23 -13 -71
            -47 -62 -24 6 -33 29 -20 54 12 21 50 25 67 8z m1266 -10 c6 -24 -1 -49 -14
            -49 -13 0 -31 40 -25 56 8 21 33 17 39 -7z m-2295 -53 c-5 -32 -23 -20 -23 14
            1 17 -3 30 -7 30 -4 0 -8 6 -8 13 1 6 10 1 21 -12 11 -13 19 -34 17 -45z
            m-115 40 c24 -18 23 -49 -1 -41 -16 4 -19 0 -20 -26 -2 -59 -12 -42 -12 19 0
            65 4 70 33 48z m64 -33 c-3 -10 -5 -2 -5 17 0 19 2 27 5 18 2 -10 2 -26 0 -35z
            m451 15 c-3 -34 -31 -51 -40 -26 -8 20 10 58 27 58 12 0 15 -8 13 -32z m90 -2
            c3 -27 -12 -56 -29 -56 -15 0 -21 47 -9 70 15 28 34 21 38 -14z m186 19 c-6
            -16 -24 -21 -24 -7 0 11 11 22 21 22 5 0 6 -7 3 -15z m1458 -8 c2 -12 3 -28 0
            -35 -7 -21 -32 7 -32 34 0 31 26 32 32 1z m-2215 -19 c-3 -7 -5 -2 -5 12 0 14
            2 19 5 13 2 -7 2 -19 0 -25z m113 22 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10
            0 6 5 10 10 10 6 0 10 -4 10 -10z m749 1 c22 -14 5 -68 -25 -75 -14 -4 -30 -4
            -35 0 -14 8 -2 56 18 72 20 14 24 15 42 3z m836 -12 c10 -31 -12 -49 -30 -26
            -15 21 -10 47 10 47 8 0 17 -9 20 -21z m-1360 -46 c-4 -11 -9 -13 -16 -6 -6 6
            -6 19 2 39 12 28 13 28 17 6 2 -13 1 -31 -3 -39z m1985 16 c0 -27 -18 -32 -30
            -8 -16 29 -5 56 15 39 8 -7 15 -21 15 -31z m260 11 c0 -20 -5 -30 -15 -30 -15
            0 -21 40 -8 53 14 15 23 6 23 -23z m-2350 -2 c0 -13 -4 -29 -9 -37 -13 -20
            -24 4 -17 35 8 30 26 32 26 2z m1658 -11 c4 -29 -12 -37 -22 -11 -10 26 -7 46
            7 42 6 -3 13 -17 15 -31z m-573 -17 c1 -16 -2 -30 -6 -30 -12 0 -22 46 -14 60
            10 16 18 5 20 -30z m1225 21 c0 -11 -4 -23 -10 -26 -6 -4 -10 5 -10 19 0 14 5
            26 10 26 6 0 10 -9 10 -19z m-561 -10 c8 -14 7 -22 -4 -31 -17 -14 -35 -5 -35
            19 0 34 24 42 39 12z m447 -27 c-7 -19 -22 -18 -30 3 -5 11 0 22 11 31 15 11
            18 11 21 -3 2 -9 1 -23 -2 -31z m-1675 -58 c13 -15 11 -26 -6 -26 -28 0 -45
            63 -28 107 5 13 9 6 16 -27 5 -25 13 -49 18 -54z m1752 82 c6 -19 -3 -38 -19
            -38 -8 0 -14 10 -14 25 0 26 25 35 33 13z m93 -35 c-10 -26 -18 -8 -9 23 5 21
            8 24 11 11 2 -10 1 -25 -2 -34z m-2416 23 c0 -24 -12 -46 -21 -40 -15 9 -10
            54 6 54 8 0 15 -6 15 -14z m215 3 c11 -16 -4 -59 -20 -59 -10 0 -15 10 -15 28
            0 39 20 56 35 31z m830 -19 c0 -31 -31 -39 -40 -11 -8 26 2 43 23 39 11 -2 17
            -13 17 -28z m303 18 c30 -30 4 -70 -39 -61 -28 5 -56 44 -45 62 10 15 69 14
            84 -1z m620 -17 c11 -32 1 -57 -21 -48 -15 6 -26 44 -19 65 7 22 30 13 40 -17z
            m-2028 -7 c0 -14 -4 -23 -10 -19 -5 3 -10 15 -10 26 0 10 5 19 10 19 6 0 10
            -12 10 -26z m140 17 c0 -19 -12 -41 -20 -36 -10 6 -1 45 11 45 5 0 9 -4 9 -9z
            m1355 -11 c7 -21 0 -40 -16 -40 -5 0 -9 14 -9 30 0 34 15 40 25 10z m-1635
            -21 c0 -16 -4 -28 -9 -25 -5 4 -12 0 -14 -6 -3 -7 -6 2 -6 20 -1 20 3 31 9 27
            6 -3 10 -1 10 4 0 6 2 11 5 11 3 0 5 -14 5 -31z m17 -6 c-3 -10 -5 -4 -5 12 0
            17 2 24 5 18 2 -7 2 -21 0 -30z m40 17 c-3 -11 -1 -20 4 -20 5 0 9 -11 9 -25
            0 -35 -16 -31 -24 6 -9 45 -8 59 5 59 6 0 9 -9 6 -20z m1078 -20 c-8 -14 -32
            -13 -41 2 -4 6 1 17 12 25 15 11 19 11 27 -2 4 -8 5 -20 2 -25z m1155 -11 c0
            -18 -5 -29 -14 -29 -16 0 -29 41 -20 64 10 25 34 0 34 -35z m90 30 c0 -11 -4
            -17 -10 -14 -5 3 -10 13 -10 21 0 8 5 14 10 14 6 0 10 -9 10 -21z m-1395 -22
            c-1 -12 -5 -21 -8 -19 -4 1 -14 2 -22 2 -20 0 -32 30 -15 40 21 13 47 0 45
            -23z m-665 13 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10
            -4 10 -10z m1510 -12 c0 -16 -3 -19 -11 -11 -6 6 -8 16 -5 22 11 17 16 13 16
            -11z m-470 -3 c0 -8 7 -15 15 -15 8 0 15 -4 15 -9 0 -14 -14 -21 -26 -13 -14
            9 -25 52 -13 52 5 0 9 -7 9 -15z m728 -29 c-2 -12 -10 -21 -18 -21 -16 0 -25
            28 -16 52 9 23 38 -4 34 -31z m486 29 c10 -28 -14 -60 -28 -37 -7 11 4 52 14
            52 4 0 10 -7 14 -15z m-2464 -25 c0 -16 -4 -30 -10 -30 -11 0 -14 43 -3 53 11
            12 13 8 13 -23z m250 23 c0 -5 -5 -15 -10 -23 -8 -12 -10 -11 -10 8 0 12 5 22
            10 22 6 0 10 -3 10 -7z m1980 -14 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5
            12 10 9 6 -3 10 -10 10 -16z m-2128 -14 c-2 -14 -8 -25 -13 -25 -5 0 -9 11 -9
            25 0 14 6 25 14 25 9 0 12 -8 8 -25z m367 1 c13 -14 21 -32 18 -43 -4 -16 -6
            -15 -12 4 -6 17 -13 22 -31 19 -20 -4 -24 -1 -24 20 0 31 20 31 49 0z m-609
            -28 c0 -10 -4 -18 -10 -18 -5 0 -10 15 -10 33 0 24 3 28 10 17 5 -8 10 -23 10
            -32z m60 21 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12 10 9 6 -3 10 -10
            10 -16z m1709 7 c15 -18 3 -46 -20 -46 -13 0 -19 7 -19 23 0 37 18 48 39 23z
            m735 -43 c4 -27 2 -43 -4 -43 -5 0 -10 7 -10 15 0 8 -5 24 -12 34 -12 20 -3
            60 11 45 5 -5 11 -28 15 -51z m54 45 c-6 -18 -28 -21 -28 -4 0 9 7 16 16 16 9
            0 14 -5 12 -12z m-1688 -8 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10
            10 10 6 0 10 -4 10 -10z m1150 -9 c12 -24 13 -41 0 -61 -7 -12 -12 -12 -29 4
            -28 25 -29 76 -1 76 10 0 24 -9 30 -19z m610 -3 c0 -16 -3 -19 -11 -11 -6 6
            -8 16 -5 22 11 17 16 13 16 -11z m-2440 -2 c0 -24 -12 -46 -21 -40 -15 9 -10
            54 6 54 8 0 15 -6 15 -14z m90 -15 c0 -17 -4 -32 -9 -35 -12 -8 -23 23 -16 45
            10 29 25 22 25 -10z m31 -8 c-9 -16 -10 -14 -11 12 0 21 3 26 11 18 8 -8 8
            -16 0 -30z m129 17 c-12 -22 -30 -18 -30 6 0 8 9 14 20 14 18 0 19 -3 10 -20z
            m68 -7 c-4 -34 -28 -43 -28 -10 0 25 7 37 22 37 5 0 8 -12 6 -27z m826 -6 c8
            -34 -31 -50 -53 -23 -9 11 -9 20 -2 34 14 25 47 18 55 -11z m932 7 c10 -37
            -12 -55 -26 -20 -14 37 -13 46 5 46 8 0 18 -12 21 -26z m159 6 c8 -24 -1 -40
            -21 -40 -9 0 -14 11 -14 30 0 35 25 42 35 10z m-2084 -57 c-9 -15 -10 -12 -11
            18 0 20 3 39 8 43 11 12 14 -42 3 -61z m1836 45 c-3 -8 -6 -5 -6 6 -1 11 2 17
            5 13 3 -3 4 -12 1 -19z m-2116 -41 c-8 -8 -11 -4 -11 14 0 33 14 47 18 19 2
            -12 -1 -27 -7 -33z m1849 34 c16 -31 12 -51 -10 -51 -23 0 -43 32 -34 54 8 22
            31 20 44 -3z m-1873 -28 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z
            m73 22 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10
            -15z m1040 -15 c0 -26 -4 -30 -27 -30 -43 0 -57 13 -44 39 8 14 21 21 41 21
            27 0 30 -3 30 -30z m560 9 c0 -23 -27 -36 -42 -21 -15 15 -4 37 21 39 15 1 21
            -4 21 -18z m654 5 c10 -25 7 -34 -9 -34 -9 0 -15 9 -15 25 0 28 15 34 24 9z
            m-1978 -19 c1 -8 -4 -19 -12 -26 -11 -9 -14 -8 -14 4 0 40 21 57 26 22z m2084
            -1 c0 -16 -16 -19 -25 -4 -8 13 4 32 16 25 5 -4 9 -13 9 -21z m-2469 -26 l-21
            -22 0 26 c0 17 6 28 18 30 25 5 26 -8 3 -34z m734 22 c7 -23 -10 -50 -31 -50
            -21 0 -33 31 -21 52 16 24 44 23 52 -2z m256 10 c5 0 9 -7 9 -15 0 -19 -46
            -20 -53 -1 -7 18 3 28 21 21 7 -3 18 -5 23 -5z m1572 4 c3 -4 1 -16 -4 -27 -9
            -15 -13 -17 -19 -7 -14 23 5 51 23 34z m37 -19 c0 -8 -4 -17 -10 -20 -6 -4
            -10 5 -10 20 0 15 4 24 10 20 6 -3 10 -12 10 -20z m34 16 c8 -12 -3 -41 -15
            -41 -5 0 -9 11 -9 25 0 25 14 34 24 16z m-310 -17 c11 -27 7 -37 -9 -24 -16
            14 -20 40 -6 40 5 0 12 -7 15 -16z m-2184 -8 c0 -8 -4 -17 -9 -21 -12 -7 -24
            12 -16 25 9 15 25 12 25 -4z m74 -24 c-4 -12 -10 -22 -15 -22 -11 0 -12 44 -2
            54 12 11 23 -9 17 -32z m356 9 c0 -31 -25 -65 -41 -56 -14 9 -11 38 7 63 21
            30 34 28 34 -7z m1002 5 c20 -30 -1 -49 -40 -35 -28 11 -40 38 -21 50 16 10
            51 2 61 -15z m644 -2 c8 -33 -9 -52 -31 -34 -18 15 -20 46 -2 53 22 10 27 7
            33 -19z m-1782 0 c9 -23 -2 -54 -20 -54 -9 0 -14 10 -14 28 0 42 22 58 34 26z
            m448 -9 c-7 -35 -45 -48 -56 -19 -9 22 13 46 38 42 15 -2 20 -9 18 -23z m348
            15 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z
            m1450 -16 c0 -8 -4 -14 -10 -14 -5 0 -10 9 -10 21 0 11 5 17 10 14 6 -3 10
            -13 10 -21z m-2607 -17 c-6 -33 -27 -31 -31 4 -2 17 0 20 6 11 7 -11 11 -10
            16 4 10 25 15 13 9 -19z m2055 -14 c3 -27 0 -31 -17 -26 -13 3 -21 14 -21 27
            0 48 33 46 38 -1z m-2103 -8 c1 -13 -3 -21 -7 -19 -4 3 -8 17 -8 31 0 32 15
            20 15 -12z m144 -7 c1 -5 -3 -8 -9 -8 -5 0 -10 12 -9 28 0 24 1 25 9 7 5 -11
            9 -23 9 -27z m81 35 c0 -5 -4 -15 -10 -23 -11 -18 -25 -7 -17 15 6 15 27 21
            27 8z m57 -30 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m183 18
            c0 -11 -4 -23 -10 -26 -6 -4 -10 5 -10 19 0 14 5 26 10 26 6 0 10 -9 10 -19z
            m1560 -12 c0 -20 -15 -26 -25 -9 -9 15 3 43 15 35 5 -3 10 -15 10 -26z m370 5
            c0 -14 -4 -23 -10 -19 -5 3 -10 15 -10 26 0 10 5 19 10 19 6 0 10 -12 10 -26z
            m50 -4 c0 -16 -4 -30 -10 -30 -11 0 -14 43 -3 53 11 12 13 8 13 -23z m-2473
            -27 c-3 -10 -5 -2 -5 17 0 19 2 27 5 18 2 -10 2 -26 0 -35z m109 11 c-5 -13
            -10 -14 -18 -6 -6 6 -8 18 -4 28 5 13 10 14 18 6 6 -6 8 -18 4 -28z m178 3
            c-7 -18 -24 -23 -24 -8 0 22 13 42 22 33 5 -5 6 -16 2 -25z m1022 -3 c-5 -13
            -9 -14 -21 -4 -20 16 -19 28 3 32 20 4 26 -6 18 -28z m518 17 c14 -15 15 -24
            6 -40 -15 -29 -44 -26 -59 5 -21 46 19 72 53 35z m436 -17 c0 -14 -4 -23 -10
            -19 -5 3 -10 15 -10 26 0 10 5 19 10 19 6 0 10 -12 10 -26z m260 1 c0 -25 -14
            -34 -24 -16 -8 12 3 41 15 41 5 0 9 -11 9 -25z m36 -12 c-11 -11 -19 6 -11 24
            8 17 8 17 12 0 3 -10 2 -21 -1 -24z m94 23 c0 -8 -4 -18 -10 -21 -5 -3 -10 3
            -10 14 0 12 5 21 10 21 6 0 10 -6 10 -14z m-670 -26 c13 -24 5 -41 -15 -33
            -16 6 -21 53 -5 53 5 0 14 -9 20 -20z m328 -26 c-5 -42 -28 -43 -28 -1 0 18 3
            37 7 41 12 12 25 -11 21 -40z m82 10 c0 -13 -4 -24 -10 -24 -5 0 -10 14 -10
            31 0 17 4 28 10 24 6 -3 10 -17 10 -31z m-2050 1 c0 -14 -4 -25 -10 -25 -11 0
            -14 33 -3 43 11 11 13 8 13 -18z m290 7 c0 -19 -18 -42 -32 -42 -10 0 -11 44
            -1 53 13 14 33 7 33 -11z m375 -1 c-3 -6 -11 -11 -16 -11 -5 0 -4 6 3 14 14
            16 24 13 13 -3z m74 5 c9 -10 8 -15 -4 -20 -21 -7 -45 3 -45 20 0 18 34 18 49
            0z m-1032 -28 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m1381 26
            c16 -5 22 -14 22 -36 0 -16 -4 -27 -8 -24 -4 2 -14 1 -22 -4 -19 -12 -50 11
            -50 37 0 18 10 30 30 32 3 0 15 -2 28 -5z m279 -26 c-3 -7 -5 -2 -5 12 0 14 2
            19 5 13 2 -7 2 -19 0 -25z m-1337 -18 c-15 -28 -23 -20 -14 15 4 17 10 23 16
            17 6 -6 6 -18 -2 -32z m1820 19 c0 -18 -2 -19 -15 -9 -8 7 -15 16 -15 21 0 5
            7 9 15 9 9 0 15 -9 15 -21z m420 -5 c0 -31 -23 -28 -28 4 -2 15 2 22 12 22 11
            0 16 -9 16 -26z m-2610 -21 c0 -32 -5 -28 -14 10 -4 19 -3 26 4 22 5 -3 10
            -18 10 -32z m2250 -4 c0 -11 -4 -19 -9 -19 -16 0 -33 30 -27 46 5 13 9 14 21
            4 8 -7 15 -21 15 -31z m-1839 -2 c-8 -8 -11 -7 -11 4 0 20 13 34 18 19 3 -7
            -1 -17 -7 -23z m889 11 c0 -19 -2 -20 -10 -8 -13 19 -13 30 0 30 6 0 10 -10
            10 -22z m1370 2 c0 -11 -4 -20 -10 -20 -5 0 -10 9 -10 20 0 11 5 20 10 20 6 0
            10 -9 10 -20z m-2472 -8 c2 -7 -3 -12 -12 -12 -9 0 -16 7 -16 16 0 17 22 14
            28 -4z m342 4 c0 -16 -18 -31 -27 -22 -8 8 5 36 17 36 5 0 10 -6 10 -14z
            m1370 -11 c0 -22 -32 -55 -52 -55 -16 0 -15 5 8 40 25 38 44 44 44 15z m-999
            1 c-6 -7 -8 -22 -4 -31 4 -13 3 -16 -5 -11 -7 4 -12 2 -12 -3 0 -6 -7 -11 -15
            -11 -8 0 -15 7 -15 15 0 25 24 55 44 55 14 0 16 -3 7 -14z m629 4 c0 -5 -4
            -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m810 -11 c0
            -11 -4 -17 -10 -14 -5 3 -10 13 -10 21 0 8 5 14 10 14 6 0 10 -9 10 -21z m120
            -9 c0 -10 -4 -21 -9 -25 -12 -7 -25 31 -16 45 9 15 25 2 25 -20z m64 22 c2 -4
            1 -14 -4 -21 -10 -17 -31 -5 -23 14 6 16 19 20 27 7z m-2407 -22 c1 -11 -2
            -22 -8 -25 -5 -4 -9 5 -9 19 0 30 15 35 17 6z m353 -4 c0 -30 -27 -55 -36 -33
            -6 17 12 57 26 57 6 0 10 -11 10 -24z m-463 -18 c-3 -7 -5 -2 -5 12 0 14 2 19
            5 13 2 -7 2 -19 0 -25z m68 23 c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18 6
            21 15 7z m68 -6 c4 -8 2 -17 -3 -20 -6 -4 -10 3 -10 14 0 25 6 27 13 6z m1063
            -43 c-15 -15 -16 -15 -16 4 0 28 24 52 28 28 2 -9 -3 -24 -12 -32z m324 38 c0
            -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9 -4 9 -10z m114 4
            c16 -6 23 -43 9 -45 -5 0 -16 -2 -25 -4 -22 -4 -42 20 -34 40 6 16 24 19 50 9z
            m424 -17 c2 -10 1 -25 -2 -34 -9 -23 -36 3 -36 34 0 18 5 24 17 21 10 -2 19
            -11 21 -21z m337 14 c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z
            m-2365 -33 c0 -5 -4 -8 -10 -8 -5 0 -10 10 -10 23 0 18 2 19 10 7 5 -8 10 -18
            10 -22z m610 8 c0 -18 -5 -23 -17 -21 -19 3 -19 25 0 37 17 11 17 11 17 -16z
            m118 -8 c5 -45 -49 -64 -65 -22 -4 11 2 24 17 36 29 23 44 19 48 -14z m1390
            20 c16 -16 15 -68 -1 -68 -21 0 -37 22 -37 52 0 30 17 37 38 16z m-1089 -20
            c12 -31 -5 -50 -42 -46 -62 6 -58 68 5 68 21 0 31 -6 37 -22z m255 6 c3 -8 2
            -12 -4 -9 -6 3 -10 10 -10 16 0 14 7 11 14 -7z m1296 -10 c0 -16 -16 -19 -25
            -4 -8 13 4 32 16 25 5 -4 9 -13 9 -21z m42 4 c-7 -7 -12 -8 -12 -2 0 14 12 26
            19 19 2 -3 -1 -11 -7 -17z m-2422 -14 c0 -14 -4 -23 -10 -19 -5 3 -10 15 -10
            26 0 10 5 19 10 19 6 0 10 -12 10 -26z m-60 1 c0 -8 -4 -15 -10 -15 -5 0 -10
            7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m2370 0 c0 -8 -4 -15 -10 -15 -5 0
            -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m-2559 -22 c-12 -20 -14 -14
            -5 12 4 9 9 14 11 11 3 -2 0 -13 -6 -23z m79 7 c0 -11 -4 -20 -10 -20 -5 0
            -10 9 -10 20 0 11 5 20 10 20 6 0 10 -9 10 -20z m30 10 c0 -5 -2 -10 -4 -10
            -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m50 -21 c0 -10 -4 -19
            -10 -19 -5 0 -10 12 -10 26 0 14 4 23 10 19 6 -3 10 -15 10 -26z m390 3 c0
            -32 -16 -53 -36 -45 -17 6 -18 27 -4 54 16 29 40 24 40 -9z m1438 16 c18 -23
            19 -62 1 -66 -23 -4 -49 24 -49 54 0 27 30 34 48 12z m-1578 -27 c0 -32 -18
            -51 -30 -32 -11 18 0 61 16 61 9 0 14 -11 14 -29z m1830 -1 c0 -30 -13 -40
            -25 -20 -8 13 4 50 16 50 5 0 9 -13 9 -30z m447 8 c-3 -8 -6 -5 -6 6 -1 11 2
            17 5 13 3 -3 4 -12 1 -19z m-2657 -13 c0 -14 -4 -25 -10 -25 -11 0 -14 33 -3
            43 11 11 13 8 13 -18z m247 -7 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19
            0 -25z m2143 28 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0
            10 -2 10 -4z m-2080 -31 c0 -14 -4 -25 -9 -25 -12 0 -23 29 -15 41 10 18 24 9
            24 -16z m1150 -10 c0 -12 -21 -25 -40 -25 -16 0 -19 30 -4 45 13 12 19 13 30
            4 8 -6 14 -17 14 -24z m990 5 c0 -11 -4 -20 -10 -20 -5 0 -10 9 -10 20 0 11 5
            20 10 20 6 0 10 -9 10 -20z m-100 0 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0
            6 5 10 10 10 6 0 10 -4 10 -10z m180 -5 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10
            15 0 8 5 15 10 15 6 0 10 -7 10 -15z m-2403 -35 c14 0 17 -12 6 -23 -11 -11
            -43 25 -42 47 0 20 1 20 11 -1 6 -13 17 -23 25 -23z m63 14 c0 -8 -4 -14 -10
            -14 -5 0 -10 9 -10 21 0 11 5 17 10 14 6 -3 10 -13 10 -21z m404 -17 c-7 -20
            -30 -23 -37 -4 -2 6 0 20 5 31 9 16 12 16 24 4 8 -8 12 -22 8 -31z m1204 11
            c3 -22 -1 -28 -15 -28 -11 0 -24 5 -31 12 -7 7 -12 20 -12 31 0 14 6 18 28 15
            21 -2 28 -9 30 -30z m506 7 c-4 -8 -10 -13 -15 -10 -4 3 -6 12 -3 20 4 8 10
            13 15 10 4 -3 6 -12 3 -20z m253 3 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4
            -12 1 -19z m-2527 3 c0 -6 -4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16 6
            0 10 -4 10 -9z m1140 -27 c0 -8 -2 -14 -4 -14 -2 0 -11 -3 -19 -6 -19 -7 -31
            24 -16 42 13 16 39 1 39 -22z m-1110 4 c0 -19 -2 -20 -10 -8 -13 19 -13 30 0
            30 6 0 10 -10 10 -22z m187 -45 c-10 -9 -13 -3 -20 37 l-6 35 16 -32 c9 -18
            13 -36 10 -40z m2010 51 c8 -22 -15 -44 -26 -25 -10 16 -4 41 10 41 5 0 12 -7
            16 -16z m-1389 -11 c2 -9 -7 -13 -27 -13 -21 0 -31 5 -31 16 0 19 51 16 58 -3z
            m750 -11 c4 -26 -6 -28 -37 -5 -23 16 -23 17 -5 24 27 11 39 6 42 -19z m480 5
            c2 -10 1 -25 -2 -34 -9 -23 -36 3 -36 34 0 18 5 24 17 21 10 -2 19 -11 21 -21z
            m60 -7 c3 -21 2 -22 -12 -11 -19 16 -21 44 -3 39 6 -3 14 -15 15 -28z m-1728
            -4 c0 -13 -7 -29 -15 -36 -13 -11 -15 -8 -15 17 0 16 3 33 7 36 13 14 23 6 23
            -17z m248 5 c3 -8 -1 -12 -9 -9 -7 2 -15 10 -17 17 -3 8 1 12 9 9 7 -2 15 -10
            17 -17z m1742 11 c0 -13 -12 -22 -22 -16 -10 6 -1 24 13 24 5 0 9 -4 9 -8z
            m-2068 -42 c4 0 8 -7 8 -15 0 -8 -3 -15 -7 -15 -9 0 -33 49 -32 64 0 6 5 1 11
            -11 6 -13 15 -23 20 -23z m686 29 c-2 -6 -8 -10 -13 -10 -5 0 -11 4 -13 10 -2
            6 4 11 13 11 9 0 15 -5 13 -11z m210 -35 c-2 -15 -8 -29 -14 -31 -17 -6 -27
            46 -13 63 17 20 31 3 27 -32z m1218 19 c-10 -10 -19 5 -10 18 6 11 8 11 12 0
            2 -7 1 -15 -2 -18z m114 13 c0 -16 -18 -31 -27 -22 -8 8 5 36 17 36 5 0 10 -6
            10 -14z m53 -6 c-3 -11 -7 -20 -9 -20 -2 0 -4 9 -4 20 0 11 4 20 9 20 5 0 7
            -9 4 -20z m-2543 -27 c0 -22 -2 -25 -10 -13 -13 20 -13 40 0 40 6 0 10 -12 10
            -27z m90 -4 c0 -10 -7 -19 -15 -19 -15 0 -21 31 -9 43 11 10 24 -3 24 -24z
            m110 21 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11
            -10z m205 -16 c0 -10 -8 -20 -17 -22 -18 -3 -26 27 -11 42 12 11 28 0 28 -20z
            m855 11 c0 -8 -9 -15 -20 -15 -22 0 -27 15 -7 23 21 9 27 7 27 -8z m1010 -10
            c0 -25 -14 -34 -24 -16 -8 12 3 41 15 41 5 0 9 -11 9 -25z m-2323 -22 c-3 -10
            -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m2253 22 c0 -8 -4 -15 -10 -15
            -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m-266 -11 c3 -9 6 -20 6
            -25 0 -14 -34 -11 -56 6 -16 13 -17 16 -4 25 22 15 47 12 54 -6z m-1817 -16
            c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m2013 -3 c0 -15 -14 -35
            -25 -35 -19 0 -27 17 -20 40 5 16 10 18 26 10 10 -6 19 -12 19 -15z m203 -12
            c-3 -7 -9 -13 -14 -13 -11 0 -12 34 -2 43 9 10 22 -13 16 -30z m77 18 c0 -20
            -13 -35 -23 -25 -7 8 4 44 14 44 5 0 9 -9 9 -19z m91 -4 c-10 -9 -11 -8 -5 6
            3 10 9 15 12 12 3 -3 0 -11 -7 -18z m-1091 -16 c0 -29 -18 -44 -49 -44 -39 0
            -55 14 -47 41 11 33 96 36 96 3z m-1430 4 c0 -8 -2 -15 -4 -15 -2 0 -6 7 -10
            15 -3 8 -1 15 4 15 6 0 10 -7 10 -15z m2487 -7 c-3 -8 -6 -5 -6 6 -1 11 2 17
            5 13 3 -3 4 -12 1 -19z m-2427 -10 c0 -18 -2 -20 -9 -8 -6 8 -7 18 -5 22 9 14
            14 9 14 -14z m538 -4 c4 -29 -23 -51 -43 -34 -16 13 -20 42 -8 54 14 14 48 1
            51 -20z m220 18 c15 -10 5 -42 -13 -42 -24 0 -45 12 -45 25 0 21 35 31 58 17z
            m1022 -7 c15 -18 9 -50 -9 -50 -19 0 -36 29 -28 49 8 20 21 21 37 1z m-1142
            -19 c3 -12 -1 -17 -10 -14 -7 3 -15 13 -16 22 -3 12 1 17 10 14 7 -3 15 -13
            16 -22z m350 -10 c-2 -15 -11 -22 -30 -24 -24 -3 -28 0 -28 22 0 15 7 26 18
            28 28 7 43 -3 40 -26z m-582 -24 c-12 -11 -18 7 -10 30 l8 23 4 -23 c2 -13 1
            -26 -2 -30z m1732 15 c-7 -14 -13 -17 -16 -9 -6 19 6 44 18 37 7 -4 6 -14 -2
            -28z m303 -10 c-8 -8 -11 -5 -11 9 0 27 14 41 18 19 2 -9 -1 -22 -7 -28z
            m-981 18 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10
            -15z m550 0 c7 -9 10 -18 7 -22 -8 -7 -37 15 -37 28 0 14 16 11 30 -6z m238 4
            c-2 -6 -8 -10 -13 -10 -5 0 -11 4 -13 10 -2 6 4 11 13 11 9 0 15 -5 13 -11z
            m138 -26 c-11 -11 -19 6 -11 24 8 17 8 17 12 0 3 -10 2 -21 -1 -24z m-909 5
            c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m1020 0 c-3 -8 -6 -5 -6
            6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-2407 2 c0 -5 -4 -10 -10 -10 -5 0 -10
            5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m50 -21 c0 -10 -4 -19 -9 -19 -12
            0 -23 29 -15 41 8 14 24 0 24 -22z m102 -21 c-15 -15 -27 14 -17 40 6 15 8 15
            18 -6 7 -16 7 -26 -1 -34z m39 -5 c-9 -15 -10 -13 -11 13 0 17 3 34 8 38 11
            12 14 -32 3 -51z m79 34 c0 -13 -7 -32 -15 -43 -12 -16 -16 -17 -24 -5 -9 15
            -5 37 12 59 15 19 27 14 27 -11z m1195 13 c3 -5 -1 -10 -9 -10 -9 0 -16 5 -16
            10 0 6 4 10 9 10 6 0 13 -4 16 -10z m766 -13 c-13 -13 -26 -3 -16 12 3 6 11 8
            17 5 6 -4 6 -10 -1 -17z m-1123 -5 c2 -7 -2 -10 -12 -6 -9 3 -16 11 -16 16 0
            13 23 5 28 -10z m772 -1 c10 -19 4 -51 -9 -51 -15 0 -31 25 -31 47 0 28 25 31
            40 4z m-1970 -6 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0
            10 -7 10 -15z m2090 5 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10
            6 0 10 -4 10 -10z m-476 -13 c25 -19 17 -52 -13 -52 -32 0 -49 28 -32 49 16
            19 22 20 45 3z m270 -3 c10 -25 -1 -29 -14 -5 -6 12 -7 21 -1 21 5 0 12 -7 15
            -16z m299 -4 c-3 -11 -7 -20 -9 -20 -2 0 -4 9 -4 20 0 11 4 20 9 20 5 0 7 -9
            4 -20z m-2143 -10 c0 -11 -4 -20 -10 -20 -5 0 -10 9 -10 20 0 11 5 20 10 20 6
            0 10 -9 10 -20z m2201 -23 c-8 -8 -11 -7 -11 4 0 9 3 19 7 23 4 4 9 2 11 -4 3
            -7 -1 -17 -7 -23z m56 1 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z
            m113 18 c0 -8 -4 -18 -10 -21 -5 -3 -10 3 -10 14 0 12 5 21 10 21 6 0 10 -6
            10 -14z m-2470 -22 c0 -8 -4 -14 -10 -14 -5 0 -10 9 -10 21 0 11 5 17 10 14 6
            -3 10 -13 10 -21z m660 11 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15
            10 15 6 0 10 -7 10 -15z m665 5 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2
            10 4 10 3 0 8 -4 11 -10z m1195 1 c0 -6 -4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9
            5 16 10 16 6 0 10 -4 10 -9z m-2490 -35 c-14 -14 -24 0 -16 21 6 15 8 15 16 3
            6 -9 5 -19 0 -24z m240 8 c0 -8 -4 -12 -10 -9 -5 3 -10 13 -10 21 0 8 5 12 10
            9 6 -3 10 -13 10 -21z m520 6 c0 -18 -3 -19 -20 -8 -14 9 -16 14 -7 20 20 13
            27 9 27 -12z m1284 5 c3 -8 1 -15 -4 -15 -6 0 -10 7 -10 15 0 8 2 15 4 15 2 0
            6 -7 10 -15z m346 3 c0 -17 -18 -44 -24 -37 -8 7 5 49 15 49 5 0 9 -6 9 -12z
            m-2340 -34 c0 -8 -4 -14 -10 -14 -5 0 -10 9 -10 21 0 11 5 17 10 14 6 -3 10
            -13 10 -21z m70 -5 c0 -11 -4 -17 -10 -14 -5 3 -10 13 -10 21 0 8 5 14 10 14
            6 0 10 -9 10 -21z m80 6 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10
            15 6 0 10 -7 10 -15z m985 1 c24 -18 13 -43 -19 -43 -20 0 -26 5 -26 22 0 36
            15 43 45 21z m545 5 c0 -14 -28 -33 -39 -27 -16 10 -1 36 20 36 10 0 19 -4 19
            -9z m234 -33 c-9 -14 -24 2 -24 25 1 21 1 21 15 3 8 -10 12 -23 9 -28z m540
            27 c-4 -8 -8 -15 -10 -15 -2 0 -4 7 -4 15 0 8 4 15 10 15 5 0 7 -7 4 -15z
            m-2422 -27 c-9 -9 -12 -7 -12 12 0 19 3 21 12 12 9 -9 9 -15 0 -24z m983 -19
            c-5 -16 -10 -18 -21 -8 -17 14 -18 46 -1 52 17 6 30 -19 22 -44z m741 19 c3
            -11 3 -22 0 -25 -9 -10 -36 18 -36 38 0 24 27 14 36 -13z m-1866 6 c0 -17 -22
            -14 -28 4 -2 7 3 12 12 12 9 0 16 -7 16 -16z m223 1 c4 -8 2 -17 -3 -20 -6 -4
            -10 3 -10 14 0 25 6 27 13 6z m112 6 c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0
            18 6 21 15 7z m155 -35 c-10 -28 -40 -34 -40 -7 0 27 30 57 41 40 4 -7 4 -22
            -1 -33z m1478 20 c5 -26 -16 -45 -28 -26 -5 8 -10 23 -10 33 0 24 34 18 38 -7z
            m190 7 c-5 -25 -28 -28 -28 -4 0 12 6 21 16 21 9 0 14 -7 12 -17z m60 6 c-2
            -6 -8 -10 -13 -10 -5 0 -11 4 -13 10 -2 6 4 11 13 11 9 0 15 -5 13 -11z
            m-1828 -35 c0 -23 -14 -32 -24 -16 -8 13 5 52 15 46 5 -3 9 -16 9 -30z m1054
            17 c11 -17 -6 -41 -29 -41 -30 0 -49 22 -33 38 14 14 54 16 62 3z m-1461 -24
            c3 -8 1 -20 -4 -28 -6 -10 -9 -7 -9 14 0 29 5 35 13 14z m102 2 c10 -15 -1
            -23 -20 -15 -9 3 -13 10 -10 16 8 13 22 13 30 -1z m915 -29 c0 -29 -28 -49
            -50 -35 -16 10 -12 53 7 63 26 15 43 3 43 -28z m1083 28 c6 -21 -4 -48 -19
            -48 -14 0 -20 41 -7 53 10 11 21 8 26 -5z m337 2 c0 -5 -4 -10 -10 -10 -5 0
            -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-2290 -27 c0 -22 -2 -25 -10
            -13 -13 20 -13 40 0 40 6 0 10 -12 10 -27z m37 5 c-3 -8 -6 -5 -6 6 -1 11 2
            17 5 13 3 -3 4 -12 1 -19z m113 -4 c0 -8 -4 -14 -9 -14 -11 0 -22 26 -14 34 9
            9 23 -3 23 -20z m396 18 c30 -20 18 -72 -16 -72 -24 0 -38 44 -20 65 14 17 20
            18 36 7z m1780 -17 c-3 -9 -11 -13 -16 -10 -8 5 -7 11 1 21 14 18 24 11 15
            -11z m-196 5 c0 -5 -7 -7 -15 -4 -8 4 -15 8 -15 10 0 2 7 4 15 4 8 0 15 -4 15
            -10z m70 -10 c0 -11 -4 -20 -10 -20 -5 0 -10 9 -10 20 0 11 5 20 10 20 6 0 10
            -9 10 -20z m53 0 c-3 -11 -9 -20 -14 -20 -5 0 -9 9 -9 20 0 11 6 20 14 20 9 0
            12 -7 9 -20z m-2183 -27 c0 -7 -4 -13 -10 -13 -5 0 -10 12 -10 28 0 21 2 24
            10 12 5 -8 10 -21 10 -27z m140 6 c0 -17 -4 -28 -10 -24 -5 3 -10 17 -10 31 0
            13 5 24 10 24 6 0 10 -14 10 -31z m186 -21 c-13 -18 -16 -19 -16 -5 0 9 5 17
            10 17 6 0 10 8 11 18 0 10 3 12 6 5 2 -7 -3 -23 -11 -35z m271 20 c-3 -8 -6
            -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m276 3 c2 -10 1 -25 -4 -32 -6 -10
            -9 -4 -9 19 0 36 5 42 13 13z m708 0 c23 -21 12 -35 -18 -24 -18 8 -28 43 -11
            43 5 0 18 -9 29 -19z m720 -18 c-9 -16 -10 -14 -11 12 0 21 3 26 11 18 8 -8 8
            -16 0 -30z m-367 18 c9 -14 -4 -41 -20 -41 -15 0 -17 10 -8 34 7 18 20 21 28
            7z m-944 -27 c0 -8 -9 -14 -20 -14 -19 0 -27 19 -14 33 10 9 34 -4 34 -19z
            m438 5 c3 -19 -21 -31 -43 -23 -8 4 -15 15 -15 26 0 15 6 19 27 16 16 -2 29
            -10 31 -19z m-1538 -22 c0 -5 -4 -5 -10 -2 -5 3 -10 14 -10 23 0 15 2 15 10 2
            5 -8 10 -19 10 -23z m517 11 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1
            -19z m685 10 c-9 -9 -15 -9 -24 0 -9 9 -7 12 12 12 19 0 21 -3 12 -12z m652
            -4 c11 -27 7 -37 -9 -24 -16 14 -20 40 -6 40 5 0 12 -7 15 -16z m71 -2 c0 -18
            -20 -15 -23 4 -3 10 1 15 10 12 7 -3 13 -10 13 -16z m255 4 c0 -16 -18 -31
            -27 -22 -8 8 5 36 17 36 5 0 10 -6 10 -14z m140 5 c0 -6 -4 -13 -10 -16 -5 -3
            -10 1 -10 9 0 9 5 16 10 16 6 0 10 -4 10 -9z m-2430 -27 c0 -31 -23 -28 -28 4
            -2 15 2 22 12 22 11 0 16 -9 16 -26z m2217 -11 c-17 -18 -22 -8 -11 22 5 12
            10 13 17 6 8 -8 6 -16 -6 -28z m253 28 c0 -6 -4 -13 -10 -16 -5 -3 -10 1 -10
            9 0 9 5 16 10 16 6 0 10 -4 10 -9z m17 -13 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13
            3 -3 4 -12 1 -19z m-624 -9 c-4 -15 -9 -17 -17 -9 -8 8 -7 13 4 20 8 5 16 10
            17 10 0 0 -1 -9 -4 -21z m485 0 c-2 -6 -8 -10 -13 -10 -5 0 -11 4 -13 10 -2 6
            4 11 13 11 9 0 15 -5 13 -11z m-908 -8 c0 -5 -9 -7 -20 -4 -11 3 -20 7 -20 9
            0 2 9 4 20 4 11 0 20 -4 20 -9z m-1040 -21 c0 -11 -4 -20 -10 -20 -5 0 -10 9
            -10 20 0 11 5 20 10 20 6 0 10 -9 10 -20z m1680 -1 c0 -11 -4 -17 -10 -14 -5
            3 -10 13 -10 21 0 8 5 14 10 14 6 0 10 -9 10 -21z m440 6 c0 -8 -4 -15 -10
            -15 -5 0 -7 7 -4 15 4 8 8 15 10 15 2 0 4 -7 4 -15z m-2350 -28 c0 -5 -4 -5
            -10 -2 -5 3 -10 14 -10 23 0 15 2 15 10 2 5 -8 10 -19 10 -23z m50 12 c0 -11
            -4 -17 -10 -14 -5 3 -10 13 -10 21 0 8 5 14 10 14 6 0 10 -9 10 -21z m83 -32
            c-3 -6 -12 -2 -20 8 -7 11 -13 15 -13 10 0 -5 -5 -4 -11 2 -6 6 -8 16 -4 23 5
            8 14 5 31 -11 13 -12 21 -26 17 -32z m2101 38 c-4 -8 -10 -15 -15 -15 -5 0 -9
            7 -9 15 0 8 7 15 15 15 9 0 12 -6 9 -15z m138 -7 c-7 -7 -12 -8 -12 -2 0 14
            12 26 19 19 2 -3 -1 -11 -7 -17z m-2192 -10 c0 -19 -2 -20 -10 -8 -13 19 -13
            30 0 30 6 0 10 -10 10 -22z m260 3 c0 -17 -18 -31 -41 -31 -5 0 -9 11 -9 25 0
            20 5 25 25 25 18 0 25 -5 25 -19z m1318 -29 c-2 -21 -8 -27 -28 -27 -18 0 -26
            6 -28 23 -5 34 15 54 40 41 13 -7 18 -19 16 -37z m-720 8 c-1 -36 -28 -66 -28
            -31 0 21 16 61 25 61 2 0 3 -14 3 -30z m142 -7 c0 -6 -7 -18 -15 -27 -31 -31
            -69 12 -39 45 13 15 20 16 35 6 10 -6 19 -17 19 -24z m780 27 c0 -5 -2 -10 -4
            -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m160 0 c0 -5 -4 -10
            -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-1202 -34 c2
            -10 -1 -23 -7 -29 -16 -16 -34 6 -26 33 8 24 28 22 33 -4z m-994 -2 c3 -8 2
            -12 -4 -9 -6 3 -10 10 -10 16 0 14 7 11 14 -7z m300 -27 c-9 -24 -24 -11 -24
            21 0 24 2 25 15 11 9 -9 13 -23 9 -32z m46 13 c0 -16 -4 -30 -10 -30 -11 0
            -14 43 -3 53 11 12 13 8 13 -23z m495 9 c9 -29 -7 -42 -30 -25 -11 8 -16 19
            -12 30 9 23 34 20 42 -5z m1385 7 c0 -15 -18 -31 -26 -23 -7 7 7 37 17 37 5 0
            9 -6 9 -14z m157 -18 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z
            m-2347 -4 c0 -8 -4 -14 -10 -14 -5 0 -10 9 -10 21 0 11 5 17 10 14 6 -3 10
            -13 10 -21z m388 -11 c-4 -43 -25 -55 -34 -19 -8 33 1 56 21 56 13 0 15 -9 13
            -37z m1009 21 c7 -18 -6 -45 -24 -51 -6 -2 -18 3 -27 12 -12 12 -13 21 -6 36
            13 23 48 25 57 3z m231 -6 c-4 -27 -23 -34 -32 -13 -8 21 1 35 20 35 10 0 14
            -8 12 -22z m-1408 -4 c0 -8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6
            0 10 -7 10 -16z m1790 -10 c0 -8 -4 -14 -10 -14 -5 0 -10 9 -10 21 0 11 5 17
            10 14 6 -3 10 -13 10 -21z m-1880 -15 c0 -21 -16 -26 -23 -6 -6 17 3 38 14 31
            5 -3 9 -14 9 -25z m-103 -11 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0
            -25z m586 13 c6 -24 -7 -46 -22 -37 -16 10 -14 56 3 56 7 0 16 -9 19 -19z
            m1697 15 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10
            -4z m-2237 -26 c3 -11 1 -20 -4 -20 -5 0 -9 9 -9 20 0 11 2 20 4 20 2 0 6 -9
            9 -20z m1384 -12 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m379
            -5 c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2 -7 1 -15 -2 -18z m72 3 c5 -22 -19
            -40 -30 -23 -4 7 -8 20 -8 30 0 25 34 18 38 -7z m65 18 c9 -10 -4 -34 -19 -34
            -8 0 -14 6 -14 13 0 22 19 35 33 21z m517 -4 c0 -5 -5 -10 -11 -10 -5 0 -7 5
            -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-2280 -36 c0 -13 -4 -24 -9 -24 -13 0
            -24 37 -16 50 10 16 25 1 25 -26z m60 0 c0 -8 -6 -11 -15 -8 -8 4 -15 12 -15
            20 0 8 6 11 15 8 8 -4 15 -12 15 -20z m860 9 c0 -10 -4 -14 -8 -8 -4 6 -11 13
            -16 18 -5 4 -2 7 7 7 10 0 17 -8 17 -17z m1090 13 c0 -3 -4 -8 -10 -11 -5 -3
            -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-1698 -58 c-14 -14 -32 -1 -32
            21 0 28 12 33 28 10 10 -14 11 -24 4 -31z m1531 27 c-3 -8 -9 -15 -14 -15 -10
            0 -12 24 -3 33 11 11 24 -3 17 -18z m-803 5 c0 -5 -4 -10 -9 -10 -6 0 -13 5
            -16 10 -3 6 1 10 9 10 9 0 16 -4 16 -10z m870 1 c0 -6 -4 -13 -10 -16 -5 -3
            -10 1 -10 9 0 9 5 16 10 16 6 0 10 -4 10 -9z m-1127 -27 c-8 -21 -23 -17 -23
            6 0 13 5 18 14 14 8 -3 12 -12 9 -20z m727 -15 c0 -5 -4 -9 -10 -9 -5 0 -10 7
            -10 16 0 8 5 12 10 9 6 -3 10 -10 10 -16z m-1580 -5 c0 -8 -4 -12 -10 -9 -5 3
            -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10 -16z m1825 6 c3 -5 -1 -20 -9 -32
            -14 -19 -16 -19 -22 -5 -12 32 15 64 31 37z m-1937 -25 c-3 -3 -9 2 -12 12 -6
            14 -5 15 5 6 7 -7 10 -15 7 -18z m37 15 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10
            0 6 2 10 4 10 3 0 8 -4 11 -10z m1493 -17 c4 -24 -22 -47 -43 -39 -20 7 -19
            43 3 55 23 14 36 9 40 -16z m246 21 c17 -6 23 -34 8 -34 -5 0 -17 9 -27 20
            -18 20 -11 25 19 14z m338 -16 c-7 -7 -12 -8 -12 -2 0 14 12 26 19 19 2 -3 -1
            -11 -7 -17z m-1869 -19 c13 -9 0 -42 -13 -34 -5 3 -10 21 -9 38 0 18 3 25 6
            17 3 -8 10 -18 16 -21z m140 6 c-3 -8 -9 -15 -14 -15 -10 0 -12 24 -3 33 11
            11 24 -3 17 -18z m28 -8 c-8 -8 -11 -7 -11 4 0 20 13 34 18 19 3 -7 -1 -17 -7
            -23z m472 10 c5 -15 -10 -57 -20 -57 -18 0 -34 40 -23 60 11 20 35 18 43 -3z
            m247 19 c0 -2 3 -11 6 -19 7 -19 -27 -32 -38 -15 -14 22 -9 38 12 38 11 0 20
            -2 20 -4z m1061 -39 c-8 -8 -11 -7 -11 4 0 9 3 19 7 23 4 4 9 2 11 -4 3 -7 -1
            -17 -7 -23z m-2301 8 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15
            6 0 10 -7 10 -15z m426 -12 c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2 -7 1 -15
            -2 -18z m419 -3 c0 -32 -30 -39 -45 -10 -14 26 -4 42 23 38 16 -2 22 -10 22
            -28z m1135 20 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10
            -4 10 -10z m188 -11 c-2 -6 -8 -10 -13 -10 -5 0 -11 4 -13 10 -2 6 4 11 13 11
            9 0 15 -5 13 -11z m292 1 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10
            10 6 0 10 -4 10 -10z m-2190 -32 c-1 -28 -2 -30 -11 -15 -6 10 -8 25 -5 33 9
            24 16 16 16 -18z m1124 26 c19 -7 21 -28 3 -43 -19 -15 -53 10 -44 33 7 17 16
            20 41 10z m-1304 -30 c0 -8 -4 -12 -10 -9 -5 3 -10 13 -10 21 0 8 5 12 10 9 6
            -3 10 -13 10 -21z m120 11 c0 -8 -2 -15 -4 -15 -2 0 -6 7 -10 15 -3 8 -1 15 4
            15 6 0 10 -7 10 -15z m1880 1 c0 -8 -4 -18 -10 -21 -5 -3 -10 3 -10 14 0 12 5
            21 10 21 6 0 10 -6 10 -14z m-1770 -37 c0 -19 -5 -29 -15 -29 -15 0 -20 26 -9
            54 9 24 24 9 24 -25z m732 -1 c-9 -9 -12 -7 -12 12 0 19 3 21 12 12 9 -9 9
            -15 0 -24z m-1108 6 c11 -29 6 -44 -14 -44 -21 0 -40 27 -33 48 7 17 40 15 47
            -4z m111 7 c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z m335 -15 c0
            -29 -19 -44 -30 -25 -9 14 5 49 20 49 6 0 10 -11 10 -24z m254 18 c20 -8 21
            -60 1 -68 -17 -6 -35 22 -35 53 0 22 8 25 34 15z m1152 -37 c-3 -26 -8 -32
            -30 -34 -20 -1 -26 3 -26 18 0 34 18 58 40 52 15 -4 19 -12 16 -36z m410 -3
            c-4 -9 -9 -15 -11 -12 -3 3 -3 13 1 22 4 9 9 15 11 12 3 -3 3 -13 -1 -22z
            m-2416 -9 c0 -8 -4 -15 -9 -15 -13 0 -22 16 -14 24 11 11 23 6 23 -9z m700 5
            c31 -17 37 -90 6 -90 -13 0 -36 54 -36 83 0 20 4 21 30 7z m760 0 c0 -5 -4
            -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m670 -21 c0
            -10 -7 -19 -15 -19 -15 0 -21 31 -9 43 11 10 24 -3 24 -24z m78 3 c2 -13 -3
            -25 -13 -28 -23 -9 -34 7 -24 34 11 28 33 24 37 -6z m-1418 -25 c0 -5 -4 -5
            -10 -2 -5 3 -10 14 -10 23 0 15 2 15 10 2 5 -8 10 -19 10 -23z m-553 1 c-3 -8
            -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m73 -31 c0 -33 -25 -12 -28 24
            -2 32 -1 33 13 15 8 -11 15 -28 15 -39z m30 28 c0 -14 -2 -25 -4 -25 -2 0 -6
            11 -8 25 -3 14 -1 25 3 25 5 0 9 -11 9 -25z m213 -6 c3 -13 1 -19 -6 -16 -15
            5 -22 37 -9 37 5 0 12 -9 15 -21z m854 15 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6
            11 1 17 -2 13 -5z m-911 -31 c-7 -7 -26 7 -26 19 0 6 6 6 15 -2 9 -7 13 -15
            11 -17z m638 11 c9 -22 8 -24 -9 -24 -8 0 -15 9 -15 20 0 24 15 27 24 4z m74
            -11 c4 -24 -24 -39 -32 -17 -7 19 4 46 18 41 6 -2 12 -13 14 -24z m1580 4 c2
            -10 -3 -17 -12 -17 -10 0 -16 9 -16 21 0 24 23 21 28 -4z m-2418 -20 c0 -12
            -5 -29 -10 -37 -7 -11 -10 -3 -10 28 0 26 4 41 10 37 6 -3 10 -16 10 -28z
            m1410 12 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12 10 9 6 -3 10 -10 10
            -16z m-736 -6 c4 -9 2 -21 -3 -26 -12 -12 -44 6 -47 27 -3 22 42 21 50 -1z
            m298 2 c0 -5 -5 -11 -11 -13 -6 -2 -11 4 -11 13 0 9 5 15 11 13 6 -2 11 -8 11
            -13z m-1472 -5 c0 -5 -9 -14 -21 -20 -19 -11 -20 -10 -9 9 11 22 30 29 30 11z
            m136 -17 c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2 -7 1 -15 -2 -18z m102 1 c2
            -11 -4 -26 -12 -33 -12 -10 -17 -8 -26 9 -6 12 -9 28 -6 37 8 21 40 12 44 -13z
            m2102 7 c0 -13 -29 -35 -37 -28 -7 8 15 37 28 37 5 0 9 -4 9 -9z m-1997 -14
            c3 -8 1 -20 -4 -28 -11 -19 -34 3 -26 25 8 20 23 21 30 3z m217 5 c0 -5 -10
            -19 -21 -31 -12 -13 -28 -42 -35 -65 -7 -22 -13 -32 -13 -21 -1 32 25 97 45
            112 21 15 24 16 24 5z m1841 -5 c-1 -4 -1 -14 -1 -22 0 -8 -7 -15 -15 -15 -16
            0 -19 9 -9 35 6 15 26 17 25 2z m-991 -18 c0 -6 -4 -7 -10 -4 -5 3 -10 11 -10
            16 0 6 5 7 10 4 6 -3 10 -11 10 -16z m785 -37 c-11 -11 -25 15 -19 33 7 17 8
            16 16 -5 5 -13 6 -25 3 -28z m-1775 12 c0 -8 -4 -12 -10 -9 -5 3 -10 13 -10
            21 0 8 5 12 10 9 6 -3 10 -13 10 -21z m1250 -3 c0 -33 -11 -44 -34 -35 -18 7
            -30 39 -21 54 3 5 17 10 31 10 20 0 24 -5 24 -29z m1184 7 c7 -30 -6 -41 -29
            -24 -11 8 -16 19 -12 30 9 23 34 20 41 -6z m-2186 -21 c-4 -44 -28 -47 -28 -4
            0 24 5 34 16 34 11 0 14 -9 12 -30z m62 3 c0 -22 -16 -35 -25 -20 -9 14 4 52
            16 45 5 -4 9 -15 9 -25z m40 -11 c0 -18 -4 -28 -10 -24 -5 3 -10 20 -10 36 0
            18 4 28 10 24 6 -3 10 -20 10 -36z m-690 21 c20 -13 2 -44 -29 -48 -24 -4 -30
            25 -9 46 14 14 18 15 38 2z m903 -15 c-7 -21 -13 -19 -13 6 0 11 4 18 10 14 5
            -3 7 -12 3 -20z m327 15 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16
            10 5 0 9 -4 9 -10z m-1410 -14 c0 -8 -4 -17 -9 -21 -12 -7 -24 12 -16 25 9 15
            25 12 25 -4z m278 -5 c3 -8 -1 -12 -9 -9 -7 2 -15 10 -17 17 -3 8 1 12 9 9 7
            -2 15 -10 17 -17z m1362 9 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10
            10 10 6 0 10 -4 10 -10z m-1576 -15 c-4 -8 -10 -15 -15 -15 -4 0 -6 7 -3 15 4
            8 10 15 15 15 4 0 6 -7 3 -15z m974 -3 c2 -7 -2 -10 -12 -6 -9 3 -16 11 -16
            16 0 13 23 5 28 -10z m2069 1 c4 -11 -26 -27 -35 -19 -2 3 -2 12 2 21 6 18 27
            16 33 -2z m-2709 -16 c2 -12 -3 -17 -17 -17 -15 0 -21 6 -21 21 0 25 33 22 38
            -4z m177 -34 c-6 -16 -8 -16 -16 5 -4 12 -5 30 -2 39 6 15 8 14 16 -5 5 -11 6
            -29 2 -39z m895 48 c0 -6 -4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16 6
            0 10 -4 10 -9z m950 -7 c0 -17 -22 -14 -28 4 -2 7 3 12 12 12 9 0 16 -7 16
            -16z m-850 -4 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10
            -4 10 -10z m945 -11 c9 -27 -19 -59 -36 -42 -15 15 -3 63 16 63 7 0 17 -10 20
            -21z m42 -11 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m271 10
            c-4 -29 -38 -38 -38 -10 0 19 11 29 33 31 5 1 7 -9 5 -21z m-2153 -26 c0 -46
            -19 -44 -23 2 -3 28 0 37 10 34 7 -3 13 -19 13 -36z m1950 28 c-3 -5 -10 -10
            -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8 0 12 -4 9 -10z m-2149 -15 c4 -8 3
            -22 0 -30 -8 -22 -46 -1 -46 25 0 24 38 28 46 5z m1644 -21 c0 -9 -6 -22 -14
            -30 -13 -12 -17 -12 -30 2 -19 18 -20 36 -4 52 14 14 48 -2 48 -24z m224 21
            c3 -8 1 -15 -4 -15 -6 0 -10 7 -10 15 0 8 2 15 4 15 2 0 6 -7 10 -15z m-2008
            -20 c11 -27 -1 -39 -19 -21 -17 17 -15 36 4 36 5 0 12 -7 15 -15z m191 -17
            c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m828 -3 c0 -24 -5 -31
            -23 -33 -27 -4 -38 19 -22 48 17 31 45 22 45 -15z m1653 13 c-2 -13 -11 -24
            -21 -26 -22 -4 -35 27 -16 39 25 16 41 10 37 -13z m192 7 c0 -8 -7 -15 -15
            -15 -26 0 -18 20 13 29 1 1 2 -6 2 -14z m-3290 -10 c0 -8 -7 -15 -15 -15 -26
            0 -18 20 13 29 1 1 2 -6 2 -14z m200 -1 c0 -8 -4 -12 -10 -9 -5 3 -10 10 -10
            16 0 5 5 9 10 9 6 0 10 -7 10 -16z m140 -4 c0 -13 -7 -20 -20 -20 -13 0 -20 7
            -20 20 0 13 7 20 20 20 13 0 20 -7 20 -20z m-206 -13 c8 -13 7 -20 -4 -27 -16
            -10 -30 4 -30 33 0 22 19 18 34 -6z m595 -24 c1 -7 -3 -13 -9 -13 -5 0 -10 15
            -9 33 0 27 2 29 9 12 5 -11 9 -26 9 -32z m1561 37 c0 -5 -2 -10 -4 -10 -3 0
            -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m447 -12 c-3 -8 -6 -5 -6 6 -1
            11 2 17 5 13 3 -3 4 -12 1 -19z m253 12 c0 -5 -9 -14 -20 -20 -22 -12 -27 -1
            -8 18 14 14 28 16 28 2z m-1202 -11 c-2 -6 -8 -10 -13 -10 -5 0 -11 4 -13 10
            -2 6 4 11 13 11 9 0 15 -5 13 -11z m-1733 -18 c-3 -6 -11 -8 -17 -5 -6 4 -5 9
            3 15 16 10 23 4 14 -10z m1835 -1 c0 -11 -7 -20 -15 -20 -15 0 -21 21 -8 33
            12 13 23 7 23 -13z m940 -5 c-7 -9 -15 -13 -18 -10 -3 2 1 11 8 20 7 9 15 13
            18 10 3 -2 -1 -11 -8 -20z m-1990 0 c0 -9 -8 -19 -17 -22 -10 -4 -21 -11 -25
            -17 -5 -8 -8 -7 -8 1 0 10 38 53 47 53 2 0 3 -7 3 -15z m-863 -27 c-3 -7 -5
            -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m3243 4 c0 -13 -23 -32 -37 -32
            -13 0 -18 41 -7 53 8 7 44 -10 44 -21z m-2719 2 c10 -13 10 -17 -1 -24 -18
            -11 -41 7 -33 26 7 18 17 18 34 -2z m201 -50 c-8 -15 -11 -12 -17 14 -3 17 -3
            37 1 44 12 19 27 -38 16 -58z m248 46 c0 -11 -2 -20 -4 -20 -2 0 -6 9 -9 20
            -3 11 -1 20 4 20 5 0 9 -9 9 -20z m380 10 c0 -5 -4 -10 -9 -10 -6 0 -13 5 -16
            10 -3 6 1 10 9 10 9 0 16 -4 16 -10z m1044 -5 c8 -22 -11 -65 -29 -65 -18 0
            -20 33 -3 60 15 24 24 25 32 5z m319 -31 c-3 -8 -11 -14 -18 -14 -19 0 -27 30
            -13 47 10 13 14 13 24 -2 7 -9 10 -23 7 -31z m-2415 12 c3 -13 -1 -17 -14 -14
            -11 2 -20 11 -22 22 -3 13 1 17 14 14 11 -2 20 -11 22 -22z m150 6 c2 -7 -3
            -12 -12 -12 -9 0 -16 7 -16 16 0 17 22 14 28 -4z m166 9 c8 -13 -4 -42 -15
            -35 -12 7 -12 44 0 44 6 0 12 -4 15 -9z m490 -13 c11 -41 7 -58 -14 -58 -27 0
            -44 34 -30 60 14 27 36 25 44 -2z m76 7 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10
            15 0 8 5 15 10 15 6 0 10 -7 10 -15z m2200 5 c0 -5 -4 -10 -10 -10 -5 0 -10 5
            -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-1793 -32 c-3 -7 -5 -2 -5 12 0 14
            2 19 5 13 2 -7 2 -19 0 -25z m-1367 -18 c0 -13 -1 -13 -10 0 -5 8 -10 22 -10
            30 0 13 1 13 10 0 5 -8 10 -22 10 -30z m800 24 c0 -8 -5 -12 -10 -9 -6 4 -8
            11 -5 16 9 14 15 11 15 -7z m1564 0 c5 -14 4 -15 -9 -4 -17 14 -19 20 -6 20 5
            0 12 -7 15 -16z m524 4 c-3 -7 -12 -14 -21 -16 -12 -2 -15 1 -11 12 7 19 38
            22 32 4z m-2978 -13 c0 -8 -4 -15 -9 -15 -13 0 -22 16 -14 24 11 11 23 6 23
            -9z m565 -6 c4 -11 4 -23 0 -27 -7 -7 -25 17 -25 36 0 20 18 14 25 -9z m235
            -24 c0 -42 -16 -54 -31 -22 -8 18 -8 30 0 46 16 29 31 17 31 -24z m-860 10
            c19 -22 -5 -48 -31 -34 -30 15 -22 44 12 48 4 1 12 -6 19 -14z m348 -8 c2 -9
            -4 -20 -13 -23 -20 -8 -50 19 -40 36 10 17 49 8 53 -13z m382 17 c0 -3 -13
            -22 -29 -42 -15 -20 -35 -55 -44 -77 -16 -39 -16 -39 -16 -10 -1 31 60 135 79
            135 6 0 10 -3 10 -6z m1007 -10 c9 -23 -11 -38 -26 -20 -13 16 -7 36 10 36 5
            0 12 -7 16 -16z m681 -21 c-2 -20 -8 -38 -15 -40 -18 -6 -28 44 -13 62 21 25
            31 17 28 -22z m282 20 c0 -23 -17 -49 -26 -41 -9 10 4 58 16 58 6 0 10 -8 10
            -17z m230 -8 c0 -20 -5 -25 -25 -25 -18 0 -25 5 -25 18 0 10 3 22 7 25 3 4 15
            7 25 7 13 0 18 -7 18 -25z m-2420 -10 c0 -8 -7 -15 -15 -15 -16 0 -20 12 -8
            23 11 12 23 8 23 -8z m890 5 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10
            4 10 6 0 11 -4 11 -10z m1986 -28 c-10 -10 -29 15 -21 28 6 9 10 8 17 -4 6
            -10 7 -20 4 -24z m-3470 -6 c1 -9 -4 -12 -12 -9 -8 3 -14 15 -14 26 0 16 3 18
            12 9 6 -6 13 -18 14 -26z m994 7 c0 -20 -3 -24 -11 -16 -7 7 -9 19 -6 27 10
            24 17 19 17 -11z m420 18 c0 -18 -18 -21 -30 -6 -10 13 -9 15 9 15 11 0 21 -4
            21 -9z m552 3 c9 -9 -3 -44 -16 -44 -22 0 -39 18 -33 34 6 15 38 22 49 10z
            m-1774 -27 c2 -12 -3 -17 -17 -17 -12 0 -21 6 -21 13 0 31 32 34 38 4z m2688
            -12 c-7 -18 -26 -20 -26 -2 0 18 24 41 28 27 2 -6 1 -17 -2 -25z m304 4 c0
            -12 -7 -19 -20 -19 -22 0 -26 20 -8 38 15 15 28 6 28 -19z m-2867 -5 c-4 -10
            -10 -11 -24 -3 -24 12 -16 29 10 22 12 -3 17 -11 14 -19z m2486 -18 c-9 -8
            -10 -7 -5 7 3 10 7 24 7 30 1 7 3 4 6 -7 3 -10 -1 -23 -8 -30z m561 20 c0 -24
            -23 -41 -41 -30 -16 9 -10 42 9 47 30 8 32 7 32 -17z m-3010 4 c0 -5 -4 -10
            -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m334 -5 c8 -22
            -4 -47 -21 -41 -8 3 -17 6 -19 6 -2 0 -4 11 -4 25 0 18 5 25 19 25 10 0 21 -7
            25 -15z m180 -1 c11 -28 6 -64 -8 -64 -18 0 -27 26 -20 56 7 28 19 31 28 8z
            m-624 -14 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4
            -10z m260 -11 c0 -25 -33 -22 -38 4 -2 12 3 17 17 17 15 0 21 -6 21 -21z
            m2440 1 c0 -11 -7 -20 -15 -20 -27 0 -18 30 13 39 1 1 2 -8 2 -19z m-2530 -6
            c0 -17 -22 -14 -28 4 -2 7 3 12 12 12 9 0 16 -7 16 -16z m2150 6 c0 -5 -4 -10
            -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-1986 -8 c2 -4
            1 -14 -4 -22 -7 -11 -12 -12 -21 -3 -6 6 -8 16 -5 22 8 13 23 14 30 3z m1206
            -13 c0 -5 -7 -9 -15 -9 -15 0 -20 12 -9 23 8 8 24 -1 24 -14z m940 0 c0 -6 -4
            -7 -10 -4 -5 3 -10 11 -10 16 0 6 5 7 10 4 6 -3 10 -11 10 -16z m306 -8 c-8
            -13 -36 -15 -36 -3 0 4 5 13 12 20 14 14 34 0 24 -17z m64 19 c0 -5 -5 -10
            -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m445 1 c3 -5 1 -12
            -5 -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z m-3605 -22 c0 -10 -5 -21 -11 -25
            -14 -9 -33 11 -26 29 7 20 37 17 37 -4z m1563 -15 c-8 -21 -43 -18 -43 5 0 11
            6 21 13 24 17 6 37 -12 30 -29z m725 14 c-4 -29 -38 -38 -38 -10 0 22 8 32 27
            32 9 0 13 -8 11 -22z m-2150 -5 c-2 -10 -10 -18 -18 -18 -8 0 -16 8 -18 18 -2
            12 3 17 18 17 15 0 20 -5 18 -17z m1290 -16 c3 -25 0 -28 -20 -25 -17 2 -24
            11 -26 31 -3 25 0 28 20 25 17 -2 24 -11 26 -31z m2112 18 c0 -8 -4 -15 -10
            -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m-3620 -16 c0 -5 -4
            -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12 10 9 6 -3 10 -10 10 -16z m498 5 c2 -1
            2 -10 0 -20 -3 -16 -4 -17 -20 -1 -9 9 -24 17 -33 17 -9 0 -13 5 -10 10 5 8
            52 4 63 -6z m2186 -8 c-6 -16 -24 -23 -24 -8 0 10 22 33 27 28 2 -2 1 -11 -3
            -20z m-2299 -11 c0 -25 -40 -33 -49 -10 -9 24 2 37 27 33 14 -2 22 -10 22 -23z
            m1660 15 c3 -5 -3 -10 -15 -10 -12 0 -18 5 -15 10 3 6 10 10 15 10 5 0 12 -4
            15 -10z m945 1 c0 -5 -7 -11 -15 -15 -15 -5 -20 5 -8 17 9 10 23 9 23 -2z
            m-3080 -11 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4
            10 -10z m381 -23 c-16 -16 -26 0 -10 19 9 11 15 12 17 4 2 -6 -1 -17 -7 -23z
            m569 17 c0 -8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10
            -16z m88 -26 c4 -46 -23 -61 -32 -17 -3 16 -3 36 1 44 10 26 28 11 31 -27z
            m1526 25 c9 -22 -10 -41 -25 -26 -13 13 -5 43 10 43 5 0 12 -8 15 -17z m786 2
            c16 -19 -3 -45 -31 -45 -21 0 -24 12 -9 41 13 23 23 24 40 4z m167 -1 c8 -21
            -13 -42 -28 -27 -13 13 -5 43 11 43 6 0 13 -7 17 -16z m-2629 -12 c2 -7 -3
            -12 -12 -12 -9 0 -16 7 -16 16 0 17 22 14 28 -4z m2282 2 c0 -2 -7 -4 -15 -4
            -8 0 -15 4 -15 10 0 5 7 7 15 4 8 -4 15 -8 15 -10z m260 7 c0 -12 -20 -25 -27
            -18 -7 7 6 27 18 27 5 0 9 -4 9 -9z m205 -10 c-8 -13 -35 -15 -35 -2 0 5 8 11
            18 13 21 5 26 2 17 -11z m-2965 -12 c0 -11 -4 -17 -10 -14 -5 3 -10 13 -10 21
            0 8 5 14 10 14 6 0 10 -9 10 -21z m646 -13 c3 -18 0 -26 -10 -26 -19 0 -29 30
            -15 47 14 17 18 13 25 -21z m98 18 c7 -18 0 -34 -15 -34 -5 0 -9 11 -9 25 0
            28 15 34 24 9z m1466 -20 c0 -27 -25 -43 -42 -26 -8 8 -8 18 0 37 14 31 42 23
            42 -11z m-2140 6 c0 -13 -5 -18 -14 -14 -8 3 -12 12 -9 20 8 21 23 17 23 -6z
            m480 -10 c0 -10 -4 -22 -10 -25 -6 -4 -10 7 -10 25 0 18 4 29 10 25 6 -3 10
            -15 10 -25z m1070 20 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10
            6 0 10 -4 10 -10z m-1455 -21 c8 -24 7 -26 -20 -31 -15 -2 -21 3 -23 25 -3 21
            1 27 17 27 11 0 22 -9 26 -21z m2269 -16 c-15 -24 -34 -28 -34 -6 0 26 21 46
            34 33 7 -7 7 -16 0 -27z m136 17 c0 -11 -7 -20 -15 -20 -8 0 -15 9 -15 20 0
            11 7 20 15 20 8 0 15 -9 15 -20z m610 5 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10
            15 0 8 5 15 10 15 6 0 10 -7 10 -15z m-3733 -17 c-3 -8 -6 -5 -6 6 -1 11 2 17
            5 13 3 -3 4 -12 1 -19z m103 7 c0 -8 -9 -15 -20 -15 -11 0 -20 7 -20 15 0 8 9
            15 20 15 11 0 20 -7 20 -15z m101 -1 c10 -13 10 -17 -2 -25 -8 -5 -17 -9 -20
            -9 -13 0 -31 29 -24 40 9 15 31 12 46 -6z m1736 -28 c-7 -18 -42 -22 -52 -5
            -4 5 1 19 11 30 15 17 19 18 32 5 8 -8 12 -22 9 -30z m593 24 c0 -21 -15 -27
            -25 -10 -7 12 2 30 16 30 5 0 9 -9 9 -20z m-2720 6 c0 -3 -4 -8 -10 -11 -5 -3
            -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m610 -5 c0 -5 -7 -12 -16 -15
            -22 -9 -24 -8 -24 9 0 8 9 15 20 15 11 0 20 -4 20 -9z m454 -61 c4 -33 3 -49
            -3 -40 -12 21 -23 117 -12 105 5 -6 11 -35 15 -65z m176 49 c0 -5 -7 -9 -15
            -9 -15 0 -20 12 -9 23 8 8 24 -1 24 -14z m2574 5 c10 -26 7 -34 -13 -34 -23 0
            -35 15 -27 35 7 20 32 19 40 -1z m-3678 -9 c3 -8 3 -19 0 -24 -10 -16 -36 -1
            -36 20 0 22 28 26 36 4z m881 -1 c8 -21 -13 -42 -28 -27 -13 13 -5 43 11 43 6
            0 13 -7 17 -16z m873 7 c0 -6 -4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10
            16 6 0 10 -4 10 -9z m-1385 -12 c10 -15 -1 -23 -20 -15 -9 3 -13 10 -10 16 8
            13 22 13 30 -1z m153 -11 c-2 -12 -9 -23 -15 -26 -19 -6 -29 28 -12 39 23 14
            31 10 27 -13z m2856 7 c3 -9 0 -15 -9 -15 -16 0 -27 14 -18 24 10 10 21 7 27
            -9z m-3454 -21 c0 -8 -4 -12 -10 -9 -5 3 -10 13 -10 21 0 8 5 12 10 9 6 -3 10
            -13 10 -21z m2218 5 c15 -24 1 -49 -29 -49 -24 0 -32 16 -25 46 8 30 36 32 54
            3z m1152 6 c-7 -9 -15 -13 -17 -11 -7 7 7 26 19 26 6 0 6 -6 -2 -15z m263 9
            c9 -9 -4 -34 -18 -34 -14 0 -27 25 -18 34 4 3 12 6 18 6 6 0 14 -3 18 -6z
            m-2498 -14 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8 0 12 -4
            9 -10z m1826 -33 c-8 -8 -11 -5 -11 9 0 27 14 41 18 19 2 -9 -1 -22 -7 -28z
            m224 34 c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z m346 -4 c-1
            -12 -15 -9 -19 4 -3 6 1 10 8 8 6 -3 11 -8 11 -12z m-801 -22 c0 -16 -6 -25
            -15 -25 -18 0 -20 21 -3 38 17 17 18 16 18 -13z m-2020 1 c0 -15 -24 -28 -34
            -19 -13 14 -5 33 14 33 11 0 20 -6 20 -14z m-325 -6 c3 -5 2 -10 -4 -10 -5 0
            -13 5 -16 10 -3 6 -2 10 4 10 5 0 13 -4 16 -10z m155 0 c0 -5 -4 -10 -9 -10
            -6 0 -13 5 -16 10 -3 6 1 10 9 10 9 0 16 -4 16 -10z m2760 -5 c0 -8 -7 -15
            -15 -15 -8 0 -15 7 -15 15 0 8 7 15 15 15 8 0 15 -7 15 -15z m-3347 -15 c6
            -23 -10 -37 -25 -22 -12 12 -4 42 11 42 5 0 11 -9 14 -20z m294 -2 c-3 -8 -6
            -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m543 -4 c0 -8 -6 -14 -13 -14 -19
            0 -27 10 -20 25 6 17 33 8 33 -11z m2685 5 c-4 -5 -13 -9 -22 -9 -13 0 -13 3
            -3 15 13 15 35 11 25 -6z m-3335 -15 c0 -16 -16 -19 -25 -4 -8 13 4 32 16 25
            5 -4 9 -13 9 -21z m350 17 c0 -5 -7 -14 -15 -21 -18 -15 -42 1 -31 19 8 13 46
            15 46 2z m383 -5 c6 -16 -16 -40 -26 -29 -4 3 -7 15 -7 25 0 21 25 24 33 4z
            m2257 -1 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10
            -15z m430 -21 c0 -19 -5 -24 -25 -24 -32 0 -44 27 -19 44 24 18 44 9 44 -20z
            m207 22 c-5 -16 -27 -19 -27 -3 0 7 7 14 15 14 9 0 14 -5 12 -11z m183 -8 c0
            -10 -4 -18 -10 -18 -5 0 -10 2 -10 4 0 2 -2 11 -5 19 -4 8 0 14 10 14 8 0 15
            -9 15 -19z m-2186 -10 c13 -22 -5 -44 -28 -35 -22 9 -22 60 0 55 9 -2 22 -11
            28 -20z m-1424 -3 c0 -8 -7 -15 -15 -15 -16 0 -20 12 -8 23 11 12 23 8 23 -8z
            m602 3 c-9 -9 -15 -9 -24 0 -9 9 -7 12 12 12 19 0 21 -3 12 -12z m334 -15 c-4
            -4 4 -38 20 -78 5 -14 1 -12 -15 7 -12 15 -25 45 -28 65 -6 35 -5 37 11 24 9
            -8 15 -16 12 -18z m1242 1 c3 -23 -21 -49 -38 -39 -5 3 -10 20 -10 36 0 26 3
            30 23 27 13 -2 23 -11 25 -24z m-899 2 c15 -18 -2 -56 -24 -56 -16 0 -31 43
            -20 59 9 15 30 14 44 -3z m1441 -13 c0 -20 -3 -24 -11 -16 -7 7 -9 19 -6 27
            10 24 17 19 17 -11z m-3035 7 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7
            10 16 10 8 0 12 -4 9 -10z m535 -6 c0 -9 -7 -14 -17 -12 -25 5 -28 28 -4 28
            12 0 21 -6 21 -16z m140 7 c0 -14 -18 -23 -30 -16 -6 4 -8 11 -5 16 8 12 35
            12 35 0z m510 -42 c0 -24 -4 -38 -10 -34 -5 3 -10 24 -10 46 0 24 4 38 10 34
            6 -3 10 -24 10 -46z m2621 34 c7 -7 5 -15 -6 -27 -20 -20 -35 -12 -35 19 0 24
            21 28 41 8z m-2461 -33 c0 -22 -4 -40 -8 -40 -28 0 -44 63 -19 73 24 10 27 7
            27 -33z m2075 9 c-11 -17 -35 -9 -35 11 0 18 3 19 20 10 11 -6 18 -15 15 -21z
            m259 -2 c-3 -9 -12 -17 -20 -17 -17 0 -19 37 -1 43 18 7 29 -7 21 -26z m-3439
            13 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z
            m-475 -10 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4
            -10z m988 1 c21 -13 10 -46 -16 -49 -24 -4 -38 18 -28 43 6 17 24 19 44 6z
            m362 -42 c0 -21 -4 -27 -15 -23 -17 7 -30 48 -20 64 11 18 35 -9 35 -41z
            m1450 41 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z
            m620 -5 c0 -9 -7 -18 -16 -22 -18 -7 -39 11 -30 26 11 17 46 13 46 -4z m-3080
            -16 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12 10 9 6 -3 10 -10 10 -16z
            m1037 -11 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m2197 24 c2
            -4 1 -14 -3 -20 -10 -16 -41 -5 -41 14 0 15 35 20 44 6z m256 -7 c0 -9 -9 -15
            -21 -15 -18 0 -19 2 -9 15 7 8 16 15 21 15 5 0 9 -7 9 -15z m-3390 -15 c0 -15
            -28 -30 -44 -24 -19 7 -20 16 -5 31 11 11 49 6 49 -7z m165 0 c0 -8 -8 -16
            -17 -18 -13 -2 -18 3 -18 18 0 15 5 20 18 18 9 -2 17 -10 17 -18z m185 10 c0
            -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m1720
            0 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9 -4 9 -10z
            m970 -22 c0 -21 -3 -29 -10 -22 -5 5 -7 19 -4 32 9 31 14 27 14 -10z m624 17
            c-4 -8 -11 -12 -16 -9 -6 4 -5 10 3 15 19 12 19 11 13 -6z m-4012 -19 c12 -22
            5 -30 -21 -21 -25 8 -29 44 -5 41 9 -1 21 -10 26 -20z m185 8 c9 -24 -19 -37
            -34 -17 -18 24 -16 33 7 33 11 0 23 -7 27 -16z m578 5 c4 -6 13 -8 21 -5 8 3
            14 1 14 -4 0 -6 -18 -10 -40 -10 -38 0 -54 13 -27 23 19 8 25 8 32 -4z m325
            -8 c0 -27 -13 -33 -35 -17 -21 15 -12 36 16 36 12 0 19 -7 19 -19z m88 -20 c3
            -27 0 -32 -15 -29 -19 3 -28 30 -19 54 10 26 31 10 34 -25z m2697 30 c3 -5 1
            -12 -5 -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z m-3445 -16 c0 -8 -4 -15 -10
            -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m198 -7 c-3 -25 -25
            -33 -47 -19 -21 14 -5 41 24 41 22 0 26 -4 23 -22z m1589 -21 c-5 -15 -19 -18
            -36 -8 -8 5 -11 16 -8 25 5 14 11 15 27 6 12 -6 20 -16 17 -23z m460 11 c-3
            -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m133 8 c0 -22 -11 -46 -21
            -46 -5 0 -9 14 -9 30 0 20 5 30 15 30 8 0 15 -6 15 -14z m96 -31 c-20 -20 -26
            -19 -26 5 0 24 33 46 38 24 2 -7 -3 -21 -12 -29z m337 12 c-3 -5 -10 -4 -15 1
            -6 6 -7 16 -3 22 6 9 9 9 16 -1 4 -7 5 -17 2 -22z m381 8 c-18 -27 -34 -33
            -34 -12 0 17 20 37 37 37 10 0 10 -6 -3 -25z m-3704 5 c0 -5 -4 -10 -10 -10
            -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m1875 0 c3 -5 1 -10 -4
            -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m1727 -12 c-7 -7 -12
            -8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11 -7 -17z m-2410 -11 c3 -13 1 -32 -3
            -43 -5 -13 -8 -7 -8 24 -1 46 4 55 11 19z m1955 -1 c-2 -6 -18 -12 -36 -14
            -29 -3 -31 -2 -22 16 7 14 17 18 36 14 14 -2 24 -10 22 -16z m-2113 -27 c-3
            -5 -12 -9 -20 -9 -14 0 -20 31 -7 44 9 9 34 -24 27 -35z m-857 -1 c-18 -11
            -29 2 -16 18 10 12 14 12 20 2 4 -7 3 -16 -4 -20z m938 22 c3 -6 -1 -13 -10
            -16 -19 -8 -30 0 -20 15 8 14 22 14 30 1z m2293 -1 c-2 -6 -8 -10 -13 -10 -5
            0 -11 4 -13 10 -2 6 4 11 13 11 9 0 15 -5 13 -11z m-2499 -36 c-11 -32 -41
            -40 -47 -12 -2 12 48 53 55 45 2 -2 -2 -17 -8 -33z m3305 22 c6 -18 -16 -30
            -39 -21 -20 7 -8 36 15 36 10 0 21 -7 24 -15z m-3849 -5 c-3 -5 -13 -10 -21
            -10 -8 0 -14 5 -14 10 0 6 9 10 21 10 11 0 17 -4 14 -10z m95 -5 c0 -8 -7 -15
            -15 -15 -16 0 -20 12 -8 23 11 12 23 8 23 -8z m170 -1 c0 -9 -5 -14 -12 -12
            -18 6 -21 28 -4 28 9 0 16 -7 16 -16z m1804 1 c8 -21 -33 -42 -45 -24 -5 8 -9
            20 -9 27 0 17 47 15 54 -3z m1316 5 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3
            6 -1 10 4 10 6 0 11 -4 11 -10z m200 -5 c0 -8 -7 -15 -15 -15 -8 0 -15 7 -15
            15 0 8 7 15 15 15 8 0 15 -7 15 -15z m118 3 c-6 -18 -28 -21 -28 -4 0 9 7 16
            16 16 9 0 14 -5 12 -12z m-4088 -18 c0 -11 -7 -20 -15 -20 -27 0 -18 30 13 39
            1 1 2 -8 2 -19z m80 -1 c0 -18 -2 -19 -15 -9 -15 12 -12 30 6 30 5 0 9 -10 9
            -21z m490 1 c0 -13 -7 -20 -20 -20 -13 0 -20 7 -20 20 0 13 7 20 20 20 13 0
            20 -7 20 -20z m3029 6 c9 -11 8 -15 -5 -20 -23 -9 -24 -8 -24 14 0 23 13 26
            29 6z m259 -3 c2 -8 -6 -13 -22 -13 -25 0 -33 10 -19 24 10 10 36 3 41 -11z
            m-3040 -18 c-3 -3 -9 2 -12 12 -6 14 -5 15 5 6 7 -7 10 -15 7 -18z m2462 10
            c0 -8 -7 -15 -15 -15 -8 0 -15 7 -15 15 0 8 7 15 15 15 8 0 15 -7 15 -15z
            m-1804 -22 c-3 -8 -6 -17 -6 -19 0 -2 -4 -4 -10 -4 -5 0 -10 11 -10 25 0 18 4
            23 16 18 8 -3 13 -12 10 -20z m324 -9 c0 -19 -39 -18 -47 2 -3 9 0 20 8 24 15
            10 39 -6 39 -26z m-1502 3 c2 -12 -3 -17 -17 -17 -23 0 -24 1 -15 25 8 19 28
            15 32 -8z m1252 -18 c0 -11 -4 -19 -9 -19 -10 0 -21 24 -21 47 0 13 3 13 15 3
            8 -7 15 -21 15 -31z m1877 24 c3 -7 -6 -17 -17 -23 -30 -16 -47 1 -24 24 17
            18 35 17 41 -1z m63 7 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10
            6 0 11 -4 11 -10z m-2760 -15 c11 -13 8 -15 -18 -15 -31 0 -47 11 -35 24 11
            11 40 6 53 -9z m686 -12 c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2 -7 1 -15 -2
            -18z m528 10 c9 -23 -16 -37 -31 -16 -7 9 -10 20 -7 24 9 15 31 10 38 -8z
            m2046 2 c0 -8 -9 -19 -20 -25 -24 -13 -27 2 -4 24 19 20 24 20 24 1z m-3862
            -7 c18 -18 14 -38 -7 -38 -23 0 -35 15 -27 35 7 18 18 19 34 3z m285 5 c11
            -10 -13 -32 -29 -26 -20 8 -17 33 4 33 10 0 22 -3 25 -7z m3277 -39 c0 -23
            -31 -30 -46 -9 -10 13 -11 22 -3 31 14 17 49 1 49 -22z m85 25 c10 -14 1 -21
            -19 -13 -9 3 -13 10 -10 15 7 12 21 11 29 -2z m390 -19 c-5 -8 -11 -8 -21 1
            -12 10 -12 14 -2 20 16 10 33 -5 23 -21z m-3667 9 c-2 -6 -8 -10 -13 -10 -5 0
            -11 4 -13 10 -2 6 4 11 13 11 9 0 15 -5 13 -11z m377 -20 c12 -18 -6 -25 -19
            -6 -7 9 -19 17 -25 17 -7 0 -9 5 -6 10 7 11 39 -2 50 -21z m295 11 c0 -21 -8
            -24 -34 -14 -25 10 -19 34 9 34 18 0 25 -5 25 -20z m1570 6 c0 -7 -6 -19 -14
            -25 -19 -16 -32 5 -16 24 16 19 30 19 30 1z m-2692 -14 c2 -7 -3 -12 -12 -12
            -9 0 -16 7 -16 16 0 17 22 14 28 -4z m547 8 c3 -5 -1 -10 -9 -10 -9 0 -16 5
            -16 10 0 6 4 10 9 10 6 0 13 -4 16 -10z m1745 0 c0 -5 -5 -10 -11 -10 -5 0 -7
            5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m877 -12 c-3 -8 -6 -5 -6 6 -1 11 2
            17 5 13 3 -3 4 -12 1 -19z m-2512 2 c4 -6 -6 -10 -22 -10 -22 0 -25 2 -13 10
            19 12 27 12 35 0z m2835 -10 c0 -11 -7 -20 -15 -20 -15 0 -21 21 -8 33 12 13
            23 7 23 -13z m518 3 c-2 -10 -13 -19 -26 -21 -17 -3 -22 2 -22 17 0 16 6 21
            26 21 19 0 25 -5 22 -17z m-3726 -40 c8 -10 3 -13 -21 -13 -29 0 -32 2 -25 23
            9 30 28 41 33 19 2 -9 8 -23 13 -29z m3003 36 c4 -6 5 -12 2 -15 -7 -7 -37 7
            -37 17 0 13 27 11 35 -2z m-2314 -10 c11 -5 17 -14 14 -19 -6 -10 -45 9 -45
            22 0 11 8 10 31 -3z m244 -23 c16 -38 11 -68 -10 -60 -23 9 -31 94 -9 94 3 0
            11 -15 19 -34z m124 -40 c1 -22 -2 -37 -5 -33 -11 10 -24 88 -17 99 9 16 21
            -19 22 -66z m1799 42 c-3 -34 -20 -42 -26 -13 -5 22 5 45 18 45 7 0 10 -14 8
            -32z m262 22 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9 -4
            9 -10z m900 -15 c0 -19 -20 -29 -32 -17 -8 8 -7 16 2 26 14 17 30 12 30 -9z
            m-3022 -50 c0 -29 0 -29 -8 -5 -4 14 -8 36 -8 50 -1 23 0 23 8 5 5 -11 8 -33
            8 -50z m2590 47 c2 -7 -3 -12 -12 -12 -9 0 -16 7 -16 16 0 17 22 14 28 -4z
            m-3698 -7 c0 -8 -9 -15 -20 -15 -13 0 -18 5 -14 15 4 8 12 15 20 15 8 0 14 -7
            14 -15z m212 -23 c-21 -16 -30 2 -11 24 10 12 14 12 20 2 6 -8 2 -18 -9 -26z
            m98 29 c0 -12 -29 -23 -42 -15 -6 4 -5 10 3 15 18 11 39 11 39 0z m97 3 c-3
            -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m233 -15 c0 -5 -51 -9 -112
            -9 -74 0 -108 4 -98 10 8 5 41 6 75 3 33 -2 71 -1 85 5 26 10 50 6 50 -9z
            m2990 -10 c0 -15 -30 -23 -42 -11 -6 6 -8 18 -4 27 4 13 10 14 26 5 11 -6 20
            -15 20 -21z m-2854 5 c18 -15 18 -15 -11 -9 -37 8 -57 25 -29 25 11 0 29 -7
            40 -16z m1262 -7 c5 -23 -16 -47 -34 -40 -14 5 -19 44 -7 57 12 12 38 1 41
            -17z m1479 -3 c-9 -9 -28 6 -21 18 4 6 10 6 17 -1 6 -6 8 -13 4 -17z m407 5
            c-6 -11 -24 -2 -24 11 0 5 7 7 15 4 8 -4 12 -10 9 -15z m116 6 c0 -8 -2 -15
            -4 -15 -2 0 -6 7 -10 15 -3 8 -1 15 4 15 6 0 10 -7 10 -15z m-4137 -2 c-7 -2
            -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m2362 -3 c3 -5 1 -10 -4 -10
            -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m1125 0 c0 -5 -4 -10 -10
            -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-3630 -14 c0 -8 -5
            -18 -11 -22 -14 -8 -33 11 -25 25 10 16 36 13 36 -3z m639 10 c-12 -11 -135
            -56 -155 -56 -12 0 -24 -6 -27 -12 -3 -10 -7 -10 -17 -1 -20 19 -1 35 34 29
            18 -4 36 -3 40 1 4 4 31 15 59 25 52 17 74 21 66 14z m2359 -37 c-3 -27 -38
            -40 -38 -14 0 9 5 26 11 37 14 25 32 10 27 -23z m-3118 15 c0 -8 -4 -12 -10
            -9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10 -16z m390 12 c0 -3 -4 -8
            -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m700 -6 c12 -8
            10 -10 -10 -10 -14 0 -32 5 -40 10 -12 8 -10 10 10 10 14 0 32 -5 40 -10z
            m1010 6 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10
            -4z m-835 -17 c12 -19 -5 -23 -22 -6 -16 16 -16 17 -1 17 9 0 20 -5 23 -11z
            m335 -10 c0 -11 -4 -18 -10 -14 -5 3 -7 12 -3 20 7 21 13 19 13 -6z m1630 4
            c0 -2 -6 -11 -14 -18 -12 -12 -15 -12 -20 1 -8 21 1 34 19 27 8 -3 15 -7 15
            -10z m324 -16 c-3 -9 -12 -17 -20 -17 -11 0 -11 5 -2 25 12 27 33 20 22 -8z
            m616 23 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z
            m-3950 -15 c0 -8 -7 -15 -15 -15 -16 0 -20 12 -8 23 11 12 23 8 23 -8z m949 2
            c6 -8 8 -17 5 -20 -7 -7 -44 14 -44 25 0 13 27 9 39 -5z m401 9 c0 -3 -4 -8
            -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m2098 -19 c2 -11
            -3 -17 -17 -17 -23 0 -35 15 -26 31 10 15 39 6 43 -14z m-1980 -25 c2 -23 -1
            -32 -11 -32 -16 0 -27 21 -27 52 0 35 35 17 38 -20z m1712 18 c0 -13 -28 -25
            -38 -16 -3 4 0 11 8 16 20 13 30 12 30 0z m420 1 c0 -14 -18 -23 -30 -16 -6 4
            -8 11 -5 16 8 12 35 12 35 0z m528 -8 c-2 -10 -10 -18 -18 -18 -8 0 -16 8 -18
            18 -2 12 3 17 18 17 15 0 20 -5 18 -17z m222 2 c0 -8 -7 -15 -15 -15 -16 0
            -20 12 -8 23 11 12 23 8 23 -8z m-4275 -5 c-3 -5 -13 -10 -21 -10 -8 0 -12 5
            -9 10 3 6 13 10 21 10 8 0 12 -4 9 -10z m75 -11 c0 -5 -10 -9 -21 -9 -18 0
            -19 2 -9 15 12 15 30 12 30 -6z m115 11 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4
            10 3 6 11 10 16 10 6 0 7 -4 4 -10z m1522 2 c19 -12 9 -47 -15 -50 -13 -2 -22
            4 -27 17 -11 35 12 52 42 33z m453 -2 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3
            6 8 10 11 10 2 0 4 -4 4 -10z m1565 0 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9 10
            0 6 7 10 16 10 8 0 12 -4 9 -10z m135 -6 c0 -17 -22 -14 -28 4 -2 7 3 12 12
            12 9 0 16 -7 16 -16z m88 -2 c2 -7 -3 -12 -12 -12 -9 0 -16 7 -16 16 0 17 22
            14 28 -4z m197 8 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8 0
            12 -4 9 -10z m-3075 -12 c0 -4 -4 -8 -9 -8 -6 0 -12 4 -15 8 -3 5 1 9 9 9 8 0
            15 -4 15 -9z m-1319 -21 c-13 -13 -26 -3 -16 12 3 6 11 8 17 5 6 -4 6 -10 -1
            -17z m469 3 c-39 -21 -65 -26 -55 -9 9 13 44 28 70 28 15 0 11 -5 -15 -19z
            m2068 -8 c2 -16 -3 -25 -20 -31 -27 -10 -48 3 -48 29 0 37 63 38 68 2z m-1295
            -2 c3 -11 2 -20 -3 -20 -4 0 -10 9 -13 20 -3 11 -2 20 3 20 4 0 10 -9 13 -20z
            m-1113 6 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10
            -4z m240 -6 c0 -12 -28 -25 -36 -17 -9 9 6 27 22 27 8 0 14 -5 14 -10z m980
            -37 c0 -7 -4 -13 -10 -13 -5 0 -7 7 -4 15 4 8 1 15 -5 15 -6 0 -11 8 -11 18 1
            15 2 15 15 -2 8 -11 15 -26 15 -33z m1607 25 c-3 -8 -6 -5 -6 6 -1 11 2 17 5
            13 3 -3 4 -12 1 -19z m-2749 -4 c-9 -9 -21 -13 -27 -9 -16 10 -3 25 22 25 20
            0 20 -1 5 -16z m2628 2 c6 -16 -12 -56 -25 -56 -13 0 -22 30 -16 51 7 22 33
            25 41 5z m874 4 c0 -5 -9 -10 -20 -10 -11 0 -20 5 -20 10 0 6 9 10 20 10 11 0
            20 -4 20 -10z m-3460 -10 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11
            10 2 0 4 -4 4 -10z m3698 -7 c2 -8 -6 -13 -22 -13 -25 0 -33 10 -19 24 10 10
            36 3 41 -11z m532 7 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8
            0 15 -4 15 -10z m-4530 -27 c0 -13 -3 -13 -15 -3 -8 7 -12 16 -9 21 8 13 24 1
            24 -18z m395 -4 c-33 -17 -64 -35 -69 -40 -6 -5 -17 -9 -25 -9 -21 1 38 50 59
            50 8 0 26 7 40 14 14 8 32 14 40 15 8 0 -12 -13 -45 -30z m3495 21 c12 -8 9
            -10 -12 -10 -16 0 -28 5 -28 10 0 13 20 13 40 0z m185 0 c3 -5 1 -10 -4 -10
            -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m100 -11 c11 -17 0 -20 -21
            -4 -17 13 -17 14 -2 15 9 0 20 -5 23 -11z m-4055 -8 c0 -11 -26 -22 -34 -14
            -12 12 -5 23 14 23 11 0 20 -4 20 -9z m60 -1 c0 -5 -7 -10 -16 -10 -8 0 -12 5
            -9 10 3 6 10 10 16 10 5 0 9 -4 9 -10z m1340 -5 c0 -8 -4 -15 -10 -15 -5 0
            -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m1751 -18 c-16 -16 -26 0 -10
            19 9 11 15 12 17 4 2 -6 -1 -17 -7 -23z m239 23 c-20 -13 -43 -13 -35 0 3 6
            16 10 28 10 18 0 19 -2 7 -10z m770 -8 c0 -24 -8 -32 -26 -25 -22 8 -17 43 6
            43 13 0 20 -7 20 -18z m80 8 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10
            15 10 8 0 15 -4 15 -10z m108 1 c11 -6 10 -11 -3 -25 -19 -19 -31 -20 -40 -6
            -17 28 14 50 43 31z m-4178 -10 c0 -5 -7 -14 -15 -21 -16 -14 -18 -10 -9 14 6
            17 24 22 24 7z m3523 2 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z
            m827 -7 c0 -8 -7 -16 -15 -20 -10 -4 -15 1 -15 14 0 11 7 20 15 20 8 0 15 -6
            15 -14z m-4400 0 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0
            10 -2 10 -4z m1310 -6 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10
            6 0 11 -4 11 -10z m1643 3 c-7 -2 -13 -11 -13 -19 0 -8 -7 -14 -15 -14 -13 0
            -14 4 -5 20 6 11 18 20 28 19 10 0 12 -3 5 -6z m404 1 c-3 -3 -12 -4 -19 -1
            -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-1987 -30 c0 -8 -4 -12 -10 -9 -5 3 -10 13
            -10 21 0 8 5 12 10 9 6 -3 10 -13 10 -21z m2528 8 c3 -9 -3 -13 -19 -10 -12 1
            -24 9 -27 16 -3 9 3 13 19 10 12 -1 24 -9 27 -16z m-4211 -14 c-3 -8 -6 -5 -6
            6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m245 0 c-6 -6 -15 -8 -19 -4 -4 4 -1 11
            7 16 19 12 27 3 12 -12z m3594 15 c-12 -12 -58 -15 -51 -3 3 5 18 10 32 10 15
            0 23 -3 19 -7z m791 -19 c-9 -9 -28 6 -21 18 4 6 10 6 17 -1 6 -6 8 -13 4 -17z
            m-4507 6 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z
            m1430 -5 c10 -12 10 -19 1 -28 -9 -9 -14 -9 -21 3 -22 34 -4 54 20 25z m2385
            5 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m175
            0 c8 -5 11 -12 7 -16 -4 -4 -15 0 -24 9 -18 18 -8 23 17 7z m-207 -7 c-7 -2
            -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m-3566 -23 c-31 -25 -36 -25
            -30 -2 2 10 15 19 30 20 l26 2 -26 -20z m1439 -17 c-11 -11 -19 6 -11 24 8 17
            8 17 12 0 3 -10 2 -21 -1 -24z m192 9 c2 -12 -1 -22 -6 -22 -15 0 -34 28 -27
            40 10 17 30 6 33 -18z m1182 18 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8
            10 11 10 2 0 4 -4 4 -10z m897 4 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17
            -2 13 -5z m233 -4 c0 -5 -4 -10 -9 -10 -6 0 -13 5 -16 10 -3 6 1 10 9 10 9 0
            16 -4 16 -10z m-670 -14 c-14 -8 -29 -12 -33 -9 -12 8 14 23 38 22 18 0 17 -1
            -5 -13z m830 -2 c0 -17 -22 -14 -28 4 -2 7 3 12 12 12 9 0 16 -7 16 -16z
            m-4278 -16 c-7 -7 -12 -8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11 -7 -17z m118 5
            c0 -19 -33 -51 -95 -92 -22 -14 -42 -28 -45 -31 -10 -11 -65 -47 -82 -53 -12
            -4 -18 -1 -18 8 0 8 7 15 15 15 29 0 62 23 80 56 13 25 25 34 44 34 28 0 54
            13 51 24 -2 10 33 56 42 56 5 0 8 -8 8 -17z m4096 -10 c-7 -7 -26 7 -26 19 0
            6 6 6 15 -2 9 -7 13 -15 11 -17z m-2301 -3 c0 -7 -6 -15 -12 -17 -8 -3 -13 4
            -13 17 0 13 5 20 13 18 6 -3 12 -11 12 -18z m2026 4 c17 -8 36 -13 42 -11 21
            8 54 -11 60 -32 6 -25 -6 -27 -29 -4 -9 9 -25 19 -36 20 -33 5 -97 43 -73 43
            4 0 20 -7 36 -16z m-3891 -4 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10
            10 10 6 0 10 -4 10 -10z m2247 -24 c-8 -19 -37 -21 -37 -3 0 7 5 18 12 25 15
            15 33 -1 25 -22z m1809 6 c-9 -9 -37 8 -31 18 5 8 11 7 21 -1 8 -6 12 -14 10
            -17z m66 0 c-19 -6 -44 6 -37 18 4 7 14 6 28 -2 14 -7 17 -13 9 -16z m308 7
            c0 -6 -4 -7 -10 -4 -5 3 -10 11 -10 16 0 6 5 7 10 4 6 -3 10 -11 10 -16z m171
            -2 c-13 -13 -26 -3 -16 12 3 6 11 8 17 5 6 -4 6 -10 -1 -17z m-291 3 c0 -5 -5
            -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-2332 -17 c6
            -19 -25 -16 -32 3 -4 11 -1 14 11 12 9 -2 18 -9 21 -15z m-568 -50 c0 -57 -5
            -53 -13 10 -4 31 -3 46 3 42 6 -3 10 -27 10 -52z m1008 34 c3 -12 -3 -17 -22
            -17 -25 0 -35 18 -19 34 12 12 38 2 41 -17z m732 -32 c-16 -42 -24 -22 -9 22
            7 21 14 31 16 22 2 -8 -1 -28 -7 -44z m-385 35 c-3 -5 -12 -10 -18 -10 -7 0
            -6 4 3 10 19 12 23 12 15 0z m-131 -15 c-7 -16 -24 -21 -24 -6 0 8 7 13 27 20
            1 1 0 -6 -3 -14z m396 -19 c0 -25 -17 -46 -37 -46 -18 0 -16 31 3 58 18 26 34
            21 34 -12z m1244 5 c3 -5 0 -11 -8 -14 -15 -6 -50 26 -40 36 7 7 40 -8 48 -22z
            m-2957 -13 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m110 0 c-3
            -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m2703 -9 c0 -11 -11 -11 -29
            0 -7 5 -10 14 -6 20 5 9 10 9 21 0 8 -6 14 -15 14 -20z m250 16 c0 -17 -2 -18
            -24 -9 -23 9 -20 24 4 24 11 0 20 -7 20 -15z m-4480 -5 c0 -5 -4 -10 -10 -10
            -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m1634 -7 c7 -16 -3 -43
            -15 -43 -5 0 -14 10 -19 23 -5 13 -9 16 -10 7 0 -13 -1 -13 -10 0 -15 23 -12
            27 20 27 16 0 31 -6 34 -14z m2530 2 c11 -8 17 -18 13 -21 -3 -3 -18 3 -33 15
            -30 23 -10 29 20 6z m-2290 -30 c7 -20 -3 -45 -20 -45 -20 0 -36 34 -22 48 16
            16 35 15 42 -3z m2741 -25 c-8 -13 -32 -13 -40 0 -4 6 -1 17 5 25 10 13 14 12
            27 0 8 -8 11 -19 8 -25z m137 18 c-12 -12 -35 -1 -27 12 3 5 13 6 21 3 10 -4
            12 -9 6 -15z m-1752 -28 l-8 -35 -1 31 c-1 17 2 34 6 37 11 12 12 3 3 -33z
            m1440 24 c0 -8 -5 -12 -10 -9 -6 4 -8 11 -5 16 9 14 15 11 15 -7z m-120 -4 c8
            -5 11 -12 8 -16 -10 -9 -38 3 -38 16 0 12 10 13 30 0z m-2315 -25 c0 -27 -26
            -25 -36 3 -8 20 -6 23 14 20 14 -2 22 -10 22 -23z m2255 3 c0 -5 5 -6 10 -3 6
            3 10 2 10 -4 0 -5 9 -13 20 -16 21 -7 33 -16 97 -74 18 -16 38 -28 46 -27 14
            1 97 -68 97 -81 0 -4 -5 -2 -12 5 -7 7 -20 12 -30 12 -10 0 -18 4 -18 9 0 5
            -9 13 -21 16 -13 4 -18 12 -15 21 3 9 1 12 -5 9 -5 -4 -26 6 -47 22 -20 15
            -41 30 -47 33 -5 3 -36 22 -67 43 -59 38 -75 59 -38 50 11 -3 20 -10 20 -15z
            m115 12 c3 -5 2 -10 -4 -10 -5 0 -13 5 -16 10 -3 6 -2 10 4 10 5 0 13 -4 16
            -10z m-1477 -54 c-6 -14 -15 -26 -20 -26 -12 0 -10 54 4 68 19 19 31 -11 16
            -42z m-1408 -3 c0 -44 -5 -41 -14 10 -5 27 -4 37 3 32 6 -3 11 -22 11 -42z
            m-1580 17 c-8 -5 -17 -7 -19 -4 -3 3 1 9 9 14 8 5 17 7 19 4 3 -3 -1 -9 -9
            -14z m4605 10 c3 -5 4 -14 0 -20 -8 -13 -35 -1 -35 16 0 16 26 19 35 4z m175
            0 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z
            m-2140 -20 c18 -11 3 -43 -24 -48 -22 -5 -31 23 -14 42 16 18 18 19 38 6z
            m260 -14 c0 -8 -6 -19 -14 -25 -11 -10 -16 -8 -24 6 -8 13 -6 20 6 29 19 14
            32 9 32 -10z m1616 10 c19 -20 18 -40 -2 -32 -9 3 -18 6 -20 6 -2 0 -4 9 -4
            20 0 24 6 25 26 6z m-66 -6 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10
            4 10 6 0 11 -4 11 -10z m-1980 -19 c0 -10 -7 -22 -16 -25 -23 -9 -24 -8 -24
            19 0 18 5 25 20 25 13 0 20 -7 20 -19z m702 -23 c-7 -7 -12 -8 -12 -2 0 14 12
            26 19 19 2 -3 -1 -11 -7 -17z m1540 7 c0 -5 -5 -11 -11 -13 -6 -2 -11 4 -11
            13 0 9 5 15 11 13 6 -2 11 -8 11 -13z m-3017 -5 c3 -5 1 -10 -4 -10 -6 0 -11
            5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m3119 -16 c4 -9 4 -19 2 -21 -8 -8
            -26 8 -26 23 0 19 16 18 24 -2z m-2804 -20 c0 -8 -4 -14 -10 -14 -5 0 -10 9
            -10 21 0 11 5 17 10 14 6 -3 10 -13 10 -21z m2590 11 c10 -12 10 -15 -4 -15
            -9 0 -16 7 -16 15 0 8 2 15 4 15 2 0 9 -7 16 -15z m-2729 -11 c11 -14 10 -15
            -11 -12 -13 1 -25 9 -28 16 -5 17 23 15 39 -4z m234 -4 c3 -5 3 -15 -1 -21 -6
            -11 -23 6 -24 24 0 10 18 9 25 -3z m2575 0 c0 -5 -4 -10 -9 -10 -6 0 -13 5
            -16 10 -3 6 1 10 9 10 9 0 16 -4 16 -10z m-1404 -45 c-6 -32 -13 -39 -33 -32
            -11 3 -4 27 14 55 19 31 27 22 19 -23z m1649 -5 c3 -5 2 -10 -4 -10 -5 0 -13
            5 -16 10 -3 6 -2 10 4 10 5 0 13 -4 16 -10z m-187 -19 c3 -8 -1 -12 -9 -9 -7
            2 -15 10 -17 17 -3 8 1 12 9 9 7 -2 15 -10 17 -17z m-3128 -38 c0 -13 -4 -23
            -10 -23 -5 0 -10 17 -10 38 0 27 3 33 10 22 5 -8 10 -25 10 -37z m68 -4 c-3
            -33 -3 -34 -16 -11 -7 12 -10 32 -7 43 10 32 27 8 23 -32z m987 31 c0 -7 -6
            -15 -12 -17 -8 -3 -13 4 -13 17 0 13 5 20 13 18 6 -3 12 -11 12 -18z m172 -6
            c-9 -9 -28 6 -21 18 4 6 10 6 17 -1 6 -6 8 -13 4 -17z m100 -6 c-3 -7 -5 -2
            -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m1903 12 c0 -5 -7 -10 -15 -10 -8 0
            -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m-2300 -14 c0 -17 -14 -29 -23
            -20 -8 8 3 34 14 34 5 0 9 -6 9 -14z m2113 -16 c14 -11 30 -20 35 -20 5 0 12
            -7 15 -14 4 -11 1 -13 -11 -8 -32 13 -82 46 -82 54 0 13 16 9 43 -12z m-2870
            -21 c6 -9 7 -24 3 -34 -6 -17 -7 -17 -26 0 -25 22 -27 58 -4 53 9 -2 21 -10
            27 -19z m647 11 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0
            10 -4 10 -10z m2346 -17 c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2 -7 1 -15 -2
            -18z m-2806 -9 c15 -38 12 -46 -11 -42 -27 4 -44 30 -36 52 10 24 36 19 47
            -10z m120 6 c13 -25 5 -40 -21 -40 -14 0 -19 7 -19 30 0 35 23 41 40 10z
            m1024 -29 c-4 -18 -10 -28 -15 -23 -5 4 -6 23 -3 41 4 18 11 29 16 24 4 -5 5
            -24 2 -42z m-1544 -40 c-1 -30 -2 -33 -11 -18 -16 28 -28 75 -21 82 12 12 32
            -29 32 -64z m3198 3 c-2 -7 6 -20 17 -28 19 -16 19 -16 0 -9 -33 12 -55 36
            -55 62 0 23 0 24 20 6 11 -10 19 -24 18 -31z m49 14 c-3 -7 -5 -2 -5 12 0 14
            2 19 5 13 2 -7 2 -19 0 -25z m-1817 -37 c0 -23 -29 -62 -31 -41 -2 19 1 41 6
            58 6 18 25 5 25 -17z m-352 -25 c6 -31 -43 -50 -54 -21 -8 20 15 47 35 43 9
            -2 17 -12 19 -22z m204 -2 c-4 -18 -19 -34 -34 -34 -13 0 -9 48 5 53 20 9 32
            1 29 -19z m1985 20 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
            m-3177 -40 c0 -8 -4 -12 -10 -9 -5 3 -10 13 -10 21 0 8 5 12 10 9 6 -3 10 -13
            10 -21z m830 11 c10 -12 10 -18 -1 -32 -7 -10 -14 -19 -15 -21 -1 -1 -9 0 -18
            4 -19 7 -21 35 -4 52 16 16 23 15 38 -3z m2360 0 c14 -8 19 -14 10 -14 -8 0
            -24 6 -34 14 -25 19 -9 19 24 0z m-3150 -20 c0 -8 -4 -15 -10 -15 -5 0 -10 7
            -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m-133 -27 c-3 -7 -5 -2 -5 12 0 14 2
            19 5 13 2 -7 2 -19 0 -25z m223 10 c0 -19 -2 -20 -10 -8 -13 19 -13 30 0 30 6
            0 10 -10 10 -22z m140 -3 c10 -12 10 -15 -4 -15 -9 0 -16 7 -16 15 0 8 2 15 4
            15 2 0 9 -7 16 -15z m60 -30 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15
            10 15 6 0 10 -7 10 -15z m113 -1 c3 -3 3 -12 0 -20 -4 -11 -9 -12 -19 -3 -8 6
            -11 15 -8 20 6 10 18 12 27 3z m1131 -31 c-8 -32 -12 -39 -19 -31 -9 8 6 58
            16 58 5 0 6 -12 3 -27z m-1554 -64 c0 -16 -4 -29 -10 -29 -5 0 -10 16 -10 36
            0 21 4 33 10 29 6 -3 10 -19 10 -36z m104 9 c9 -33 8 -38 -8 -38 -16 0 -31 34
            -21 50 10 17 22 11 29 -12z m856 8 c0 -8 -4 -17 -9 -21 -12 -7 -24 12 -16 25
            9 15 25 12 25 -4z m530 6 c0 -13 -12 -22 -22 -16 -10 6 -1 24 13 24 5 0 9 -4
            9 -8z m-66 -39 c-10 -40 -14 -41 -14 -5 0 18 5 32 10 32 6 0 8 -11 4 -27z
            m-1134 -12 c5 -11 7 -27 4 -36 -9 -22 -34 0 -34 31 0 29 15 31 30 5z m500 -6
            c0 -8 -7 -15 -15 -15 -16 0 -20 12 -8 23 11 12 23 8 23 -8z m-376 -31 c10 -26
            7 -34 -13 -34 -24 0 -33 13 -25 34 8 21 30 21 38 0z m896 -18 c0 -24 -16 -43
            -27 -32 -8 8 6 56 17 56 6 0 10 -11 10 -24z m-157 -36 c-7 -27 -22 -35 -29
            -16 -7 18 13 49 26 41 5 -3 6 -14 3 -25z m-205 -17 c-4 -37 -38 -45 -38 -10 0
            23 10 34 33 36 5 1 7 -11 5 -26z m-848 -45 c0 -25 -3 -29 -10 -18 -13 20 -13
            50 0 50 6 0 10 -15 10 -32z m663 16 c10 -10 -12 -44 -28 -44 -9 0 -15 10 -15
            25 0 18 5 25 18 25 10 0 22 -3 25 -6z m-575 -15 c-2 -6 -8 -10 -13 -10 -5 0
            -11 4 -13 10 -2 6 4 11 13 11 9 0 15 -5 13 -11z m102 -20 c0 -11 -4 -17 -10
            -14 -5 3 -10 15 -10 26 0 11 5 17 10 14 6 -3 10 -15 10 -26z m96 -45 c-5 -13
            -10 -14 -18 -6 -6 6 -8 18 -4 28 5 13 10 14 18 6 6 -6 8 -18 4 -28z m572 -26
            c-6 -18 -28 -21 -28 -4 0 9 7 16 16 16 9 0 14 -5 12 -12z m449 -45 c-3 -10 -5
            -2 -5 17 0 19 2 27 5 18 2 -10 2 -26 0 -35z m-1398 -40 c2 -23 -19 -13 -25 11
            -8 35 8 59 17 26 4 -14 7 -31 8 -37z m811 36 c0 -5 -4 -9 -10 -9 -5 0 -10 7
            -10 16 0 8 5 12 10 9 6 -3 10 -10 10 -16z m-714 -24 c7 -29 1 -41 -15 -30 -14
            8 -14 55 -1 55 5 0 12 -11 16 -25z m204 -25 c16 -16 30 -35 30 -42 0 -9 -3 -9
            -12 0 -7 7 -22 12 -35 12 -23 0 -43 26 -36 48 7 19 20 15 53 -18z m1020 -14
            c0 -24 -22 -51 -33 -40 -3 3 -4 12 -1 20 2 8 6 21 9 29 7 24 25 17 25 -9z
            m-340 -40 c0 -22 -16 -36 -41 -36 -14 0 -11 48 4 53 26 11 37 6 37 -17z m184
            15 c9 -14 -4 -41 -20 -41 -15 0 -17 10 -8 34 7 18 20 21 28 7z m-319 -41 c9
            -14 -13 -50 -31 -50 -24 0 -30 31 -9 46 22 17 32 17 40 4z m-848 -102 c-3 -8
            -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m100 -30 c-3 -8 -6 -5 -6 6 -1
            11 2 17 5 13 3 -3 4 -12 1 -19z m143 -9 c0 -11 -4 -17 -10 -14 -5 3 -10 15
            -10 26 0 11 5 17 10 14 6 -3 10 -15 10 -26z m90 -5 c0 -8 -4 -14 -10 -14 -5 0
            -10 9 -10 21 0 11 5 17 10 14 6 -3 10 -13 10 -21z m826 -31 c-11 -11 -17 4 -9
            23 7 18 8 18 11 1 2 -10 1 -21 -2 -24z m-106 2 c0 -8 -7 -15 -15 -15 -8 0 -15
            7 -15 15 0 8 7 15 15 15 8 0 15 -7 15 -15z m-160 -17 c0 -16 -3 -19 -11 -11
            -6 6 -8 16 -5 22 11 17 16 13 16 -11z m-871 -85 c0 -31 -2 -34 -9 -18 -12 28
            -12 55 0 55 6 0 10 -17 9 -37z m1291 10 c0 -36 -15 -61 -23 -41 -7 19 3 68 14
            68 5 0 9 -12 9 -27z m-1198 -50 c1 -12 -1 -23 -7 -25 -5 -2 -11 13 -13 32 -4
            38 14 32 20 -7z m378 1 c0 -14 -21 -19 -32 -8 -3 3 -4 14 -1 25 4 15 8 17 19
            8 8 -6 14 -18 14 -25z m-160 -20 c0 -13 -4 -24 -9 -24 -11 0 -21 25 -21 52 0
            17 3 18 15 8 8 -7 15 -23 15 -36z m838 14 c-4 -27 -28 -36 -28 -10 0 20 8 32
            22 32 5 0 8 -10 6 -22z m-920 0 c14 -14 16 -48 2 -48 -10 0 -30 32 -30 49 0
            14 13 14 28 -1z m507 -36 c5 -15 -11 -42 -25 -42 -11 0 -20 28 -14 43 7 18 32
            17 39 -1z m195 -1 c0 -21 -13 -41 -26 -41 -17 0 -17 5 -4 31 11 20 30 26 30
            10z m-923 -113 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m93 -18
            c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m105
            -30 c3 -5 2 -10 -4 -10 -5 0 -13 5 -16 10 -3 6 -2 10 4 10 5 0 13 -4 16 -10z
            m219 -25 c3 -8 1 -15 -4 -15 -6 0 -10 7 -10 15 0 8 2 15 4 15 2 0 6 -7 10 -15z
            m877 -142 c-9 -16 -10 -15 -11 9 0 14 3 35 8 45 6 15 8 14 10 -9 2 -15 -1 -35
            -7 -45z m-1271 -3 c0 -20 -2 -22 -10 -10 -5 8 -10 26 -10 40 0 20 2 22 10 10
            5 -8 10 -26 10 -40z m95 -39 c5 -17 4 -22 -6 -19 -8 3 -15 18 -17 34 -4 33 11
            24 23 -15z m1039 30 c7 -11 -20 -48 -29 -39 -9 8 4 48 15 48 5 0 11 -4 14 -9z
            m-884 -41 c0 -5 3 -15 6 -24 9 -22 -23 -21 -34 2 -12 23 -4 42 14 35 8 -3 14
            -9 14 -13z m139 -53 c1 -10 -3 -16 -8 -13 -11 7 -20 53 -12 61 7 7 19 -23 20
            -48z m537 8 c-3 -8 -12 -15 -21 -15 -21 0 -19 38 3 42 19 4 26 -6 18 -27z
            m-196 1 c0 -14 -21 -58 -26 -54 -8 8 7 58 17 58 5 0 9 -2 9 -4z m-713 -138
            c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m1250 -30 c-3 -8 -6 -5
            -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-810 -70 c-2 -13 -4 -3 -4 22 0 25 2
            35 4 23 2 -13 2 -33 0 -45z m-330 10 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3
            4 -12 1 -19z m137 -14 c3 -9 -1 -14 -14 -14 -13 0 -20 7 -20 20 0 22 25 18 34
            -6z m840 -19 c-4 -8 -10 -13 -15 -10 -4 3 -6 12 -3 20 4 8 10 13 15 10 4 -3 6
            -12 3 -20z m-1055 -115 c1 -13 0 -13 -9 0 -5 8 -9 26 -9 40 l1 25 8 -25 c4
            -14 8 -32 9 -40z m1217 -15 c-4 -14 -11 -25 -16 -25 -10 0 -4 43 8 65 9 15 14
            -14 8 -40z m-1106 -73 c0 -12 -3 -12 -15 -2 -8 7 -15 22 -15 34 1 21 1 21 15
            2 8 -11 15 -26 15 -34z m954 -1 c7 -11 -18 -45 -31 -40 -7 3 -7 12 -2 27 9 22
            24 29 33 13z m-774 -50 c6 -12 6 -22 1 -25 -10 -7 -41 20 -41 35 0 17 29 9 40
            -10z m-283 -23 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m817 -22
            c-7 -19 -34 -29 -34 -12 0 16 10 26 26 26 8 0 11 -6 8 -14z m396 -31 c0 -8 -4
            -15 -10 -15 -5 0 -7 7 -4 15 4 8 8 15 10 15 2 0 4 -7 4 -15z m-200 -115 c0 -5
            -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-981 -55 c1
            -17 -1 -17 -9 -5 -5 8 -9 26 -9 40 0 23 1 23 9 5 5 -11 9 -29 9 -40z m211 35
            c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z
            m630 -50 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9 -4 9
            -10z m335 -12 c-10 -31 -25 -47 -25 -26 0 24 11 48 22 48 5 0 6 -10 3 -22z
            m-1067 -63 c14 -29 -2 -44 -17 -16 -6 11 -11 24 -11 30 0 20 17 11 28 -14z
            m907 -36 c7 -10 -21 -38 -37 -39 -12 0 -9 25 4 38 15 15 25 15 33 1z m-778
            -61 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-214 -18 c3 -11 1
            -18 -4 -14 -5 3 -9 12 -9 20 0 20 7 17 13 -6z m1134 -32 c-3 -8 -6 -5 -6 6 -1
            11 2 17 5 13 3 -3 4 -12 1 -19z m-333 -3 c-4 -8 -10 -15 -15 -15 -5 0 -9 7 -9
            15 0 8 7 15 15 15 9 0 12 -6 9 -15z m-774 -178 c0 -10 -4 -15 -10 -12 -5 3
            -10 18 -10 33 0 21 2 24 10 12 5 -8 10 -23 10 -33z m1090 -39 c0 -19 -18 -43
            -25 -36 -9 9 4 48 16 48 5 0 9 -6 9 -12z m-955 -86 c-8 -8 -35 20 -35 37 1 12
            5 11 21 -8 11 -13 17 -26 14 -29z m795 -57 c0 -16 -27 -32 -37 -21 -7 7 16 36
            28 36 5 0 9 -7 9 -15z m-910 -33 c0 -15 -2 -15 -10 -2 -13 20 -13 33 0 25 6
            -3 10 -14 10 -23z m709 -48 c-13 -16 -39 -19 -39 -4 0 12 32 30 43 23 4 -3 3
            -11 -4 -19z m-691 -106 c7 -7 12 -21 12 -32 -1 -20 -1 -20 -20 3 -29 34 -22
            59 8 29z m1012 -76 c0 -15 -28 -47 -35 -40 -8 8 13 48 25 48 6 0 10 -4 10 -8z
            m-680 -98 c0 -8 -4 -14 -10 -14 -5 0 -10 9 -10 21 0 11 5 17 10 14 6 -3 10
            -13 10 -21z m520 12 c0 -15 -37 -32 -47 -22 -9 8 17 36 33 36 8 0 14 -6 14
            -14z m-119 -40 c-6 -8 -20 -16 -31 -19 -17 -4 -18 -2 -9 14 5 10 19 19 31 19
            16 0 18 -3 9 -14z m-268 -41 c-3 -9 -8 -14 -10 -11 -3 3 -2 9 2 15 9 16 15 13
            8 -4z m-432 -65 c5 0 9 -7 9 -15 0 -8 -4 -15 -10 -15 -15 0 -32 39 -23 53 5 9
            9 7 11 -5 2 -10 8 -18 13 -18z m390 -7 c-12 -20 -14 -14 -5 12 4 9 9 14 11 11
            3 -2 0 -13 -6 -23z m517 -60 c-2 -10 -11 -19 -21 -21 -16 -3 -17 -1 -7 17 13
            25 33 28 28 4z m-719 -52 c7 -5 10 -14 6 -20 -5 -9 -10 -9 -21 0 -26 22 -14
            38 15 20z m551 -51 c0 -5 -12 -10 -27 -10 -22 0 -25 2 -13 10 20 13 40 13 40
            0z m-722 -102 c14 -14 16 -42 2 -33 -14 9 -32 45 -22 45 4 0 13 -5 20 -12z
            m842 -46 c0 -11 -22 -32 -32 -32 -5 0 -4 9 2 20 11 20 30 28 30 12z m-626
            -103 c-4 -6 -11 -6 -20 2 -21 17 -17 31 6 19 11 -6 18 -15 14 -21z m442 -4
            c-22 -17 -36 -19 -36 -7 0 14 12 22 34 22 21 -1 21 -1 2 -15z m-156 -59 c0 -9
            -30 -14 -35 -6 -4 6 3 10 14 10 12 0 21 -2 21 -4z m-460 -31 c21 -25 1 -31
            -22 -7 -18 18 -19 22 -6 22 8 0 21 -7 28 -15z m-185 -35 c-11 -5 -29 -8 -40
            -8 -16 0 -15 2 5 8 34 11 60 11 35 0z m339 -45 c3 -9 0 -15 -8 -15 -8 0 -16 7
            -20 15 -3 9 0 15 8 15 8 0 16 -7 20 -15z m466 11 c0 -3 -4 -8 -10 -11 -5 -3
            -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-640 -121 c9 -11 10 -15 1 -15
            -7 0 -19 7 -27 15 -13 13 -13 15 -1 15 8 0 20 -7 27 -15z m120 -46 c0 -6 -4
            -7 -10 -4 -5 3 -10 11 -10 16 0 6 5 7 10 4 6 -3 10 -11 10 -16z m-94 -86 c-8
            -8 -36 5 -36 17 0 7 6 7 21 0 11 -7 18 -14 15 -17z m459 -13 c-3 -5 -10 -10
            -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8 0 12 -4 9 -10z m90 -30 c3 -5 -3
            -10 -14 -10 -12 0 -21 5 -21 10 0 6 6 10 14 10 8 0 18 -4 21 -10z m-480 -29
            c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z m-199 -57 c-4 -9 -9
            -15 -11 -12 -3 3 -3 13 1 22 4 9 9 15 11 12 3 -3 3 -13 -1 -22z m594 -4 c-19
            -13 -30 -13 -30 0 0 6 10 10 23 10 18 0 19 -2 7 -10z m-420 -35 c0 -8 -4 -15
            -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m-10 -150 c0 -8
            -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m-70 -87
            c0 -16 -3 -19 -11 -11 -6 6 -8 16 -5 22 11 17 16 13 16 -11z m-118 -50 c-7 -7
            -12 -8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11 -7 -17z m28 -108 c0 -5 -4 -10
            -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-50 -69 c0 -6
            -4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16 6 0 10 -4 10 -9z"
              />
              <path
                d="M5190 12073 c-14 -3 -28 -8 -32 -12 -12 -10 70 -3 89 8 16 9 15 10
            -7 9 -14 0 -36 -3 -50 -5z"
              />
              <path d="M5047 12047 c-4 -10 2 -13 19 -9 13 2 24 8 24 13 0 14 -37 10 -43 -4z" />
              <path
                d="M5350 12050 c-8 -5 -10 -10 -5 -10 6 0 17 5 25 10 8 5 11 10 5 10 -5
            0 -17 -5 -25 -10z"
              />
              <path d="M5400 12050 c-13 -8 -12 -10 3 -10 9 0 17 5 17 10 0 12 -1 12 -20 0z" />
              <path
                d="M5630 12056 c0 -2 7 -6 15 -10 8 -3 15 -1 15 4 0 6 -7 10 -15 10 -8
            0 -15 -2 -15 -4z"
              />
              <path
                d="M5413 12028 c-13 -6 -23 -15 -23 -20 0 -14 16 -9 35 12 20 22 19 23
            -12 8z"
              />
              <path d="M6228 12033 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
              <path
                d="M5037 12000 c-9 -11 -13 -20 -8 -20 4 0 16 9 26 20 10 11 14 20 9 20
            -5 0 -17 -9 -27 -20z"
              />
              <path
                d="M4840 11985 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6
            2 -11 -4 -11 -13z"
              />
              <path
                d="M6370 11975 c-1 -5 -1 -20 -1 -32 2 -13 -4 -23 -16 -25 -15 -3 -15
            -5 5 -10 17 -5 22 -2 22 13 0 20 15 26 25 9 3 -5 17 -10 31 -10 15 0 23 4 19
            10 -4 6 -14 9 -23 6 -19 -6 -46 11 -55 34 -3 8 -6 11 -7 5z"
              />
              <path d="M4748 11963 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
              <path d="M4808 11963 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
              <path d="M5508 11963 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />
              <path
                d="M5490 11940 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
            0 -10 -4 -10 -10z"
              />
              <path
                d="M5510 11904 c0 -2 9 -13 19 -23 13 -13 21 -16 24 -7 3 6 -6 17 -19
            23 -13 6 -24 9 -24 7z"
              />
              <path
                d="M5668 11903 c-16 -2 -28 -9 -28 -14 0 -7 13 -8 33 -4 29 5 30 5 14
            -8 -16 -14 -16 -15 -1 -21 20 -8 21 -7 26 23 3 26 -3 30 -44 24z"
              />
              <path
                d="M6470 11898 c0 -6 3 -8 6 -5 4 3 16 2 28 -4 20 -10 20 -10 2 5 -24
            19 -36 20 -36 4z"
              />
              <path
                d="M4620 11881 c0 -5 7 -12 16 -15 24 -9 28 -7 14 9 -14 17 -30 20 -30
            6z"
              />
              <path
                d="M5480 11876 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
            -10 -1 -10 -9z"
              />
              <path
                d="M5430 11859 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
            -10 -5 -10 -11z"
              />
              <path
                d="M5516 11841 c5 -21 4 -23 -12 -16 -11 4 -29 10 -42 12 -18 5 -21 2
            -15 -13 4 -11 8 -13 11 -6 5 17 22 15 22 -3 0 -9 6 -12 16 -8 9 3 20 0 25 -8
            7 -11 9 -10 9 5 0 14 6 17 25 13 17 -3 24 0 22 7 -2 6 -12 10 -21 8 -10 -2
            -24 5 -32 15 -14 17 -15 16 -8 -6z"
              />
              <path d="M4584 11835 c4 -25 14 -27 36 -8 l20 20 -29 1 c-19 0 -28 -4 -27 -13z" />
              <path
                d="M5660 11830 c-23 -5 -23 -7 -6 -13 11 -4 24 -3 30 3 14 14 5 18 -24
            10z"
              />
              <path
                d="M6529 11838 c-6 -28 -4 -37 11 -41 12 -3 17 1 16 16 -1 16 -24 38
            -27 25z"
              />
              <path
                d="M6590 11789 c0 -38 16 -56 22 -25 3 15 0 31 -8 37 -11 9 -14 7 -14
            -12z"
              />
              <path
                d="M6625 11750 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10
            -2 0 -7 -4 -10 -10z"
              />
              <path
                d="M4476 11739 c3 -6 12 -8 20 -5 8 3 11 0 8 -8 -9 -22 16 -30 31 -10
            17 23 15 25 -29 29 -22 2 -34 0 -30 -6z"
              />
              <path d="M4360 11534 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z" />
              <path
                d="M4277 11363 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
            -9 2 -12 -2z"
              />
              <path d="M6841 11264 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
              <path
                d="M6830 10932 c0 -10 -4 -22 -8 -27 -5 -6 -6 -37 -2 -70 4 -33 8 -47 9
            -32 0 16 6 38 12 49 8 15 8 19 0 14 -6 -4 -11 -3 -11 3 0 5 6 12 13 15 8 4 9
            8 1 11 -6 3 -9 17 -6 30 2 14 2 25 -2 25 -3 0 -6 -8 -6 -18z"
              />
              <path
                d="M6965 10484 c-7 -51 -3 -63 18 -43 22 23 27 49 14 69 -18 30 -24 25
            -32 -26z"
              />
              <path d="M6951 10504 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
              <path
                d="M3952 10242 c-9 -54 -9 -78 -1 -94 6 -11 9 -36 7 -54 -2 -19 -1 -34
            3 -34 10 0 15 93 9 180 l-5 75 -13 -73z"
              />
              <path d="M3952 10030 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
              <path d="M3942 9805 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z" />
              <path d="M6882 9420 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z" />
              <path d="M4671 3084 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
            </g>
          </svg>
        </div>
        <a href="#contact" className="scroll-down">
          Scroll Down
        </a>
        <HeaderSocials />
      </div>
    </header>
  );
};

export default Header;
