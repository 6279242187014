import React from "react";
import "./blog.css";

const Blog = () => {
  // return (
  //   <section id="blog">Blog</section>
  // )
};

export default Blog;
