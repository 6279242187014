import React from "react";
import "./about.css";
import { BsBook } from "react-icons/bs";
import picture1 from "../../assets/face-me/face-traits.png";

const About = () => {
  return (
    <section id="about">
      <div className="text">
        <h5>Get to know</h5>
        <h2>About Me</h2>
      </div>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={picture1} alt="my_face"></img>
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <BsBook className="about__icon" />
              <h5>Education</h5>
              <small>2 years studying code</small>
            </article>
            <article className="about__card">
              <BsBook className="about__icon" />
              <h5>Projects</h5>
              <small>40+ projects completed</small>
            </article>
          </div>
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ratione
            nesciunt, eveniet vel quibusdam porro ad, laboriosam officiis
            mollitia sint explicabo dolorum assumenda incidunt magnam. Eum
            reprehenderit illo omnis delectus id!
          </p>
          <a href="#contact" className="btn btn-primary">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
